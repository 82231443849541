import { PaginatedSelect } from "components";
import { useInfiniteMarketplaceFormulas } from "features/profitability";

export const MarketplaceFormulasFilter = ({
  store,
  name = "market_formula",
}) => {
  const {
    formulasOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteMarketplaceFormulas();

  return (
    <div>
      <label>Formula</label>

      <PaginatedSelect
        className="w-full mt-1"
        value={store.filter_by[name]}
        onChange={value => store.setFilter({ [name]: value })}
        searchValue={searchQuery}
        isLoading={isLoading}
        placeholder="Select Formula"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        options={formulasOptions}
        allowClear={false}
      />
    </div>
  );
};
