import { Pagination } from "antd";

export const InvoicesPagination = ({ controller, disabled }) => {
  return (
    <Pagination
      disabled={disabled}
      className="text-center py-2 mt-auto"
      onShowSizeChange={(_, pageSize) => controller.setPageSize(pageSize)}
      pageSizeOptions={[4, 8, 12, 24, 48]}
      showSizeChanger
      defaultCurrent={1}
      total={controller.total}
      current={controller.page}
      pageSize={controller.per_page}
      onChange={controller.setPage}
    />
  );
};
