import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { usePromotionsStore } from "features/promotions";
import { useDebounce } from "use-debounce";

const getPromotions = ({
  search_term = null,
  per_page = 12,
  page = 1,
  end_date = null,
  start_date = null,
  filter_by = null,
}) =>
  apiClient.get("api/marketplaces/promotion", {
    params: { per_page, page, search_term, end_date, start_date, filter_by },
  });

export const usePromotions = () => {
  const {
    page,
    pageSize,
    setPage,
    setTotal,
    searchQuery,
    // end_date,
    // start_date,
    filter_by,
  } = usePromotionsStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data: promotions, isLoading } = useQuery(
    [
      "promotions",
      page,
      pageSize,
      debouncedQuery,
      // end_date,
      // start_date,
      filter_by,
    ],
    () =>
      getPromotions({
        page: page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        // end_date,
        // start_date,
        filter_by,
      }),
    {
      onSuccess: sending => {
        const { last_page, total } = sending.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
      },
    },
  );
  return { promotions, isLoading };
};
