import { useState } from "react";
import { Modal, Button, message, Form, Input } from "antd";
import { useSendingApprove } from "features/sending";

export const Approve = ({ listId, disabled }) => {
  const [form] = Form.useForm();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { approve, isApproving } = useSendingApprove({
    listId,
    onSuccess: response => {
      if (response.message === "Wrong credentials") {
        message.error(response.message);
      } else {
        message.success(response.message || "Success");
        form.resetFields();
        setIsConfirmOpen(false);
      }
    },
  });

  return (
    <>
      <Button
        className="text-xs sm:text-sm"
        loading={isApproving}
        danger
        type="primary"
        disabled={disabled}
        onClick={() => setIsConfirmOpen(true)}
      >
        Approve Sending
      </Button>

      <Modal
        confirmLoading={isApproving}
        open={isConfirmOpen}
        title="Approving Credentials"
        onCancel={() => {
          if (!isApproving) {
            setIsConfirmOpen(false);
            form.resetFields();
          }
        }}
        onOk={form.submit}
      >
        <Form
          disabled={isApproving}
          className="mt-6"
          form={form}
          onFinish={approve}
          labelCol={{ span: 5 }}
          wrapperCol={{ flex: 1 }}
        >
          <Form.Item
            disabled={isApproving}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Email must be valid value",
              },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
