import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getStatuses = () => apiClient.get("api/sending/statuses");

export const useSendingStatuses = () => {
  const { data: statuses, isLoading: isLoadingStatuses } = useQuery(
    ["sending-statuses"],
    getStatuses,
  );
  return { statuses, isLoadingStatuses };
};
