import {
  PromotionSKUSelector,
  usePromotionSKUsStore,
} from "features/promotions";
import { Checkbox, Tooltip } from "antd";
import { SKULink } from "features/sales";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const getPromotionSKUsColumns = (promoId, disabled = false) => [
  {
    key: "checkboxes",
    dataIndex: "id",
    width: 40,
    title: <PromotionSKUSelector id={promoId} disabled={disabled} />,
    render: id => {
      return (
        <Checkbox
          disabled={disabled}
          checked={usePromotionSKUsStore.getState().isSelected(id)}
          onChange={({ target }) => {
            usePromotionSKUsStore.getState().setSelection(id, target.checked);
          }}
        />
      );
    },
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "Id",
    width: 120,
    align: "center",
  },
  {
    title: "SKU",
    key: "sku_number",
    width: 160,
    align: "center",
    render: (_, item) => {
      const emulatedDevice = {
        grade: {
          id: item.grade.id,
          name: item.grade.name,
          sku_display: item.grade.sku_display,
        },
        original_box: item.original_box,
        sku: { sku_id: item.sku.sku_id, sku_number: item.sku.sku_number },
      };

      return <SKULink device={emulatedDevice} />;
    },
  },
  {
    title: "Device",
    key: "device",
    dataIndex: "device",
    align: "center",
    render: (text, { sku }) => {
      const model =
        Object.values(sku.field_values).find(
          field => field.device_field.device_field_name === "Model",
        )?.device_field.device_field_option[0]?.value || "";

      const memory =
        Object.values(sku.field_values).find(
          field => field.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      const color = sku.color_shade?.name || "";

      const result = `${model} ${memory} ${color}`;

      return (
        <Tooltip placement="topLeft" title={result}>
          {result}
        </Tooltip>
      );
    },
    // width: "30%",
  },
  {
    title: "Original Box",
    key: "original_box",
    width: 120,
    align: "center",
    render: (_, { original_box }) =>
      original_box ? <CheckCircleOutlined className="text-green-500" /> : null,
  },
  {
    title: "Price",
    key: "sort_by_on_sale",
    dataIndex: "price",
    width: 120,
    align: "center",
    render: item => `€ ${item}`,
  },
  {
    title: "Afterbuy",
    dataIndex: "afterbuy",
    key: "afterbuy",
    width: 160,
    align: "center",
    render: (afterbuy, { target_link }) =>
      afterbuy &&
      target_link && (
        <Link to={target_link} target="_blank">
          {afterbuy}
        </Link>
      ),
  },
];
