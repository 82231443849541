import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getSKU = id => apiClient.get(`api/directory/skus/${id}`);

export const useSKU = ({ id, onSuccess, onError }) => {
  const { data: sku, isLoading } = useQuery(["sku", id], () => getSKU(id), {
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: err => {
      onError?.(err);
    },
    retry: false,
  });

  return { sku, isLoading };
};
