import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useSkuConnectedProductsStore } from "../store";

const getConnected = ({
  sku_id,
  grade_id,
  per_page = 12,
  page = 1,
  original_box = null,
}) =>
  apiClient.get(`api/sku-page/average/connected/${sku_id}/${grade_id}`, {
    params: { per_page, page, original_box },
  });

export const useSkuConnectedProducts = ({
  onSuccess,
  grade_id,
  sku_id,
  original_box = null,
} = {}) => {
  const { page, pageSize, setPage, setTotal } = useSkuConnectedProductsStore();
  const {
    data: connectedProducts,
    isLoading: isLoadingConnected,
    isFetching: isFetchingConnected,
  } = useQuery(
    ["sku-connected-products", sku_id, grade_id, original_box, page, pageSize],
    () =>
      getConnected({
        sku_id,
        grade_id,
        page,
        per_page: pageSize,
        original_box,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );
  return { connectedProducts, isLoadingConnected, isFetchingConnected };
};
