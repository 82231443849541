import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const SearchInput = ({
  searchQuery,
  setSearchQuery,
  disabled = false,
}) => {
  return (
    <Input
      className="w-64 text-xs sm:text-sm [&_.anticon]:text-red-400 hover:[&_.anticon]:text-red-600"
      placeholder="Enter Search Query"
      allowClear
      value={searchQuery}
      onChange={({ target }) => setSearchQuery(target.value)}
      prefix={<SearchOutlined style={{ color: "black" }} />}
      disabled={disabled}
    />
  );
};
