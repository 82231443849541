import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useInSaleStore } from "features/devices";
import { useDebounce } from "use-debounce";

const sortingNamesMap = {
  ascend: "ASC",
  descend: "DESC",
};

const getInSaleDevices = ({
  search_term = null,
  per_page = 12,
  page = 1,
  filter_by = null,
}) =>
  apiClient.get("api/product", {
    params: {
      per_page,
      page,
      search_term,
      filter_by: { ...filter_by, status: "ON_SALE" },
    },
  });

export const useInSaleDevices = ({ onSuccess, onError } = {}) => {
  const { page, setPage, pageSize, setTotal, searchQuery, filter_by, sorting } =
    useInSaleStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const getFilterQuery = () => {
    const result = { ...filter_by };
    for (const key in sorting) {
      if (Boolean(sorting[key])) {
        const validSortingName = sortingNamesMap[sorting[key]];
        result[key] = validSortingName;
        break;
      }
    }
    if ("color" in result) delete result.color;
    if (Object.keys(result).length === 0) return null;
    return result;
  };

  const {
    data: devices,
    isLoading,
    isFetching,
  } = useQuery(
    ["devices-in-sale", page, pageSize, debouncedQuery, filter_by, sorting],
    () =>
      getInSaleDevices({
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        filter_by: getFilterQuery(),
      }),
    {
      onSuccess: res => {
        const { per_page, current_page } = res.meta;
        const { next } = res.links;

        if (next) {
          setTotal(per_page * current_page + 1);
        } else if (!res.data.length) {
          setPage(1);
          setTotal(0);
        }

        onSuccess?.(res);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { devices, isLoading, isFetching };
};
