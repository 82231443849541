import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "../../../lib/react-query-client";

const remove = (id, data) =>
  apiClient.post(`api/marketplaces/promotion/${id}/remove-sku`, data);

export const useSKURemoving = ({ onSuccess, promoId } = {}) => {
  const { mutate: removeSKU, isLoading: isRemovingSKU } = useMutation(
    data => remove(promoId, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(["available-promotion-skus", promoId]);
        queryClient.invalidateQueries(["promotion-skus", promoId]);
        queryClient.invalidateQueries(["promotion-info", promoId]);
      },
    },
  );

  return { removeSKU, isRemovingSKU };
};
