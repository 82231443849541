import _ from "lodash";
import { Button, Card, Form, Radio } from "antd";
import { queryClient } from "lib/react-query-client";
import { InvoiceInput, useInvoiceExport } from "features/invoices";
import { StatusInput } from "features/devices";
import { requiredRule } from "constants/validation";

export const InvoiceExport = () => {
  const [form] = Form.useForm();
  const selectedStatuses = Form.useWatch("statuses", form);

  const { exportInvoice, isExporting } = useInvoiceExport();

  const onSubmit = data => exportInvoice(data);

  const allStatusesSet = Object.keys(
    queryClient.getQueryData("statuses")?.data || {},
  );

  return (
    <div className="flex-1 px-4">
      <Card
        title={<h1 className="text-center my-0">Invoice Export</h1>}
        className="max-w-xl m-auto mt-16"
      >
        <Form
          className="pt-4"
          form={form}
          requiredMark
          onFinish={onSubmit}
          disabled={isExporting}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
        >
          <InvoiceInput
            disabled={isExporting}
            rules={[requiredRule]}
            onClear={() => {
              form.resetFields(["invoice_id"]);
              form.validateFields(["invoice_id"]);
            }}
          />

          <div className="relative">
            <StatusInput
              fieldName="statuses"
              placeholder="Select one or more statuses"
              label="Statuses"
              multiple
              rules={[requiredRule]}
            />

            {!_.isEqual(allStatusesSet, selectedStatuses) && (
              <Button
                className="text-xs absolute right-0 top-0"
                type="link"
                onClick={() => {
                  const allStatusesSet = Object.keys(
                    queryClient.getQueryData("statuses")?.data || {},
                  );
                  form.setFieldValue("statuses", allStatusesSet);
                  form.validateFields(["statuses"]);
                }}
              >
                All
              </Button>
            )}
          </div>

          <Form.Item label="Selection" rules={[requiredRule]} name="format">
            <Radio.Group className="ml-2">
              <Radio value="by_device">By Device</Radio>
              <Radio value="by_sku">By SKU</Radio>
            </Radio.Group>
          </Form.Item>

          <div className="flex justify-center mt-8">
            <Button type="primary" htmlType="submit" loading={isExporting}>
              Export
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};
