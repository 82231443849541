import { PaginatedSelect } from "components";
import { Form } from "antd";
import { useInfiniteInvoiceProducts } from "features/invoices";
import { Device } from "features/devices";
import { useEffect } from "react";

export const InvoiceProductInput = ({
  currentInvoice,
  disabled,
  onClear,
  className,
  initialData,
}) => {
  const {
    productOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
    setInvoiceId,
  } = useInfiniteInvoiceProducts();

  const noInitialOption =
    initialData?.id &&
    !productOptions?.some(({ value }) => value === initialData?.id);

  useEffect(() => {
    setInvoiceId(currentInvoice);
  }, [currentInvoice]);

  return (
    <Form.Item
      className={className}
      label="Connected Invoice Product"
      name="invoice_product_id"
    >
      <PaginatedSelect
        disabled={disabled || !currentInvoice}
        searchValue={searchQuery}
        isLoading={isLoading}
        placeholder="Select Product"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        onClear={onClear}
        options={
          noInitialOption
            ? [Device.getInProductOption(initialData), ...productOptions]
            : productOptions
        }
      />
    </Form.Item>
  );
};
