import { Pagination, Table } from "antd";
import { DirectorySupplierLink } from "features/directory";

import _ from "lodash";

export const InvoicesTable = ({ store, data, isLoading, total = 0 }) => {
  return (
    <>
      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sticky={true}
        rowKey={() => _.uniqueId()}
        pagination={false}
        size="small"
        footer={() => (
          <span className="text-xs font-bold">{`Total: ${total}`}</span>
        )}
        scroll={{ x: 800 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        current={store.page}
        pageSize={store.per_page}
        onChange={store.setPage}
      />
    </>
  );
};

const columns = [
  {
    title: "Supplier",
    key: "supplier_name",
    align: "center",
    render: (_, { supplier_id, supplier_name }) => (
      <DirectorySupplierLink supplierId={supplier_id} label={supplier_name} />
    ),
  },
  {
    title: "Quantity",
    dataIndex: "count",
    key: "count",
    width: "25%",
    align: "center",
  },
];
