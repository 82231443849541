import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getDevice = ({ id, scanned }) =>
  apiClient.get(`api/product/${id}`, {
    params: { scanned },
    data: {},
  });

export const useDevice = ({ id, onSuccess, onError, scanned = null }) => {
  const {
    data: device,
    isLoading,
    isFetching,
  } = useQuery(["device", id], () => getDevice({ id, scanned }), {
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: err => {
      onError?.(err);
    },
    retry: false,
  });

  return { device, isLoading, isFetching };
};
