import { useMemo } from "react";
import { SKULink } from "features/sales";
import { RollbackOutlined } from "@ant-design/icons";

export const DeviceInfo = ({ device }) => {
  const fieldValues = useMemo(() => {
    if (!device) return;
    let result = [];

    Object.values(device?.field_values).forEach(field => {
      const key = field.device_field?.device_field_name;

      const isSelect =
        field.device_field.device_field_type.toUpperCase() === "SELECT";
      const isMultiSelect =
        field.device_field.device_field_type.toUpperCase() === "MULTISELECT";

      let value;
      if (isSelect) {
        value = field.device_field.device_field_option[0]?.value;
      } else if (isMultiSelect) {
        value = field.device_field.device_field_option
          ?.map(opt => opt?.value)
          ?.join(", ");
      } else {
        value = field.device_field.device_field_value;
      }

      result.push({ key, value });
    });

    return result;
  }, [device]);

  return (
    <div className="max-w-5xl w-full mx-auto px-5 my-6">
      <div className="mx-8 grid auto-rows-auto grid-cols-1 lg:grid-cols-3 gap-4">
        {device?.invoice?.invoice_number && (
          <span>
            WE:{" "}
            <span className="font-semibold">
              {device.return && (
                <RollbackOutlined className="text-blue-700 mr-0.5" />
              )}
              {device.invoice.invoice_number}
            </span>
          </span>
        )}

        {device?.grade && (
          <span>
            Grade: <span className="font-semibold">{device.grade.name}</span>
          </span>
        )}

        {device?.color?.name && (
          <span>
            Color: <span className="font-semibold">{device.color?.name}</span>
          </span>
        )}

        {device?.sku?.sku_number && (
          <span>
            SKU:{" "}
            <span className="font-semibold">
              <SKULink hideGrade device={device} />
            </span>
          </span>
        )}

        {!!fieldValues?.length &&
          fieldValues
            .filter(item => item.key !== "Model" && item.key !== "IMEI")
            .map(item => (
              <span key={item?.key}>
                {item?.key}:{" "}
                <span className="font-semibold">{item?.value}</span>
              </span>
            ))}

        {device?.invoice?.supplier && (
          <span className="whitespace-nowrap flex gap-1">
            Supplier:
            <span className="font-semibold ">
              {device.invoice.supplier.supplier_name || ""}
            </span>
            {device.invoice.first_supplier &&
              `(${device.invoice.first_supplier})`}
          </span>
        )}
      </div>
    </div>
  );
};
