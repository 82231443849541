import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

export const useAvgPriceExport = ({ onSuccess } = {}) => {
  const { mutate: exportAvgPrice, isLoading: isExporting } = useMutation(
    data => apiClient.post("api/faktura/export/products-average-price", data),
    {
      onSuccess: res => {
        onSuccess?.(res);
      },
    },
  );

  return { exportAvgPrice, isExporting };
};
