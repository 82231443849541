import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const addSKU = (id, marketPlaceId) => {
  return apiClient.post(`api/marketplaces/promotion/${id}/add-sku`, {
    sku_ids: [marketPlaceId],
  });
};

export const useAddPromoToSKU = ({ onSuccess, marketPlaceId } = {}) => {
  const { mutate: addPromo, isLoading: isAddingPromo } = useMutation(
    promoId => addSKU(promoId, marketPlaceId),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(["sku-marketplaces"]);
      },
    },
  );

  return { addPromo, isAddingPromo };
};
