import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, message } from "antd";
import { useSKUsExport } from "features/sales";

export const SKUsExport = () => {
  const { exportSKUs, isExporting } = useSKUsExport({
    onSuccess: () => {
      message.success({
        content: "Export in progress. The result will be sent to your email.",
        duration: 5,
        onClick: () => message.destroy(),
      });
    },
  });

  const items = [
    { onClick: () => exportSKUs(), key: "withSales", label: "With Sales" },
    { onClick: () => exportSKUs({ isAll: true }), key: "all", label: "All" },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} placement="bottom">
        <Button
          className="text-xs sm:text-sm"
          disabled={isExporting}
          loading={isExporting}
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Dropdown>
    </>
  );
};
