import { useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import _ from "lodash";
import clsx from "clsx";
import { Dropdown, Form, Input, Modal, Table, message } from "antd";
import { MoreOutlined, EditFilled } from "@ant-design/icons";

import { Pagination, SKU } from "components";
import { requiredRule } from "constants/validation";
import { useAttachIssueProduct } from "features/sales";

export const SalesIssuesTable = ({
  dataSource,
  store,
  isDisabled,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const [editedID, setEditedID] = useState(null);
  const openForm = id => setEditedID(id);
  const closeForm = () => {
    setEditedID(null);
    form.resetFields();
  };

  const { attachProduct, isAttaching } = useAttachIssueProduct({
    onSuccess: res => {
      message.success(res.message || "Success!");
      closeForm();
    },
  });

  const onSubmit = ({ product_id }) => {
    attachProduct({ productID: product_id, issueID: editedID });
  };

  const columns = useMemo(
    () => getColumns({ handleProductAttaching: openForm }),
    [],
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="overflow-y-auto"
        loading={isLoading}
        sticky={true}
        rowKey={record => record.id}
        scroll={{ x: 1000 }}
      />

      <Pagination store={store} disabled={isDisabled} className="mt-auto" />

      <Modal
        destroyOnClose
        getContainer={document.getElementById("root")}
        open={!!editedID}
        onCancel={closeForm}
        onOk={form.submit}
        title="Set product"
        confirmLoading={isAttaching}
      >
        <Form form={form} onFinish={onSubmit} disabled={isAttaching}>
          <Form.Item name="product_id" rules={[requiredRule]}>
            <Input autoFocus placeholder="Enter product id" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const getColumns = ({ handleProductAttaching }) => [
  {
    title: "Marketplace",
    key: "marketplace",
    dataIndex: ["marketplace", "marketplace"],
    render: (marketplace, { state }) => (
      <div className="flex justify-between gap-1">
        <span>{marketplace}</span>
        <StateTag state={state} />
      </div>
    ),
  },
  {
    title: "SKU",
    dataIndex: "marketplace",
    key: "sku",
    align: "center",
    width: 140,
    render: ({ sku, grade, original_box }) => {
      if (!sku) return null;
      const { label, url } = SKU.getLinkData({
        skuId: sku.sku_id,
        skuNumber: sku.sku_number,
        gradeId: grade?.id,
        gradeName: grade?.name,
        gradeSkuDisplay: grade?.sku_display,
        originalBox: original_box,
      });
      return (
        <Link target="_blank" to={url}>
          {label}
        </Link>
      );
    },
  },
  {
    title: "Price",
    align: "center",
    key: "price",
    dataIndex: "price",
    render: price => <span className="whitespace-nowrap">{`€ ${price}`}</span>,
    width: 120,
  },

  {
    title: "Quantity",
    align: "center",
    key: "quantity",
    dataIndex: "quantity",
    width: 100,
  },
  {
    title: "Order",
    key: "order",
    align: "center",
    dataIndex: "order",
    render: order => {
      if (!order) return "";
      return (
        <Link to={order?.order_link} target={"_blank"}>
          {order?.order_id}
        </Link>
      );
    },
  },
  {
    title: "Country",
    key: "country",
    align: "center",
    dataIndex: "order",
    render: order => (
      <div className="flex items-center gap-1.5 justify-center text-slate-600">
        {order?.country_code && (
          <ReactCountryFlag countryCode={order?.country_code} svg />
        )}
        {order?.country_code || ""}
      </div>
    ),
    width: 100,
  },
  {
    title: "Date",
    align: "center",
    key: "date",
    dataIndex: "date",
    width: 140,
    render: date => (
      <span className="whitespace-nowrap">
        {dayjs(date).format("YYYY-MM-DD H:mm")}
      </span>
    ),
  },
  {
    title: null,
    width: 46,
    render: (_, item) => {
      return (
        <Dropdown
          menu={{
            items: [
              {
                key: 0,
                label: "Set product",
                onClick: () => {
                  handleProductAttaching(item.id);
                },
                icon: <EditFilled />,
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined className={" hover:text-gray-400"} />
        </Dropdown>
      );
    },
  },
];

const StateTag = ({ state }) => {
  if (!state) return null;

  return (
    <span
      className={clsx("text-xs px-1.5 py-0.5 rounded", {
        "bg-green-100 text-green-800": state === "CONFIRM",
        "bg-yellow-200 text-lime-700": state === "NEW",
      })}
    >
      {_.capitalize(state)}
    </span>
  );
};
