import { apiClient } from "lib/api-client";
import { useMutation, useQuery } from "react-query";
import { useSKUPairListStore } from "features/sales";
import { useDebounce } from "use-debounce";
import { queryClient } from "lib/react-query-client";

//QUERIES

const getList = params =>
  apiClient.get("api/marketplaces/monitor/sku-pair/listing", {
    params,
  });

const create = data =>
  apiClient.post("api/marketplaces/monitor/sku-pair", data);

const edit = (id, data) =>
  apiClient.put(`api/marketplaces/monitor/sku-pair/${id}`, data);

const remove = id =>
  apiClient.delete(`api/marketplaces/monitor/sku-pair/${id}`, {});

const swap = id =>
  apiClient.post(`api/marketplaces/monitor/sku-pair/${id}/swap`, {});

// HOOKS

export const useSKUPairList = ({ onSuccess } = {}) => {
  const { page, per_page, setPage, setTotal, search_term } =
    useSKUPairListStore();
  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: pairs,
    isLoading,
    isFetching,
  } = useQuery(
    ["sku-pair-list", page, per_page, debouncedQuery],
    () =>
      getList({
        page,
        per_page,
        search_term: debouncedQuery || null,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        setTotal(total, last_page);
        onSuccess?.(data);
      },
      retry: false,
    },
  );

  return { pairs, isLoading, isFetching };
};

export const useCreatePair = ({ onSuccess } = {}) => {
  const { mutate: createPairList, isLoading: isCreating } = useMutation(
    data => create(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-pair-list");
      },
    },
  );

  return { createPairList, isCreating };
};

export const useEditPair = ({ onSuccess } = {}) => {
  const { mutate: editPairList, isLoading: isEditing } = useMutation(
    ({ id, data }) => edit(id, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-pair-list");
      },
    },
  );

  return { editPairList, isEditing };
};

export const useRemovePair = ({ onSuccess } = {}) => {
  const { mutate: removePairList, isLoading: isRemoving } = useMutation(
    id => remove(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-pair-list");
      },
    },
  );

  return { removePairList, isRemoving };
};

export const useSwapPairSKU = ({ onSuccess } = {}) => {
  const { mutate: swapSKUs, isLoading: isSwapping } = useMutation(
    id => swap(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-pair-list");
      },
    },
  );

  return { swapSKUs, isSwapping };
};
