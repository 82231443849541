import { Form, Select, Spin } from "antd";
import { useStatusOptions } from "features/devices";
import { useMemo } from "react";

export const StatusInput = ({
  rules,
  disabled,
  className = "",
  excluded = [],
  disabledOptions = [],
  fieldName = "status",
  placeholder = "Select Status",
  label = "Status",
  multiple = false,
}) => {
  const { statuses, isLoadingStatuses } = useStatusOptions();

  const options = useMemo(() => {
    if (!statuses?.data) return [];
    const result = Object.entries(statuses.data).map(([key, value]) => ({
      value: key,
      label: value,
      disabled: disabledOptions.some(opt => opt === key),
    }));
    return result.filter(
      ({ value }) => !excluded.some(status => status === value),
    );
  }, [statuses, excluded]);

  return (
    <Form.Item
      rules={rules}
      label={label}
      name={fieldName}
      className={className}
    >
      <Select
        mode={multiple ? "multiple" : null}
        className="w-full"
        showSearch
        allowClear
        disabled={disabled}
        notFoundContent={
          isLoadingStatuses ? (
            <div className="text-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        placeholder={placeholder}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        filterOption={(searchValue, { label }) =>
          label?.toLowerCase().includes(searchValue?.toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
};
