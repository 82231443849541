import { Button, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useComLists,
  useComListsStore,
  SendingDevicesTable,
  FilterLists,
  useSendingReadyList,
} from "features/sending";
import { SearchInput } from "components";

export const SendingDevices = () => {
  const navigate = useNavigate();
  const store = useComListsStore();

  const { comLists, isLoadingComLists } = useComLists();
  const { readyDevices } = useSendingReadyList();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center mb-6 sm:mb-10">Sending Devices</h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-4 mb-3 gap-2">
        <SearchInput
          searchQuery={store.searchQuery}
          setSearchQuery={store.setSearchQuery}
        />

        <FilterLists disabled={isLoadingComLists} store={store} />
        <Button
          disabled={!readyDevices?.data?.length}
          className="ml-auto after:content-['New'] sm:after:content-['New_Commission_List']
                      text-xs sm:text-sm"
          type="primary"
          onClick={() => navigate(`/sending/devices/ready`)}
        />
      </div>

      <SendingDevicesTable data={comLists} loading={isLoadingComLists} />
      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        current={store.page}
        pageSize={store.pageSize}
        onChange={store.setPage}
      />
    </div>
  );
};
