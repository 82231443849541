export const LogoShopify = ({ width = "100%", height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 60"
    >
      <path
        d="M37.072 19.164a.35.35 0 0 0-.313-.293c-.125-.02-2.87-.21-2.87-.21l-2.093-2.093c-.188-.21-.606-.148-.764-.104-.02 0-.418.125-1.067.336-.632-1.826-1.75-3.502-3.718-3.502h-.168c-.565-.73-1.255-1.067-1.84-1.067-4.584.02-6.77 5.738-7.46 8.66l-3.204.983c-.983.313-1.02.336-1.15 1.273L9.724 43.98l20.248 3.79 10.974-2.366c0-.02-3.853-26.04-3.873-26.238zm-8.23-2.012c-.51.168-1.1.336-1.716.525V17.3c.02-.942-.123-1.88-.418-2.776 1.026.158 1.716 1.31 2.134 2.628zm-3.37-2.386c.335.987.5 2.027.458 3.07v.188l-3.54 1.1c.693-2.587 1.97-3.866 3.08-4.347zm-1.36-1.273c.21.005.413.07.586.188-1.477.688-3.038 2.424-3.708 5.9l-2.8.858c.79-2.638 2.633-6.952 5.92-6.952z"
        fill="#95bf47"
      />
      <path
        d="M36.76 18.87c-.125-.02-2.87-.21-2.87-.21l-2.093-2.093c-.077-.08-.182-.124-.293-.125l-1.528 31.324 10.974-2.366-3.876-26.218c-.026-.16-.152-.288-.313-.313z"
        fill="#5e8e3e"
      />
      <path
        d="M26.246 24.942l-1.362 4.02c-.823-.4-1.724-.613-2.638-.63-2.137 0-2.24 1.34-2.24 1.676 0 1.83 4.795 2.534 4.795 6.848 0 3.392-2.157 5.57-5.047 5.57-3.476 0-5.256-2.157-5.256-2.157l.924-3.08s1.82 1.57 3.372 1.57a1.37 1.37 0 0 0 1.424-1.383c0-2.41-3.937-2.514-3.937-6.45 0-3.31 2.386-6.535 7.182-6.535 1.885.028 2.786.55 2.786.55z"
        fill="#fff"
      />
      <path d="M50.474 30.93c-.783-.42-1.188-.783-1.188-1.28 0-.63.55-1.037 1.443-1.037a5.12 5.12 0 0 1 1.925.421l.722-2.194s-.662-.512-2.602-.512c-2.706 0-4.586 1.55-4.586 3.728 0 1.232.872 2.18 2.044 2.856.947.55 1.28.914 1.28 1.488s-.466 1.053-1.353 1.053c-1.307 0-2.54-.676-2.54-.676l-.766 2.194s1.143.766 3.052.766c2.78 0 4.78-1.368 4.78-3.84.015-1.338-.993-2.27-2.2-2.977zm11.094-4.615c-1.368 0-2.45.647-3.278 1.646l-.046-.015 1.188-6.216h-3.095L53.33 37.56h3.097l1.037-5.412c.406-2.044 1.463-3.308 2.45-3.308.69 0 .962.466.962 1.143 0 .46-.046.917-.135 1.368l-1.172 6.194h3.097l1.218-6.4c.133-.663.21-1.336.225-2.01-.015-1.744-.932-2.796-2.54-2.796zm9.53 0c-3.728 0-6.194 3.368-6.194 7.1 0 2.406 1.488 4.33 4.27 4.33 3.657 0 6.134-3.278 6.134-7.1 0-2.225-1.307-4.33-4.205-4.33zm-1.52 9.065c-1.053 0-1.503-.9-1.503-2.01 0-1.774.914-4.675 2.602-4.675 1.097 0 1.463.947 1.463 1.865-.004 1.905-.92 4.82-2.56 4.82zm13.65-9.065c-2.1 0-3.278 1.828-3.278 1.828h-.046l.183-1.646h-2.743l-.63 4.105-2.15 11.336h3.083l.858-4.586h.06s.63.406 1.82.406c3.638 0 6.013-3.728 6.013-7.496.015-2.075-.914-3.938-3.172-3.938zm-2.962 9.11a1.93 1.93 0 0 1-1.28-.452l.512-2.902c.366-1.925 1.368-3.218 2.45-3.218.947 0 1.232.872 1.232 1.7.005 2.017-1.18 4.873-2.91 4.873zm10.57-13.56c-.474-.003-.93.185-1.263.522s-.518.794-.5 1.268c0 .914.587 1.55 1.463 1.55h.046a1.75 1.75 0 0 0 1.819-1.79c0-.9-.602-1.55-1.55-1.55zM86.51 37.53h3.097l2.104-10.97h-3.108m11-.015H97.44l.106-.512c.183-1.053.812-2 1.828-2a3.37 3.37 0 0 1 .993.15l.598-2.423s-.55-.27-1.684-.27a4.63 4.63 0 0 0-3.037 1.037c-1.053.9-1.55 2.194-1.8 3.503l-.1.512h-1.443l-.452 2.33h1.443l-1.646 8.66h3.108l1.646-8.66h2.136l.42-2.33zm7.458.015l-2.812 7.547h-.046l-.766-7.547h-3.264l1.865 10.072a.71.71 0 0 1-.06.512c-.405.747-.982 1.386-1.684 1.865-.538.37-1.125.664-1.744.872l.858 2.63a7.1 7.1 0 0 0 3.031-1.689c1.413-1.324 2.72-3.368 4.06-6.15l3.788-8.118h-3.227z" />
    </svg>
  );
};
