import { Button, Tooltip } from "antd";
import {
  useDevice,
  DeviceInfo,
  Steps,
  useActivities,
  Comments,
  CommentForm,
  DeviceLogs,
  DeviceAccessories,
} from "features/devices";
import { FireFilled, PrinterFilled } from "@ant-design/icons";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "components";

import { useMemo, useRef } from "react";
import { globalErrorHandler } from "lib/react-query-client";
import { DeviceStatus, EditDevice } from "features/devices";
import { useReactToPrint } from "react-to-print";
import { QRLabel } from "features/invoices";
import { useAuthStore } from "features/auth";
import _ from "lodash";

export const DevicePage = () => {
  let componentRef = useRef();
  const { deviceId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isRight, user } = useAuthStore();

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });

  const { device, isLoading } = useDevice({
    id: deviceId,
    scanned: Boolean(searchParams.get("scanned")) ? 1 : null,
    onError: err => {
      globalErrorHandler(err);
      console.warn(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { activities, isActivitiesLoading } = useActivities({
    id: deviceId,
  });

  const title = useMemo(() => {
    let result = `Device #${deviceId}`;

    if (device?.data.device_type) {
      result = `${device?.data.device_type?.name} #${deviceId}`;
    }

    if (device?.data.field_values) {
      const model = Object.values(device?.data.field_values).find(
        item => item.device_field?.device_field_name === "Model",
      )?.device_field.device_field_option?.[0]?.value;

      if (model) result = `${device?.data.device_type?.name}: ${model}`;
    }

    return result;
    // eslint-disable-next-line
  }, [device]);

  const imei = useMemo(() => {
    if (device?.data.field_values) {
      return Object.values(device?.data.field_values).find(
        item => item.device_field?.device_field_name === "IMEI",
      )?.device_field.device_field_value;
    }
  }, [device]);

  const canEdit = isRight([
    "admin",
    "sales_manager",
    "sender",
    "head_of_flasher",
  ]);

  const canSeeLogs = isRight(["admin", "head_of_flasher"]);

  const statusAfterRegistration = ![
    "ENTERED",
    "IDENTIFIED",
    "GRADED",
    "GRADE_HOLD",
  ].includes(device?.data.step_of_process);

  return (
    <div className="flex flex-col h-full relative">
      <h2
        className={`flex justify-center items-center gap-2 flex-wrap px-12 text-center text-lg md:text-xl ${
          imei ? "mb-1" : ""
        }`}
      >
        {device?.data.priority && <FireFilled className="text-red-400" />}
        {title}
      </h2>

      {imei && (
        <span className="text-center text-sm text-gray-500">IMEI: {imei}</span>
      )}

      <div className="flex justify-center gap-2 p-2">
        <Tooltip title="print">
          <Button
            size="small"
            onClick={() => {
              console.log(device?.data?.qr);
              handlePrint();
            }}
            disabled={!device}
          >
            <PrinterFilled />
          </Button>
        </Tooltip>

        {canEdit && (
          <EditDevice initialDeviceData={device?.data} deviceId={deviceId} />
        )}

        {!_.isEqual(user.data.roles, ["visor"]) && (
          <CommentForm disabled={!device} deviceId={deviceId} />
        )}

        {canSeeLogs && <DeviceLogs disabled={!device} deviceId={deviceId} />}
      </div>

      {device && (
        <main className="overflow-y-auto flex flex-col max-w-5xl w-full mx-auto px-4 mb-3">
          <DeviceStatus deviceData={device.data} />
          <DeviceInfo device={device.data} />
          <Steps
            deviceData={device.data}
            activities={activities}
            isActivitiesLoading={isActivitiesLoading}
          />
          {statusAfterRegistration && (
            <DeviceAccessories
              device={device.data}
              className="lg:mx-10 mx-0 md:mt-20 mt-2 text-center font-bold"
            />
          )}
          <Comments activities={activities} />
        </main>
      )}

      {isLoading && <Loader />}

      <div style={{ display: "none" }}>
        <div className="m-0 p-0" ref={el => (componentRef = el)}>
          <QRLabel
            priority={device?.data?.priority}
            url={device?.data?.qr}
            id={device?.data?.product_id}
          />
        </div>
      </div>
    </div>
  );
};
