import { Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {
  RollbackOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

export const InvoicesTable = ({ data, loading }) => {
  const navigate = useNavigate();

  return (
    <Table
      className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
      rowClassName="hover:cursor-pointer"
      loading={loading}
      columns={columns}
      dataSource={data?.data}
      sticky={true}
      rowKey={record => record.id}
      pagination={false}
      onRow={record => ({
        onClick: () => navigate(`/invoices/manage/${record.id}`),
      })}
      scroll={{ x: 900 }}
    />
  );
};

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Supplier",
    dataIndex: "supplier_name",
    key: "supplier_name",
    render: (text, record) => <span>{record.supplier?.supplier_name}</span>,
  },
  {
    title: "Invoice Number",
    dataIndex: "invoice_number",
    key: "invoice_number",
  },
  {
    title: "Return",
    dataIndex: "return",
    key: "return",
    render: (isReturn, item) => {
      return isReturn ? (
        <Tooltip className="flex gap-2" title={item.return_list?.status}>
          <RollbackOutlined className="text-blue-700" />

          {item.return_list?.status === "MAPPING" ? (
            <LoadingOutlined className="text-yellow-900" />
          ) : item.return_list?.status === "MAPPED" ? (
            <CheckCircleOutlined className="text-green-500" />
          ) : null}
        </Tooltip>
      ) : null;
    },
    align: "center",
    width: 90,
  },
  {
    title: "Grade",
    dataIndex: "grades[]",
    key: "grades[]",
    render: (text, record) => (
      <span>{record.grades.map(grade => grade.name).join(", ")}</span>
    ),
  },
  {
    title: "Date",
    dataIndex: "invoice_date",
    key: "invoice_date",
    render: invoice_date => <span>{invoice_date.split("T")[0]}</span>,
  },
  {
    title: "Quantity of Prior Devices",
    dataIndex: "priority_total",
    key: "priority_total",
    children: [
      {
        title: () => (
          <span className="whitespace-nowrap text-xs">Not Processed</span>
        ),
        align: "center",
        render: ({ priority_total }) => {
          const { quantity_of_not_processed_prior_devices } = priority_total[0];
          return <span>{quantity_of_not_processed_prior_devices}</span>;
        },
        width: 110,
      },
      {
        title: () => (
          <span className="whitespace-nowrap text-xs">Processing</span>
        ),
        align: "center",
        render: ({ priority_total }) => {
          const { quantity_of_processing_prior_devices } = priority_total[0];
          return <span>{quantity_of_processing_prior_devices}</span>;
        },
        width: 90,
      },
      {
        title: () => (
          <span className="whitespace-nowrap text-xs">Processed</span>
        ),
        align: "center",
        render: ({ priority_total }) => {
          const { quantity_of_processed_prior_devices } = priority_total[0];
          return <span>{quantity_of_processed_prior_devices}</span>;
        },
        width: 90,
      },
    ],
  },
];
