import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const gradeDevice = (id, data) => {
  return apiClient.put(`api/product/grade/${id}`, data);
};

export const useGrading = (id, onSuccess) => {
  const { mutate: grade, isLoading: isGrading } = useMutation(
    data => gradeDevice(id, data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        await queryClient.invalidateQueries(["device", id]);
        queryClient.invalidateQueries(["activities", id]);
        onSuccess?.(data);
      },
    },
  );

  return { grade, isGrading };
};
