import { Statistic } from "antd";
import { ClockCircleOutlined, EuroOutlined } from "@ant-design/icons";
import { SkuChips } from "features/sales";

export const SkuInfo = ({ sku, original_box }) => {
  if (!sku) return null;

  return (
    <div>
      <div className="flex gap-4 flex-wrap justify-center">
        <SkuChips label="SKU" value={sku?.data?.sku.sku_number} />
        <SkuChips label="Grade" value={sku?.data?.grade.name} />
        {sku?.data?.grade.id === 1 && (
          <SkuChips
            label="Original Box"
            value={original_box === "1" ? "Yes" : "No"}
            color={original_box === "1" ? "#178adc" : "#920070"}
          />
        )}
        <SkuChips label="Color" value={sku?.data?.sku.color_shade.name} />

        {sku.data?.sku.field_values &&
          Object.values(sku.data.sku.field_values).map(({ device_field }) => {
            const {
              device_field_id,
              device_field_name,
              device_field_value,
              device_field_option,
            } = device_field;

            return (
              <SkuChips
                key={device_field_id}
                label={device_field_name}
                value={device_field_value || device_field_option[0]?.value}
              />
            );
          })}
      </div>

      <div className="flex justify-center gap-4 mt-10">
        <div className="bg-white px-4 py-1 rounded-lg">
          <Statistic
            title="Ready for Sale"
            className="w-24"
            value={sku?.data?.products.ready_for_sale}
            valueStyle={{ color: "#3f8600" }}
            prefix={<ClockCircleOutlined />}
          />
        </div>

        <div className="bg-white px-4 py-1 rounded-lg">
          <Statistic
            title="On Sale"
            className="w-24"
            value={sku?.data?.products.in_sale}
            valueStyle={{ color: "#1661b1" }}
            prefix={<EuroOutlined />}
          />
        </div>
      </div>
    </div>
  );
};
