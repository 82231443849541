import { PaginatedSelect } from "components";
import { Form } from "antd";
import { useInfiniteGrades } from "features/directory";
import { Device } from "features/devices";

export const GradeInput = ({
  disabled,
  onClear,
  rules,
  className,
  initialData,
}) => {
  const {
    gradeOptions,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    setSearchQuery,
    searchQuery,
  } = useInfiniteGrades();

  const noInitialOption =
    !searchQuery &&
    initialData?.id &&
    !gradeOptions?.some(({ value }) => value === initialData?.id);

  return (
    <Form.Item
      rules={rules}
      label="Grade"
      name="grade_id"
      className={className}
    >
      <PaginatedSelect
        disabled={disabled}
        isLoading={isLoading}
        placeholder="Select Grade"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        searchValue={searchQuery}
        onSearch={setSearchQuery}
        onClear={onClear}
        options={
          noInitialOption
            ? [Device.getGradeOption(initialData), ...gradeOptions]
            : gradeOptions
        }
      />
    </Form.Item>
  );
};
