import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const useSearchDevicesStore = create(
  devtools(
    immer((set, get) => ({
      filter_by: {},
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      selectedToPrint: [],
      selectToPrint: rows =>
        set(state => {
          state.selectedToPrint = rows;
        }),
      clearSelection: () =>
        set(state => {
          state.selectedToPrint = [];
        }),
      setSearchQuery: searchQuery =>
        set(state => {
          state.searchQuery = searchQuery;
        }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (value === null || value === undefined) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clearFilters: () => {
        set(state => {
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useInSaleStore = create(
  devtools(
    immer((set, get) => ({
      sorting: {
        sort_by_sale_date: null,
      },
      filter_by: {},
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSorting: (key, value) =>
        set(state => {
          state.sorting[key] = value;
        }),
      setSearchQuery: searchQuery =>
        set(state => {
          state.searchQuery = searchQuery;
        }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (value === null || value === undefined) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clearFilters: () => {
        set(state => {
          state.filter_by = {};
        });
      },
      clearWithoutStatus: () => {
        set(state => {
          state.filter_by = { status: state.filter_by.status };
        });
      },
    })),
  ),
);

export const useReadyForSaleStore = create(
  devtools(
    immer((set, get) => ({
      sorting: {
        sort_by_ready_for_sale: null,
        sort_by_on_sale: null,
      },
      filter_by: {},
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSorting: (key, value) =>
        set(state => {
          if (key === "sort_by_ready_for_sale")
            state.sorting.sort_by_on_sale = null;
          if (key === "sort_by_on_sale")
            state.sorting.sort_by_ready_for_sale = null;

          state.sorting[key] = value;
        }),
      setSearchQuery: searchQuery =>
        set(state => {
          state.searchQuery = searchQuery;
        }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (value === null || value === undefined) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clearFilters: () => {
        set(state => {
          state.filter_by = {};
        });
      },
      clearWithoutStatus: () => {
        set(state => {
          state.filter_by = { status: state.filter_by.status };
        });
      },
    })),
  ),
);

export const useDeviceHistoryStore = create(
  devtools(
    immer((set, get) => ({
      called: false,
      page: 1,
      total: 0,
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      allowQueries: () => set({ called: true }),
    })),
  ),
);

export const useDeviceAccessoryHistoryStore = create(
  devtools(
    immer((set, get) => ({
      called: false,
      page: 1,
      total: 0,
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      allowQueries: () => set({ called: true }),
    })),
  ),
);

export const usePackingAccessoriesStore = create(
  devtools(
    immer((set, get) => ({
      ids: [],
      setIds: ids =>
        set(state => {
          state.ids = ids;
        }),
      toggleOne: id =>
        set(state => {
          if (state.ids.includes(id)) {
            state.ids = state.ids.filter(existingId => existingId !== id);
          } else {
            state.ids.push(id);
          }
        }),
      reset: () => set({ ids: [] }),
    })),
  ),
);
