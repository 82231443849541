import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const moveInSale = product_ids =>
  apiClient.post("api/product/in-sale", {
    product_ids,
    status: "ON_SALE",
  });

export const useMoveInSale = onSuccess => {
  const { mutate: move, isLoading: isMoving } = useMutation(
    ids => moveInSale(ids),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["devices"]);
        queryClient.invalidateQueries(["devices-ready"]);
        onSuccess?.(data);
      },
    },
  );

  return { move, isMoving };
};
