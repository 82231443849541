import { Checkbox } from "antd";
import { queryClient } from "lib/react-query-client";
import { useReadyListSelection } from "../store";

export const ReadyListSelector = () => {
  const {
    page,
    pageSize,
    searchQuery,
    filter_by,
    start_date,
    end_date,
    selection,
    setSelection,
  } = useReadyListSelection();

  const currentPageIds = queryClient
    .getQueryData([
      "sending-ready-list",
      page,
      pageSize,
      searchQuery,
      filter_by,
      start_date,
      end_date,
    ])
    ?.data?.map(({ product_id }) => product_id);

  const isSelectedAll =
    !!currentPageIds?.length &&
    currentPageIds?.every(i => selection.includes(i));
  const isSelectedSome = currentPageIds?.some(i => selection.includes(i));

  return (
    <Checkbox
      checked={isSelectedAll}
      indeterminate={isSelectedSome && !isSelectedAll}
      onChange={({ target }) => {
        setSelection(currentPageIds, target.checked);
      }}
    />
  );
};
