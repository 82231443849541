import { globalErrorHandler } from "lib/react-query-client";
import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { usePromotionSKUsStore } from "../store";
import { useState } from "react";

const getSKUs = ({ promoId, search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get(`api/marketplaces/promotion/${promoId}/sku-listing`, {
    params: {
      per_page,
      page,
      search_term,
    },
  });

export const usePromotionSKUs = ({ promoId, onSuccess, onError } = {}) => {
  const [allowed, setAllowed] = useState(false);
  const allowSKUs = () => setAllowed(true);

  const { page, pageSize, setPage, setTotal, searchQuery } =
    usePromotionSKUsStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: skus,
    isLoading,
    isFetching,
  } = useQuery(
    ["promotion-skus", promoId, page, pageSize, debouncedQuery],
    () =>
      getSKUs({
        promoId,
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      enabled: allowed,
    },
  );

  return { skus, isLoading, isFetching, allowSKUs };
};
