import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const identifyDevice = (id, fieldValues) => {
  return apiClient.put(`api/product/identified/${id}`, fieldValues);
};

export const useIdentifyDevice = ({ id, onSuccess } = {}) => {
  const { mutate: identify, isLoading: isIdentifying } = useMutation(
    fieldValues => identifyDevice(id, fieldValues),
    {
      onSuccess: async data => {
        onSuccess?.(data);
        await queryClient.invalidateQueries(["device", String(id)]);
        queryClient.invalidateQueries(["activities", String(id)]);
      },
    },
  );

  return { identify, isIdentifying };
};
