import { apiClient } from "lib/api-client";
import { useQuery, useInfiniteQuery } from "react-query";
import { useColorsSelection } from "features/directory";
import { useDebounce } from "use-debounce";
import { useMemo, useState } from "react";

const getColors = ({ search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get("api/directory/colors", {
    params: { per_page, page, search_term },
  });

export const useColors = onSuccess => {
  const { page, pageSize, setPage, setTotal } = useColorsSelection();

  const {
    data: colors,
    isLoading,
    isFetching,
  } = useQuery(
    ["colors", page, pageSize],
    () =>
      getColors({
        page: page,
        per_page: pageSize,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );

  return { colors, isLoading, isFetching };
};

const infinite_getColors = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteColors = onSuccess => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-colors", debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/directory/colors?per_page=12&page=1`,
      }) =>
        infinite_getColors({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const colorOptions = useMemo(
    () =>
      data?.pages
        ? data?.pages
            .map(({ data }) =>
              data?.map(({ name, id }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            )
            ?.flat()
        : [],
    [data],
  );

  const shadeOptions = useMemo(() => {
    if (!data?.pages) return [];

    const colors = data.pages.map(({ data }) => data)?.flat();
    const shades = {};
    for (const color of colors) {
      shades[color.id] = color.shades?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
    return shades;
  }, [data]);

  const colors = data?.pages?.map(({ data }) => data)?.flat();

  return {
    colors,
    colorOptions,
    shadeOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
