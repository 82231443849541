import { Select } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useInfiniteColors } from "features/directory";
import { PaginatedSelect } from "components";

export const ColorFilter = ({ store }) => {
  const { setFilter, filter_by } = store;
  const {
    colors,
    isFetchingNextPage: isFetchingNextColors,
    isLoading: isColorsLoading,
    fetchNextPage: fetchNextColors,
    setSearchQuery: searchColor,
    searchQuery: searchingColor,
  } = useInfiniteColors();

  const colorOptions = colors?.map(({ id, name, shades }) => ({
    value: id,
    label: name,
    shades: shades.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  }));

  const currentColor = colorOptions?.find(
    ({ value }) => value === filter_by.color,
  );

  return (
    <div className="flex">
      <div className=" flex-1">
        <label>Color</label>
        <PaginatedSelect
          className="w-full mt-1"
          localSearch
          value={filter_by.color}
          // disabled={isFlashing}
          searchValue={searchingColor}
          isLoading={isColorsLoading}
          placeholder="Select Color"
          fetchNextPage={fetchNextColors}
          isFetchingNextPage={isFetchingNextColors}
          onSearch={searchColor}
          onChange={e => {
            setFilter({ color_id: null });
            setFilter({ color: e });
          }}
          onClear={() => {
            setFilter({ color_id: null });
            setFilter({ color: null });
          }}
          options={colorOptions}
          filterOption={(searchValue, { children }) =>
            children?.toLowerCase().includes(searchValue?.toLowerCase())
          }
        />
      </div>

      <div className="flex items-end m-2">
        <CaretRightOutlined className="text-slate-300" />
      </div>

      <div className=" flex-1">
        <label>Shade</label>
        <Select
          className="w-full mt-1"
          showArrow
          showSearch
          value={filter_by.color_id}
          disabled={!filter_by.color}
          allowClear
          placeholder="Select Shade"
          options={currentColor?.shades}
          onChange={color_id => setFilter({ color_id })}
          filterOption={(searchValue, { label }) =>
            label?.toLowerCase().includes(searchValue?.toLowerCase())
          }
        />
      </div>
    </div>
  );
};
