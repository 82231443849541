export const LogoIdealo = ({ width = "100%", height }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 584 406.2"
    >
      <path
        fill="#123274"
        d="M92,142.2h20.7v16.4H92V142.2z M92,167.2h20.7v56.6H92V167.2z M124.9,188c-0.2-3.5,0.1-7,0.6-10.4
c0.6-3.1,2.1-6,4.3-8.3c2.2-2.2,5.4-3.9,9.6-5.1c4.2-1.2,9.9-1.8,17-1.8h5.1c2.3,0,4.6,0.2,6.8,0.6c2.1,0.3,4.2,1,6.2,1.9
c1.8,0.9,3.4,2.2,4.7,3.7c1.3,1.7,2.1,3.7,2.3,5.8l0.1-32.1h20.6v80.9h-19.4v-10.9c-0.6,1.7-1.6,3.2-2.7,4.5
c-1.1,1.5-2.5,2.8-4.1,3.8c-2,1.1-4.1,1.9-6.3,2.4c-2.7,0.6-5.4,0.8-8.1,0.8h-5c-7.1,0-12.8-0.6-17-1.8c-4.2-1.2-7.4-2.9-9.7-5.2
c-2.2-2-3.7-4.7-4.3-7.6c-0.6-3.4-0.8-6.9-0.6-10.4V188z M144.9,196.7c0,1.5,0.1,3.1,0.4,4.6c0.2,1.3,0.7,2.4,1.6,3.4
c1,1,2.2,1.7,3.6,2.1c2.2,0.6,4.4,0.8,6.6,0.7h11.2c2,0,4-0.2,6-0.5c1.6-0.2,3.1-0.8,4.4-1.8c1.2-0.9,2.1-2.1,2.6-3.5
c0.7-1.8,1-3.8,0.9-5.7V190c0.1-1.9-0.2-3.9-0.9-5.7c-0.5-1.4-1.4-2.6-2.6-3.5c-1.3-0.9-2.8-1.5-4.4-1.7c-2-0.3-4-0.5-6-0.5H157
c-2.2-0.1-4.5,0.2-6.6,0.7c-1.4,0.3-2.6,1.1-3.6,2.1c-0.8,1-1.4,2.1-1.6,3.4c-0.3,1.5-0.4,3.1-0.4,4.6L144.9,196.7z M267.5,207.4
c-0.3,3.1-3.3,3.7-8.1,3.7h-14.3c-4.9,0-10.2-2.4-10.2-7.7v-4.1h53v-10c0-19.9-9.1-27-32.1-27h-15.2c-15.6,0-26.2,6.9-26.2,28.7v5.6
c0,24.5,14.2,27.2,28.6,27.2h20.6c17.9,0,24.3-3.6,24.5-16.4L267.5,207.4z M235,184.6c0-6.3,2.5-9.6,10.4-9.6h12.2
c7.9,0,10.4,3.3,10.4,9.6v2.7h-33L235,184.6L235,184.6z M296,202.4c0-15.8,22.5-15.1,24.5-15.1h16.3c4.2,0,9.9,1.4,11.9,3.5l0.4,0.4
v-6.5c0-6.3-2.4-9.7-10.2-9.7h-12.3c-6.6,0-10.2,0.9-10.2,3.8h-18.6c0-12.3,9.9-16.4,25.8-16.4h24.1c14.4,0,21.8,7.6,21.8,21.4v39.4
H349v-7.7l-1.2,2.1c-3.5,5-6.1,6.1-16.6,6.1h-14.5c-12.8,0-20.7-2.7-20.7-16.4L296,202.4z M341.3,211.8c5.7,0,7.9-2.3,7.9-6.5
c0-7.3-1.8-8.3-12.9-8.5h-9.9c-8.2-0.1-10.2,2-10.3,6.4c-0.1,6.7,2.1,8.5,10.3,8.5L341.3,211.8z M381.8,223.8h20.7v-81.5h-20.7
L381.8,223.8z M414.1,185.8c0-19.5,12.9-23.5,24.2-23.5h29.5c11.3,0,24.2,4,24.2,23.5v14.6c0,19.5-12.9,23.5-24.2,23.5h-29.6
c-11.3,0-24.2-4-24.2-23.5L414.1,185.8z M434.1,196.5c0,1.5,0.1,3.1,0.4,4.6c0.2,1.3,0.7,2.5,1.6,3.4c1,1,2.3,1.7,3.6,2.1
c2.2,0.6,4.5,0.8,6.8,0.7h11.3c2.1,0,4.1-0.2,6.1-0.5c1.6-0.2,3.2-0.8,4.5-1.8c1.2-0.9,2.1-2,2.6-3.4c0.7-1.9,1-3.8,0.9-5.8v-5.8
c0.1-2-0.2-3.9-0.9-5.8c-0.6-1.4-1.5-2.6-2.7-3.5c-1.3-0.9-2.9-1.5-4.5-1.8c-2-0.3-4.1-0.5-6.1-0.5h-11.3c-2.3-0.1-4.6,0.2-6.8,0.7
c-1.4,0.3-2.7,1-3.6,2.1c-0.8,1-1.4,2.2-1.6,3.4c-0.3,1.5-0.4,3.1-0.4,4.6L434.1,196.5z"
      />
      <path fill="#FF6600" d="M92,264h400v-16.4H92V264z" />
    </svg>
  );
};
