import { PaginatedSelect } from "components";
import { Form } from "antd";
import { useInfiniteInvoices } from "features/invoices";
import { Device } from "features/devices";

export const InvoiceInput = ({
  className,
  disabled,
  onClear,
  initialData,
  rules,
}) => {
  const {
    invoicesOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteInvoices();

  const noInitialOption =
    initialData &&
    !invoicesOptions?.some(({ value }) => value === initialData?.invoice_id);

  return (
    <Form.Item
      label="Invoice"
      className={className}
      name="invoice_id"
      rules={rules}
    >
      <PaginatedSelect
        disabled={disabled}
        searchValue={searchQuery}
        isLoading={isLoading}
        placeholder="Select Invoice"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        onClear={onClear}
        options={
          noInitialOption
            ? [Device.getInvoiceOption(initialData), ...invoicesOptions]
            : invoicesOptions
        }
      />
    </Form.Item>
  );
};
