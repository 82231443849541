import { Radio } from "antd";

const filterName = "connect";

export const ConnectingFilter = ({ store }) => {
  return (
    <div>
      <label>Connected</label>

      <Radio.Group
        className="w-full mt-1"
        onChange={e => {
          store.setFilter({ [filterName]: e.target.value });
        }}
        value={store.filter_by[filterName]}
      >
        <Radio value={undefined}>All</Radio>
        <Radio value={"CONNECTED"}>Yes</Radio>
        <Radio value={"DISCONNECTED"}>No</Radio>
      </Radio.Group>
    </div>
  );
};
