import { Dropdown } from "antd";
import { styles } from "features/directory";

export const SelectType = ({ disabled = false, value, onChange }) => {
  return (
    <Dropdown
      className={disabled ? "hover:cursor-default" : "hover:cursor-pointer"}
      disabled={disabled}
      trigger={["click"]}
      menu={{
        items: optionsConfig,
        onClick: ({ item }) => onChange(item.props.value),
      }}
    >
      <div
        className={styles.form_item_dropdown}
        style={{
          color: disabled ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.88)",
        }}
      >
        {value}
      </div>
    </Dropdown>
  );
};

const optionsConfig = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Checkbox",
    value: "checkbox",
  },
  {
    label: "Select",
    value: "select",
  },
  {
    label: "Multiselect",
    value: "multiselect",
  },
];
