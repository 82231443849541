import { useGrades, useGradesSelection } from "features/directory";
import { Collapse, Button, Switch, Pagination } from "antd";
import { Loader } from "components";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const GradeDirectory = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin"]);
  const { grades, isLoading } = useGrades();
  const goTo = useNavigate();
  const { page, pageSize, setPage, total, setPageSize } = useGradesSelection();

  return (
    <div className="flex flex-col h-full relative">
      <h2 className="text-center">Grades</h2>

      {grades && (
        <div className="overflow-y-auto max-w-5xl w-full mx-auto px-4 mt-5">
          <Collapse accordion>
            {grades.data.map(grade => (
              <Collapse.Panel header={grade.name} key={grade.id}>
                <div className="flex justify-between">
                  <div className="flex gap-3 items-center">
                    Show in SKU
                    <Switch disabled checked={grade.sku_display} />
                  </div>

                  {rightRole && (
                    <Button
                      onClick={() => goTo(`/directory/grades/${grade.id}`)}
                    >
                      Edit
                    </Button>
                  )}
                </div>
                <p>{grade.description}</p>

                {!!grade.additional_options.length && (
                  <>
                    <span>Additional options:</span>
                    <ul className="mt-1 mb-0">
                      {grade.additional_options.map((item, i) => (
                        <li key={i}>{item.name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      )}

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading}
      />
      {isLoading && <Loader />}
    </div>
  );
};
