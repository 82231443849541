import { useMemo } from "react";
import _ from "lodash";
import { DeleteFilled } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import { Button, Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import { PaginatedSelect } from "components";
import { useMarketplaceNames } from "features/marketplaces";
import { useInfiniteGrades } from "features/directory";
import { requiredRule } from "constants/validation";
import { DeviceTypeMultiSelect } from "features/devices";

const { TextArea } = Input;

export const MarketplacesFormulaForm = ({
  form,
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  formulaData,
  onDelete,
}) => {
  const formGrades = useWatch("grades", form);

  const { marketplaceOptions, isLoadingMarketplaces } = useMarketplaceNames();

  const {
    gradeOptions,
    isFetchingNextPage,
    isLoading: isLoadingGradeOptions,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteGrades();

  const preparedOptions = useMemo(() => {
    if (!formulaData) return gradeOptions;
    const existingOpts = formulaData?.grades.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const diff = _.differenceWith(existingOpts, gradeOptions, _.isEqual);
    return [...diff, ...gradeOptions];
  }, [gradeOptions, formulaData]);

  return (
    <Modal
      title={formulaData ? "Edit Formula" : "Create Formula"}
      open={isOpen}
      onCancel={() => !isLoading && onClose()}
      confirmLoading={isLoading}
      width={640}
      footer={
        <div className={"flex"}>
          {formulaData && (
            <Button
              danger
              className={"ml-1"}
              icon={<DeleteFilled />}
              onClick={onDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          )}

          <div className={"ml-auto"}>
            <Button onClick={() => !isLoading && onClose()}>Cancel</Button>
            <Button type={"primary"} onClick={form.submit} loading={isLoading}>
              Ok
            </Button>
          </div>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={isLoading}
        className={"mt-6"}
        initialValues={{ difference_price: 0 }}
      >
        <Form.Item
          required
          name={"title"}
          label={"Title"}
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          required
          name={"marketplace"}
          label={"Marketplace"}
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <Select
            loading={isLoadingMarketplaces}
            placeholder={"Select Marketplace"}
            options={marketplaceOptions.filter(({ value }) => value !== "EBAY")}
          />
        </Form.Item>

        <Form.Item
          required
          name={"expression_greater"}
          label={"Expression Greater"}
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <TextArea className="font-mono" autoSize />
        </Form.Item>

        <Form.Item
          required
          name={"expression_less"}
          label={"Expression Less"}
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <TextArea className="font-mono" autoSize />
        </Form.Item>

        <DeviceTypeMultiSelect
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
          name="device_types"
          initialData={formulaData?.device_types}
          label="Device Type"
          placeholder="Select Device Type"
          disabled={isLoading}
          onSelect={e => {
            let current = form.getFieldValue("device_types");
            if (!current) current = [];
            form.setFieldValue("device_types", [...current, e]);
          }}
          onDeselect={e => {
            const current = form.getFieldValue("device_types");
            form.setFieldValue(
              "device_types",
              current.filter(item => item !== e),
            );
          }}
          onClear={() => {
            form.setFieldValue("device_types", []);
          }}
        />

        <Form.Item
          name={"grades"}
          label={"Grades"}
          valuePropName="fake"
          initialValue={[]}
          required
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <PaginatedSelect
            className={"w-full"}
            value={formGrades}
            mode="multiple"
            isLoading={isLoadingGradeOptions}
            placeholder="Select Grade"
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            searchValue={searchQuery}
            onSearch={setSearchQuery}
            onDeselect={value => {
              form.setFieldValue(
                "grades",
                formGrades.filter(item => item !== value),
              );
            }}
            onChange={value => {
              form.setFieldValue("grades", [...formGrades, value]);
            }}
            onClear={() => form.setFieldValue("grades", [])}
            options={preparedOptions}
            disabled={isLoading}
          />
        </Form.Item>

        <Form.Item labelCol={{ span: 6 }} label={"Difference price"}>
          <Form.Item className={"inline-block"} name={"difference_price"}>
            <InputNumber
              type="number"
              className={"inline-block"}
              addonBefore="€"
              min={0}
            />
          </Form.Item>

          <Form.Item
            initialValue={true}
            name={"active"}
            className={"inline-block ml-20"}
            label={"Active"}
            valuePropName="checked"
          >
            <Switch defaultChecked={true} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};
