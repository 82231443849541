import { Button, App } from "antd";
import { useRestorePassword, styles } from "features/auth";

export const PasswordRecovery = ({ form }) => {
  const { modal, message } = App.useApp();

  const { restore } = useRestorePassword({
    onSuccess: () => {
      const email = form.getFieldValue("email");
      message.success(`You will receive a new password in ${email} shortly.`);
    },
  });

  const onSubmit = async () => {
    const email = form.getFieldValue("email");
    try {
      await restore({ email });
    } catch (err) {
      console.error(err);
    }
  };

  const onFirstClick = () => {
    const email = form.getFieldValue("email");
    const error = form.getFieldError("email");
    const isValidEmail = !!email && !error.length;

    if (isValidEmail) {
      modal.confirm({
        title: "Password recovery",
        content: "Want to ask the administrator for a new password?",
        onOk: onSubmit,
      });
    } else {
      modal.warning({ title: "First enter your email!" });
    }
  };

  return (
    <Button
      type="link"
      block
      className={styles.recovery_button}
      onClick={onFirstClick}
    >
      Forgot password?
    </Button>
  );
};
