import { Input } from "antd";
import { RemoveBtn } from "./RemoveBtn";
import { useFormConstructor } from "features/directory";
import { ModelOptionMenu } from "./ModelOptionMenu";

export const SelectOption = ({
  readOnly = false,
  showOptionMenu = false,
  showCategories = false,
  onChange,
  onRemove,
  onEnter,
  disabled,
  fieldIndex,
  index,
}) => {
  const option = useFormConstructor(
    state => state.fields[fieldIndex]?.options[index],
  );

  const actionComponent = showOptionMenu ? (
    <ModelOptionMenu
      className="absolute -right-5 top-2"
      showCategories={showCategories}
      model={option}
      onRemove={onRemove}
    />
  ) : (
    <RemoveBtn
      disabled={disabled}
      className="absolute opacity-10 hover:opacity-100 -right-5"
      onClick={onRemove}
    />
  );

  return (
    <div align="middle" className="relative">
      <Input
        className="text-xs h-8"
        classNames={{
          input: readOnly
            ? "hover:border-slate-300 hover:cursor-default text-gray-500"
            : "",
        }}
        readOnly={readOnly}
        disabled={disabled}
        placeholder="Enter Option"
        value={option?.value}
        onChange={onChange}
        onPressEnter={onEnter}
        onFocus={e => readOnly && e.target.blur()}
      />

      {!readOnly && actionComponent}
    </div>
  );
};
