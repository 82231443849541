import {
  useProductDevices,
  useConnectedDevicesStore,
  QUERY_NAMES,
  ProductsTable,
  ProfitStats,
  ProductsToolbar,
  ProfitabilityPage,
} from "features/profitability";

export const ConnectedDevices = () => {
  const store = useConnectedDevicesStore();

  const {
    data: devices,
    isFetching,
    isLoading,
  } = useProductDevices({
    store,
    groupName: "connected",
    queryName: QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
  });

  const isProcessing = !devices || isLoading || isFetching;

  return (
    <ProfitabilityPage
      store={store}
      title="Connected Products by Devices"
      preloadFormula
    >
      <ProfitStats
        data={devices}
        keys={[
          "total_count",
          "total_profit",
          "total_invoice_price",
          "total_sale_price",
          "total_formula_price",
        ]}
      />

      <ProductsToolbar
        store={store}
        disabled={isProcessing}
        isLoading={isProcessing}
        allowExport={!!devices?.data.length}
        groupName="connected"
      />

      <ProductsTable
        isLoading={isProcessing}
        queryName={QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED}
        data={devices?.data}
        idKey="product_id"
        store={store}
      />
    </ProfitabilityPage>
  );
};
