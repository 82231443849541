import { Button, Checkbox, InputNumber, Space } from "antd";
import { useEffect, useState } from "react";

export const SalesFilter = ({ store }) => {
  const { filter_by, setFilter } = store;
  const [localDays, setLocalDays] = useState(1);

  const onChangeChecked = ({ target }) => {
    if (!filter_by.day_period) setFilter({ day_period: localDays });
    setFilter({ [target.name]: target.checked || null });
  };

  const inActive = !filter_by.without_sold && !filter_by.without_updates;

  useEffect(() => {
    setLocalDays(filter_by.day_period || 1);
  }, [inActive]);

  if (!store) return null;

  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-1">
        <Checkbox
          checked={filter_by.without_sold}
          name="without_sold"
          onChange={onChangeChecked}
        >
          Unsold
        </Checkbox>

        <Checkbox
          name="without_updates"
          checked={filter_by.without_updates}
          onChange={onChangeChecked}
        >
          No Price Update
        </Checkbox>
      </div>

      <div className="flex flex-col text-slate-500 w-1/2">
        <span className="text-xs ml-2 -mt-1 mb-[2px] flex items-center">
          For Last Days
          {!inActive && (
            <strong
              className="text-white ml-1 bg-blue-500 rounded px-1 leading-tight"
              style={{ fontSize: 10 }}
            >
              {filter_by.day_period}
            </strong>
          )}
        </span>
        <Space.Compact className="w-full">
          <InputNumber
            className="w-full"
            min={1}
            onBlur={({ target }) => {
              if (!target.value || isNaN(+target.value)) {
                setLocalDays(filter_by.day_period || 1);
              }
            }}
            value={localDays}
            onChange={setLocalDays}
            disabled={!filter_by.without_sold && !filter_by.without_updates}
          />

          <Button
            disabled={
              inActive || !localDays || localDays === filter_by.day_period
            }
            className="text-xs"
            type="primary"
            onClick={() => {
              if (localDays === filter_by.day_period) {
                store.setFilter({ day_period: 1 });
              } else {
                store.setFilter({ day_period: localDays });
              }
            }}
          >
            Apply
          </Button>
        </Space.Compact>
      </div>
    </div>
  );
};
