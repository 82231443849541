import { Button, Form, Input, Alert, Card, App, InputNumber } from "antd";
import {
  useMarketplaceConfigInfo,
  useSaveMarketplaceConfig,
  useUpdateMarketplaceConfig,
  useRemoveMarketplaceConfig,
  BMCountryItem,
} from "features/marketplaces";
import { Loader } from "components";
import { useEffect } from "react";
import _ from "lodash";
import { requiredRule } from "constants/validation";
import { ExcludeSKUsField } from "features/directory";

const marketKey = "backmarket";

export const BackMarketConfig = () => {
  const { modal, message } = App.useApp();
  const [form] = Form.useForm();

  const { info, isLoadingInfo, isFetchingInfo } = useMarketplaceConfigInfo({
    marketKey,
  });

  const { saveConfig, isSaving } = useSaveMarketplaceConfig({
    marketKey,
    onSuccess: () => {},
  });

  const { updateConfig, isUpdating } = useUpdateMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  const { remove, isRemoving } = useRemoveMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  useEffect(() => {
    if (info) {
      form.setFieldValue("auth_token", info.data.auth_token);
      form.setFieldValue("host", info.data.host);
      form.setFieldValue("sek_currency", info.data.sek_currency);
      form.setFieldValue("jp_skus", info.data.jp_skus || []);

      for (let i = 0; i < info.data.countries_setting?.length; i++) {
        form.setFieldValue(
          ["countries_setting", i, "price"],
          info.data.countries_setting[i].price,
        );
        form.setFieldValue(
          ["countries_setting", i, "min_price"],
          info.data.countries_setting[i].min_price,
        );
        form.setFieldValue(
          ["countries_setting", i, "increase"],
          info.data.countries_setting[i].increase,
        );

        form.setFieldValue(
          ["countries_setting", i, "currency"],
          info.data.countries_setting[i].currency,
        );
        form.setFieldValue(
          ["countries_setting", i, "country_code"],
          info.data.countries_setting[i].country_code,
        );
        form.setFieldValue(
          ["countries_setting", i, "country_name"],
          info.data.countries_setting[i].country_name,
        );
      }
    }
    // eslint-disable-next-line
  }, [info]);

  const noConfig = _.isEmpty(info?.data);
  const anyProcessing =
    isSaving || isUpdating || isRemoving || isFetchingInfo || isLoadingInfo;

  return (
    <div className="flex flex-col max-w-2xl w-full mx-auto relative h-full overflow-hidden pb-6">
      <h2 className="text-center">BackMarket</h2>

      {info?.data && noConfig && (
        <Alert
          className="mb-5 mt-6"
          type="error"
          message="First you need create config"
        />
      )}

      {info?.data?.is_connect && (
        <Alert
          className="mb-12 mt-8 "
          type="success"
          showIcon
          message="Connected"
        />
      )}

      {info?.data.is_connect === false && (
        <Alert
          className=" mb-5 mt-6"
          type="error"
          message="Need to update config"
        />
      )}

      <Card title="Config Settings" className="overflow-auto">
        <Form
          form={form}
          requiredMark
          onFinish={noConfig ? saveConfig : updateConfig}
          disabled={anyProcessing}
          initialValues={{ sek_currency: 0 }}
        >
          <Form.Item label="Token" name="auth_token" rules={[requiredRule]}>
            <Input placeholder="Enter Auth Token" />
          </Form.Item>

          <Form.Item label="Host" name="host" rules={[requiredRule]}>
            <Input placeholder="Enter Host" />
          </Form.Item>

          <Form.Item
            label="SEK Currency "
            name="sek_currency"
            rules={[requiredRule]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>

          <ExcludeSKUsField
            className="mb-2 mt-8"
            form={form}
            fieldName={"jp_skus"}
            label="Set 9999 price for JP SKUs (Germany, Austria, Italy)"
            initialValue={info?.data?.jp_skus}
            isLoading={isUpdating || isSaving}
          />

          <div className="overflow-y-auto flex-1">
            {info?.data?.countries_setting && (
              <>
                <Alert
                  className="mt-10 mb-6"
                  description=" If you edit the increases to price for any of
                  country, each SKU page in the system should be updated (saved)
                  to apply the price changes on Backmarket."
                  type="warning"
                  showIcon
                />
                <header className="flex justify-between items-center mb-3  font-semibold">
                  <span className="w-32">Country</span>
                  <span className="flex-1 text-center">Price</span>
                  <span className="flex-1 text-center">Min Price</span>
                  <span>Increase</span>
                </header>
              </>
            )}

            {info?.data?.countries_setting?.map((item, i) => (
              <BMCountryItem
                data={item}
                form={form}
                key={item.country_name}
                index={i}
              />
            ))}
          </div>

          <div className="flex justify-end gap-2 mt-4">
            {noConfig ? (
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Create
              </Button>
            ) : (
              <>
                <Button
                  danger
                  loading={isRemoving}
                  onClick={() => {
                    modal.confirm({
                      title: "Config Deleting",
                      content: "Are you sure you want to delete the config?",
                      onOk: () => remove(),
                    });
                  }}
                >
                  Delete
                </Button>

                <Button htmlType="submit" loading={isUpdating}>
                  Update
                </Button>
              </>
            )}
          </div>

          {(isLoadingInfo || isFetchingInfo) && <Loader />}
        </Form>
      </Card>
    </div>
  );
};
