import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Form, Input, Alert, Card, App, Divider } from "antd";
import {
  useMarketplaceConfigInfo,
  useSaveMarketplaceConfig,
  useUpdateMarketplaceConfig,
  useRemoveMarketplaceConfig,
  useEbayAuthLink,
  useGenerateEbayToken,
} from "features/marketplaces";
import { Loader } from "components";
import { requiredRule } from "constants/validation";

const marketKey = "ebay";

export const EbayMarketplace = () => {
  const { modal, message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [form] = Form.useForm();

  const [needReconnect, setNeedReconnect] = useState(false);

  const { saveConfig, isSaving } = useSaveMarketplaceConfig({ marketKey });

  const { isLoadingAuthLink, generateEbayAuthLink } = useEbayAuthLink({
    onSuccess: data => {
      window.open(data?.data, "_self");
      message.success(data.message || "Success");
    },
  });

  const { info, isLoadingInfo, isFetchingInfo } = useMarketplaceConfigInfo({
    marketKey,
    onError: err => {
      if (err.response?.data?.message === 'Undefined array key "token"') {
        setNeedReconnect(true);
      }
    },
  });

  const { generateToken, isGeneratingToken } = useGenerateEbayToken(data =>
    message.success(data.message || "Success"),
  );

  const { updateConfig, isUpdating } = useUpdateMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  const { remove, isRemoving } = useRemoveMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  useEffect(() => {
    if (code) {
      generateToken(code);
      setSearchParams({});
    }
    // eslint-disable-next-line
  }, [code]);

  useEffect(() => {
    if (info) {
      form.setFieldValue("app_id", info.data.app_id);
      form.setFieldValue("dev_id", info.data.dev_id);
      form.setFieldValue("cert_id", info.data.cert_id);
      form.setFieldValue("partner_token", info.data.partner_token);
      form.setFieldValue("account_token", info.data.account_token);
    }
    // eslint-disable-next-line
  }, [info]);

  const noConfig = _.isEmpty(info?.data);
  const anyProcessing =
    isSaving ||
    isUpdating ||
    isRemoving ||
    isFetchingInfo ||
    isLoadingInfo ||
    isLoadingAuthLink ||
    isGeneratingToken;

  return (
    <div className="flex flex-col max-w-lg w-full mx-auto relative">
      <h2 className="text-center">eBay</h2>

      {info?.data && noConfig && (
        <Alert
          className="mb-5 mt-6"
          type="error"
          message="First you need create config"
        />
      )}

      {info?.data?.is_connect && (
        <Alert
          className="mb-12 mt-8 "
          type="success"
          showIcon
          message="Connected"
        />
      )}

      {needReconnect && (
        <>
          <Alert
            className=" mb-5 mt-6"
            type="error"
            message="Need reconnect."
          />

          <Button
            className="mx-auto my-10 block"
            type="primary"
            loading={isLoadingAuthLink}
            onClick={generateEbayAuthLink}
          >
            RECONNECT
          </Button>
        </>
      )}

      {info?.data && !noConfig && !info?.data?.is_connect && (
        <>
          <Alert
            className=" mb-5 mt-6"
            type="error"
            message="You need to be authenticated on eBay."
          />

          <Button
            className="mx-auto my-10 block"
            type="primary"
            onClick={generateEbayAuthLink}
            loading={anyProcessing}
          >
            LOGIN TO EBAY
          </Button>
        </>
      )}

      <Card title="Config Settings">
        <Form
          form={form}
          // layout="vertical"
          requiredMark
          onFinish={noConfig ? saveConfig : updateConfig}
          disabled={anyProcessing}
        >
          <Form.Item label="App id" name="app_id" rules={[requiredRule]}>
            <Input placeholder="Enter app id" />
          </Form.Item>

          <Form.Item label="Dev id" name="dev_id" rules={[requiredRule]}>
            <Input placeholder="Enter dev id" />
          </Form.Item>

          <Form.Item label="Cert id" name="cert_id" rules={[requiredRule]}>
            <Input placeholder="Enter cert id" />
          </Form.Item>

          <Divider>
            <h5 className="text-center">AfterBuy</h5>
          </Divider>
          <Form.Item
            label="Partner Token"
            labelCol={{ span: 6 }}
            name="partner_token"
            rules={[requiredRule]}
          >
            <Input placeholder="Enter token" />
          </Form.Item>

          <Form.Item
            label="Account Token"
            labelCol={{ span: 6 }}
            name="account_token"
            rules={[requiredRule]}
          >
            <Input placeholder="Enter token" />
          </Form.Item>

          <div className="flex justify-end gap-2">
            {noConfig ? (
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Create
              </Button>
            ) : (
              <>
                <Button
                  danger
                  loading={isRemoving}
                  onClick={() => {
                    modal.confirm({
                      title: "Config Deleting",
                      content: "Are you sure you want to delete the config?",
                      onOk: () => remove(),
                    });
                  }}
                >
                  Delete
                </Button>

                <Button htmlType="submit" loading={isUpdating}>
                  Update
                </Button>
              </>
            )}
          </div>

          {(isLoadingInfo || isFetchingInfo) && <Loader />}
        </Form>
      </Card>
    </div>
  );
};
