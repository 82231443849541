import { EditFilled, PlusOutlined, ReloadOutlined } from "@ant-design/icons";

export const HoverActionSheet = ({
  onEdit,
  onReconnectEbay,
  onDuplicateEbay,
  marketplace,
  onDuplicateAmazon,
}) => {
  return (
    <div className="absolute right-0 top-0 pt-1 flex gap-0.5 items-center w-14 justify-center">
      {marketplace.marketplace === "EBAY" && (
        <>
          <span
            onClick={onDuplicateEbay}
            className="w-4 flex justify-center items-center rounded-full bg-blue-600 text hover:cursor-pointer active:bg-violet-900 hover:bg-violet-700"
            style={{ width: 16, height: 16 }}
          >
            <PlusOutlined style={{ fontSize: 10 }} className="text-white" />
          </span>

          <span
            onClick={onReconnectEbay}
            className="w-4 flex justify-center items-center rounded-full bg-blue-600 text hover:cursor-pointer active:bg-violet-900 hover:bg-violet-700"
            style={{ width: 16, height: 16 }}
          >
            <ReloadOutlined style={{ fontSize: 10 }} className="text-white" />
          </span>
        </>
      )}

      {marketplace.marketplace === "AMAZON" && !marketplace.amazon_rn && (
        <span
          onClick={onDuplicateAmazon}
          className="w-4 flex justify-center items-center rounded-full bg-blue-600 text hover:cursor-pointer active:bg-violet-900 hover:bg-violet-700"
          style={{ width: 16, height: 16 }}
        >
          <PlusOutlined style={{ fontSize: 10 }} className="text-white" />
        </span>
      )}

      <EditFilled
        className="hover:cursor-pointer text-blue-600 hover:text-violet-700 ml-1"
        onClick={onEdit}
      />
    </div>
  );
};
