import { Link } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const SKULink = ({ device }) => {
  const { isRight } = useAuthStore();

  const disabled = !isRight(["admin", "sales_manager"]);

  const gradeLabel =
    device?.grade?.sku_display && device?.grade?.sku_display !== "0"
      ? device?.grade?.name + "-"
      : "";
  const original_box = device.original_box;
  const nv = device.grade?.id === 1 && !original_box ? "-NV" : "";
  const nvParam = original_box
    ? "?original_box=1"
    : original_box === false
    ? "?original_box=0"
    : "";

  if (disabled || !device?.grade?.id)
    return (
      <span className="whitespace-nowrap">
        {gradeLabel + device.sku?.sku_number + nv}
      </span>
    );

  return (
    <Link
      className="whitespace-nowrap"
      to={`/sales/sku/${device.sku?.sku_id}/${device.grade?.id}${nvParam}`}
    >
      {gradeLabel + device.sku?.sku_number + nv}
    </Link>
  );
};
