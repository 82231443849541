import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useEmployeesStore } from "features/employees";
import { useDebounce } from "use-debounce";

const getEmployees = ({ search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get("api/users", { params: { search_term, per_page, page } });

export const useEmployees = () => {
  const { page, pageSize, setPage, setTotal, searchQuery } =
    useEmployeesStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data: employees, isLoading } = useQuery(
    ["employees", debouncedQuery, page, pageSize],
    () =>
      getEmployees({
        search_term: debouncedQuery || null,
        page,
        per_page: pageSize,
      }),
    {
      onSuccess: employees => {
        const { last_page, total } = employees.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
      },
    },
  );

  return { employees, isLoading };
};
