import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getSuperUsers = () => apiClient.get("api/users/super-users");

export const useSuperUsers = ({ onSuccess, enabled = true } = {}) => {
  const { data, isLoading } = useQuery(["superusers"], getSuperUsers, {
    onSuccess: data => {
      onSuccess?.(data);
    },
    enabled,
    refetchOnmount: false,
  });

  return { superUsers: data?.data, isLoading };
};
