import { StarFilled } from "@ant-design/icons";
import { formatRole } from "utils/formatRole";
import { Tag } from "antd";
import { useMediaQuery } from "react-responsive";

export const RoleTag = ({ role }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 639px)" });

  if (!role) return null;

  if (isMobile)
    return (
      <span
        className="text-white text-xs"
        style={{
          fontSize: 7,
          textTransform: "uppercase",
          color: role === "admin" ? "#ffb020" : "#ddd",
          lineHeight: 0.4,
          textWrap: "nowrap",
        }}
      >
        {formatRole(role)}
      </span>
    );

  return (
    <Tag
      style={{ margin: 0 }}
      icon={role === "admin" ? <StarFilled /> : null}
      color="#3b5999"
    >
      {formatRole(role)}
    </Tag>
  );
};
