import { useInfiniteDeviceTypes } from "features/directory";
import { PaginatedSelect } from "components";
import { Form } from "antd";
import { Device } from "features/devices";

export const DeviceTypeInput = ({
  className,
  rules,
  disabled,
  onClear,
  initialData,
  label = "Device Type",
  placeholder = "Select Device Type",
}) => {
  const { deviceTypeOptions, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteDeviceTypes();

  const noInitialOption =
    initialData?.id &&
    !deviceTypeOptions?.some(({ value }) => value === initialData?.id);

  return (
    <Form.Item
      className={className}
      label={label}
      name="device_type_id"
      rules={rules}
    >
      <PaginatedSelect
        localSearch
        isLoading={isLoading}
        placeholder={placeholder}
        disabled={disabled}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onClear={onClear}
        options={
          noInitialOption
            ? [Device.getDevTypeOption(initialData), ...deviceTypeOptions]
            : deviceTypeOptions
        }
        filterOption={(searchValue, { children }) =>
          children?.toLowerCase().includes(searchValue?.toLowerCase())
        }
      />
    </Form.Item>
  );
};
