import { useAuthStore } from "features/auth";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";
import { resetAllStores } from "lib/zustand-poxy";

export const onLogout = () => {
  apiClient.post("api/auth/logout");
  resetAllStores();
  useAuthStore.getState().clear();
  queryClient.clear();
};
