import React, { useState } from "react";
import { Loader, SearchInput } from "components";
import { useQueryClient } from "react-query";
import { Space, Button, Row, Col } from "antd";
import {
  EmployeeCard,
  useEmployees,
  useEmployeesStore,
  CreateEmployee,
  EmployeesPagination,
  styles,
} from "features/employees";

export const Employees = () => {
  const queryClient = useQueryClient();
  const employeesStore = useEmployeesStore();
  const { employees, isLoading } = useEmployees();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openForm = () => setModalIsOpen(true);
  const closeForm = () => setModalIsOpen(false);

  const onSearch = query => {
    employeesStore.setSearchQuery(query);
    queryClient.invalidateQueries(["employees"]);
  };

  return (
    <div className={styles.employees_container}>
      <Row wrap className={styles.employees_header}>
        <Col sm={8} xs={24}>
          <h2>Employees</h2>
        </Col>
        <Col className={styles.employees_toolbar} sm={16} xs={24}>
          <Space className={styles.employees_toolbar_inner}>
            <SearchInput
              searchQuery={employeesStore.searchQuery}
              setSearchQuery={onSearch}
            />

            <Button onClick={openForm}>Add Employee</Button>
          </Space>
        </Col>
      </Row>

      <div className={styles.employees_cards}>
        {employees &&
          employees.data.map(user => (
            <div className={styles.employees_card} key={user.id}>
              <EmployeeCard data={user} />
            </div>
          ))}

        {isLoading && <Loader />}
      </div>

      <EmployeesPagination />
      <CreateEmployee open={modalIsOpen} onClose={closeForm} />
    </div>
  );
};
