import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";
import { useState } from "react";

const addColor = ({ option_id, color_shade_id }) =>
  apiClient.post(
    `api/directory/devices/model/${option_id}/color/${color_shade_id}`,
  );

export const useAddModelColor = ({ onSuccess, deviceId } = {}) => {
  const { mutate: addModelColor, isLoading: isAddingModelColor } = useMutation(
    ({ option_id, color_shade_id }) => addColor({ option_id, color_shade_id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["device-type", deviceId]);
        onSuccess?.();
      },
    },
  );

  return { addModelColor, isAddingModelColor };
};

const removeColor = ({ option_id, color_shade_id }) =>
  apiClient.delete(
    `api/directory/devices/model/${option_id}/color/${color_shade_id}`,
  );

export const useRemoveModelColor = ({ onSuccess, deviceId } = {}) => {
  const [removingId, setRemovingId] = useState(null);

  const { mutate: remove, isLoading: isRemoving } = useMutation(
    ({ option_id, color_shade_id }) => {
      setRemovingId(color_shade_id);
      removeColor({ option_id, color_shade_id });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["device-type", deviceId]);
        setRemovingId(null);
        onSuccess?.();
      },
    },
  );

  return { remove, isRemoving, removingId };
};
