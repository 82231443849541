import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const changeOption = ({ product_ids, repair_option }) => {
  return apiClient.post(`api/product/batch-change-repair-option`, {
    product_ids,
    repair_option,
  });
};

export const useRepairWithOption = onSuccess => {
  const { mutate: repairWithOption, isLoading: isRepairing } = useMutation(
    data => changeOption(data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        onSuccess?.(data);
      },
    },
  );

  return { repairWithOption, isRepairing };
};
