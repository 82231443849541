import { useRoles, useRoleEmployees } from "features/employees";
import { PaginatedSelect } from "components";
import { useEffect, useMemo } from "react";
import { useStatisticsStore } from "../store";
import { useMediaQuery } from "react-responsive";

export const SelectEmployee = ({ roleName }) => {
  const store = useStatisticsStore();
  const isTightly = useMediaQuery({
    query: "(max-width: 960px)",
  });

  const { roles, isRolesLoading } = useRoles();
  const { setRoleId, roleUsers, isFetchingNextPage, isLoading, fetchNextPage } =
    useRoleEmployees();

  useEffect(() => {
    if (roles) setRoleId(roles[roleName]);
  }, [roles, roleName]);

  const options = useMemo(
    () =>
      roleUsers?.map(({ id, first_name, last_name }) => ({
        value: id,
        label: first_name + " " + last_name,
      })),
    [roleUsers],
  );

  return (
    <PaginatedSelect
      status={store.by_user_id ? "error" : null}
      size={isTightly ? "small" : "middle"}
      className="min-w-40  text-red-200"
      options={options || []}
      fetchNextPage={fetchNextPage}
      isLoading={isRolesLoading || isLoading}
      isFetchingNextPage={isFetchingNextPage}
      onChange={id => store.setUserId(id)}
      placeholder="Select employee"
      value={store.by_user_id}
      onClear={() => store.setUserId(null)}
    />
  );
};
