import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

const createPromotion = data =>
  apiClient.post("api/marketplaces/promotion", data);

export const useNewPromotion = ({ onSuccess } = {}) => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => createPromotion(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { create, isCreating };
};
