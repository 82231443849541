import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const TextQueryFilter = ({ store }) => {
  return (
    <div>
      <label>Search</label>
      <Input
        className="w-full mt-1"
        placeholder="Enter Search query"
        allowClear
        value={store.searchQuery}
        onChange={({ target }) => store.setSearchQuery(target.value)}
        prefix={<SearchOutlined style={{ color: "black" }} />}
      />
    </div>
  );
};
