import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getSupplier = id => apiClient.get(`api/directory/suppliers/${id}`);

export const useSupplier = ({ id, onSuccess, onError }) => {
  const { data: supplier, isLoading: isSupplierLoading } = useQuery(
    ["supplier", id],
    () => getSupplier(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
      retry: false,
    },
  );

  return { supplier, isSupplierLoading };
};
