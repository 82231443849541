import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const reconnectMarketplace = id =>
  apiClient.post(`api/sku/marketplaces/reconnect/${id}`);

export const useReconnect = ({ onSuccess }) => {
  const { mutate: reconnect, isLoading: isReconnecting } = useMutation(
    id => reconnectMarketplace(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { reconnect, isReconnecting };
};
