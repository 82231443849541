import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const replaceDevice = ({ listId, exist_product_id, change_product_id }) => {
  return apiClient.post(
    `api/sending/commission-list/${listId}/replace-device`,
    {
      exist_product_id,
      change_product_id,
    },
  );
};

export const useReplaceDevice = ({ listId, onSuccess } = {}) => {
  const { mutateAsync: replace, isLoading: isReplacing } = useMutation(
    async ({ exist_product_id, change_product_id }) => {
      await replaceDevice({ listId, exist_product_id, change_product_id });
    },
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries([
          "commission-list-products",
          listId,
        ]);
        onSuccess?.(data);
      },
    },
  );

  return { replace, isReplacing };
};
