import { Checkbox } from "antd";
import { queryClient } from "lib/react-query-client";
import { useAccessoryReadyListSelection } from "../store";
import _ from "lodash";

export const ReadyAccessoriesListSelector = () => {
  const {
    page,
    pageSize,
    searchQuery,
    filter_by,
    start_date,
    end_date,
    selection,
    setSelection,
  } = useAccessoryReadyListSelection();

  const currentPageIds = queryClient
    .getQueryData([
      "sending-accessory-ready-list",
      page,
      pageSize,
      searchQuery,
      _.omit(filter_by, "device_type_id"),
      start_date,
      end_date,
    ])
    ?.data?.map(({ product_id }) => product_id);

  const isSelectedAll =
    !!currentPageIds?.length &&
    currentPageIds?.every(i => selection.includes(i));
  const isSelectedSome = currentPageIds?.some(i => selection.includes(i));

  return (
    <Checkbox
      checked={isSelectedAll}
      indeterminate={isSelectedSome && !isSelectedAll}
      onChange={({ target }) => {
        setSelection(currentPageIds, target.checked);
      }}
    />
  );
};
