import { Link } from "react-router-dom";

export const config = [
  {
    title: "Invoice",
    dataIndex: "invoice",
    render: ({ invoice_number, invoice_id }) => (
      <Link to={`/invoices/manage/${invoice_id}`}>{invoice_number}</Link>
    ),
    align: "center",
  },
  {
    title: "Supplier",
    render: (_, { invoice }) => {
      const supplierName = invoice.supplier.supplier_name || "";
      const firstSupplier = invoice.first_supplier
        ? ` (${invoice.first_supplier})`
        : "";
      return supplierName + firstSupplier;
    },
    width: 140,
    align: "center",
  },
  {
    title: "Device id",
    dataIndex: "product_id",
    render: product_id => (
      <Link to={`/devices/${product_id}`}>{product_id}</Link>
    ),
    align: "center",
  },
  {
    title: "Single Price",
    dataIndex: "invoice_product",
    align: "center",
    render: invoice_product =>
      invoice_product?.single_price ? (
        <strong className={"font-semibold text-gray-700"}>
          €{invoice_product?.single_price}
        </strong>
      ) : (
        ""
      ),
  },
];

export const disconnectedProductColumns = config.filter(({ dataIndex }) =>
  ["invoice", "product_id"].includes(dataIndex),
);

export const connectedProductColumns = config.filter(({ dataIndex }) =>
  ["invoice", "product_id", "invoice_product"].includes(dataIndex),
);

export const latestProductColumns = connectedProductColumns;

export const returnedProductColumns = config;
