import clsx from "clsx";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Table,
  Button,
  Switch,
  InputNumber,
  Radio,
  message,
  Modal,
  Form,
  Input,
  Tag,
} from "antd";
import {
  CheckCircleFilled,
  ReloadOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

import {
  useUpdateSKU,
  useReconnect,
  useReconnectEbay,
  useEbayDuplicate,
  useAmazonRN,
} from "features/marketplaces";
import {
  AmountBanner,
  ApplyFormulas,
  PromoModal,
  useSkuMarketplaces,
} from "features/sales";
import { HoverActionSheet } from "./HoverActionSheet";
import { AddMarketplace } from "./AddMarketplace";
import { BuyboxPrice } from "./BuyboxPrice";
import {
  FORMULAS_OPTIONS,
  useCheckingFormulasProfit,
} from "features/profitability";

const inactive = "text-red-400";

//TODO: need refactoring!!!

export const SalesControl = ({ skuId, gradeId, amount, deviceType }) => {
  const [searchParams] = useSearchParams();
  const original_box = searchParams.get("original_box");

  const [form] = Form.useForm();
  const [formRN] = Form.useForm();

  const [editableRow, setEditableRow] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [values, setValues] = useState({});
  const [isPromoOpen, setIsPromoOpen] = useState(false); // marketID || false
  const [isAddingAmazonRN, setIsAddingAmazonRN] = useState({
    isOpen: false,
    id: null,
  });

  const [connectingEbayItem, setConnectingEbayItem] = useState({
    isOpen: false,
    id: null,
    isReconnect: false,
  });

  const { marketplaces, isFetchingMarketplaces } = useSkuMarketplaces({
    original_box,
    grade_id: gradeId,
    sku_id: skuId,
  });

  const { addAmazonRN, isAddingRN } = useAmazonRN({
    onSuccess: data => {
      setIsAddingAmazonRN({ isOpen: false });
      formRN.resetFields();
      message.success(data.message || "Success");
    },
  });

  const { updateSKU, isSKUUpdating } = useUpdateSKU({
    onSuccess: data => {
      message.success(data.message || "Success");
      setEditableRow(null);
    },
  });

  const { reconnect, isReconnecting } = useReconnect({
    onSuccess: data => message.success(data.message || "Success"),
  });

  const { reconnectEbay, isEbayReconnecting } = useReconnectEbay({
    onSuccess: data => {
      setConnectingEbayItem({ isOpen: false });
      form.resetFields();
      message.success(data.message || "Success");
    },
  });

  const { isDuplication, duplicate } = useEbayDuplicate({
    onSuccess: data => {
      setConnectingEbayItem({ isOpen: false });
      form.resetFields();
      message.success(data.message || "Success");
    },
  });

  const onCancelEdit = () => {
    setEditableRow(null);
    setValues({});
  };

  const onSubmitAfterbuyKey = () => {
    const request = {
      id: connectingEbayItem.id,
      afterbuyKey: form.getFieldValue("afterbuy"),
    };

    if (connectingEbayItem.isReconnect) reconnectEbay(request);
    else duplicate(request);
  };

  const { checkFormulasProfit, isChecking } = useCheckingFormulasProfit({
    onSuccess: ({ data }) => {
      Modal.confirm({
        title: "Confirm the applied values",
        width: 600,
        mask: false,
        maskClosable: true,
        content: (
          <div className="my-3">
            {Object.entries(data).map(([k, v]) => {
              const name = k === "not_found" ? null : k;
              const valueColorClass = name ? "text-green-800" : "text-red-600";

              return (
                <div key={k} className="flex gap-6 my-1">
                  <span className="w-1/3 text-gray-600 font-semibold">
                    {name}
                  </span>

                  <span className={`flex-1 font-mono ${valueColorClass}`}>
                    {v}
                  </span>
                </div>
              );
            })}
          </div>
        ),
        onOk: () => updateSKU({ id: editableRow, data: values }),
      });
    },
  });

  const onSaveSKUClick = () => {
    const { marketplace, price, min_price } = values;
    const needMinPrice = ["REFURBED", "BACKMARKET"].includes(marketplace);

    checkFormulasProfit({
      data: {
        price: needMinPrice ? min_price : price,
        grade_id: gradeId,
        sku_id: skuId,
        original_box: Boolean(original_box),
        marketplace: values.marketplace,
      },
      byMarket: true,
    });
  };

  const tableFooter = editableRow
    ? () => (
        <div className="flex justify-end gap-2">
          <Button disabled={isSKUUpdating} onClick={onCancelEdit}>
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={() => onSaveSKUClick({ id: editableRow, data: values })}
            loading={isChecking || isSKUUpdating}
          >
            Save
          </Button>
        </div>
      )
    : null;

  return (
    <>
      <h3 className="text-center mt-16">On Sale</h3>

      <Modal
        confirmLoading={isAddingRN}
        open={isAddingAmazonRN.isOpen}
        onCancel={() => {
          if (!isAddingRN) {
            setIsAddingAmazonRN({ isOpen: false });
            formRN.resetFields();
          }
        }}
        onOk={formRN.submit}
        title={<h3 className="text-center mt-0">Amazon RN SKU</h3>}
      >
        <Form
          layout="vertical"
          form={formRN}
          onFinish={form => {
            addAmazonRN({
              marketplaceId: isAddingAmazonRN.id,
              amazon_rn: form.amazon_rn,
            });
          }}
          disabled={isAddingRN}
        >
          <Form.Item
            label="RN SKU"
            name="amazon_rn"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        confirmLoading={isEbayReconnecting || isDuplication}
        open={connectingEbayItem.isOpen}
        onCancel={() => {
          setConnectingEbayItem({ isOpen: false });
          form.resetFields();
        }}
        onOk={form.submit}
        title={
          <h3 className="text-center mt-0">
            {connectingEbayItem.isReconnect
              ? "EBAY Reconnection"
              : "EBAY Duplication"}
          </h3>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmitAfterbuyKey}
          disabled={isEbayReconnecting || isDuplication}
        >
          <Form.Item
            label="Afterbuy key"
            name="afterbuy"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <div className="overflow-y-auto max-w-5xl w-full mx-auto px-4">
        <Table
          dataSource={marketplaces?.data}
          rowKey={record => record.id}
          pagination={false}
          loading={isFetchingMarketplaces}
          footer={tableFooter}
          onRow={record => ({
            onMouseOver: () => {
              if (!editableRow) setHoveredRow(record.id);
            },
            onMouseLeave: () => setHoveredRow(null),
          })}
          columns={[
            {
              title: "Marketplace",
              dataIndex: "marketplace",
              key: "marketplace",
              render: (
                marketplace,
                { exist_on_market, target_link, promotion, id, amazon_rn },
              ) => {
                if (target_link) {
                  return (
                    <>
                      <Link to={target_link} target="_blank">
                        <span>{marketplace}</span>
                        {amazon_rn && (
                          <span className={"text-slate-400"}> (RN)</span>
                        )}{" "}
                        {!exist_on_market && (
                          <i className={inactive}>{" - need reconnect"}</i>
                        )}
                      </Link>

                      {promotion && (
                        <Link className="ml-3" to={`/promotions/${promotion}`}>
                          <Tag color="magenta" className="hover:bg-red-200">
                            Promotion
                          </Tag>
                        </Link>
                      )}

                      {!promotion &&
                        exist_on_market &&
                        hoveredRow === id &&
                        marketplace === "EBAY" && (
                          <Button
                            type="primary"
                            className="ml-3 inline-block text-xs"
                            size="small"
                            style={{ fontSize: 12 }}
                            onClick={() => setIsPromoOpen(id)}
                          >
                            + Promotion
                          </Button>
                        )}
                    </>
                  );
                }

                return (
                  <span className={!exist_on_market ? inactive : ""}>
                    {marketplace}
                    {amazon_rn && (
                      <span className={"text-slate-400"}> (RN)</span>
                    )}{" "}
                    {!exist_on_market && <i>{"- need reconnect"}</i>}
                    {promotion && (
                      <Link className="ml-3" to={`/promotions/${promotion}`}>
                        <Tag color="magenta" className="hover:bg-red-200">
                          Promotion
                        </Tag>
                      </Link>
                    )}
                    {!promotion &&
                      exist_on_market &&
                      hoveredRow === id &&
                      marketplace === "EBAY" && (
                        <Button
                          type="primary"
                          className="ml-3 inline-block text-xs"
                          size="small"
                          style={{ fontSize: 12 }}
                          onClick={() => setIsPromoOpen(id)}
                        >
                          + Promotion
                        </Button>
                      )}
                  </span>
                );
              },
            },
            {
              title: "Active/Not Active",
              key: "active",
              align: "center",
              dataIndex: "active",
              render: (active, record) => {
                if (editableRow === record.id) {
                  return (
                    <div className="flex items-center justify-center gap-2">
                      <Switch
                        disabled={isSKUUpdating}
                        checked={values.active}
                        onChange={checked => {
                          setValues({ ...values, active: checked });
                        }}
                      />
                    </div>
                  );
                } else {
                  return active ? (
                    <CheckCircleFilled
                      className={
                        record.exist_on_market
                          ? "text-green-500"
                          : "text-red-200"
                      }
                    />
                  ) : (
                    <CloseCircleFilled
                      className={
                        record.exist_on_market ? "text-red-700" : "text-red-200"
                      }
                    />
                  );
                }
              },
            },
            {
              title: "Min. Price",
              key: "min_price",
              dataIndex: "min_price",
              width: "20%",
              align: "center",
              render: (
                min_price,
                { exist_on_market: exist, id, marketplace },
              ) => {
                if (
                  ["EBAY", "AMAZON", "MEDIAMARKT", "CONRAD", "VOELKNER"].some(
                    m => marketplace.includes(m),
                  )
                )
                  return null;

                return editableRow === id ? (
                  <InputNumber
                    addonBefore="€"
                    size="middle"
                    min={0}
                    className="w-28 box-border"
                    controls={false}
                    disabled={isSKUUpdating}
                    value={values.min_price}
                    onChange={min_price => setValues({ ...values, min_price })}
                  />
                ) : (
                  <div className={`h-7 box-border ${!exist ? inactive : ""}`}>
                    <span
                      className={clsx("whitespace-nowrap", {
                        "line-through text-slate-500":
                          marketplace === "SHOPIFY",
                      })}
                    >
                      € {min_price}
                    </span>

                    {marketplace === "REFURBED" && (
                      <BuyboxPrice
                        marketplaceId={id}
                        className={clsx(
                          "opacity-0 text-gray-400 absolute right-2",
                          hoveredRow === id && "opacity-100",
                        )}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              title: "Price",
              key: "price",
              dataIndex: "price",
              align: "center",
              width: "20%",
              render: (price, { exist_on_market: exist, id }) =>
                editableRow === id ? (
                  <InputNumber
                    addonBefore="€"
                    size="middle"
                    min={0}
                    className="w-28 box-border"
                    controls={false}
                    disabled={isSKUUpdating}
                    value={values.price}
                    onChange={price => setValues({ ...values, price })}
                  />
                ) : (
                  <div className={`h-7 box-border ${!exist ? inactive : ""}`}>
                    <span className="whitespace-nowrap">€ {price}</span>
                  </div>
                ),
            },
            {
              title: () => (
                <span className="relative">
                  Quantity
                  {editableRow && <AmountBanner amount={amount} />}
                </span>
              ),
              key: "quantity",
              dataIndex: "quantity",
              align: "center",
              width: "20%",
              render: (quantity, record) =>
                editableRow === record.id ? (
                  <div className="flex items-center justify-center gap-2">
                    <InputNumber
                      size="middle"
                      min={0}
                      className="w-24 mr-3box-border"
                      disabled={isSKUUpdating || !values.manual}
                      controls={false}
                      value={values.quantity}
                      onChange={quantity => setValues({ ...values, quantity })}
                    />

                    <Radio.Group
                      disabled={isSKUUpdating}
                      size="small"
                      value={values.manual}
                      onChange={e => {
                        const isManual = e.target.value;
                        setValues({
                          ...values,
                          manual: isManual,
                          quantity: isManual ? values.quantity : amount,
                        });
                      }}
                    >
                      <Radio className="text-xs" value={false}>
                        Default
                      </Radio>
                      <Radio className="text-xs" value={true}>
                        Manual
                      </Radio>
                    </Radio.Group>
                  </div>
                ) : (
                  <div className="h-7 box-border relative">
                    <span
                      className={`${record.manual ? "" : "font-bold"} ${
                        !record.exist_on_market ? inactive : ""
                      }`}
                    >
                      {quantity}
                    </span>

                    {record.exist_on_market && hoveredRow === record.id && (
                      <HoverActionSheet
                        marketplace={record}
                        onDuplicateEbay={() => {
                          setConnectingEbayItem({
                            id: marketplaces?.data[0]?.id, // original ebay always first
                            isOpen: true,
                            isReconnect: false,
                          });
                        }}
                        onDuplicateAmazon={() => {
                          setIsAddingAmazonRN({ isOpen: true, id: record.id });
                        }}
                        onReconnectEbay={() => {
                          setConnectingEbayItem({
                            id: record.id,
                            isOpen: true,
                            isReconnect: true,
                          });
                        }}
                        onEdit={() => {
                          const {
                            active,
                            manual,
                            price,
                            quantity,
                            min_price,
                            marketplace,
                          } = record;

                          setValues({
                            active,
                            manual,
                            price,
                            quantity,
                            min_price,
                            marketplace,
                          });
                          setEditableRow(record.id);
                        }}
                      />
                    )}

                    {!record.exist_on_market && (
                      <Button
                        shape="circle"
                        className="absolute right-4 pt-1 text-red-600"
                        onClick={() => {
                          if (record.marketplace === "EBAY") {
                            setConnectingEbayItem({
                              id: record.id,
                              isOpen: true,
                              isReconnect: true,
                            });
                          } else {
                            reconnect(record.id);
                          }
                        }}
                        loading={isReconnecting || isEbayReconnecting}
                        size="small"
                        icon={<ReloadOutlined />}
                      />
                    )}
                  </div>
                ),
            },
          ]}
          scroll={{ x: 600 }}
        />

        <footer className="flex justify-between items-center my-1">
          <div>
            {marketplaces?.activity_sale && (
              <p className="pl-1 m-0 text-gray-500 text-xs my-[2px]">
                <b>{`${marketplaces.activity_sale.user.first_name} ${marketplaces?.activity_sale.user.last_name} `}</b>
                <span className="font-nomral">sent this SKU to Sell on </span>
                <b>
                  {dayjs(marketplaces.activity_sale.date).format(
                    "YYYY-MM-DD HH:mm",
                    "YYYY-MM-DD HH:mm",
                  )}
                </b>
              </p>
            )}

            {marketplaces?.activity && (
              <p className="pl-1 m-0 text-gray-500 text-xs">
                <b>{`${marketplaces.activity.user.first_name} ${marketplaces?.activity.user.last_name} `}</b>
                <span className="font-nomral">updated this SKU on </span>
                <b>
                  {dayjs(marketplaces.activity.date).format("YYYY-MM-DD HH:mm")}
                </b>
              </p>
            )}
          </div>

          <ApplyFormulas
            skuData={{
              original_box,
              grade_id: gradeId,
              sku_id: skuId,
            }}
          />
          <AddMarketplace
            sku={{ original_box, grade_id: gradeId, sku_id: skuId }}
          />
        </footer>
      </div>

      {isPromoOpen && (
        <PromoModal
          marketId={isPromoOpen}
          deviceType={deviceType}
          onCancel={() => setIsPromoOpen(false)}
        />
      )}
    </>
  );
};
