import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useSalesIssuesStore } from "../store";
import { useDebounce } from "use-debounce";

const getSalesIssues = params =>
  apiClient.get("api/sku/marketplaces/sales-issues", {
    params,
  });

export const useSalesIssues = ({ onSuccess } = {}) => {
  const { page, per_page, setTotal, search_term } = useSalesIssuesStore();
  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: issues,
    isLoading: isLoadingIssues,
    isFetching: isFetchingIssues,
  } = useQuery(
    ["sales-issues", page, per_page, debouncedQuery],
    () =>
      getSalesIssues({
        page,
        per_page,
        search_term: debouncedQuery,
      }),
    {
      onSuccess: data => {
        setTotal?.(data.meta.total, data.meta.last_page);
        onSuccess?.(data);
      },
    },
  );
  return { issues, isLoadingIssues, isFetchingIssues };
};
