import { Alert } from "antd";
import { ToolFilled } from "@ant-design/icons";

const special = ["HOLD", "REPAIR", "RETURNED"];

export const DeviceStatus = ({ deviceData }) => {
  return (
    <>
      {deviceData.step_of_process !== "ENTERED" &&
        deviceData.step_of_process !== "IDENTIFIED" &&
        deviceData.step_of_process !== "GRADED" &&
        !!deviceData.invoice_product && (
          <StatusBanner message="CONNECTED" type="success" />
        )}

      {deviceData.step_of_process !== "ENTERED" &&
        deviceData.step_of_process !== "IDENTIFIED" &&
        deviceData.step_of_process !== "GRADED" &&
        !deviceData.invoice_product && (
          <StatusBanner message="DISCONNECTED" type="error" />
        )}

      {deviceData.status === "REGISTERED" && deviceData.waiting_for_approve && (
        <StatusBanner message="Waiting for admin approval" type="warning" />
      )}

      {deviceData.status === "HOLD" && (
        <StatusBanner message="HOLD" type="warning" />
      )}

      {deviceData.status === "RETURNED" && (
        <StatusBanner message="RETURNED" type="error" />
      )}

      {deviceData.status === "REPAIR" && (
        <StatusBanner message="REPAIR" type="error" icon={<ToolFilled />} />
      )}

      {/* Default info banner */}
      {!special.includes(deviceData.status) && (
        <StatusBanner message={deviceData.status?.replaceAll("_", " ")} />
      )}
    </>
  );
};

const StatusBanner = ({ message, type = "info", icon }) => (
  <Alert
    className="lg:mx-10 mx-0 mb-2 mt-3 lg:mb-5 lg:mt-6"
    type={type}
    showIcon
    message={message}
    icon={icon}
  />
);
