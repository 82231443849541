import { FilterDrawer, FilterSummary } from "components";
import {
  GradeFilter,
  ColorFilter,
  DeviceTypeFilter,
  PriceFilter,
} from "features/filter";
import { useMemo } from "react";
import { Spin } from "antd";

export const AvailableSkusFilter = ({
  disabled,
  store,
  loading,
  availableTypes,
}) => {
  const { filter_by, clear } = store;

  const active = useMemo(() => {
    const active = Object.entries(filter_by)
      .filter(([key]) => key !== "color")
      .map(([key, value]) => ({
        label: key,
        value,
      }));

    const tooltip = active.length ? <FilterSummary list={active} /> : null;

    return { count: active.length, tooltip };
  }, [filter_by]);

  return (
    <FilterDrawer
      disabled={disabled}
      title={
        <span>
          Available SKUs
          {loading && <Spin size="small" className="ml-2" />}
        </span>
      }
      onClear={clear}
      activeCount={active.count}
      btnStyle="primary"
      tooltip={active.tooltip}
    >
      <div className="flex flex-col gap-8 px-6 py-10">
        <PriceFilter store={store} />
        <GradeFilter store={store} />
        <DeviceTypeFilter
          store={store}
          gap={8}
          availableTypes={availableTypes}
        />
        <ColorFilter store={store} />
      </div>
    </FilterDrawer>
  );
};
