import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const useCompareQuantityStore = create(
  devtools(
    immer(set => ({
      result: null,
      searchQuery: "",
      setSearchQuery: value => {
        set(state => {
          state.searchQuery = value;
        });
      },
      saveResult: value => {
        set(state => {
          state.result = value;
        });
      },
      clear: () => {
        set({ result: null });
      },
    })),
  ),
);
