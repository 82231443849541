import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useDashboardStore } from "features/dashboard";

const getStats = ({ status = null }) =>
  apiClient.get(`api/dashboard`, {
    params: { status },
    data: {},
  });

export const useDashboardStats = ({ onSuccess } = {}) => {
  const { role } = useDashboardStore();

  const { data: stats, isLoading: isLoadingStats } = useQuery(
    ["dashboard-stats", role],
    () => getStats({ status: statusesMap[role] }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { stats, isLoadingStats };
};

const statusesMap = {
  admin: "IDENTIFIED",
  grader: "IDENTIFIED",
  flasher: "GRADED",
  packer: "REGISTERED",
  sales_manager: "READY_FOR_SALE",
  all_hold: "HOLD",
  all_repair: "REPAIR",
};
