import { Radio } from "antd";
import { useMediaQuery } from "react-responsive";
import { useStatisticsStore } from "features/statistics";

export const AreaSelector = () => {
  const store = useStatisticsStore();
  const isTightly = useMediaQuery({
    query: "(max-width: 960px)",
  });

  return (
    <Radio.Group
      size={isTightly ? "small" : "middle"}
      value={store.isCompanyArea}
      onChange={store.toggleArea}
      buttonStyle="solid"
    >
      <Radio.Button value={true}>
        <span className="lg:text-sm text-xs lg:font-normal font-semibold">
          Company
        </span>
      </Radio.Button>
      <Radio.Button value={false}>
        <span className="lg:text-sm text-xs lg:font-normal font-semibold">
          My
        </span>
      </Radio.Button>
    </Radio.Group>
  );
};
