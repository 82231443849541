import { App, Button, message, Pagination, Table } from "antd";
import {
  useFormulas,
  useCreateFormula,
  MarketplacesFormulaForm,
  useUpdateFormula,
  useDeleteFormula,
  useFormulasStore,
} from "features/formulas";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import {
  EditFilled,
  PlusOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";

export const MarketplacesFormulas = () => {
  const store = useFormulasStore();
  const { modal } = App.useApp();
  const [form] = useForm();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const onExpand = id => {
    if (_.isEqual(expandedRowKeys, [id])) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys([id]);
    }
  };

  const [modalForm, setModalForm] = useState({
    isOpen: false,
    editedFormula: null,
  });

  const closeForm = () => setModalForm({ isOpen: false, editedFormula: null });
  const openForm = (editedFormula = null) =>
    setModalForm({ isOpen: true, editedFormula });
  const { formulas, isLoading, isFetching } = useFormulas();

  const { createFormula, isCreating } = useCreateFormula({
    onSuccess: res => {
      message.success(res.message);
      closeForm();
    },
  });

  const { updateFormula, isUpdating } = useUpdateFormula({
    onSuccess: res => {
      message.success(res.message);
      closeForm();
    },
  });

  const { deleteFormula, isDeleting } = useDeleteFormula({
    onSuccess: res => {
      message.success(res.message);
      closeForm();
    },
  });

  const onDelete = () => {
    modal.confirm({
      title: "Formula removing",
      content: <span>Do you really want to remove this formula?</span>,
      onOk: () => deleteFormula(modalForm.editedFormula.id),
    });
  };

  const uploadFormData = () => {
    form.setFieldsValue({
      ...modalForm.editedFormula,
      grades: modalForm.editedFormula.grades.map(grade => grade.id),
      device_types: modalForm.editedFormula.device_types?.map(({ id }) => id),
    });
  };

  useEffect(() => {
    modalForm.editedFormula ? uploadFormData() : form.resetFields();
  }, [modalForm]);

  return (
    <>
      <h3 className="text-center mt-16 text-slate-800 relative max-w-7xl w-full mx-auto px-4">
        Marketplace Formulas
        <span
          className={
            "absolute left-6 -bottom-2 text-xs font-normal text-gray-500"
          }
        >
          <InfoCircleOutlined className={"text-slate-500 mr-1"} />
          <strong className={"font-semibold"}>{"{ebay_price}"}</strong> -
          calculated result from eBay formula
        </span>
      </h3>

      {modalForm.isOpen && (
        <MarketplacesFormulaForm
          form={form}
          formulaData={modalForm.editedFormula}
          isOpen={modalForm.isOpen}
          onClose={closeForm}
          onDelete={onDelete}
          isLoading={isCreating || isUpdating || isDeleting}
          onSubmit={values => {
            const data = { ...values, active: values.active ? 1 : 0 };
            modalForm.editedFormula
              ? updateFormula({ id: modalForm.editedFormula.id, data })
              : createFormula(data);
          }}
        />
      )}

      <Table
        className="overflow-y-auto max-w-7xl w-full mx-auto px-4 min-h-[200px]"
        loading={isLoading || isFetching}
        sticky
        dataSource={formulas?.data}
        rowKey={record => record.id}
        pagination={false}
        scroll={{ x: 1000 }}
        expandable={{
          expandedRowClassName: () => "bg-white",
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: ({ expression_greater, expression_less }) => (
            <div className={"flex font-mono text-green-800 text-center"}>
              <div className={"w-1/2 flex flex-col"}>
                <span className={"text-gray-600 text-xs font-semibold mb-2"}>
                  {"Price > 400"}
                </span>
                <span>{expression_greater}</span>
              </div>
              <div className={"w-1/2 flex flex-col"}>
                <span className={"text-gray-600 text-xs font-semibold mb-2"}>
                  {"Price < 400"}
                </span>
                <span>{expression_less}</span>
              </div>
            </div>
          ),
          onExpand: (expanded, record) => onExpand(record.id),
        }}
        columns={[
          {
            title: "Active",
            width: 76,
            dataIndex: "active",
            render: active =>
              active ? (
                <CheckCircleFilled className={"text-green-600"} />
              ) : (
                <CloseCircleFilled className={"text-red-300"} />
              ),
          },
          {
            title: "Title",
            dataIndex: "title",
            align: "center",
          },
          {
            title: "Marketplace",
            dataIndex: "marketplace",
            align: "center",
            width: 130,
          },
          {
            title: "Device Types",
            dataIndex: "device_types",
            render: dt => dt.map(({ name }) => name).join(", "),
            align: "center",
          },
          {
            title: "Grades",
            dataIndex: "grades",
            render: grades => grades.map(({ name }) => name).join(", "),
            align: "center",
          },
          {
            title: "Difference",
            dataIndex: "difference_price",
            width: 110,
            render: diff => `€${diff}`,
            align: "center",
          },
          Table.EXPAND_COLUMN,
          {
            title: "Formulas",
            render: (_, { expression_greater, expression_less }) => {
              return (
                <div
                  className={
                    "flex flex-col text-xs gap-1 font-medium text-gray-600"
                  }
                >
                  <div>
                    <strong className={"mr-1 text-gray-400 font-medium"}>
                      {"Price > 400:"}
                    </strong>
                    <span>{expression_greater}</span>
                  </div>
                  <div>
                    <strong className={"mr-1 text-gray-400 font-medium"}>
                      {"Price < 400:"}
                    </strong>
                    <span>{expression_less}</span>
                  </div>
                </div>
              );
            },
          },

          {
            title: (
              <Button
                type={"primary"}
                onClick={() => openForm()}
                icon={<PlusOutlined />}
              />
            ),
            key: "edit",
            fixed: "right",
            width: 80,
            render: (_, item) => (
              <Button
                icon={<EditFilled color={"red"} />}
                onClick={() => openForm(item)}
                disabled={isLoading}
              />
            ),
          },
        ]}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        current={store.page}
        pageSize={store.pageSize}
        onChange={page => {
          store.setPage(page);
        }}
        disabled={isLoading || isFetching}
      />
    </>
  );
};
