import { Button, Form, Modal, Input, Select, message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useInfiniteGradeGroup } from "features/directory";
import {
  useGrading,
  useApproveRegrade,
  DeviceDynamicInput,
  Device,
  Approvement,
  useOnKeySubmit,
  GroupedGradeInput,
} from "features/devices";
import { useAuthStore } from "features/auth";
import { CloseCircleFilled, LockFilled, ToolFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;

export const Grading = ({ deviceData, regrade = false }) => {
  const needApprove = !!deviceData?.grader_waiting_for_approve;
  const { deviceId } = useParams();
  const { isRight } = useAuthStore();

  const [form] = Form.useForm();
  const selectedGrade = Form.useWatch("grade_id", form);

  const {
    additionalOptions,
    gradeGroup,
    isLoading: isGroupLoading,
  } = useInfiniteGradeGroup();

  const { grade, isGrading } = useGrading(deviceId, response => {
    if (!!response.data?.grader_waiting_for_approve) {
      message.open({
        type: "warning",
        content: "Must be approved by admin.",
        duration: 5,
      });
    } else {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      onClose();
    }
  });

  const { approve, isApproving } = useApproveRegrade(deviceId, response => {
    if (response?.message === "Wrong credentials") {
      message.error("Wrong credentials");
    } else {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      onClose();
    }
  });

  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onSubmit = formData => {
    if (needApprove) {
      approve(Device.buildDeviceObj(formData));
    } else {
      grade(Device.buildDeviceObj(formData));
    }
  };

  const onGrade = () => {
    form.setFieldValue("status", "GRADED");
    form.submit();
  };

  const onRepair = () => {
    form.setFieldValue("status", "REPAIR");
    form.submit();
  };

  const onHold = () => {
    form.setFieldValue("status", "HOLD");
    form.submit();
  };

  const { isOpen, setIsOpen } = useOnKeySubmit(onGrade);

  useEffect(() => {
    if (!deviceData || !isOpen) return;

    if (!!deviceData?.grader_waiting_for_approve) {
      Device.fillDeviceForm({
        deviceData: deviceData.grader_waiting_for_approve.productData,
        form,
        isGradeApprove: true,
      });
    } else {
      Device.fillDeviceForm({ deviceData, form });
    }
  }, [deviceData, isOpen]);

  const isWrongGrade =
    !!deviceData?.grade?.id &&
    !isGroupLoading &&
    gradeGroup?.length &&
    !gradeGroup.some(({ id }) => id === deviceData?.grade?.id);

  const isTightly = useMediaQuery({
    query: "(max-width: 420px)",
  });

  if (regrade && !isRight(["grader"])) return null;

  if (!isRight(["admin", "grader"])) return <span>Grading</span>;

  return (
    <>
      <Button
        size={regrade ? "small" : "middle"}
        type="primary"
        onClick={() => setIsOpen(true)}
        danger={regrade}
      >
        {regrade ? "Regrade" : "Grade It"}
      </Button>

      <Modal
        title={
          <>
            {isWrongGrade && (
              <CloseCircleFilled className="mr-2 text-red-500" />
            )}
            Grading
          </>
        }
        open={isOpen}
        confirmLoading={isGrading || isApproving}
        maskClosable={false}
        onCancel={onClose}
        footer={
          <div className="flex">
            <Button
              danger
              onClick={onRepair}
              loading={isGrading}
              disabled={regrade || isWrongGrade}
              icon={<ToolFilled />}
            >
              {isTightly ? "" : "Repair"}
            </Button>

            <Button
              danger
              icon={<LockFilled />}
              className="mr-auto"
              onClick={onHold}
              loading={isGrading}
              disabled={regrade || isWrongGrade}
            >
              {isTightly ? "" : "Hold"}
            </Button>

            <Button
              type={isWrongGrade ? "primary" : "default"}
              danger={isWrongGrade}
              onClick={() => {
                if (!isGrading && !isApproving) onClose();
              }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              onClick={onGrade}
              loading={isGrading || isApproving}
              disabled={isWrongGrade}
            >
              Ok
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          disabled={isGrading || isApproving}
        >
          {deviceData?.grader_waiting_for_approve && <Approvement />}

          <Form.Item label="Status" name="status" hidden>
            <Select
              className="w-full"
              placeholder="Select Status"
              options={[
                { label: "GRADED", value: "GRADED" },
                { label: "REPAIR", value: "REPAIR" },
              ]}
            />
          </Form.Item>

          <GroupedGradeInput
            noGrouping
            rules={[requiredRule]}
            disabled={isGrading || isApproving || isWrongGrade}
            initialData={
              needApprove
                ? deviceData.grader_waiting_for_approve.productData
                : deviceData.grade
            }
            onClear={() => form.resetFields(["grade_id"])}
          />
          {isWrongGrade && (
            <Alert
              className="mb-10"
              message="Wrong Grade"
              description="Current product grade is not supported at this step.
                Contact your administrator to resolve the issue."
              type="error"
              showIcon
            />
          )}

          {additionalOptions &&
            additionalOptions[selectedGrade]?.map(field => (
              <DeviceDynamicInput
                field={field}
                key={field.name}
                groupName="additional_options"
              />
            ))}

          <Form.Item label="Comment" name="comment">
            <TextArea placeholder="Enter Comment" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
