import { Form, Input } from "antd";

export const Approvement = () => {
  return (
    <div className="border py-3 px-6 rounded-lg bg-red-50 mt-8 mb-12">
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Required",
          },
          {
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: "Email must be valid value",
          },
        ]}
      >
        <Input placeholder="Enter Email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <Input.Password placeholder="Enter Password" />
      </Form.Item>
    </div>
  );
};
