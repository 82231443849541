import { apiClient } from "lib/api-client";
import { useQuery, useInfiniteQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { useMemo, useState } from "react";
import { useGradesSelection } from "features/directory";

const getGrades = ({ search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get(`api/directory/grades`, {
    params: { per_page, page, search_term },
  });

export const useGrades = onSuccess => {
  const { page, pageSize, setPage, setTotal } = useGradesSelection();

  const { data: grades, isLoading } = useQuery(
    ["grades", page, pageSize],
    () =>
      getGrades({
        page: page,
        per_page: pageSize,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );

  return { grades, isLoading };
};

const infinite_getGrades = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteGrades = onSuccess => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-grades", debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/directory/grades?per_page=12&page=1`,
      }) =>
        infinite_getGrades({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const gradeOptions = useMemo(
    () =>
      data?.pages
        ? data?.pages
            .map(({ data }) =>
              data?.map(({ name, id }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            )
            ?.flat()
        : [],
    [data],
  );

  const additionalOptions = useMemo(() => {
    if (!data?.pages) return null;

    const grades = data?.pages?.map(({ data }) => data)?.flat();
    const result = {};
    for (const grade of grades) {
      result[grade.id] = grade?.additional_options;
    }
    return result;
  }, [data]);

  const grades = data?.pages?.map(({ data }) => data)?.flat();

  return {
    grades,
    gradeOptions,
    additionalOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
