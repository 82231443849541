import { FilterDrawer, FilterSummary } from "components";
import { useMemo } from "react";
import { Divider, Spin } from "antd";
import {
  GradeFilter,
  DeviceTypeFilter,
  ColorFilter,
  StatusFilter,
  ConnectingFilter,
  OriginalBoxFilter,
  PriorityFilter,
  ReturnFilter,
  SalesFilter,
  PriceFilter,
} from "features/filter";

export const SKUsFilter = ({
  store,
  disabled,
  isLoading,
  fixedStatus = false,
  showPriority = false,
}) => {
  const active = useMemo(() => {
    const active = Object.entries(store.filter_by)
      .filter(([name]) => name !== "day_period")
      .map(([key, value]) => ({
        label: key,
        value,
      }));

    const tooltip = active.length ? <FilterSummary list={active} /> : null;

    return { count: active.length, tooltip };
  }, [store.filter_by]);

  return (
    <FilterDrawer
      title={
        <span>
          SKU filter
          {isLoading && <Spin size="small" className="ml-2" />}
        </span>
      }
      onClear={store.clearFilters}
      activeCount={active.count}
      btnStyle="primary"
      tooltip={active.tooltip}
      disabled={disabled}
    >
      <div className="px-6 pb-10">
        <Divider>
          <span className="text-gray-500 text-xs">Devices</span>
        </Divider>

        <div className="flex flex-col gap-8">
          {showPriority && (
            <div className="flex flex-col gap-1">
              <label>Priority</label>
              <PriorityFilter store={store} />
            </div>
          )}

          <div className="flex flex-col gap-1">
            <label>Return</label>
            <ReturnFilter store={store} />
          </div>

          {!fixedStatus && <StatusFilter store={store} />}
          <ConnectingFilter store={store} />
          <GradeFilter store={store} />
          <OriginalBoxFilter store={store} />
          <ColorFilter store={store} />
          <DeviceTypeFilter gap={4} store={store} />
        </div>

        <div className="flex flex-col pt-6 gap-8">
          <Divider>
            <span className="text-gray-500 text-xs">SKUs</span>
          </Divider>
          <SalesFilter store={store} />
          <PriceFilter store={store} />
        </div>
      </div>
    </FilterDrawer>
  );
};
