import {
  Button,
  Form,
  Modal,
  Input,
  Select,
  message,
  Divider,
  Checkbox,
  Alert,
  App,
} from "antd";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  useInfiniteDeviceTypes,
  useInfiniteGradeGroup,
} from "features/directory";
import {
  usePacking,
  useApprove,
  Device,
  DeviceDynamicInput,
  SkuInput,
  ColorShadeInput,
  DeviceTypeInput,
  Approvement,
  useOnKeySubmit,
  GroupedGradeInput,
  usePackingAccessoriesStore,
} from "features/devices";
import { useAuthStore } from "features/auth";
import { CloseCircleFilled } from "@ant-design/icons";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;

const specialAllowedGradeNames = ["S1"];
const statusOptions = [
  { label: "Hold", value: "HOLD" },
  { label: "Ready for Sale", value: "READY_FOR_SALE" },
];

export const Packing = ({ deviceData }) => {
  const { modal } = App.useApp();
  const needApprove = !!deviceData.waiting_for_approve;
  const { deviceId } = useParams();
  const [form] = Form.useForm();
  const { isRight } = useAuthStore();
  const { ids: attachedAccessoryIDs } = usePackingAccessoriesStore();

  const { isOpen, setIsOpen } = useOnKeySubmit(form.submit);
  const selectedColor = Form.useWatch("color", form);
  const selectedGrade = Form.useWatch("grade_id", form);
  const selectedDeviceType = Form.useWatch("device_type_id", form);
  const currentAdditionalOptions = Form.useWatch("additional_options", form);

  const {
    additionalOptions,
    groupOptions,
    isLoading: isGradesLoading,
  } = useInfiniteGradeGroup({
    grade_id: deviceData?.grade?.id,
  });
  const { deviceTypesFields } = useInfiniteDeviceTypes();

  const { pack, isPacking } = usePacking(deviceId, response => {
    if (response.data.waiting_for_approve) {
      message.warning("Must be approved by admin.");
    } else {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      setIsOpen(false);
      form.resetFields();
    }
  });

  const { approve, isApproving } = useApprove(deviceId, response => {
    if (response.message === "Wrong credentials") {
      message.error(response.message);
      form.scrollToField("email");
      return;
    }

    message.success({
      content: response.message || "Success!",
      style: { left: 0, position: "absolute" },
    });
    setIsOpen(false);
    form.resetFields();
  });

  const onFinish = data => {
    const accessories = attachedAccessoryIDs.length
      ? attachedAccessoryIDs
      : null;

    const body = {
      ...Device.buildDeviceObj(data, deviceTypesFields[selectedDeviceType]),
      accessories,
    };

    const submitFn = () => {
      needApprove ? approve(body) : pack(body);
    };

    const attachedAccessoryIDsData = Device.getFlatSelectedAccessoriesByIDs(
      deviceData,
      attachedAccessoryIDs,
    );

    modal.confirm({
      title: "Attached Accessories",
      content: (
        <ul className="p-0">
          {attachedAccessoryIDsData.map(a => (
            <li
              key={a.id}
              className="text-ellipsis whitespace-nowrap overflow-hidden"
            >
              <span className="text-xs font-normal text-slate-800 mr-1">
                #{a.sku} ·
              </span>
              {a.name}
            </li>
          ))}
          {!attachedAccessoryIDsData.length && (
            <span>You have not attached any accessories.</span>
          )}
        </ul>
      ),
      onOk: submitFn,
    });
  };

  useEffect(() => {
    if (
      !statusOptions.some(({ value }) => value === "AUCTION") &&
      isRight(["auction"])
    ) {
      statusOptions.push({ label: "Auction", value: "AUCTION" });
    }
  }, []);

  useEffect(() => {
    if (!(deviceData && isOpen)) return;

    Device.fillDeviceForm({ deviceData, form });
    if (
      !statusOptions.some(({ value }) => value === form.getFieldValue("status"))
    ) {
      form.resetFields(["status"]);
    }

    if (needApprove)
      Device.fillPackerApprovement(
        deviceData.waiting_for_approve.productData,
        form,
      );
    // eslint-disable-next-line
  }, [deviceData, isOpen]);

  const isWrongGrade =
    !isGradesLoading &&
    groupOptions?.length === 0 &&
    !specialAllowedGradeNames.includes(deviceData?.grade.name);

  useEffect(() => {
    if (isOpen && isWrongGrade) {
      setTimeout(() => {
        form.scrollToField(["grade_id"]);
      }, 0);
    }
  }, [isOpen, isWrongGrade]);

  if (!isRight(["admin", "packer"])) return <span>Packing</span>;

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Pack It
      </Button>

      <Modal
        title={
          <div className="flex items-center">
            {isWrongGrade && (
              <CloseCircleFilled className="mr-2 text-red-500" />
            )}
            Packing
            {needApprove && (
              <b className="text-red-500 mx-auto">Need admin approval</b>
            )}
          </div>
        }
        modalRender={modal => (
          <div className="max-h-screen pb-0 pt-6">{modal}</div>
        )}
        width={860}
        centered
        wrapClassName="h-screen relative"
        style={{ top: 0, maxHeight: "100%" }}
        open={isOpen}
        onOk={form.submit}
        okButtonProps={{
          disabled: isWrongGrade,
        }}
        cancelButtonProps={{
          danger: isWrongGrade,
          type: isWrongGrade ? "primary" : "default",
        }}
        maskClosable={false}
        onCancel={() => setIsOpen(false)}
        confirmLoading={isPacking || isApproving}
      >
        <div className="overflow-y-auto pr-3" style={{ maxHeight: "76vh" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            disabled={isPacking || isApproving}
          >
            {needApprove && <Approvement />}

            <Form.Item
              rules={[requiredRule]}
              label="Status"
              name="status"
              className="mb-10"
            >
              <Select
                className="w-full"
                placeholder="Select Status"
                getPopupContainer={triggerNode => triggerNode.parentElement}
                options={statusOptions}
              />
            </Form.Item>

            <Divider />
            <GroupedGradeInput
              disabled={isPacking || isApproving || groupOptions?.length === 0}
              initialData={deviceData?.grade}
            />
            {isWrongGrade && (
              <Alert
                message="Wrong Grade"
                description="Current product grade is not supported at this step.
                Contact your administrator to resolve the issue."
                type="error"
                showIcon
              />
            )}

            {additionalOptions &&
              additionalOptions[selectedGrade]?.map(field => {
                if (field.name !== "Original Box") {
                  return (
                    <DeviceDynamicInput
                      field={field}
                      key={field.name}
                      groupName="additional_options"
                    />
                  );
                }
                const isEmpty = !currentAdditionalOptions?.["Original Box"];
                return (
                  <div className="flex" key={field.name}>
                    <DeviceDynamicInput
                      field={field}
                      groupName="additional_options"
                    />

                    {field.name === "Original Box" && isEmpty && (
                      <Form.Item
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(new Error("Must be verified")),
                          },
                        ]}
                        initialValue={false}
                        valuePropName="checked"
                        className="ml-4"
                        name={["additional_options", "verified"]}
                      >
                        <Checkbox>
                          <b>Verify</b>
                        </Checkbox>
                      </Form.Item>
                    )}
                  </div>
                );
              })}

            <Divider />

            <SkuInput
              disabled
              initialData={deviceData?.sku}
              typeId={deviceData?.device_type?.id}
            />

            <DeviceTypeInput disabled initialData={deviceData?.device_type} />
            {deviceTypesFields &&
              deviceTypesFields[selectedDeviceType]?.map(field => (
                <DeviceDynamicInput
                  field={field}
                  key={field.id}
                  groupName="field_values"
                  disabled
                />
              ))}

            <ColorShadeInput
              currentColor={selectedColor}
              disabled
              initialData={deviceData?.color}
            />

            <Form.Item label="Comment" name="comment">
              <TextArea placeholder="Enter Comment" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};
