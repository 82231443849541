import { SKU, Pagination } from "components";
import { Table, Tooltip } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { QUERY_NAMES, SORTING_NAMES_MAP } from "features/profitability";
import { queryClient } from "lib/react-query-client";

export const ProductsTable = ({
  isLoading,
  queryName,
  data,
  idKey,
  isSKUTable = false,
  store,
}) => {
  const onSKURowClick = record => {
    const { url } = SKU.getLinkData({
      skuId: record.sku_id,
      skuNumber: record.sku_number,
      gradeId: record.grade_id,
      gradeName: record.grade_name,
      gradeSkuDisplay: record.grade_sku_display,
      originalBox: record.original_box,
    });
    window.open(url, "_blank", "noreferrer");
  };

  const onDeviceRowClick = record => {
    window.open(`/devices/${record.product_id}`, "_blank", "noreferrer");
  };

  const onRowClick = record => {
    if (isSKUTable) {
      onSKURowClick(record);
    } else {
      onDeviceRowClick(record);
    }
  };

  const getTableColumns = name =>
    devicesTable
      .filter(col => col.for?.includes(name))
      .map(col => {
        if ("children" in col) {
          return {
            ...col,
            children: col.children.filter(child => child.for?.includes(name)),
          };
        } else {
          return col;
        }
      });

  return (
    <>
      <Table
        className="overflow-y-auto"
        rowClassName="hover:cursor-pointer"
        loading={isLoading}
        columns={getTableColumns(queryName)}
        dataSource={data}
        sticky={true}
        rowKey={record => record[idKey]}
        pagination={false}
        onChange={(pag, f, { order, column }) => {
          store?.setSorting?.(column?.sortingKey, SORTING_NAMES_MAP[order]);
        }}
        onRow={record => ({
          onClick: () => onRowClick(record),
        })}
        scroll={{ x: 1000 }}
      />

      <Pagination
        store={store}
        disabled={isLoading || !data}
        className="mt-auto"
      />
    </>
  );
};

export const devicesTable = [
  {
    title: "ID",
    dataIndex: "product_id",
    key: "product_id",
    width: 100,
    for: [
      QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES,
    ],
  },
  {
    title: "SKU",
    dataIndex: "sku_number",
    key: "sku_number",
    width: 140,
    render: (_, r) =>
      SKU.getLinkData({
        skuId: r.sku_id,
        skuNumber: r.sku_number,
        gradeId: r.grade_id,
        gradeName: r.grade_name,
        gradeSkuDisplay: r.grade_sku_display,
        originalBox: r.original_box,
      }).label,
    for: [
      QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE,
    ],
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    key: "device_type",
    align: "center",
    render: (_, record) => record.device_type?.name,
    for: [
      QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES,
    ],
  },
  {
    title: "Device Type",
    dataIndex: "device_type_name",
    key: "device_type_name",
    align: "center",
    for: [
      QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE,
    ],
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
    align: "center",
    for: [
      QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES,
    ],
    render: (device, { color, field_values, color_shade_name }) => {
      if (typeof device === "string") return `${device} ${color_shade_name}`;

      const model =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Model",
        )?.device_field.device_field_option[0]?.value || "";

      const memory =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      let title = `${model} ${memory} ${color?.name || ""}`;
      if (!(model || memory || color?.name)) title = "Unknown";

      return (
        <Tooltip placement="topLeft" title={title}>
          {title}
        </Tooltip>
      );
    },
  },
  {
    title: "Grade",
    dataIndex: "grade",
    align: "center",
    key: "grade",
    for: [
      QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES,
    ],
    render: (_, record) => record.grade?.name,
    width: "12%",
  },
  {
    title: <span className="text-xs">{"Original Box"}</span>,
    dataIndex: "original_box",
    align: "center",
    key: "original_box",
    for: [
      QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE,
      QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES,
    ],
    render: (_, { original_box }) =>
      original_box ? <CheckCircleOutlined className="text-green-500" /> : null,
    width: 80,
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
    sortingKey: "sort_count",
    align: "center",
    sorter: true,
    showSorterTooltip: false,
    for: [
      QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE,
    ],
    width: 110,
  },
  {
    title: "€ Price",
    key: "price",
    for: [
      QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
      QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
    ],
    children: [
      {
        title: () => <span className="text-xs text-slate-500">* invoice</span>,
        dataIndex: "invoice_price",
        width: 100,
        align: "center",
        key: "invoice_price",
        sortingKey: "sort_invoice_price",
        sorter: true,
        showSorterTooltip: false,
        for: [QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED],
      },
      {
        title: () => <span className="text-xs text-slate-500">* sale</span>,
        dataIndex: "sale_price",
        align: "center",
        width: 100,
        key: "sale_price",
        sortingKey: "sort_sale_price",
        sorter: true,
        showSorterTooltip: false,
        for: [
          QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
          QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
        ],
      },
      {
        title: () => <span className="text-xs text-slate-500">* formula</span>,
        dataIndex: "formula_price",
        align: "center",
        width: 110,
        key: "formula_price",
        sortingKey: "sort_formula_price",
        sorter: true,
        showSorterTooltip: false,
        for: [
          QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED,
          QUERY_NAMES.PRODUCTS.DEVICES.DISCONNECTED,
        ],
      },
      {
        title: () => <span className="text-xs text-slate-500">* profit</span>,
        dataIndex: "profit_price",
        align: "center",
        width: 100,
        key: "profit_price",
        sortingKey: "sort_profit_price",
        sorter: true,
        showSorterTooltip: false,
        for: [QUERY_NAMES.PRODUCTS.DEVICES.CONNECTED],
      },
    ],
  },
  {
    title: "€ Price",
    key: "price",
    for: [
      QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
      QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
    ],
    children: [
      {
        title: () => <span className="text-xs text-slate-500">* invoice</span>,
        dataIndex: "total_invoice_price",
        width: 100,
        align: "center",
        key: "invoice_price",
        sortingKey: "sort_invoice_price",
        sorter: true,
        showSorterTooltip: false,
        for: [QUERY_NAMES.PRODUCTS.SKUS.CONNECTED],
      },
      {
        title: () => <span className="text-xs text-slate-500">* sale</span>,
        dataIndex: "total_sale_price",
        align: "center",
        width: 100,
        key: "sale_price",
        sortingKey: "sort_sale_price",
        sorter: true,
        showSorterTooltip: false,
        for: [
          QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
          QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
        ],
      },
      {
        title: () => <span className="text-xs text-slate-500">* formula</span>,
        dataIndex: "total_formula_price",
        align: "center",
        width: 110,
        key: "formula_price",
        sortingKey: "sort_formula_price",
        sorter: true,
        showSorterTooltip: false,
        for: [
          QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
          QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
        ],
      },
      {
        title: () => <span className="text-xs text-slate-500">* profit</span>,
        dataIndex: "total_profit",
        align: "center",
        width: 100,
        key: "total_profit",
        sortingKey: "sort_profit_price",
        sorter: true,
        showSorterTooltip: false,
        for: [QUERY_NAMES.PRODUCTS.SKUS.CONNECTED],
      },
    ],
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    width: 160,
    key: "status",
    render: status => {
      const statusLabels = queryClient.getQueryData(["statuses"])?.data;
      return (
        <span className="whitespace-nowrap pr-1">
          {statusLabels ? statusLabels[status] : status}
        </span>
      );
    },
    for: [QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES],
  },
];
