import { DatePicker } from "antd";
import dayjs from "dayjs";

export const DatesFilter = ({ store, className = "", showTime = false }) => {
  const { setEndDate, setStartDate, start_date, end_date } = store;
  return (
    <div className={"flex gap-4 " + className}>
      <div className="flex-1">
        <label>Start Date</label>
        <DatePicker
          showTime={showTime}
          placeholder="Select Date"
          className="w-full mt-1"
          onChange={(_, dateText) => setStartDate(dateText)}
          value={start_date ? dayjs(start_date) : null}
        />
      </div>

      <div className="flex-1">
        <label>End Date</label>
        <DatePicker
          showTime={showTime}
          placeholder="Select Date"
          className="w-full mt-1"
          onChange={(_, dateText) => setEndDate(dateText)}
          value={end_date ? dayjs(end_date) : null}
        />
      </div>
    </div>
  );
};
