export const LogoVoelkner = ({ width = "100%", height }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 184.5 32.5"
      id="shop"
    >
      <path
        fill="#94c125"
        d="M43.5 32.5c-3 .1-5.9-1-8-3.1-4.2-4.4-4.2-11.4 0-15.8 4.5-4.1 11.4-4.1 16 0 4.2 4.4 4.2 11.4 0 15.8-2.1 2.1-5 3.2-8 3.1zm-4.2-6.7c2.4 2.3 6.1 2.3 8.5 0 2.2-2.4 2.2-6.2 0-8.6-2.4-2.3-6.1-2.3-8.5 0-2.2 2.4-2.2 6.1 0 8.6zM79.5 25.5c-.6 2.2-2 4.1-4 5.3-2 1.2-4.3 1.8-6.7 1.7-6.1-.1-11-5.1-10.9-11.2v-.2c.1-2.8 1.2-5.5 3.1-7.6 2.1-2.1 5-3.2 8-3.1 2.8-.1 5.5.9 7.6 2.8 2 1.9 3 4.5 2.9 7.2 0 .9-.1 1.8-.3 2.7H63.9c-.6 0-.9.3-.9.8.1 1.1.7 2.1 1.7 2.7 1.2.8 2.6 1.3 4.1 1.2 2.8 0 4.6-.8 5.4-2.5l5.3.2zM69.1 14.8c-1.5-.1-2.9.4-4.1 1.2-1.1.8-1.8 2-2 3.3h10.9c.3 0 .6-.2.7-.5v-.2c0-1.1-.6-2.1-1.5-2.7-1.2-.7-2.6-1.1-4-1.1zM84.6 0h5.1v32.1h-5.1V0zM96.3 32.1V0h5.1v32.1h-5.1zm8-8.3c-.7-.8-1.2-1.8-1.4-2.8 0-.4.1-.8.2-1.2.3-.6.7-1.1 1.2-1.5l6.8-7.5h6.3l-8.3 9c-.3.2-.5.6-.5 1 .1.3.3.7.5.9l8.7 10.3h-6.7l-6.8-8.2zM120.5 20.6c-.1-2.8.9-5.5 2.8-7.5 4.3-3.6 10.5-3.6 14.8 0 2 2 3 4.7 2.9 7.5V32h-5.1V20.8c.1-1.4-.4-2.8-1.4-3.9-2.1-1.8-5.3-1.8-7.4 0-1 1-1.5 2.5-1.4 3.9v11.3h-5.1l-.1-11.5zM166.8 25.5c-.6 2.2-2 4.1-4 5.3-2 1.2-4.3 1.8-6.7 1.7-6.1-.1-11-5.1-10.9-11.2v-.2c.1-2.8 1.2-5.5 3.1-7.6 2.1-2.1 5-3.2 8-3.1 2.8-.1 5.5.9 7.6 2.8 2 1.9 3 4.5 2.9 7.2 0 .9-.1 1.8-.3 2.7h-15.3c-.6 0-.9.3-.9.8.1 1.1.7 2.1 1.7 2.7 1.2.8 2.6 1.3 4.1 1.2 2.8 0 4.6-.8 5.4-2.5l5.3.2zm-10.3-10.7c-1.5-.1-2.9.4-4.1 1.2-1.1.8-1.8 2-2 3.3h10.9c.3 0 .6-.2.7-.5v-.2c0-1.1-.6-2.1-1.5-2.7-1.2-.7-2.6-1.1-4-1.1zM182.1 10.9h2.4V16h-2.4c-1.6-.1-3.1.5-4.2 1.7s-1.7 2.8-1.6 4.4v10.1h-5.1V22.1c0-3.8 1-6.6 2.9-8.4 1.9-1.9 4.6-2.8 8-2.8zM24.7 10.9c-1.7 6.8-5.6 16-9.5 16s-7.8-9.2-9.4-16H0c.8 3.5 1.9 7 3.3 10.3 3.2 7.5 7.2 11.3 11.9 11.3s8.7-3.8 11.9-11.3c1.4-3.3 2.5-6.8 3.4-10.3h-5.8z"
      ></path>
    </svg>
  );
};
