import { dateTypeName, booleanTypeName } from "features/logs";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";

export const TypedValue = ({ type, value }) => {
  if (value === null || value === undefined) return;
  if (type === booleanTypeName) return <BooleanValue value={value} />;
  if (type === dateTypeName && !!value)
    return <span>{dayjs(value).format("YYYY-MM-DD H:mm:ss")}</span>;
  if (typeof value === "string" && value.length > 1)
    return (
      // value[0].toUpperCase() + value.replaceAll("_", " ").slice(1).toLowerCase()
      value.replaceAll("_", " ")
    );
  return value;
};

const BooleanValue = ({ value }) => {
  if (value === true) return <CheckCircleFilled className="text-green-500" />;
  if (value === false) return <CloseCircleFilled className="text-red-300" />;
};
