import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";
import { useAuthStore } from "features/auth";
import { apiClient } from "lib/api-client";

const login = async data => {
  await createSanctumToken();
  return apiClient({
    method: "POST",
    url: "api/auth/login",
    data: JSON.stringify(data),
  });
};

const createSanctumToken = () => apiClient.get("sanctum/csrf-cookie");

export const useLogin = () => {
  const { mutate: submit, isLoading } = useMutation({
    mutationFn: login,
    onSuccess: user => {
      useAuthStore.getState().putUser(user);
      queryClient.setQueryData(["auth-user"], user); // TODO
    },
  });

  return { submit, isLoading };
};
