import { Diagram } from "./Diagram";
import { Tabs } from "antd";
import {
  useProductsStats,
  useProductsGraph,
  useInvoicesStats,
  useInvoicesGraph,
  useStatisticsStore,
  useProductsStatsByDevices,
  DevicesTable,
  InvoicesTable,
} from "features/statistics";
import { STATISTICS_TABS } from "features/statistics";
import { useMemo } from "react";

export const StatisticsItem = ({ operation }) => {
  const isRegistration = operation === "Registration";
  const store = useStatisticsStore();

  const { productsStats, isProductsStatsLoading } = useProductsStats();
  const { productsGraph, isProductsGraphLoading } = useProductsGraph();
  const { invoicesStats, isInvoicesStatsLoading } = useInvoicesStats();
  const { invoicesGraph, isInvoicesGraphLoading } = useInvoicesGraph();
  const { productsStatsByDevices, isProductsByDevicesStatsLoading } =
    useProductsStatsByDevices();

  const statsData = store.byDevices ? productsStatsByDevices : productsStats;
  const statsLoading = store.byDevices
    ? isProductsByDevicesStatsLoading
    : isProductsStatsLoading;

  const registrationTabs = useMemo(
    () => [
      {
        label: STATISTICS_TABS[0].tabs[0].name,
        key: STATISTICS_TABS[0].tabs[0].name,
        children: (
          <div className="flex flex-col flex-1 h-full">
            <Diagram loading={isInvoicesGraphLoading} data={invoicesGraph} />
            <InvoicesTable
              data={invoicesStats?.data}
              isLoading={isInvoicesStatsLoading}
              total={invoicesStats?.total_count}
              store={store}
            />
          </div>
        ),
      },
      {
        label: STATISTICS_TABS[0].tabs[1].name,
        key: STATISTICS_TABS[0].tabs[1].name,
        children: (
          <div className="flex flex-col flex-1 h-full">
            <Diagram loading={isProductsGraphLoading} data={productsGraph} />
            <DevicesTable
              data={statsData?.data}
              isLoading={statsLoading}
              total={statsData?.total_count}
              store={store}
            />
          </div>
        ),
      },
      {
        label: STATISTICS_TABS[0].tabs[2].name,
        key: STATISTICS_TABS[0].tabs[2].name,
        children: (
          <div className="flex flex-col flex-1 h-full">
            <Diagram loading={isProductsGraphLoading} data={productsGraph} />
            <DevicesTable
              data={statsData?.data}
              isLoading={statsLoading}
              total={statsData?.total_count}
              store={store}
            />
          </div>
        ),
      },
    ],
    [
      operation,
      statsData,
      statsLoading,
      invoicesStats,
      invoicesGraph,
      productsGraph,
    ],
  );

  return (
    <div
      className="bg-white rounded rounded-b max-w-5xl w-full mx-auto px-4
      flex flex-1 flex-col pt-3"
    >
      {!isRegistration && (
        <>
          <Diagram loading={isProductsGraphLoading} data={productsGraph} />
          <DevicesTable
            data={statsData?.data}
            isLoading={statsLoading}
            total={statsData?.total_count}
            store={store}
          />
        </>
      )}

      {isRegistration && (
        <Tabs
          activeKey={store.activeRegistrarTab}
          onChange={store.setActiveRegistrarTab}
          destroyInactiveTabPane
          className="overflow-y-auto max-w-5xl w-full mx-auto flex flex-1"
          items={registrationTabs}
          centered
          tabBarStyle={{ marginBottom: 0 }}
          size="small"
        />
      )}
    </div>
  );
};
