import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const importInvoice = data =>
  apiClient.post("api/invoices/import", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useImportInvoice = ({ onSuccess }) => {
  const { mutate: upload, isLoading: isImporting } = useMutation(
    data => importInvoice(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["invoices"]);
        onSuccess?.(res);
      },
    },
  );

  return { upload, isImporting };
};
