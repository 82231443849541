import { Collapse, Button } from "antd";
import { SelectOption } from "./OptionsItem";
import { useFormConstructor } from "features/directory";
import { useMemo, useRef, useState } from "react";
import { SearchInput } from "components";
const { Panel } = Collapse;

export const Options = ({
  showOptionMenu = false,
  showCategories = false,
  fieldIndex,
  readOnly = false,
  disabled,
  onRemoveOption,
  onChangeOption,
  onAddNewOption,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const field = useFormConstructor(state => state.fields[fieldIndex]);
  const options = field?.options || [];
  const scrollRef = useRef(null);

  const optionItems = useMemo(
    () =>
      options.reduce((accumulator, item, i) => {
        if (
          !!searchQuery.trim() &&
          !item.value.toLowerCase().includes(searchQuery.trim().toLowerCase())
        )
          return accumulator;

        return accumulator.concat(
          <SelectOption
            showOptionMenu={showOptionMenu}
            showCategories={showCategories}
            readOnly={readOnly}
            disabled={disabled}
            key={item.key}
            fieldIndex={fieldIndex}
            index={i}
            value={item.value}
            onChange={e => onChangeOption(fieldIndex, e.target.value, item.key)}
            onRemove={() => onRemoveOption(item.key)}
            onEnter={e => {
              if (i === options.length - 1 && !!e.target.value) {
                onAddNewOption();
              }
            }}
          />,
        );
      }, []),
    [options.length, disabled, fieldIndex, readOnly, searchQuery],
  );

  return (
    <Collapse disabled={disabled} size="small" ghost expandIconPosition="end">
      <Panel
        className="[&_.ant-collapse-content-box]:!p-0 "
        key="blah"
        forceRender
        header={
          <div
            className="text-gray-400 hover:text-gray-600 text-end text-xs"
            style={{ paddingTop: 2 }}
          >
            {`Options: (${options.filter(opt => !!opt.value).length})`}
          </div>
        }
      >
        <div className="ml-4 mb-2">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>

        <div
          ref={scrollRef}
          className="flex flex-col gap-2 max-h-96 overflow-y-auto py-2 pl-4 pr-6 overflow-x-hidden"
        >
          {optionItems}
        </div>

        <div className="pl-4 pr-6 py-2 flex">
          {!readOnly && (
            <Button
              className="w-full"
              type="default"
              disabled={
                (options.length && options.some(opt => !opt?.value)) || disabled
              }
              onClick={() => {
                onAddNewOption();
                setTimeout(() => {
                  scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                });
              }}
            >
              +
            </Button>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};
