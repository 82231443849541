import { useSkuPage } from "features/devices";
import {
  useParams,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import { Loader } from "components";
import {
  SalesHistory,
  SalesControl,
  SkuInfo,
  AveragePrice,
} from "features/sales";
import { useEffect, useMemo } from "react";
import { MarketplaceIcon } from "features/marketplaces";
import { pusher } from "lib/socket-client";
import { queryClient } from "lib/react-query-client";

export const OnSaleSku = () => {
  const navigate = useNavigate();
  const { gradeId, skuId } = useParams();
  const [searchParams] = useSearchParams();
  const original_box = searchParams.get("original_box");

  const { sku, isLoading, isFetching } = useSkuPage({
    original_box,
    skuId,
    gradeId,
    onError: err => {
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const header = useMemo(() => {
    if (!sku) return `SKU #${skuId}`;

    const postfix = original_box === "1" || gradeId !== "1" ? "" : "-NV";
    const { name, sku_display } = sku?.data?.grade;

    return `${sku_display ? name + "-" : "SKU "}${
      sku?.data?.sku.sku_number
    }${postfix}`;
  }, [sku, skuId, original_box]);

  const pairLink = sku?.data.monitor_pair ? (
    <Link
      className="ml-2"
      to={`/sales-control/sku-monitoring?pair=${sku?.data.monitor_pair}`}
      target="_blank"
    >
      Paired
    </Link>
  ) : null;

  useEffect(() => {
    const channelName = `marketplace-sync-channel-${skuId}-${gradeId}-${
      original_box ? 1 : 0
    }`;
    const channel = pusher.subscribe(channelName);
    channel.bind("marketplace-sync-event", () => {
      queryClient.invalidateQueries("sku-marketplaces");
      queryClient.invalidateQueries("sku-sales-history");
      queryClient.invalidateQueries("sku-page");
    });

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, []);

  return (
    <div className="flex flex-col h-full relative">
      <h2 className="text-center mb-12">
        {header}
        {pairLink}
      </h2>

      {sku && (
        <div className="flex-1 overflow-auto">
          <div className="flex flex-wrap gap-4 justify-around items-center max-w-5xl w-full mx-auto px-4">
            <SkuInfo sku={sku} original_box={original_box} />
          </div>

          <div className="mb-24">
            <h3 className="text-center mt-16">Check Price</h3>

            <div className="px-4 max-w-5xl w-full mx-auto mb-4">
              <div className="flex bg-white justify-around items-center mb-1  flex-wrap">
                {Object.entries(sku.data?.price_statistic_links).map(
                  ([key, value]) => {
                    return (
                      <Link
                        to={value}
                        key={key}
                        target="_blank"
                        className="flex justify-center flex-1 hover:bg-slate-50"
                      >
                        <div className="h-14  flex justify-center items-center w-24 px-2">
                          <MarketplaceIcon name={key} />
                        </div>
                      </Link>
                    );
                  },
                )}
              </div>

              <AveragePrice skuId={skuId} gradeId={gradeId} />
            </div>
          </div>

          <SalesControl
            skuId={skuId}
            gradeId={gradeId}
            amount={sku?.data?.products.in_sale}
            deviceType={sku?.data.sku?.device_type?.device_type_id}
          />

          <SalesHistory skuId={skuId} gradeId={gradeId} />
        </div>
      )}

      {(isLoading || isFetching) && <Loader />}
    </div>
  );
};
