import {
  useProductSKUs,
  useDisconnectedSKUsStore,
  ProductsToolbar,
  QUERY_NAMES,
  ProductsTable,
  ProfitStats,
  ProfitabilityPage,
} from "features/profitability";

export const DisconnectedSKUs = () => {
  const store = useDisconnectedSKUsStore();

  const {
    data: skus,
    isFetching,
    isLoading,
  } = useProductSKUs({
    store,
    groupName: "disconnected",
    queryName: QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED,
  });

  const isProcessing = !skus || isLoading || isFetching;

  return (
    <ProfitabilityPage
      store={store}
      title="Disconnected Products by SKU"
      preloadFormula
    >
      <ProfitStats
        data={skus}
        keys={["total_count", "total_sale_price", "total_formula_price"]}
      />

      <ProductsToolbar
        store={store}
        disabled={isProcessing}
        isLoading={isProcessing}
        allowExport={!!skus?.data.length}
        groupName="disconnected"
        isSKU
      />

      <ProductsTable
        isSKUTable
        isLoading={isProcessing}
        queryName={QUERY_NAMES.PRODUCTS.SKUS.DISCONNECTED}
        data={skus?.data}
        idKey="item_id"
        store={store}
      />
    </ProfitabilityPage>
  );
};
