import { Checkbox, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useReadyListSelection, ReadyListSelector } from "features/sending";
import { SKULink } from "features/sales";
import dayjs from "dayjs";
import ReactCountryFlag from "react-country-flag";

export const readyProductsConfig = [
  {
    key: "checkboxes",
    dataIndex: "product_id",
    width: 40,
    title: () => <ReadyListSelector />,
    render: id => (
      <Checkbox
        checked={useReadyListSelection.getState().isSelected(id)}
        onChange={({ target }) => {
          useReadyListSelection.getState().setSelection(id, target.checked);
        }}
      />
    ),
  },
  {
    title: "ID",
    key: "id",
    dataIndex: "product_id",
    width: 90,
    render: id => <Link to={`/devices/${id}`}>{id}</Link>,
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    width: "12%",
    render: (_, record) => <SKULink device={record} />,
  },
  {
    title: "Type",
    align: "center",
    key: "device_type",
    dataIndex: ["device_type", "name"],
  },
  {
    title: "Device",
    key: "device",
    align: "center",
    render: (_, { color, field_values }) => {
      const model =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Model",
        )?.device_field.device_field_option[0]?.value || "";

      const memory =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      return (
        <Tooltip
          placement="topLeft"
          title={`${model} ${memory} ${color?.name || ""}`}
        >{`${model} ${memory} ${color?.name || ""}`}</Tooltip>
      );
    },
  },
  {
    title: "Order",
    key: "order",
    align: "center",
    dataIndex: "sale_histories",
    render: sh => {
      const order = sh?.[0]?.order;
      if (!order) return "";
      return (
        <Link to={order?.order_link} target={"_blank"}>
          {order?.order_id}
        </Link>
      );
    },
  },
  {
    title: "Country",
    key: "country",
    align: "center",
    dataIndex: "sale_histories",
    render: sh => {
      const code = sh?.[0]?.order?.country_code;
      if (!code) return null;
      return (
        <div className="flex items-center gap-1.5 justify-center text-slate-600">
          <ReactCountryFlag countryCode={code} svg />
          {code}
        </div>
      );
    },
  },
  {
    title: "Ready from Date",
    key: "ready_for_sending_date",
    align: "center",
    dataIndex: "ready_for_sending_date",
    render: date => (date ? dayjs(date).format("YYYY-MM-DD  H:mm") : "?"),
  },
];
