import { Link } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const InvoiceLink = ({
  invoiceId,
  label = null,
  rightRoles = ["admin", "registrar", "sales_manager"],
}) => {
  const { isRight } = useAuthStore();

  if (isRight(rightRoles))
    return (
      <Link
        className="whitespace-nowrap"
        to={`/invoices/manage/${invoiceId}`}
        target="_blank"
      >
        {label || invoiceId}
      </Link>
    );

  return <span>{label || invoiceId}</span>;
};
