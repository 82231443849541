import { PaginatedSelect } from "components";
import { Form } from "antd";
import { useInfiniteSKUs } from "features/directory";
import { Device } from "features/devices";

export const SkuInput = ({
  className,
  disabled,
  onClear,
  onChange,
  initialData,
  typeId = null,
  hidden = false,
}) => {
  const {
    skusData,
    skuOptions,
    isFetchingNextPage,
    isLoading: isLoadingSKUs,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteSKUs({ typeId });

  const noInitialOption =
    initialData &&
    !skuOptions?.some(({ value }) => value === initialData?.sku_id);

  return (
    <Form.Item label="SKU" className={className} name="sku_id" hidden={hidden}>
      <PaginatedSelect
        disabled={disabled}
        searchValue={searchQuery}
        isLoading={isLoadingSKUs}
        placeholder="Select SKU"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        onClear={onClear}
        onChange={id => {
          if (skusData[id]) onChange(skusData[id]);
        }}
        options={
          noInitialOption
            ? [Device.getSkuOption(initialData), ...skuOptions]
            : skuOptions
        }
      />
    </Form.Item>
  );
};
