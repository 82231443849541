import { Input, Form, Select, Spin, Switch } from "antd";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDepartments } from "features/directory";
import { useRoles } from "features/employees";
import _ from "lodash";

export const EmployeeForm = ({
  onFinish,
  form,
  disabled,
  isAdding = false,
  onValuesChange,
  layout = "vertical",
  labelCol,
  wrapperCol,
}) => {
  const { departments, isLoading } = useDepartments();
  const { roles, isRolesLoading } = useRoles();

  const rolesOptions = roles
    ? Object.keys(roles).map(roleValue => ({
        value: roleValue,
        label: _.capitalize(roleValue.replaceAll("_", " ")),
      }))
    : [];

  return (
    <Form
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      form={form}
      disabled={disabled}
      layout={layout}
      requiredMark={false}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label={<b>Confidant</b>}
        valuePropName="checked"
        name="confidant"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="First Name"
        name="first_name"
        rules={[
          {
            required: true,
            message: "Please input first name!",
          },
        ]}
      >
        <Input placeholder="Enter First Name" />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[
          {
            required: true,
            message: "Please input last name!",
          },
        ]}
      >
        <Input placeholder="Enter Last Name" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input email!",
          },
          {
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: "Email must be valid value",
          },
          {
            pattern: /^[A-Za-z0-9._%+-]+@firstcomtrading\.de$/,
            message: "Domain must be @firstcomtrading.de",
          },
        ]}
      >
        <Input placeholder="Enter Email" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone_number"
        rules={[
          {
            required: true,
            message: "Please input your phone!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || isValidPhoneNumber(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Invalid phone number!"));
            },
          }),
        ]}
      >
        <Input placeholder="Enter Phone" />
      </Form.Item>

      <Form.Item
        label="Departments"
        name="department"
        rules={[
          {
            required: true,
            message: "Please input departments!",
          },
        ]}
      >
        <Select
          mode="multiple"
          allowClear
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          placeholder="Select Departments"
          onChange={() => {
            return;
          }}
          options={
            departments
              ? departments.data.map(item => ({
                  label: item.name,
                  value: +item.id,
                }))
              : []
          }
          filterOption={(searchValue, { label }) =>
            label?.toLowerCase().includes(searchValue?.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Position"
        name="role"
        rules={[
          {
            required: true,
            message: "Please input role!",
          },
        ]}
      >
        <Select
          mode="multiple"
          allowClear
          placeholder="Select Positions"
          onChange={() => {
            return;
          }}
          options={rolesOptions}
          loading={isRolesLoading}
        />
      </Form.Item>

      {!isAdding && (
        <Form.Item label="Dismissed" valuePropName="checked" name="dismissed">
          <Switch />
        </Form.Item>
      )}

      {isAdding && (
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            { pattern: /.{8,}/, message: "Minimum 8 symbols" },
            {
              pattern: /[a-z]/,
              message: "At least one lower case letter",
            },
            {
              pattern: /[A-Z]/,
              message: "At least one upper case letter",
            },
            {
              pattern: /\W/,
              message: "At least one symbol",
            },
            {
              pattern: /[\d]/,
              message: "At least one number",
            },
          ]}
        >
          <Input.Password placeholder="Enter Password" />
        </Form.Item>
      )}

      {isAdding && (
        <Form.Item
          label="Repeat Password"
          name="repeatPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!"),
                );
              },
            }),
          ]}
          style={{ margin: 0 }}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
      )}
    </Form>
  );
};
