import { FilterDrawer, FilterSummary } from "components";
import { Spin } from "antd";
import { EmployeesFilter, DatesFilter, LogTypeFilter } from "features/filter";
import { useMemo } from "react";

export const LogsFilter = ({ store, disabled, isLoading }) => {
  const { start_date, end_date, filter_by } = store;

  const active = useMemo(() => {
    const active = Object.entries(filter_by).map(([key, value]) => ({
      label: key,
      value,
    }));

    if (start_date) active.push({ label: "Start Date", value: start_date });
    if (end_date) active.push({ label: "End Date", value: end_date });

    const tooltip = active.length ? <FilterSummary list={active} /> : null;

    return { count: active.length, tooltip };
  }, [start_date, end_date, filter_by]);

  return (
    <FilterDrawer
      title={
        <span>
          Logs Filter
          {isLoading && <Spin size="small" className="ml-2" />}
        </span>
      }
      onClear={store.clearFilters}
      activeCount={active.count}
      btnStyle="primary"
      // tooltip={active.tooltip}
      disabled={disabled}
    >
      <div className="flex flex-col gap-8 px-6 py-10">
        <DatesFilter store={store} />
        <EmployeesFilter store={store} />
        <LogTypeFilter store={store} />
      </div>
    </FilterDrawer>
  );
};
