import { Badge, Button, Dropdown, Form, Input, message, Modal } from "antd";
import { useStatusOptions, useStatusChange } from "features/devices";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { requiredRule } from "constants/validation";

const { confirm, info } = Modal;
const { TextArea } = Input;

const excludedStatuses = ["ON_SALE", "IN_COMMISSION_LIST", "READY_FOR_SENDING"];

export const DevicesStatusChange = ({
  disabled,
  count,
  ids = [],
  clearSelection = () => {},
}) => {
  const [form] = useForm();
  const { statuses } = useStatusOptions();

  const [showModal, setShowModal] = useState(false);

  const { changeStatus, isChanging } = useStatusChange(response => {
    message.success({
      content: response.message || "Success!",
    });
    form.resetFields();
    setShowModal(false);
    info({
      title: "Status is updated for next devices:",
      content: ids.join(", "),
      onOk: clearSelection,
    });
  });

  const onSelectStatus = status => {
    confirm({
      title: "Attention!",
      icon: <ExclamationCircleFilled />,
      content:
        "If the selection includes devices currently On Sale, the devices " +
        "will be automatically removed from sale on marketplaces.",
      onOk: () => {
        setShowModal(true);
        form.setFieldValue("status", status);
      },
    });
  };

  const onChangingStatus = val => {
    changeStatus({ ...val, product_ids: ids });
  };

  const onCancelChangingStatus = () => {
    if (!isChanging) {
      setShowModal(false);
      form.resetFields();
    }
  };

  const statusOptions = statuses?.data
    ? Object.entries(statuses?.data)
        .filter(([key]) => !excludedStatuses.includes(key))
        .map(([key, label]) => ({
          onClick: () => onSelectStatus(key),
          key,
          label,
        }))
    : null;

  return (
    <Badge count={count} size="small" color="red" offset={[-4, 0]}>
      <Dropdown
        trigger={["click"]}
        menu={{ items: statusOptions }}
        placement="bottom"
        disabled={disabled || !statusOptions}
      >
        <Button type="primary" className="text-xs sm:text-sm">
          Status Change
        </Button>
      </Dropdown>

      <Modal
        title="Comment"
        open={showModal}
        onOk={form.submit}
        maskClosable={false}
        onCancel={onCancelChangingStatus}
        confirmLoading={isChanging}
      >
        <Form disabled={isChanging} form={form} onFinish={onChangingStatus}>
          <Form.Item name="comment" rules={[requiredRule]}>
            <TextArea placeholder="Enter Comment" />
          </Form.Item>

          <Form.Item hidden name="status" />
        </Form>
      </Modal>
    </Badge>
  );
};
