import { EditFilled } from "@ant-design/icons";
import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useReplaceDevice } from "../api/replace-device";
import { useForm } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import { useComListStore } from "../store";

export const ReplaceDevice = ({ deviceId }) => {
  const { commissionListId } = useParams();
  const [form] = useForm();
  const { clearSelection } = useComListStore();

  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const { replace, isReplacing } = useReplaceDevice({
    listId: commissionListId,
    onSuccess: response => {
      message.success({
        content: response?.message || "Replaced Successful!",
      });
    },
  });

  const onFinish = async ({ change_product_id }) => {
    clearSelection();
    await replace({ exist_product_id: deviceId, change_product_id });
  };

  return (
    <>
      <Button
        type={"link"}
        icon={<EditFilled className="text-blue-300 hover:text-blue-500" />}
        onClick={() => setIsOpen(true)}
      />

      <Modal
        open={isOpen}
        onCancel={() => {
          if (!isReplacing) close();
        }}
        title="Replace Device"
        onOk={form.submit}
        confirmLoading={isReplacing}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          disabled={isReplacing}
        >
          <Form.Item
            label="New Device Id"
            name="change_product_id"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
