import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { globalErrorHandler } from "lib/react-query-client";

const restorePassword = async data => {
  await createSanctumToken();
  return apiClient.post("api/users/forgot-password", {
    ...data,
  });
};

const createSanctumToken = () => apiClient.get("sanctum/csrf-cookie");

export const useRestorePassword = ({ onSuccess, onError } = {}) => {
  const { mutateAsync: restore, isLoading } = useMutation(restorePassword, {
    onSuccess: res => onSuccess?.(res),
    onError: err => {
      globalErrorHandler(err);
      onError?.();
    },
  });

  return { restore, isLoading };
};
