import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const createCommissionList = body => {
  return apiClient.post("api/sending/create-commission-list", body);
};

export const useNewCommissionList = ({ onSuccess } = {}) => {
  const { mutate: createComList, isLoading: isCreating } = useMutation(
    body => createCommissionList(body),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["sending-ready-list"]);
        queryClient.invalidateQueries(["sending-commission-lists"]);
        onSuccess?.(data);
      },
    },
  );

  return { createComList, isCreating };
};
