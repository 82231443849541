import { useParams } from "react-router-dom";
import { RegistrarForm, useDevice, useIdentifyDevice } from "features/devices";
import { Loader } from "components";
import { useState } from "react";
import { Button, Checkbox, Form, message } from "antd";
import { useMediaQuery } from "react-responsive";

export const RegistrarScannerForm = ({
  onSuccess,
  onCancel,
  onCloseScanner,
}) => {
  const isTightly = useMediaQuery({
    query: "(max-width: 560px)",
  });

  const [form] = Form.useForm();
  const { deviceId } = useParams();
  const [keepOpen, setKeepOpen] = useState(true);
  const { device, isLoading, isFetching } = useDevice({
    id: deviceId,
    onSuccess: res => {
      const status = res?.data?.step_of_process;
      if (status !== "ENTERED") {
        message.warning("Already identified");
        onCancel();
      }
    },
  });

  const { identify, isIdentifying } = useIdentifyDevice({
    id: deviceId,
    onSuccess: response => {
      message.success(response.message || "Success!");
      onSuccess?.();
      if (!keepOpen) onCloseScanner();
    },
  });

  if (isLoading && isFetching) return <Loader />;

  if (device?.data?.step_of_process === "ENTERED")
    return (
      <div className="absolute inset-0 w-full flex justify-center items-center bg-slate-100 z-10">
        <div className="w-10/12 @[360px]:w-4/6">
          <RegistrarForm
            disabled={isIdentifying}
            form={form}
            deviceFields={device.data.device_type?.fields}
            onSubmit={data => {
              data && identify(data);
            }}
          />

          <div className="flex gap-2 items-center flex-wrap">
            <Checkbox
              disabled={isIdentifying}
              checked={keepOpen}
              className="mr-auto text-xs @[260px]:text-sm"
              onChange={() => setKeepOpen(!keepOpen)}
            >
              Keep{" "}
              <span className="hidden @[290px]:inline-block"> scanner </span>{" "}
              open
            </Checkbox>

            <div className="flex gap-2 ml-auto">
              <Button
                className="text-xs @[260px]:text-sm"
                size={isTightly ? "small" : "middle"}
                disabled={isIdentifying}
                onClick={() => {
                  onCancel?.();
                  if (!keepOpen) onCloseScanner();
                }}
              >
                Cancel
              </Button>

              <Button
                className="text-xs @[260px]:text-sm"
                size={isTightly ? "small" : "middle"}
                loading={isIdentifying}
                type="primary"
                onClick={form.submit}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
};
