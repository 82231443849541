import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { FrontEndError } from "components";
import { useAuthStore } from "features/auth";

Bugsnag.start({
  apiKey: import.meta.env.VITE_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  onError: function (event) {
    const { user } = useAuthStore.getState();
    event.setUser(user?.data?.id, user?.data?.email, user?.data?.first_name);
  },
  enabledReleaseStages: ["production", "staging"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={FrontEndError}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
