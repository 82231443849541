import {
  useSupplier,
  useEditSuppliers,
  useRemoveSupplier,
} from "features/directory";
import { Loader } from "components";
import { useParams } from "react-router-dom";
import { message, Button, Row, Col, App, Form, Input } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { globalErrorHandler } from "lib/react-query-client";
import { useAuthStore } from "features/auth";

export const Supplier = () => {
  const { isRight } = useAuthStore();
  const isAdmin = isRight(["admin"]);
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);

  const navigate = useNavigate();
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const { supplierId } = useParams();

  const { supplier, isSupplierLoading } = useSupplier({
    id: supplierId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { edit, isEditing } = useEditSuppliers(supplierId, () => {
    navigate(`/directory/suppliers`);
    message.success("Success!");
  });

  const { remove, isRemoving } = useRemoveSupplier(supplierId, () =>
    message.success("Removed!"),
  );

  const onSubmit = data => {
    const supplierData = {
      supplier_name: data.supplier_name,
      supplier_nr: data.supplier_nr,
    };
    edit(supplierData);
  };

  useEffect(() => {
    if (supplier) {
      form.setFieldsValue({
        supplier_name: supplier.data.supplier_name,
        supplier_nr: supplier.data.supplier_nr,
      });
    }
  }, [supplier]);

  return (
    <div
      style={{
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: "0 20px",
        position: "relative",
        height: "100%",
      }}
    >
      <Row justify="center">
        {supplier ? (
          <h2
            style={{ marginLeft: "25px" }}
          >{`Supplier #${supplier.data.supplier_nr}: ${supplier.data.supplier_name}`}</h2>
        ) : (
          <h2>{`Supplier #${supplierId}`}</h2>
        )}
        {/* <h2>{`Supplier #${supplierId}: ${
          supplier?.data?.supplier_name || ""
        }`}</h2> */}
      </Row>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
        style={{ position: "relative" }}
      >
        <Form.Item
          initialValue={supplier?.data.supplier_nr}
          label="Supplier Number"
          name="supplier_nr"
          rules={[
            {
              required: true,
              message: "Please input supplier number!",
            },
          ]}
        >
          <Input
            placeholder="Enter Supplier Number"
            disabled={isSupplierLoading || isEditing || isRemoving}
          />
        </Form.Item>

        <Form.Item
          initialValue={supplier?.data.supplier_name}
          label="Supplier Name"
          name="supplier_name"
          rules={[
            {
              required: true,
              message: "Please input supplier name!",
            },
          ]}
        >
          <Input
            placeholder="Enter Supplier Name"
            disabled={isSupplierLoading || isEditing || isRemoving}
          />
        </Form.Item>

        {rightRole && (
          <Row justify="center" gutter={12}>
            <Col>
              <Button
                danger
                onClick={() => {
                  modal.confirm({
                    title: "Supplier Deleting",
                    content: "Are you sure you want to delete the supplier?",
                    onOk: async () => await remove(),
                  });
                }}
                loading={isRemoving}
                disabled={isSupplierLoading || isEditing || !isAdmin}
              >
                Delete
              </Button>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isEditing}
                  disabled={isSupplierLoading || isRemoving}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}

        {isSupplierLoading && <Loader />}
      </Form>
    </div>
  );
};
