import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getDeviceTypes = id => apiClient.get(`api/directory/devices/${id}`);

export const useDevice = ({ id, onSuccess, onError }) => {
  const { data: device, isLoading } = useQuery(
    ["device-type", id],
    () => getDeviceTypes(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
      retry: false,
    },
  );

  return { device, isLoading };
};
