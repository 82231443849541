import { apiClient } from "lib/api-client";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { useInfiniteQuery } from "react-query";

const infinite_getShades = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteShades = onSuccess => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-shades", debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/directory/colors/shades?per_page=12&page=1`,
      }) =>
        infinite_getShades({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const shadeOptions = useMemo(
    () =>
      data?.pages
        ? data?.pages
            .map(({ data }) =>
              data?.map(({ name, id }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            )
            ?.flat()
        : [],
    [data],
  );

  return {
    shadeOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
