import { useInfiniteQuery, useQuery } from "react-query";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import dayjs from "dayjs";
import { apiClient } from "lib/api-client";
import { QUERY_NAMES } from "features/profitability";

const getFormulas = params =>
  apiClient.get("api/profit/listing/marketplace-formulas", {
    params,
  });

export const useMarketplaceFormulas = ({
  store,
  onSuccess,
  enabled = true,
} = {}) => {
  const { page = 1, per_page = 12, setTotal, setCurrentFormula } = store || {};

  const { data, isLoading, isFetching } = useQuery(
    [QUERY_NAMES.FORMULAS, page, per_page],
    () => getFormulas({ page, per_page }),
    {
      onSuccess: ({ meta, data }) => {
        setCurrentFormula?.(data[0].id);
        setTotal?.(meta.total, meta.last_page);
        onSuccess?.(data);
      },
      enabled,
    },
  );

  return { formulas: data, isLoading, isFetching };
};

// for infinite scroll
const infinite_getInvoices = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteMarketplaceFormulas = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      [QUERY_NAMES.FORMULAS_INFINITE, debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/profit/listing/marketplace-formulas?per_page=12&page=1`,
      }) =>
        infinite_getInvoices({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const buildFormulaOption = ({ id, created_at }) => ({
    value: id,
    label: dayjs(created_at).format("MMM-DD-YYYY H:mm"),
  });

  const formulasOptions = useMemo(() => {
    if (!data?.pages) return [];

    const formulas = data?.pages?.map(({ data }) => data)?.flat();
    return formulas.map(f => buildFormulaOption(f));
  }, [data]);

  return {
    formulasOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
