import useSound from "use-sound";
import bipSound from "assets/sounds/bip.mp3";
import successSound from "assets/sounds/success.mp3";
import failSound from "assets/sounds/fail.mp3";

export const useSounds = () => {
  const [playSuccess] = useSound(successSound);
  const [playFail] = useSound(failSound);
  const [playBip] = useSound(bipSound);

  return { playBip, playSuccess, playFail };
};
