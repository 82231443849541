import React from "react";
import { Button, Tooltip, App } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { onLogout } from "features/auth";
import { useMediaQuery } from "react-responsive";

export const LogOut = () => {
  const { modal } = App.useApp();
  const isMobile = useMediaQuery({ query: "(max-width: 639px)" });

  const onClick = () => {
    modal.confirm({
      title: "Log out",
      content: "Do you really want to log out of the system?",
      onOk: onLogout,
    });
  };

  return (
    <Tooltip title="Log out" color="purple">
      <Button
        size={isMobile ? "small" : "middle"}
        type="text"
        icon={<LogoutOutlined style={{ color: "#888" }} />}
        onClick={onClick}
      />
    </Tooltip>
  );
};
