import { useAddSupplier, useSuppliersSelection } from "features/directory";
import { useNavigate } from "react-router-dom";
import { Row, Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useAuthStore } from "features/auth";

export const AddSupplier = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const navigate = useNavigate();

  const { setPageSize } = useSuppliersSelection();

  const { add, isAdding } = useAddSupplier(() => {
    message.success("Success!");
    navigate(`/directory/suppliers`);
  });

  useEffect(() => {
    setPageSize(9999);
    return () => setPageSize(12);
  }, []);

  const onSubmit = data => {
    const supplierData = {
      supplier_name: data.supplier_name,
      supplier_nr: data.supplier_nr,
    };
    add(supplierData);
  };

  return (
    <div
      style={{
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: "0 20px",
        position: "relative",
        height: "100%",
      }}
    >
      <Row justify="center" style={{ marginBottom: 60 }}>
        <h2>Add New Supplier</h2>
      </Row>

      <Form layout="vertical" requiredMark={false} onFinish={onSubmit}>
        <Form.Item
          label="Supplier Number"
          name="supplier_nr"
          rules={[
            {
              required: true,
              message: "Please input supplier number!",
            },
          ]}
        >
          <Input
            type="number"
            controls={false}
            placeholder="Enter Supplier Number"
            disabled={isAdding}
          />
        </Form.Item>

        <Form.Item
          label="Supplier Name"
          name="supplier_name"
          rules={[
            {
              required: true,
              message: "Please input supplier name!",
            },
          ]}
        >
          <Input
            type="text"
            controls={false}
            placeholder="Enter Supplier Name"
            disabled={isAdding}
          />
        </Form.Item>
        {rightRole && (
          <Row justify="center">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isAdding}>
                Create
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
    </div>
  );
};
