import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Table, Pagination, message, Button, App, Badge } from "antd";
import MediaQuery from "react-responsive";

import {
  useSendingReadyList,
  useReadyListSelection,
  useNewCommissionList,
  SendingDevicesFilter,
  readyProductsConfig,
  RedirectDevice,
} from "features/sending";
import { SearchInput } from "components";
import { useAuthStore } from "features/auth";

export const SendingReadyList = () => {
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const store = useReadyListSelection();
  const { isRight } = useAuthStore();
  const isAdmin = isRight(["admin"]);

  const { readyDevices, isLoadingReadyDevices, isFetchingReadyDevices } =
    useSendingReadyList();

  const { createComList, isCreating } = useNewCommissionList({
    onSuccess: response => {
      message.success(response.message || "Success!");
      navigate(`/sending/devices/commission-list/${response.data.list_number}`);
    },
  });

  const idle = isLoadingReadyDevices || isFetchingReadyDevices || isCreating;

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center mb-6 sm:mb-10">Ready For Sending Devices</h2>

      <div className="flex justify-start max-w-5xl w-full mx-auto px-4 mb-3 gap-1 text-xs sm:gap-2">
        <MediaQuery minWidth={500}>
          <SearchInput
            searchQuery={store.searchQuery}
            setSearchQuery={store.setSearchQuery}
          />
        </MediaQuery>

        <div className="mr-auto">
          <SendingDevicesFilter
            disabled={isLoadingReadyDevices}
            store={store}
            isLoading={idle}
          />
        </div>

        {isAdmin && (
          <RedirectDevice
            selection={store.selection}
            disabled={idle}
            onSuccess={store.clearSelection}
          />
        )}

        <Badge
          className="mr-1"
          size="small"
          color="volcano"
          count={store.selection?.length}
          offset={[-4, 0]}
        >
          <Button
            className="text-xs sm:text-sm"
            disabled={!store.selection?.length || idle}
            loading={isCreating}
            onClick={() => {
              modal.confirm({
                title: "New commission list",
                content: (
                  <span className="text-gray-700">
                    Are you sure you want to create a new commission list with
                    the selected{" "}
                    <b className="text-blue-700 text-base">
                      {store.selection.length}
                    </b>{" "}
                    devices?
                  </span>
                ),
                onOk: () => {
                  store.clearSelection();
                  createComList({ product_ids: store.selection });
                },
              });
            }}
            type="primary"
          >
            <span className="after:content-['Send'] sm:after:content-['Send_Selected']" />
          </Button>
        </Badge>

        <Button
          className="text-xs sm:text-sm"
          disabled={idle || !readyDevices?.data.length}
          loading={isCreating}
          onClick={() => {
            modal.confirm({
              title: "New commission list",
              content: (
                <span className="text-gray-700">
                  Are you sure you want to create a new commission list with all{" "}
                  <b className="text-blue-700 text-base">{store.total}</b>{" "}
                  available devices?
                </span>
              ),
              onOk: () => {
                store.clearSelection();
                createComList({ all_products: true });
              },
            });
          }}
          type="primary"
        >
          Send All
        </Button>
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName={record => {
          const isSelected = store.selection.includes(record.product_id);
          const isCanceled = record.sale_histories[0]?.order?.cancel;
          return clsx({
            "bg-blue-50": isSelected,
            "text-red-400": isCanceled,
            "bg-rose-50": isCanceled && !isSelected,
            "bg-violet-100": isCanceled && isSelected,
          });
        }}
        loading={idle}
        columns={readyProductsConfig}
        dataSource={readyDevices?.data}
        sticky={true}
        rowKey={record => record.product_id}
        pagination={false}
        scroll={{ x: 720 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        current={store.page}
        pageSize={store.pageSize}
        onChange={store.setPage}
        disabled={store.idle}
      />
    </div>
  );
};
