import { Checkbox, Form, Input, Select } from "antd";

export const DeviceDynamicInput = ({
  field,
  disabled,
  className,
  groupName,
  rules = [],
  onSelect,
}) => {
  const isMultiSelect =
    field.type.toUpperCase() === "MULTISELECT" ||
    field.type.toUpperCase() === "MULTI-SELECT";

  if (field.type.toUpperCase() === "SELECT" || isMultiSelect) {
    return (
      <Form.Item
        className={className}
        label={field.name}
        name={[groupName, field.name]}
        key={field.id}
        rules={rules}
      >
        <Select
          disabled={disabled}
          showSearch
          allowClear
          showArrow
          onSelect={() => onSelect?.()}
          placeholder={`Select ${field.name}`}
          mode={isMultiSelect ? "multiple" : null}
          filterOption={(searchValue, { label }) =>
            label?.toLowerCase().includes(searchValue?.toLowerCase())
          }
          options={field.options.map(opt => ({
            label: opt.value,
            value: opt.id || opt.value,
          }))}
        />
      </Form.Item>
    );
  }

  if (field.type.toUpperCase() === "CHECKBOX") {
    return (
      <Form.Item
        initialValue={false}
        className={className}
        name={[groupName, field.name]}
        key={field.id}
        valuePropName="checked"
      >
        <Checkbox disabled={disabled}>{field.name}</Checkbox>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      rules={rules}
      className={className}
      label={field.name}
      name={[groupName, field.name]}
      key={field.id}
    >
      <Input disabled={disabled} placeholder="Type here" />
    </Form.Item>
  );
};
