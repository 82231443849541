import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import dayjs from "dayjs";

const export_invoice = async data => {
  const file = await apiClient.post("api/invoices/export", data);

  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `invoice_${data.invoice_id}-products_${dayjs().format("YYYY-MM-DD")}.csv`,
  );
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

export const useInvoiceExport = onSuccess => {
  const { mutate: exportInvoice, isLoading: isExporting } = useMutation(
    data => export_invoice(data),
    {
      onSuccess: devices => {
        onSuccess?.(devices);
      },
    },
  );

  return { exportInvoice, isExporting };
};
