import { useEffect } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import {
  useCreateAccessoryCategory,
  useEditAccessoryCategory,
  useRemoveAccessoryCategory,
} from "features/directory";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;

export const AccessoryCategoryForm = ({ isOpen, initialData, onClose }) => {
  const [form] = Form.useForm();

  const closeAndClear = () => {
    onClose();
    form.resetFields();
  };

  const onSuccess = res => {
    message.success(res.message);
    closeAndClear();
  };
  const { create, isCreating } = useCreateAccessoryCategory({ onSuccess });
  const { edit, isEditing } = useEditAccessoryCategory({ onSuccess });
  const { remove, isRemoving } = useRemoveAccessoryCategory({ onSuccess });

  const onConfirm = value => {
    if (initialData) {
      edit({ id: initialData.id, data: value });
    } else {
      create(value);
    }
  };

  const onRemove = () => {
    Modal.confirm({
      title: "Attention!",
      icon: <ExclamationCircleFilled />,
      content: (
        <span>
          Are you sure you want to delete category{" "}
          <strong>{initialData.name}</strong>?
        </span>
      ),
      okText: "Delete",
      onOk: () => {
        remove(initialData.id);
      },
    });
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldValue("name", initialData.name);
      form.setFieldValue("description", initialData.description);
    }
  }, [isOpen, initialData]);

  const idle = isCreating || isEditing || isRemoving;
  const title = initialData ? "Edit Category" : "Create Category";

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={() => !idle && closeAndClear()}
      destroyOnClose
      footer={
        <div className="flex">
          {initialData && (
            <Button
              danger
              icon={<DeleteFilled />}
              onClick={onRemove}
              disabled={idle}
            >
              Delete
            </Button>
          )}

          <div className="ml-auto">
            <Button disabled={idle} onClick={() => !idle && closeAndClear()}>
              Cancel
            </Button>

            <Button
              type="primary"
              onClick={form.submit}
              loading={isEditing}
              disabled={idle}
            >
              Ok
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} onFinish={onConfirm} disabled={idle} className={"mt-6"}>
        <Form.Item
          required
          name="name"
          label="Name"
          labelCol={{ span: 5 }}
          rules={[requiredRule]}
        >
          <TextArea autoSize placeholder="Enter accessory name" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          labelCol={{ span: 5 }}
        >
          <TextArea
            autoSize
            placeholder="Enter some description if necessary"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
