import {
  LaptopOutlined,
  SearchOutlined,
  AuditOutlined,
  FieldTimeOutlined,
  EuroOutlined,
  BarsOutlined,
  TeamOutlined,
  PieChartOutlined,
  DatabaseOutlined,
  RocketOutlined,
  GiftOutlined,
  FundProjectionScreenOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const visorExcluded = [
  "admin",
  "registrar",
  "grader",
  "flasher",
  "packer",
  "sales_manager",
  "sender",
  "head_of_flasher",
];

const config = [
  {
    icon: <LaptopOutlined />,
    key: "/dashboard",
    label: "Dashboard",
    permissions: [
      "admin",
      "sales_manager",
      "grader",
      "flasher",
      "packer",
      "head_of_flasher",
    ],
  },
  {
    icon: <SearchOutlined />,
    key: "/devices",
    label: "Search Device",
  },
  {
    key: "/invoices",
    icon: <AuditOutlined />,
    label: "Invoices",
    permissions: ["admin", "sales_manager", "prioritizer", "registrar"],
    children: [
      { key: "/invoices/manage", label: "Import/Manage" },
      {
        key: "/invoices/export",
        label: "Export",
      },
    ],
  },
  {
    icon: <FieldTimeOutlined />,
    key: "/sales",
    label: "Sales",
    permissions: ["admin", "sales_manager"],
  },
  {
    icon: <EuroOutlined />,
    key: "/sales-control",
    label: "Sales Control",
    permissions: ["admin", "sales_manager"],
    children: [
      {
        key: "/sales-control/devices",
        label: "By Devices",
      },
      {
        key: "/sales-control/sku",
        label: "By SKU",
      },
      {
        key: "/sales-control/formulas",
        label: "Formulas",
      },
      {
        key: "/sales-control/sku-monitoring",
        label: "SKU Monitoring",
      },
      { label: "B2B Import", key: "/sales-control/b2b-import" },
      { label: "Sales Issues", key: "/sales-control/issues" },
    ],
  },
  {
    icon: <GiftOutlined />,
    key: "/promotions",
    label: "Promotions",
    permissions: ["admin", "sales_manager"],
  },
  {
    icon: <RocketOutlined />,
    key: "/sending",
    label: "Sending",
    permissions: ["admin", "sender"],
    children: [
      {
        key: "/sending/devices",
        label: "Devices",
      },
      {
        key: "/sending/accessories",
        label: "Accessories",
      },
    ],
  },
  {
    icon: <ScheduleOutlined />,
    key: "/faktura",
    label: "Faktura",
    permissions: ["admin"],
    children: [
      {
        key: "/faktura/compare",
        label: "Compare Quantity",
      },
      { key: "/faktura/average-price-export", label: "Avg. Price Export" },
    ],
  },
  {
    key: "/directory",
    permissions: visorExcluded,
    icon: <DatabaseOutlined />,
    label: "Directory",
    children: [
      {
        label: "Accessory",
        key: "/directory/accessory-submenu",
        children: [
          {
            label: <span className="text-xs">Accessories</span>,
            key: "/directory/accessory",
          },
          {
            label: <span className="text-xs">Categories</span>,
            key: "/directory/accessory/categories",
          },
        ],
      },
      {
        key: "/directory/devices",
        label: "Device Types",
      },
      {
        key: "/directory/colors",
        label: "Colors",
      },
      {
        key: "/directory/sku",
        label: "SKU",
      },
      {
        key: "/directory/grades",
        label: "Grades",
      },
      {
        key: "/directory/marketplaces",
        label: "Marketplaces",
        permissions: ["admin"],
      },
      {
        key: "/directory/suppliers",
        label: "Suppliers",
      },
      {
        key: "/directory/departments",
        label: "Departments",
      },
    ],
  },
  {
    icon: <TeamOutlined />,
    key: "/employees",
    label: "Employees",
    permissions: ["admin"],
  },
  {
    label: "Profitability",
    icon: <FundProjectionScreenOutlined />,
    key: "/profit",
    permissions: ["super"],
    children: [
      { label: "Formulas", key: "/profit/formulas" },
      { type: "group", label: "Products" },
      {
        label: "Connected",
        key: "/profit/connected",
        children: [
          {
            label: <span className="text-xs">By Device</span>,
            key: "/profit/connected/devices",
          },
          {
            label: <span className="text-xs">By SKU</span>,
            key: "/profit/connected/skus",
          },
        ],
      },
      {
        label: "Disconnected",
        key: "/profit/disconnected",
        children: [
          {
            label: <span className="text-xs">By Device</span>,
            key: "/profit/disconnected/devices",
          },
          {
            label: <span className="text-xs">By SKU</span>,
            key: "/profit/disconnected/skus",
          },
        ],
      },
      {
        label: "Without Sale",
        key: "/profit/without-sale",
        children: [
          {
            label: <span className="text-xs">By Device</span>,
            key: "/profit/without-sale/devices",
          },
          {
            label: <span className="text-xs">By SKU</span>,
            key: "/profit/without-sale/skus",
          },
        ],
      },
      { label: "Other statuses", key: "/profit/other-status/devices" },
    ],
  },
  {
    icon: <PieChartOutlined />,
    key: "/statistics",
    label: "Statistics",
    permissions: visorExcluded,
  },
  {
    icon: <BarsOutlined />,
    key: "/logs",
    label: "Log History",
    permissions: ["admin", "head_of_flasher"],
  },
];

const addLinks = menu => {
  menu.forEach(item => {
    if ("children" in item) {
      addLinks(item.children);
    } else if (item.type !== "group") {
      item.label = <Link to={item.key}>{item.label}</Link>;
    }
  });
};

const menuItems = [...config];
addLinks(menuItems);
export { menuItems };
