import { PaginatedSelect } from "components";
import { Form, Tooltip } from "antd";
import { useInfiniteDevices } from "features/devices";

export const DeviceSelect = ({
  disabled,
  onClear,
  className,
  initialData,
  filter_by = null,
  label = "",
  name = "",
  rules = [],
  onChange,
}) => {
  const {
    productOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
    getProductOption,
  } = useInfiniteDevices({ filter_by });

  const noInitialOption =
    initialData?.product_id &&
    !productOptions?.some(({ value }) => value === initialData?.product_id);

  const options = noInitialOption
    ? [getProductOption(initialData), ...productOptions]
    : productOptions;

  return (
    <Tooltip
      mouseEnterDelay={0.2}
      placement="topRight"
      overlayStyle={{ maxWidth: "90vw" }}
      title={initialData && disabled && getProductOption(initialData)?.label}
    >
      <Form.Item
        className={className}
        label={label}
        name={name}
        initialValue={initialData?.product_id || null}
        rules={rules}
      >
        <PaginatedSelect
          // popupMatchSelectWidth={false}
          dropdownStyle={{ minWidth: 860 }}
          disabled={disabled}
          searchValue={searchQuery}
          isLoading={isLoading}
          placeholder={disabled ? "" : "Select Product"}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onSearch={setSearchQuery}
          onChange={v =>
            onChange?.(
              v,
              options.find(({ value }) => value === v),
            )
          }
          onClear={() => {
            searchQuery ? setSearchQuery("") : onClear();
          }}
          options={options}
        />
      </Form.Item>
    </Tooltip>
  );
};
