import { Button, Table, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useColors } from "features/directory";
import { useColorsSelection } from "features/directory";
import { useAuthStore } from "features/auth";

export const ColorDirectory = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);

  const navigate = useNavigate();
  const { page, pageSize, setPage, total, setPageSize } = useColorsSelection();

  const { colors, isLoading, isFetching } = useColors();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Device Colors</h2>

      <div className="flex justify-end max-w-5xl w-full mx-auto px-4 mb-3">
        {rightRole && (
          <Button
            type="primary"
            onClick={() => navigate("/directory/colors/create")}
          >
            Create New
          </Button>
        )}
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={colors?.data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        onRow={record => ({
          onClick: () => navigate(`/directory/colors/${record.id}`),
        })}
        scroll={{
          x: 350,
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Shades",
    dataIndex: "shades",
    key: "shades",
    render: (text, record) => (
      <span>{record.shades.map(field => field.name).join(", ")}</span>
    ),
  },
  {
    title: "Possible Names",
    dataIndex: "possible_names",
    key: "possible_names",
    render: (text, record) => (
      <span>{record.possible_names.map(field => field.name).join(", ")}</span>
    ),
  },
];
