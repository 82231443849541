import { Link } from "react-router-dom";

export const comListAccessoryConfig = [
  {
    title: "SKU",
    dataIndex: "sku_number",
    key: "sku_number",
    width: 130,
    render: (sku_number, { grade_name, grade_id, sku_id }) => {
      const nvParam = grade_name === "A" ? "?original_box=1" : "";
      return (
        <Link
          className="whitespace-nowrap"
          to={`/sales/sku/${sku_id}/${grade_id}${nvParam}`}
        >
          {sku_number}
        </Link>
      );
    },
  },
  {
    title: "Grade",
    dataIndex: "grade_name",
    key: "grade_name",
    width: 90,
    align: "center",
  },
  {
    title: "Device",
    dataIndex: "model",
    align: "center",
    key: "model",
    render: (model, { color }) => model + " " + color,
  },
  {
    title: "Quantity",
    dataIndex: "count",
    key: "count",
    width: 90,
    align: "center",
  },
  {
    title: "Marketplace",
    dataIndex: "marketplace",
    key: "marketplace",
    width: "22%",
    align: "center",
  },
];
