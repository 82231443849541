import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getLogTypes = () => apiClient.get("api/logs/subject-types");

export const useLogTypes = ({ onSuccess } = {}) => {
  const { data: logTypes, isLoading: isLoadingLogTypes } = useQuery(
    ["log-types"],
    getLogTypes,
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { logTypes, isLoadingLogTypes };
};
