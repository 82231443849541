import { Radio } from "antd";
import { useEffect } from "react";
import { useAuthStore } from "features/auth";

export const DashboardSelector = ({ onChangeRole, value }) => {
  const { user, isRight } = useAuthStore();
  const isAdmin = isRight(["admin"]);

  useEffect(() => {
    if (isAdmin) return;

    if (user.data.roles?.includes("head_of_flasher")) {
      onChangeRole("flasher");
    } else {
      onChangeRole(
        user.data.roles?.filter(dashboardRole =>
          ["grader", "flasher", "packer", "sales_manager"].some(
            item => item === dashboardRole,
          ),
        )?.[0],
      );
    }
  }, [user]);

  if (!isAdmin && user?.data?.roles?.length < 2) return null;

  return (
    <Radio.Group
      className="font-semibold"
      onChange={e => {
        onChangeRole(e.target.value);
      }}
      value={value}
    >
      {(isRight(["grader"]) || isAdmin) && (
        <Radio className="py-2" value="grader">
          Grading
        </Radio>
      )}
      {(isRight(["flasher", "head_of_flasher"]) || isAdmin) && (
        <Radio className="py-2" value="flasher">
          Flashing
        </Radio>
      )}
      {(isRight(["packer"]) || isAdmin) && (
        <Radio className="py-2" value="packer">
          Packing
        </Radio>
      )}
      {(isRight(["sales_manager"]) || isAdmin) && (
        <Radio className="py-2" value="sales_manager">
          Sales
        </Radio>
      )}

      {isAdmin && (
        <>
          <Radio className="font-normal text-slate-500 py-2" value="all_hold">
            Hold
          </Radio>
          <Radio className="font-normal text-slate-500 py-2" value="all_repair">
            Repair
          </Radio>
        </>
      )}
    </Radio.Group>
  );
};
