import { apiClient } from "lib/api-client";
import { useQuery, useInfiniteQuery } from "react-query";
import { useSuppliersSelection } from "features/directory";
import { useDebounce } from "use-debounce";
import { useState } from "react";

const getSuppliers = ({ search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get(`api/directory/suppliers`, {
    params: { per_page, page, search_term },
  });

export const useSuppliers = onSuccess => {
  const { page, pageSize, setPage, setTotal, searchQuery } =
    useSuppliersSelection();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: suppliers,
    isLoading,
    isFetching,
  } = useQuery(
    ["suppliers", page, pageSize, debouncedQuery],
    () =>
      getSuppliers({
        page: page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );

  return { suppliers, isLoading, isFetching };
};

const infinite_getSuppliers = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteSuppliers = onSuccess => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-suppliers", debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/directory/suppliers?per_page=12&page=1`,
      }) =>
        infinite_getSuppliers({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const suppliersOptions = data?.pages
    ? data?.pages
        .map(({ data }) =>
          data?.map(({ supplier_name, id }) => {
            return {
              value: id,
              label: supplier_name,
            };
          }),
        )
        ?.flat()
    : [];

  return {
    suppliersOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
