export const QRLabel = ({ url, id, priority }) => {
  return (
    <div className="qr-label box-border flex justify-center items-center">
      {priority && (
        <strong className="bg-gray-800 text-white m-1 py-1 font-bold ">
          PRIORITY
        </strong>
      )}

      <img className="h-full py-5" src={url} alt="qr code" />
      <div className="flex items-center">
        <FormattedId id={id} />
      </div>
    </div>
  );
};

const FormattedId = ({ id }) => {
  const idString = String(id);

  if (idString.length < 8) return <span>{id}</span>;

  const firstPart = idString.substring(0, 3);
  const lastPart = idString.substring(idString.length - 4, idString.length - 1);
  return <span>{`${firstPart}..${lastPart}`}</span>;
};
