import { Button, message } from "antd";
import clsx from "clsx";
import { useStatisticsExport } from "features/statistics";

export const StatisticsExport = ({ disabled, className = "" }) => {
  const { download, isDownloading } = useStatisticsExport({
    onSuccess: res => message.success(res?.message),
  });

  return (
    <Button
      disabled={disabled}
      onClick={download}
      loading={isDownloading}
      className={clsx("text-xs sm:text-sm", className)}
    >
      Export
    </Button>
  );
};
