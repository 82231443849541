import { Card, Statistic } from "antd";

export const ProfitStats = ({ keys, data = [] }) => {
  return (
    <div className="mb-8 flex flex-wrap justify-center gap-6">
      {keys.map(key => {
        if (key === "total_count")
          return (
            <Stat
              key={key}
              value={data?.total_count}
              title="Total count"
              color={"#006086"}
            />
          );

        return (
          <Stat
            key={key}
            value={data[key]}
            title={getBiutyName(key)}
            prefix="€"
            precision={2}
          />
        );
      })}
    </div>
  );
};

const Stat = ({
  title = "Title",
  value,
  color = "#3f8600",
  prefix,
  precision = 0,
}) => (
  <Card bordered={false} size="small">
    <Statistic
      title={title}
      value={value || " "}
      valueStyle={{ color: value ? color : "lightgray" }}
      prefix={prefix}
      precision={precision}
    />
  </Card>
);

const getBiutyName = name =>
  name?.[0].toUpperCase() + name?.slice(1)?.replaceAll("_", " ");
