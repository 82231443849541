import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useExportAccessory } from "features/directory";
import { useMediaQuery } from "react-responsive";

export const ExportAccessories = () => {
  const isTightly = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  const { startExport, isExporting } = useExportAccessory({
    onSuccess: res => {
      message.success(res.message || "Export in progress. Check an email");
    },
  });

  return (
    <Button
      className="shrink-0"
      onClick={startExport}
      loading={isExporting}
      icon={<DownloadOutlined />}
    >
      {isTightly ? "" : "Export"}
    </Button>
  );
};
