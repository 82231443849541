import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

const importB2B = data =>
  apiClient.post("api/product/predefined-products", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const usePredefinedDevicesImport = ({ onSuccess }) => {
  const { mutate: upload, isLoading: isUploading } = useMutation(
    data => importB2B(data),
    {
      onSuccess: async res => {
        onSuccess?.(res);
      },
    },
  );

  return { upload, isUploading };
};
