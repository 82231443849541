import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const toggle = (id, active = false) => {
  return apiClient.post(`api/marketplaces/promotion/${id}/activate`, {
    active,
  });
};

export const useSKUToggle = ({ onSuccess, promoId } = {}) => {
  const { mutate: toggleSKU, isLoading: isToggling } = useMutation(
    (isActive = false) => toggle(promoId, isActive),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(["promotion-info", promoId]);
        queryClient.invalidateQueries(["promotion-skus", promoId]);
      },
    },
  );

  return { toggleSKU, isToggling };
};
