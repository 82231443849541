import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { STATISTICS_TABS } from "./config";

const initialPagination = STATISTICS_TABS.reduce((acc, current) => {
  if (current.name === "Registration") {
    acc[current.name] = {
      [STATISTICS_TABS[0].tabs[0].name]: {
        page: 1,
        per_page: 12,
        total: 0,
        by_user_id: null,
        search: null,
        byDevices: false,
      },
      [STATISTICS_TABS[0].tabs[1].name]: {
        page: 1,
        per_page: 12,
        total: 0,
        by_user_id: null,
        search: null,
        byDevices: false,
      },
      [STATISTICS_TABS[0].tabs[2].name]: {
        page: 1,
        per_page: 12,
        total: 0,
        by_user_id: null,
        search: null,
        byDevices: false,
      },
    };
  } else {
    acc[current.name] = {
      page: 1,
      per_page: 12,
      total: 0,
      by_user_id: null,
      search: null,
      byDevices: false,
    };
  }

  return acc;
}, {});

export const useStatisticsStore = create(
  devtools(
    immer((set, get) => ({
      page: 1,
      per_page: 12,
      total: 0,
      search: null,
      byDevices: false,

      pagination: initialPagination,
      status: STATISTICS_TABS[0].tabs[0].status,
      activeTab: STATISTICS_TABS[0].name,
      activeRegistrarTab: STATISTICS_TABS[0].tabs[0].name,

      start_date: null,
      end_date: null,
      by_user_id: null,
      by_auth_user: false,
      period: "today",
      isCompanyArea: true,
      setupUsersData: initialTab =>
        set(state => {
          state.isCompanyArea = false;
          state.by_user_id = null;
          state.activeTab = initialTab;
          state.by_auth_user = true;
          const selectedTab = STATISTICS_TABS.find(
            item => item.name === initialTab,
          );
          if (initialTab === "Registration") {
            state.status = STATISTICS_TABS[0].tabs.find(
              item => item.name === state.activeRegistrarTab,
            ).status;
          } else {
            state.status = selectedTab.status;
          }
        }),
      toggleArea: () =>
        set(state => {
          state.isCompanyArea = !state.isCompanyArea;
          state.by_auth_user = !state.isCompanyArea;
          if (!state.isCompanyArea) state.by_user_id = null;
        }),
      toggleByDevices: () =>
        set(state => {
          state.page = 1;
          state.total = 0;
          state.byDevices = !state.byDevices;
        }),
      setPeriod: period =>
        set(state => {
          const isCustom = state.period === "custom";
          if (isCustom) {
            state.start_date = null;
            state.end_date = null;
          }

          state.period = period;
        }),
      setActiveTab: newTabName =>
        set(state => {
          switchTabStates(newTabName, state);
          state.activeTab = newTabName;

          const selectedTab = STATISTICS_TABS.find(
            item => item.name === newTabName,
          );
          if (newTabName === "Registration") {
            state.status = STATISTICS_TABS[0].tabs.find(
              item => item.name === state.activeRegistrarTab,
            ).status;
          } else {
            state.status = selectedTab.status;
          }
        }),
      setActiveRegistrarTab: tabName =>
        set(state => {
          switchTabStates(state.activeTab, state, tabName);

          state.activeRegistrarTab = tabName;

          state.status = STATISTICS_TABS[0].tabs.find(
            item => item.name === state.activeRegistrarTab,
          ).status;
        }),
      setPageSize: pageSize =>
        set(state => {
          state.per_page = pageSize;
        }),
      setPage: page =>
        set(state => {
          state.page = page;
        }),
      setSearchQuery: query =>
        set(state => {
          if (!query) {
            state.search = null;
          } else {
            state.search = query;
          }
        }),
      setUser: id =>
        set(state => {
          state.by_user_id = id;
        }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setUserId: by_user_id => set({ by_user_id }),
    })),
  ),
);

const switchTabStates = (newTabName, state, newRegistrarTabName = null) => {
  const currentTab = state.activeTab;
  const currentRegistrarTab = state.activeRegistrarTab;
  const currentPagination = {
    page: state.page,
    per_page: state.per_page,
    total: state.total,
    by_user_id: state.by_user_id,
    search: state.search,
    byDevices: state.byDevices,
  };

  const uploadRegistrationTab = () => {
    state.page =
      state.pagination.Registration[
        newRegistrarTabName || currentRegistrarTab
      ].page;
    state.per_page =
      state.pagination.Registration[
        newRegistrarTabName || currentRegistrarTab
      ].per_page;
    state.total =
      state.pagination.Registration[
        newRegistrarTabName || currentRegistrarTab
      ].total;
    state.by_user_id =
      state.pagination.Registration[
        newRegistrarTabName || currentRegistrarTab
      ].by_user_id;
    state.search =
      state.pagination.Registration[
        newRegistrarTabName || currentRegistrarTab
      ].search;
    state.byDevices =
      state.pagination.Registration[
        newRegistrarTabName || currentRegistrarTab
      ].byDevices;
  };

  const uploadCommonTab = () => {
    state.page = state.pagination[newTabName].page;
    state.per_page = state.pagination[newTabName].per_page;
    state.total = state.pagination[newTabName].total;
    state.by_user_id = state.pagination[newTabName].by_user_id;
    state.search = state.pagination[newTabName].search;
    state.byDevices = state.pagination[newTabName].byDevices;
  };

  const saveCommonTab = () => {
    state.pagination[currentTab] = currentPagination;
  };

  const saveRegistrationTab = () => {
    state.pagination.Registration[currentRegistrarTab] = currentPagination;
  };

  switch (true) {
    case newTabName === "Registration" && currentTab === "Registration": {
      uploadRegistrationTab();
      saveRegistrationTab();
      break;
    }
    case newTabName === "Registration" && currentTab !== "Registration": {
      uploadRegistrationTab();
      saveCommonTab();
      break;
    }

    case newTabName !== "Registration" && currentTab !== "Registration": {
      uploadCommonTab();
      saveCommonTab();
      break;
    }

    case newTabName !== "Registration" && currentTab === "Registration": {
      uploadCommonTab();
      saveRegistrationTab();
      break;
    }

    default: {
      console.error("wrong default");
    }
  }
};
