import { App, Badge, Button, Dropdown, message } from "antd";
import { useRedirectDevice } from "features/devices";

export const RedirectDevice = ({
  selection,
  disabled,
  onSuccess,
  isAccessory = false,
}) => {
  const { modal } = App.useApp();

  const handleRedirect = ({ value, label }) => {
    modal.confirm({
      title: "Redirect",
      content: (
        <span>
          Do you really want to change the status of the selected devices to
          <b className="ml-1">"{label}"</b>?
        </span>
      ),
      onOk: () => {
        redirect({ product_ids: selection, status: value });
      },
    });
  };

  const { redirect, isRedirecting } = useRedirectDevice({
    onSuccess: response => {
      message.success(response.message || "Success!");
      onSuccess?.();
    },
  });

  const items = statusesMenu.map(({ key, label }) => ({
    onClick: () => handleRedirect({ value: key, label }),
    key,
    label,
    disabled: isAccessory && key !== "READY_FOR_SALE",
  }));

  return (
    <Badge
      className="ml-auto mr-1"
      size="small"
      color="volcano"
      count={selection?.length}
      offset={[-4, 0]}
    >
      <Dropdown
        trigger={["click"]}
        menu={{ items }}
        placement="bottom"
        disabled={!selection?.length || disabled || isRedirecting}
      >
        <Button danger type="primary" className="text-xs sm:text-sm">
          Redirect
        </Button>
      </Dropdown>
    </Badge>
  );
};

const statusesMenu = [
  {
    key: "GRADED",
    label: "Graded",
  },
  {
    key: "REGISTERED",
    label: "Registered",
  },
  {
    key: "READY_FOR_SALE",
    label: "Ready for sale",
  },
];
