import { SearchInput } from "components";
import {
  AvailableSkusFilter,
  getAvailableSKUsColumns,
  useAvailableSKUs,
  useAvailableSKUsStore,
  useSKUAdding,
} from "features/promotions";
import { Badge, Button, message, Modal, Pagination, Table } from "antd";
import _ from "lodash";
import { useState } from "react";
import { queryClient } from "lib/react-query-client";

export const PromotionAvailableSkus = ({
  disabled = false,
  promotion,
  title = "Include SKU",
}) => {
  const store = useAvailableSKUsStore();
  const promotionId = promotion.id;

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
    allowAvailableSKUs();
    queryClient.invalidateQueries(["available-promotion-skus", promotionId]);
  };

  const closeModal = () => {
    store.clear();
    setIsOpen(false);
  };

  const { availableSkus, allowAvailableSKUs, isLoading, isFetching } =
    useAvailableSKUs({
      promoId: promotionId,
    });

  const { addSKUs, isAddingSKUs } = useSKUAdding({
    promoId: promotionId,
    onSuccess: response => {
      message.success(response.message);
      store.clearSelection();
    },
  });

  const selectedCount = store.selection.length;

  return (
    <>
      <Button onClick={openModal} disabled={disabled} type="primary">
        {title}
      </Button>

      <Modal
        title={<h3 className="text-center">Available SKUs</h3>}
        open={isOpen}
        onCancel={closeModal}
        width={"100%"}
        style={{ maxHeight: "100%", maxWidth: 1200 }}
        footer={
          <Pagination
            className="text-center  mt-auto"
            onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
            pageSizeOptions={[4, 8, 12, 24, 48]}
            showSizeChanger
            defaultCurrent={1}
            total={store.total}
            current={store.page}
            pageSize={store.pageSize}
            onChange={store.setPage}
            disabled={isAddingSKUs || isLoading || isFetching}
          />
        }
      >
        <div className="flex px-4 mb-3 mt-6 gap-2 items-end">
          <SearchInput
            searchQuery={store.searchQuery}
            setSearchQuery={store.setSearchQuery}
            disabled={isAddingSKUs}
          />

          <AvailableSkusFilter
            availableTypes={promotion.device_types}
            store={store}
            loading={isLoading || isFetching}
            disabled={isAddingSKUs || isLoading || isFetching}
          />

          <Button
            className="ml-auto"
            onClick={closeModal}
            disabled={isAddingSKUs}
          >
            Cancel
          </Button>

          <Badge
            className="mr-1"
            size="small"
            color="red"
            count={store.selection?.length}
            offset={[-4, 3]}
          >
            <Button
              loading={isAddingSKUs}
              disabled={!selectedCount}
              className="ml-auto"
              type="primary"
              onClick={() => addSKUs({ sku_ids: store.selection })}
            >
              Apply
            </Button>
          </Badge>
        </div>

        <div className="overflow-y-auto pr-3" style={{ maxHeight: "64vh" }}>
          <Table
            loading={isLoading || isFetching || isAddingSKUs}
            columns={getAvailableSKUsColumns(promotionId)}
            dataSource={availableSkus?.data}
            sticky={true}
            rowKey={() => _.uniqueId("promotion_")}
            pagination={false}
            scroll={{ x: 600 }}
            onChange={(pag, f, { columnKey, order }) => {
              store.setSorting(columnKey, order);
            }}
          />
        </div>
      </Modal>
    </>
  );
};
