import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const batchChangeStatus = ({ product_ids, status, comment }) => {
  return apiClient.post(`api/product/batch-change-status`, {
    product_ids,
    status,
    comment,
  });
};

export const useStatusChange = onSuccess => {
  const { mutate: changeStatus, isLoading: isChanging } = useMutation(
    data => batchChangeStatus(data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        onSuccess?.(data);
      },
    },
  );

  return { changeStatus, isChanging };
};
