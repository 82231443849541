import { Button, Table, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useSuppliers, useSuppliersSelection } from "features/directory";
import { EditFilled } from "@ant-design/icons";
import { useInvoicesStore } from "features/invoices";
import { useAuthStore } from "features/auth";
import { SearchInput } from "components";

export const SupplierDirectory = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const navigate = useNavigate();
  const { setSearchTerm: selectSupplier } = useInvoicesStore();

  const {
    setSearchQuery,
    searchQuery,
    page,
    pageSize,
    setPage,
    total,
    setPageSize,
  } = useSuppliersSelection();

  const { suppliers, isLoading, isFetching } = useSuppliers();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Suppliers</h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-4 mb-3">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        {rightRole && (
          <Button
            type="primary"
            onClick={() => navigate("/directory/suppliers/adding")}
          >
            Add Supplier
          </Button>
        )}
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName={rightRole ? "hover:cursor-pointer" : ""}
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={suppliers?.data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        onRow={record =>
          rightRole && {
            onClick: () => {
              selectSupplier(record.supplier_name);
              navigate(`/invoices/manage`);
            },
          }
        }
        scroll={{
          x: 530,
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};

const columns = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "10%",
  },
  {
    title: "Supplier Number",
    dataIndex: "supplier_nr",
    key: "supplier_nr",
  },
  {
    title: "Supplier Name",
    dataIndex: "supplier_name",
    key: "supplier_name",
  },
  {
    title: "Last Supply",
    dataIndex: "last_supply",
    key: "last_supply",
    render: (text, record) => <span>{record.last_supply?.split(" ")[0]}</span>,
  },
  {
    title: "Edit",
    dataIndex: "",
    key: "x",
    width: 80,
    align: "center",
    render: record => <GoEdit id={record.id} />,
    fixed: "right",
  },
];

const GoEdit = ({ id }) => {
  const navigate = useNavigate();
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar"]);
  return (
    <EditFilled
      className={
        rightRole
          ? "text-indigo-600 hover:text-fuchsia-500"
          : "text-gray-300 hover:cursor-not-allowed"
      }
      onClick={e => {
        e.stopPropagation();
        rightRole && navigate(`/directory/suppliers/${id}`);
      }}
    />
  );
};
