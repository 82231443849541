import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const reconnect = (id, afterbuy) =>
  apiClient.post(`api/sku/marketplaces/reconnect-ebay/${id}`, { afterbuy });

export const useReconnectEbay = ({ onSuccess }) => {
  const { mutate: reconnectEbay, isLoading: isEbayReconnecting } = useMutation(
    ({ id, afterbuyKey }) => reconnect(id, afterbuyKey),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { reconnectEbay, isEbayReconnecting };
};
