import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import _ from "lodash";

const requestReport = async ({ params, groupName, isSKU }) =>
  await apiClient.post(
    `api/profit/${groupName}/${isSKU ? "skus" : "products"}/export`,
    {},
    { params },
  );

export const useProfitabilityExport = ({
  onSuccess,
  onError,
  store = {},
  isSKU = false,
  groupName = "",
} = {}) => {
  const { filter_by, sorting, start_date, end_date, search_term } = store;

  const realFilters = _.omit(filter_by, "color");
  if (!_.isEmpty(sorting)) Object.assign(realFilters, sorting);

  const { mutate: download, isLoading: isDownloading } = useMutation(
    () =>
      requestReport({
        params: {
          search_term,
          filter_by: realFilters,
          start_date,
          end_date,
        },
        groupName,
        isSKU,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
    },
  );

  return { download, isDownloading };
};
