import { apiClient } from "lib/api-client";
import { useQuery, useInfiniteQuery } from "react-query";
import { useSKUSelection } from "features/directory";
import { useDebounce } from "use-debounce";
import { useMemo, useState } from "react";
import _ from "lodash";

const getSKUs = ({ search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get(`api/directory/skus`, {
    params: { per_page, page, search_term },
  });

export const useSKUs = onSuccess => {
  const { page, pageSize, setPage, setTotal, searchQuery } = useSKUSelection();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: skus,
    isLoading,
    isFetching,
  } = useQuery(
    ["skus", page, pageSize, debouncedQuery],
    () =>
      getSKUs({
        page: page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );

  return { skus, isLoading, isFetching };
};

const infinite_getSKUs = async ({ pageParam, search_term, typeId }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term, filter_by: { device_type_id: typeId } },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteSKUs = ({ onSuccess, typeId = null } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const {
    data: skus,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["i-skus", debouncedQuery, typeId],
    ({
      pageParam = `${
        import.meta.env.VITE_APP_API_BASE_URL
      }/api/directory/skus?per_page=12&page=1`,
    }) =>
      infinite_getSKUs({
        pageParam,
        search_term: debouncedQuery || null,
        typeId,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      getPreviousPageParam: firstPage => firstPage.next ?? undefined,
      getNextPageParam: lastPage => lastPage.next ?? undefined,
    },
  );

  const buildSkuOption = sku => {
    if (!sku) return { value: null };

    let label = `${sku.sku_id} - no data`;
    if (!_.isEmpty(sku.field_values)) {
      const field_values = Object.values(sku.field_values);

      const model = field_values.find(
        item => item.device_field.device_field_name === "Model",
      )?.device_field.device_field_option[0]?.value;

      const memory =
        field_values.find(
          item => item.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      label = `#${sku.sku_number} - ${model} ${sku.color_shade?.name} ${memory}`;
    }

    return {
      value: sku.sku_id,
      label,
      data: sku,
    };
  };

  const skuOptions = useMemo(
    () =>
      skus?.pages
        ? skus?.pages
            .map(({ data }) => data?.map(sku => buildSkuOption(sku)))
            ?.flat()
        : [],
    [skus],
  );

  const skusData = useMemo(() => {
    if (!skus?.pages) return null;

    const data = skus?.pages?.map(({ data }) => data)?.flat();
    const result = {};

    for (const sku of data) {
      result[sku.sku_id] = sku;
    }

    return result;
  }, [skus]);

  return {
    skus,
    skusData,
    buildSkuOption,
    skuOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
