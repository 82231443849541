import dayjs from "dayjs";
import { Button, Card, DatePicker, Form, message } from "antd";
import { requiredRule } from "constants/validation";
import { useAvgPriceExport } from "features/faktura";
import { useMediaQuery } from "react-responsive";

export const AveragePriceExport = () => {
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({
    query: "(max-width: 580px)",
  });

  const { exportAvgPrice, isExporting } = useAvgPriceExport({
    onSuccess: res => {
      message.success(res.message || "Export in progress. Check an email");
    },
  });

  const onSubmit = ({ start_date, end_date }) => {
    const normalize = d => d.format().split("T")[0];
    exportAvgPrice({
      start_date: normalize(start_date),
      end_date: normalize(end_date),
    });
  };

  return (
    <div className="flex-1 px-4">
      <Card
        title={<h1 className="text-center my-0">Export Average Price</h1>}
        className="max-w-xl m-auto mt-16"
      >
        <span className="block w-full text-center">
          Products created for period
        </span>

        <Form
          form={form}
          layout={isMobile ? "vertical" : "inline"}
          className="flex flex-wrap justify-around pb-10 pt-6"
          onFinish={onSubmit}
          disabled={isExporting}
        >
          <Form.Item
            rules={[requiredRule]}
            label="from"
            name="start_date"
            initialValue={dayjs().subtract(7, "day")}
            layout="inline"
          >
            <DatePicker
              placeholder="Select Date"
              disabledDate={current =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>

          <Form.Item
            rules={[requiredRule]}
            label="to"
            name="end_date"
            initialValue={dayjs()}
          >
            <DatePicker
              placeholder="Select Date"
              disabledDate={current =>
                current &&
                (current > dayjs().endOf("day") ||
                  current < form.getFieldValue("start_date"))
              }
            />
          </Form.Item>
        </Form>

        <Button
          type="primary"
          className="m-auto block"
          onClick={form.submit}
          loading={isExporting}
        >
          Export
        </Button>
      </Card>
    </div>
  );
};
