import { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Table } from "antd";
import { useEbayFormulas, useUpdateEbayFormula } from "features/formulas";
import { EditFilled, InfoCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;

export const EbayFormulas = () => {
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const { ebayFormulas, isLoading, isFetching } = useEbayFormulas();
  const { updateFormula, isUpdating } = useUpdateEbayFormula({
    onSuccess: data => {
      message.success(data.message);
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (isOpen && ebayFormulas.data) {
      form.setFieldValue(
        "expression_greater",
        ebayFormulas.data[0]?.expression_greater,
      );
      form.setFieldValue(
        "expression_less",
        ebayFormulas.data[0]?.expression_less,
      );
    } else if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen]);

  return (
    <>
      <h3 className="text-center mt-16 text-slate-800 relative max-w-7xl w-full mx-auto px-4">
        <div className={"inline-block mr-1  text-lg"}>
          <span className={"text-red-700"}>e</span>
          <span className={"text-blue-700"}>b</span>
          <span className={"text-yellow-700"}>a</span>
          <span className={"text-green-800"}>y</span>
        </div>
        Formulas
        <span
          className={
            "absolute left-6 -bottom-2 text-xs font-normal text-gray-500"
          }
        >
          <InfoCircleOutlined className={"text-slate-500 mr-1"} />
          use <strong className={"font-semibold"}>{"{price}"}</strong> in
          formula for eBay price value
        </span>
      </h3>

      <Table
        className="max-w-7xl w-full mx-auto px-4"
        loading={isLoading || isFetching}
        pagination={false}
        dataSource={ebayFormulas?.data}
        rowKey={record => record.id}
        columns={[
          {
            key: "expression_greater",
            title: "Price > 400",
            dataIndex: "expression_greater",
            render: exp => (
              <span className="font-mono text-green-900">{exp}</span>
            ),
            align: "center",
          },
          {
            key: "expression_less",
            title: "Price < 400",
            dataIndex: "expression_less",
            render: exp => (
              <span className="font-mono text-green-900">{exp}</span>
            ),
            align: "center",
          },
          {
            key: "edit",
            render: () => (
              <Button
                icon={<EditFilled />}
                onClick={() => setIsOpen(true)}
                disabled={isLoading}
              />
            ),
          },
        ]}
      />

      <Modal
        title={"Edit eBay Formula"}
        open={isOpen}
        onCancel={() => !isUpdating && setIsOpen(false)}
        onOk={form.submit}
        confirmLoading={isUpdating}
        width={640}
      >
        <Form
          className={"mt-6"}
          requiredMark
          form={form}
          onFinish={values =>
            updateFormula({ id: ebayFormulas?.data[0]?.id, data: values })
          }
        >
          <Form.Item
            name={"expression_greater"}
            label={"Expression Greater"}
            rules={[requiredRule]}
            labelCol={{ span: 6 }}
          >
            <TextArea className="font-mono" autoSize />
          </Form.Item>

          <Form.Item
            name={"expression_less"}
            label={"Expression Less"}
            rules={[requiredRule]}
            labelCol={{ span: 6 }}
          >
            <TextArea className="font-mono" autoSize />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
