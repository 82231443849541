import { apiClient } from "lib/api-client";
import { useQuery, useInfiniteQuery } from "react-query";
import { useDeviceTypeSelection } from "features/directory";
import { useMemo } from "react";

const getDeviceTypes = ({ search_term = null, per_page = 12, page = 1 }) =>
  apiClient.get("api/directory/devices", {
    params: { per_page, page, search_term },
  });

export const useDeviceTypes = onSuccess => {
  const { page, pageSize, setPage, setTotal } = useDeviceTypeSelection();

  const {
    data: devices,
    isLoading,
    isFetching,
  } = useQuery(
    ["device-types", page, pageSize],
    () =>
      getDeviceTypes({
        page: page,
        per_page: pageSize,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );

  return { devices, isLoading, isFetching };
};

const infinite_getDeviceTypes = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteDeviceTypes = onSuccess => {
  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-device-types"],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/directory/devices?per_page=12&page=1`,
      }) =>
        infinite_getDeviceTypes({
          pageParam,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const deviceTypeOptions = useMemo(
    () =>
      data?.pages
        ? data?.pages
            .map(({ data }) =>
              data?.map(({ name, id }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            )
            ?.flat()
        : [],
    [data],
  );

  const deviceTypesFields = useMemo(() => {
    if (!data) return null;

    const devices = data?.pages?.map(({ data }) => data)?.flat();
    const fields = {};
    for (const device of devices) {
      fields[device.id] =
        device.fields
          ?.filter(({ name }) => name !== "Color")
          .sort(sortDeviceFields) || [];
    }
    return fields;
  }, [data]);

  const devices = data?.pages?.map(({ data }) => data)?.flat();

  return {
    devices,
    deviceTypesFields,
    deviceTypeOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  };
};

function sortDeviceFields(a, b) {
  const namesPriority = { IMEI: 1, Serial: 2, Model: 3, Memory: 4 };
  const aPriority = namesPriority[a.name] || 99;
  const bPriority = namesPriority[b.name] || 99;
  return aPriority - bPriority;
}
