import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./lib/react-query-client";
import { App as AntdProvider } from "antd";
import "./index.css";

import AppRouter from "./router";

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <AntdProvider>
          <AppRouter />
        </AntdProvider>

        {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
      </QueryClientProvider>
    </>
  );
}

export default App;
