import { Link } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const DirectorySkuLink = ({
  skuId,
  label = null,
  rightRoles = ["admin"],
}) => {
  const { isRight } = useAuthStore();

  if (isRight(rightRoles))
    return (
      <Link className="whitespace-nowrap" to={`/directory/sku/${skuId}`}>
        {label || skuId}
      </Link>
    );

  return <span>{label || skuId}</span>;
};
