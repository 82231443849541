import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const createDeviceType = data => apiClient.post("api/directory/devices", data);

export const useCreateDevice = onSuccess => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => createDeviceType(data),
    {
      onSuccess: async device => {
        await queryClient.invalidateQueries(["device-types"]);
        onSuccess?.();
      },
    },
  );

  return { create, isCreating };
};
