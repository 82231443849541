import { Select, Spin } from "antd";
import { useRepairOptions } from "features/devices";

export const RepairFilter = ({ store, excluded = [] }) => {
  const { repairOptions, isRepairOptionsLoading } = useRepairOptions();
  const { setFilter, filter_by } = store;

  return (
    <div>
      <label>Repair Option</label>
      <Select
        className="w-full mt-1"
        placeholder="Repair Option"
        allowClear
        showArrow
        showSearch
        notFoundContent={isRepairOptionsLoading ? <Spin size="small" /> : null}
        value={filter_by?.repair_option}
        onChange={e => setFilter({ repair_option: e })}
        filterOption={(searchValue, { label }) =>
          label?.toLowerCase().includes(searchValue?.toLowerCase())
        }
        options={[{ label: "No Option", value: "NONE" }, ...repairOptions]}
      />
    </div>
  );
};
