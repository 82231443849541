import {
  ColorConstructor,
  useAddColor,
  useFormConstructor,
} from "features/directory";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const CreateColor = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);

  const navigate = useNavigate();
  const { add, isAdding } = useAddColor(() => {
    message.success("Success!");
    navigate(`/directory/colors`);
  });

  const { validate, fields } = useFormConstructor();

  const buildRequest = () => {
    const newValue = [...fields];
    return {
      name: newValue[0].name,
      shades: newValue[1].options?.map(shade => ({ name: shade.value })),
      possible_names: newValue[2].options?.map(possible_name => ({
        name: possible_name.value,
      })),
    };
  };

  return (
    <div className="relative flex flex-col h-full">
      <h2 className="text-center">Create New Color</h2>

      <div className="overflow-y-auto flex-2">
        <div className="max-w-md my-12 mx-auto flex flex-col px-7 sm:px-0">
          <ColorConstructor disabled={isAdding} />

          {rightRole && (
            <Button
              type="primary"
              className="w-1/2 mx-auto"
              loading={isAdding}
              onClick={() => {
                const isValid = validate();
                if (isValid) add(buildRequest());
              }}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
