import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getDepartment = id => apiClient.get(`api/directory/departments/${id}`);

export const useDepartment = ({ id, onSuccess, onError }) => {
  const { data: department, isLoading: isDepartmentLoading } = useQuery(
    ["department", id],
    () => getDepartment(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
      retry: false,
    },
  );

  return { department, isDepartmentLoading };
};
