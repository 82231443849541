import { Form, Button, message } from "antd";
import { useCreateSKU, SKUForm } from "features/directory";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const CreateSKU = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { create, isCreating } = useCreateSKU(() => {
    message.success("Success!");
    navigate(`/directory/sku`, { replace: true });
  });

  return (
    <div className="h-full flex flex-col">
      <h2 className="text-center">Create SKU</h2>

      <div className="overflow-y-auto flex-1">
        <SKUForm form={form} onSubmit={create} disabled={isCreating} />
        {rightRole && (
          <Button
            type="primary"
            onClick={form.submit}
            loading={isCreating}
            className="block mx-auto my-16"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  );
};
