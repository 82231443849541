import { create } from "lib/zustand-poxy";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import {
  filters,
  pagination,
  search,
  dateFilters,
} from "utils/standart-stores";
import { immer } from "zustand/middleware/immer";

export const useInvoiceProductsStore = create(
  devtools(
    immer(set => ({ ...pagination(set), ...filters(set), ...search(set) })),
  ),
);

export const useInvoicesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...dateFilters(set),
      ...search(set),
    })),
  ),
);
