import { Form } from "antd";
import _ from "lodash";
import { useInfiniteDeviceTypes } from "features/directory";
import { PaginatedSelect } from "components";

export const DeviceTypeMultiSelect = ({
  className,
  rules,
  disabled,
  onClear,
  initialData = [],
  name = "device_type_id",
  label = "Device Type",
  placeholder = "Select Device Type",
  hidden = false,
  labelCol = null,
  ...rest
}) => {
  const { deviceTypeOptions, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteDeviceTypes();

  const options = _.uniqBy(
    [
      ...initialData.map(({ id, name }) => ({ value: id, label: name })),
      ...deviceTypeOptions,
    ],
    "value",
  );

  return (
    <Form.Item
      className={className}
      label={label}
      labelCol={labelCol}
      name={name}
      rules={rules}
      hidden={hidden}
    >
      <PaginatedSelect
        mode={"multiple"}
        localSearch
        isLoading={isLoading}
        placeholder={placeholder}
        disabled={disabled}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onClear={onClear}
        options={options}
        filterOption={(searchValue, { children }) =>
          children?.toLowerCase().includes(searchValue?.toLowerCase())
        }
        {...rest}
      />
    </Form.Item>
  );
};
