import { Navigate } from "react-router-dom";
import { LoginForm, useAuthStore, styles } from "features/auth";
import { Logo } from "components";

export const Login = () => {
  const { isLoggedIn } = useAuthStore();

  if (isLoggedIn) return <Navigate to="/" />;

  return (
    <div className={styles.container}>
      <div className={styles.logo_wrapper}>
        <Logo width="200" height="auto" layout="responsive" />
      </div>
      <LoginForm />
    </div>
  );
};
