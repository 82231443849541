import { Button, Card, Form, Input, message, Upload } from "antd";
import { useState } from "react";
import { useImportB2B } from "features/sales";

const { TextArea } = Input;

export const B2BImport = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);

  const clearForm = () => {
    setFile([]);
    form.resetFields();
  };

  const { upload, isImporting } = useImportB2B({
    onSuccess: res => {
      message.success(res.message);
      clearForm();
    },
  });

  const onSubmit = ({ info }) => {
    const body = new FormData();
    body.append("info", info);
    body.append("file", file[0]);
    upload(body);
  };

  return (
    <Card
      title={<h1 className="text-center my-0">B2B Import</h1>}
      className="max-w-xl m-auto mt-16"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={isImporting}
      >
        <Form.Item required>
          <Upload
            name="file"
            withCredentials
            fileList={file}
            customRequest={({ file }) => {
              setFile([file]);
            }}
            onRemove={() => setFile([])}
          >
            {!file.length && <Button danger>Choose File</Button>}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Additional Information"
          name="info"
          required
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <TextArea placeholder="Additional information" />
        </Form.Item>

        <Button
          type="primary"
          className="block mx-auto my-2"
          disabled={!file.length}
          onClick={form.submit}
          loading={isImporting}
        >
          Import
        </Button>
      </Form>
    </Card>
  );
};
