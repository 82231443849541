const booleanTypeName = "boolean";
const dateTypeName = "date";

const config = {
  subject_id: { name: "ID", fixed: true },
  "colorShade.name": { name: "Color" },
  comment: { name: "Comment" },
  "deviceType.name": { name: "Type" },
  found_in_commission_list: {
    name: "Found in com. list",
    type: booleanTypeName,
  },
  "grade.name": { name: "Grade", fixed: true },
  in_sale_date: { name: "In sale from", type: dateTypeName },
  "invoice.invoice_number": { name: "Invoice" },
  "invoice.return": { name: "Invoice return", type: booleanTypeName },
  "invoice.supplier.name": { name: "Supplier" },
  original_box: { name: "Original box", type: booleanTypeName },
  ready_for_sending_date: {
    name: "Ready for sending from",
    type: dateTypeName,
  },
  ready_for_sale_date: { name: "Ready for sale from", type: dateTypeName },
  "sku.sku_number": { name: "SKU", fixed: true },
  soft_update: { name: "Soft update", type: booleanTypeName },
  status: { name: "Status", fixed: true },
};

function flatProductDataWithNames(rowProductData) {
  const result = {};
  for (const rowName in rowProductData) {
    if (rowName in config) {
      const item = config[rowName];
      result[item.name] = { ...item, value: rowProductData[rowName] };
    } else if (rowName === "fieldValues") {
      rowProductData.fieldValues.forEach(
        ({ device_field, device_field_option, value }) => {
          result[device_field.name] = {
            name: device_field.name,
            value: value || device_field_option?.value,
            type: device_field.type === "CHECKBOX" ? booleanTypeName : null,
          };
        },
      );
    }
  }
  return result;
}

function getDisplayedNames(preparedProductObject1, preparedProductObject2) {
  return Object.keys(preparedProductObject1).filter(
    key =>
      preparedProductObject1[key].fixed ||
      preparedProductObject1[key].value !== preparedProductObject2[key]?.value,
  );
}

const getProductChanges = ({ properties, subject_id }) => {
  const flatNew = flatProductDataWithNames(properties?.attributes);
  const flatOld = flatProductDataWithNames(properties?.old);
  const selectedNames = getDisplayedNames(flatNew, flatOld);
  const result = selectedNames.map(name => ({
    name,
    type: flatNew[name].type,
    old: flatOld[name]?.value,
    new: flatNew[name].value,
  }));

  return [{ name: "ID", old: subject_id, new: subject_id }].concat(result);
};

export { getProductChanges, booleanTypeName, dateTypeName };
