import { Table } from "antd";
import _ from "lodash";

export const ComparisonTable = ({ data = [], isLoading }) => {
  return (
    <Table
      className="overflow-y-auto flex-1"
      loading={isLoading}
      sticky={true}
      dataSource={data}
      rowKey={() => _.uniqueId()}
      pagination={false}
      columns={[
        {
          title: "Sku",
          dataIndex: "sku",
          key: "sku",
          render: text => <span className="text-gray-600 text-xs">{text}</span>,
          width: 140,
        },
        {
          title: "Faktura quantity",
          dataIndex: "faktura_quantity",
          render: text => (
            <span className="text-sky-700 font-semibold">{text}</span>
          ),
          key: "faktura_quantity",
          align: "center",
        },
        {
          title: "System quantity",
          dataIndex: "system_quantity",
          render: text => (
            <span className="text-green-600 font-semibold">{text}</span>
          ),
          key: "system_quantity",
          align: "center",
        },
        {
          title: "System repair quantity",
          dataIndex: "system_repair_quantity",
          render: text => <span className="text-green-600">{text}</span>,
          key: "system_repair_quantity",
          align: "center",
        },
      ]}
    />
  );
};
