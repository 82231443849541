import { Button, InputNumber, Space } from "antd";
import { useEffect, useState } from "react";

export const PriceFilter = ({ store }) => {
  const { filter_by } = store;
  const [localMin, setLocalMin] = useState(null);
  const [localMax, setLocalMax] = useState(null);

  const syncValues = () => {
    if (filter_by?.price_from !== localMin) setLocalMin(filter_by?.price_from);
    if (filter_by?.price_to !== localMax) setLocalMax(filter_by?.price_to);
  };

  useEffect(() => {
    syncValues();
  }, [filter_by?.price_to, filter_by?.price_from]);

  return (
    <>
      <div>
        <label className="mb-1 flex items-center">
          Min Price
          {!!filter_by.price_from && (
            <strong
              className="text-white ml-1 bg-blue-500 rounded px-1 leading-tight"
              style={{ fontSize: 10 }}
            >
              {filter_by.price_from}
            </strong>
          )}
        </label>
        <Space.Compact className="w-full">
          <InputNumber
            value={localMin}
            onChange={setLocalMin}
            min={0}
            className="flex-1"
            addonBefore="€"
            onBlur={({ target }) => {
              if (!target.value) {
                setLocalMin(filter_by.price_from);
              }
            }}
          />
          <Button
            disabled={!localMin}
            danger={localMin && localMin === filter_by.price_from}
            className="text-xs"
            type={localMin === filter_by.price_from ? "default" : "primary"}
            onClick={() => {
              if (localMin === filter_by.price_from) {
                store.setFilter({ price_from: null });
              } else {
                store.setFilter({ price_from: localMin });
              }
            }}
          >
            {localMin && localMin === filter_by.price_from ? "Clear" : "Apply"}
          </Button>
        </Space.Compact>
      </div>

      <div>
        <label className="mb-1 flex items-center">
          Max Price
          {!!filter_by.price_to && (
            <strong
              className="text-white ml-1 bg-blue-500 rounded px-1 leading-tight"
              style={{ fontSize: 10 }}
            >
              {filter_by.price_to}
            </strong>
          )}
        </label>
        <Space.Compact className="w-full">
          <InputNumber
            value={localMax}
            onChange={setLocalMax}
            min={0}
            className="flex-1"
            addonBefore="€"
            onBlur={({ target }) => {
              if (!target.value) {
                setLocalMax(filter_by.price_to);
              }
            }}
          />
          <Button
            className="text-xs"
            disabled={!localMax}
            danger={localMax && localMax === filter_by.price_to}
            type={localMax === filter_by.price_to ? "default" : "primary"}
            onClick={() => {
              if (localMax === filter_by.price_to) {
                store.setFilter({ price_to: null });
              } else {
                store.setFilter({ price_to: localMax });
              }
            }}
          >
            {localMax && localMax === filter_by.price_to ? "Clear" : "Apply"}
          </Button>
        </Space.Compact>
      </div>
    </>
  );
};
