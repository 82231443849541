import { Button, Modal } from "antd";
import { EditFilled } from "@ant-design/icons";
import { useState } from "react";
import { PromotionForm } from "./promotion-form";

export const PromotionInfo = ({ promotion, disabled }) => {
  const [isEdit, setIsEdit] = useState(false);
  const show = () => setIsEdit(true);
  const hide = () => setIsEdit(false);

  return (
    <div className="relative max-w-5xl w-full mx-auto px-4 mb-6">
      <div
        className="flex flex-wrap justify-around items-center text-sky-700 gap-4
              pl-6 pr-10 py-4 rounded-md border-blue-200 border-solid border relative"
        style={{ backgroundColor: disabled ? "#e4e4e4" : "white" }}
      >
        <div className="absolute -left-1 -top-2 text-xs text-white font-semibold">
          {promotion?.data.active && (
            <span
              className={`block rounded-sm py-1 px-2 ${
                disabled ? "bg-gray-400" : "bg-blue-500"
              }`}
            >
              active
            </span>
          )}
          {!promotion?.data.active && (
            <span
              className={`block rounded-sm py-1 px-2 ${
                disabled ? "bg-gray-400" : "bg-red-400"
              }`}
            >
              inactive
            </span>
          )}
        </div>

        <div>
          <span className="mr-2 font-semibold">Name:</span>
          <span>{promotion?.data.title}</span>
        </div>

        <div>
          <b className="mr-2  font-semibold">Device Type:</b>
          <span>
            {promotion?.data.device_types?.map(({ name }) => name)?.join(", ")}
          </span>
        </div>

        <div>
          <b className="mr-2  font-semibold">Type:</b>
          {promotion?.data.type ? (
            <span>
              <span className="text-green-400 mr-1">▲</span>
              Increase
            </span>
          ) : (
            <span>
              <span className="text-red-300 mr-1">▼</span>
              Decrease
            </span>
          )}
        </div>

        <div>
          <b className="mr-2 font-semibold">Amount:</b>
          <span>{`€ ${promotion?.data.price}`}</span>
        </div>

        <Button
          disabled={disabled || promotion?.data.active}
          onClick={show}
          className="absolute right-0.5 top-0.5"
          icon={
            <EditFilled
              className={
                disabled || promotion?.data.active
                  ? "text-gray-400"
                  : "text-blue-500"
              }
            />
          }
        />
      </div>

      {isEdit && (
        <Modal
          maskClosable={false}
          width={640}
          open={isEdit}
          promotion={promotion}
          onCancel={hide}
          footer={null}
          title={<h3 className="text-center mt-0 mb-8">Edit Promotion</h3>}
        >
          <PromotionForm
            isEditing
            promotion={promotion}
            onUpdate={hide}
            onCancel={hide}
          />
        </Modal>
      )}
    </div>
  );
};
