import { Select, Spin } from "antd";
import { useStatusOptions } from "features/devices";

export const StatusFilter = ({ store, excluded = [] }) => {
  const { statuses, isLoadingStatuses } = useStatusOptions();
  const { setFilter, filter_by } = store;

  return (
    <div>
      <label>Status</label>
      <Select
        className="w-full mt-1"
        placeholder="Select Status"
        allowClear
        showArrow
        showSearch
        notFoundContent={isLoadingStatuses ? <Spin size="small" /> : null}
        value={filter_by?.status}
        onChange={e => setFilter({ status: e })}
        filterOption={(searchValue, { label }) =>
          label?.toLowerCase().includes(searchValue?.toLowerCase())
        }
        options={
          statuses?.data &&
          Object.entries(statuses?.data)
            ?.map(([value, label]) => ({
              value,
              label,
            }))
            .filter(
              ({ value, label }) =>
                !excluded.includes(value) && !excluded.includes(label),
            )
        }
      />
    </div>
  );
};
