import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const sync = data => apiClient.post("api/sku/marketplaces/sync", data);

export const useAddMarketplace = ({ onSuccess } = {}) => {
  const { mutateAsync: addMarketplace, isLoading: isAdding } = useMutation(
    data => sync(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { addMarketplace, isAdding };
};
