import { Button, Modal, Upload, message } from "antd";
import { useState } from "react";
import { usePredefinedDevicesImport } from "features/devices";

export const PredefinedDevices = () => {
  const [file, setFile] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setFile([]);
    setIsOpen(false);
  };

  const { upload, isUploading } = usePredefinedDevicesImport({
    onSuccess: res => {
      message.success(res.message);
      close();
    },
  });

  const onSubmit = () => {
    const body = new FormData();
    body.append("file", file[0]);
    upload(body);
  };

  return (
    <>
      <Button
        type="primary"
        className="text-xs sm:text-sm"
        disabled={isUploading}
        onClick={() => setIsOpen(true)}
      >
        Predefined Devices
      </Button>

      <Modal
        title="Import Predefined Devices"
        maskClosable={false}
        open={isOpen}
        confirmLoading={isUploading}
        onCancel={() => {
          if (!isUploading) close();
        }}
        onOk={onSubmit}
        okText="Import"
        okButtonProps={{ disabled: !file.length }}
        bodyStyle={{ height: 40 }}
      >
        <Upload
          name="file"
          withCredentials
          fileList={file}
          customRequest={({ file }) => {
            setFile([file]);
          }}
          onRemove={() => setFile([])}
        >
          {!file.length && <Button danger>Choose File</Button>}
        </Upload>
      </Modal>
    </>
  );
};
