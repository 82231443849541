import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const removeDepartment = id => {
  return apiClient.delete(`api/directory/departments/${id}`);
};

export const useRemoveDepartment = (id, onSuccess) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(
    () => removeDepartment(id),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["departments"]);
        onSuccess?.(data);
      },
    },
  );

  return { remove, isRemoving };
};
