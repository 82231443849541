import { Tabs } from "antd";
import {
  PeriodSelector,
  AreaSelector,
  useStatisticsStore,
  StatisticsItem,
  STATISTICS_TABS,
  SelectEmployee,
} from "features/statistics";
import { useMediaQuery } from "react-responsive";
import { useAuthStore } from "features/auth";
import { useEffect, useMemo } from "react";

export const CompanyStatistics = () => {
  const store = useStatisticsStore();
  const { user, isRight } = useAuthStore();
  const isAdminOrFlasherHeadOrConfidant =
    isRight(["admin", "head_of_flasher"]) || user.data.confidant;

  const isTightly = useMediaQuery({
    query: "(max-width: 960px)",
  });

  useEffect(() => {
    if (!isAdminOrFlasherHeadOrConfidant) {
      for (let tab of STATISTICS_TABS) {
        if (isRight(tab.role)) {
          store.setupUsersData(tab.name);
          break;
        }
      }
    }
  }, [user]);

  const tabItems = useMemo(() => {
    return STATISTICS_TABS.filter(
      tab => isAdminOrFlasherHeadOrConfidant || isRight(tab.role),
    ).map(({ name }) => ({
      label:
        name === "Hold" || name === "Repair" ? (
          <span className="text-red-700">{name}</span>
        ) : (
          name
        ),
      key: name,
      children: <StatisticsItem operation={name} />,
    }));
  }, [user]);

  const usersRoleName = useMemo(() => {
    const activeTab = STATISTICS_TABS.find(
      ({ name }) => name === store.activeTab,
    );

    if (activeTab.role?.length === 1) {
      return activeTab.role[0];
    } else {
      return null;
    }
  }, [store.activeTab]);

  const showUsersSelect =
    store.isCompanyArea &&
    isAdminOrFlasherHeadOrConfidant &&
    !(store.activeTab === "Hold" || store.activeTab === "Repair");

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Statistics</h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-8 mt-6 mb-8 gap-2 flex-wrap">
        {isAdminOrFlasherHeadOrConfidant && <AreaSelector />}
        <PeriodSelector />
      </div>

      <Tabs
        className="max-w-5xl w-full mx-auto px-4 flex flex-1 overflow-y-auto p-4"
        destroyInactiveTabPane
        activeKey={store.activeTab}
        onChange={store.setActiveTab}
        tabBarExtraContent={{
          right: showUsersSelect ? (
            <SelectEmployee roleName={usersRoleName} />
          ) : null,
        }}
        size={isTightly ? "small" : "middle"}
        type="card"
        items={tabItems}
        tabBarStyle={{ marginBottom: 0 }}
      />
    </div>
  );
};
