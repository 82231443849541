import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { useOnSaleSkuStore } from "../store";

const exportSKUS = (data, isAll = false) => {
  return apiClient.post(
    `api/product/export-by-sku${isAll ? "-all-data" : ""}`,
    data,
  );
};

export const useSKUsExport = ({ onSuccess } = {}) => {
  const { searchQuery, filter_by } = useOnSaleSkuStore();

  const getFilterQuery = () => {
    const result = { ...filter_by };
    if ("color" in result) delete result.color;
    return result;
  };

  const { mutate: exportSKUs, isLoading: isExporting } = useMutation(
    ({ isAll } = {}) =>
      exportSKUS(
        {
          search_term: searchQuery || null,
          filter_by: getFilterQuery(),
        },
        isAll,
      ),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { exportSKUs, isExporting };
};
