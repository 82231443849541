import { useState } from "react";
import clsx from "clsx";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import {
  ConnectedProducts,
  DisconnectedProducts,
  useSkuAveragePrice,
  ReturnedProducts,
  LatestConnectedProducts,
} from "features/sales";

export const AveragePrice = ({ skuId, gradeId }) => {
  const [searchParams] = useSearchParams();
  const original_box = searchParams.get("original_box");

  const [isConnectedOpen, setConnectedOpen] = useState(false);
  const [isLatestConnectedOpen, setLatestConnectedOpen] = useState(false);
  const [isDisconnectedOpen, setDisconnectedOpen] = useState(false);
  const [isReturnedOpen, setReturnedOpen] = useState(false);

  const { getPrice, priceData, isFetching, isLoading } = useSkuAveragePrice({
    sku_id: skuId,
    grade_id: gradeId,
    original_box,
  });

  if (!priceData?.data)
    return (
      <div className={"flex justify-end align-top "}>
        <Button
          className={"text-blue-700 ml-2"}
          loading={isLoading || isFetching}
          onClick={getPrice}
          type={"link"}
          size={"small"}
        >
          Show Average Price
        </Button>
      </div>
    );

  const {
    average_total_price,
    average_price,
    average_flashed_price,
    connected_count,
    latest_connected_count,
    disconnected_count,
    returned_count,
  } = priceData.data;

  const defaultProductsStyle = "flex gap-1 text-gray-600 whitespace-nowrap";

  return (
    <div className={"flex flex-col"}>
      <div className="relative flex flex-wrap items-center justify-center gap-4 bg-white p-1 px-8">
        <h4 className={"m-0 text-blue-700"}>Total: {average_total_price}</h4>

        <h4 className={"m-0 text-blue-900"}>Sale: {average_price}</h4>

        <h4 className={"m-0 text-blue-900"}>
          Flashed: {average_flashed_price}
        </h4>

        <div className={"flex justify-center gap-4 flex-wrap"}>
          <span
            onClick={() => connected_count && setConnectedOpen(true)}
            className={clsx(
              defaultProductsStyle,
              connected_count &&
                "hover:underline hover:cursor-pointer hover:text-green-800",
              !connected_count && "hover:cursor-not-allowed",
            )}
          >
            Connected: <strong>{connected_count}</strong>
          </span>

          <span
            onClick={() =>
              latest_connected_count && setLatestConnectedOpen(true)
            }
            className={clsx(
              defaultProductsStyle,
              latest_connected_count &&
                "hover:underline hover:cursor-pointer hover:text-green-800",
              !latest_connected_count && "hover:cursor-not-allowed",
            )}
          >
            Latest: <strong>{latest_connected_count}</strong>
          </span>

          <span
            onClick={() => disconnected_count && setDisconnectedOpen(true)}
            className={clsx(
              defaultProductsStyle,
              disconnected_count &&
                "hover:underline hover:cursor-pointer hover:text-red-800",
              !disconnected_count && "hover:cursor-not-allowed",
            )}
          >
            Disconnected: <strong>{disconnected_count}</strong>
          </span>

          <span
            onClick={() => returned_count && setReturnedOpen(true)}
            className={clsx(
              defaultProductsStyle,
              returned_count &&
                "hover:underline hover:cursor-pointer hover:text-red-800",
              !returned_count && "hover:cursor-not-allowed",
            )}
          >
            Returned: <strong>{returned_count}</strong>
          </span>
        </div>

        <Button
          onClick={getPrice}
          loading={isLoading || isFetching}
          className={"absolute right-2 top-1"}
          size={"small"}
          type={"ghost"}
          icon={<ReloadOutlined />}
        />
      </div>

      {isReturnedOpen && (
        <ReturnedProducts
          sku_id={skuId}
          grade_id={gradeId}
          original_box={original_box}
          onClose={() => setReturnedOpen(false)}
        />
      )}

      {isDisconnectedOpen && (
        <DisconnectedProducts
          sku_id={skuId}
          grade_id={gradeId}
          original_box={original_box}
          onClose={() => setDisconnectedOpen(false)}
        />
      )}

      {isConnectedOpen && (
        <ConnectedProducts
          sku_id={skuId}
          grade_id={gradeId}
          original_box={original_box}
          onClose={() => setConnectedOpen(false)}
        />
      )}

      {isLatestConnectedOpen && (
        <LatestConnectedProducts
          sku_id={skuId}
          grade_id={gradeId}
          original_box={original_box}
          onClose={() => setLatestConnectedOpen(false)}
        />
      )}
    </div>
  );
};
