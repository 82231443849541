import { Dropdown, Tooltip } from "antd";
import { MoreOutlined, EditFilled } from "@ant-design/icons";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  InfoCircleTwoTone,
  FireFilled,
  FireOutlined,
} from "@ant-design/icons";

export const getProductsTableConfig = (onEditClick, isEditingEnabled) => {
  const config = [
    {
      title: (
        <Tooltip title="Priority">
          <FireOutlined />
        </Tooltip>
      ),
      key: "priority_label",
      dataIndex: "priority",
      width: 40,
      render: priority =>
        priority[0].quantity_of_prior_devices > 0 ? (
          <FireFilled className="text-red-400" />
        ) : null,
    },
    {
      title: "SKU",
      dataIndex: "article",
      key: "article",
      width: 140,
      render: article => <span className="whitespace-nowrap">{article}</span>,
      align: "center",
    },
    {
      title: "Model",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: 100,
      render: quantity => parseFloat(quantity),
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      width: 180,
      align: "center",
      ellipsis: {
        showTitle: false,
      },

      render: imei => (
        <Tooltip placement="topLeft" title={imei?.join(", ")}>
          {imei}
        </Tooltip>
      ),
    },
    {
      title: "Price",
      dataIndex: "single_price",
      key: "single_price",
      width: 100,
      align: "center",
      render: amount => (
        <span className="whitespace-nowrap">{`€ ${amount}`}</span>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "global_price",
      key: "global_price",
      width: 120,
      align: "center",
      render: amount => (
        <span className="whitespace-nowrap">{`€ ${amount}`}</span>
      ),
    },
    {
      title: "Readiness",
      dataIndex: "priority",
      key: "priority",
      width: 110,
      align: "center",
      render: priority => {
        const {
          quantity_of_prior_devices,
          quantity_of_not_processed_prior_devices,
          quantity_of_processed_prior_devices,
          quantity_of_processing_prior_devices,
        } = priority?.[0];

        if (!quantity_of_prior_devices) return null;

        return (
          <Tooltip
            title={() => (
              <ul className="m-0  pl-1 list-none">
                <li>Prior devices: {quantity_of_prior_devices}</li>
                <li>
                  Not processed: {quantity_of_not_processed_prior_devices}
                </li>
                <li>Processed: {quantity_of_processed_prior_devices}</li>
                <li>In process: {quantity_of_processing_prior_devices}</li>
              </ul>
            )}
          >
            {quantity_of_processing_prior_devices > 0 &&
              quantity_of_not_processed_prior_devices == 0 && (
                <ClockCircleOutlined className="text-yellow-500" />
              )}

            {quantity_of_prior_devices ===
              quantity_of_processed_prior_devices && (
              <CheckCircleOutlined className="text-green-500" />
            )}

            {quantity_of_not_processed_prior_devices > 0 && (
              <CloseCircleOutlined className="text-red-400" />
            )}

            <InfoCircleTwoTone className="ml-2" />
          </Tooltip>
        );
      },
    },
  ];

  if (isEditingEnabled)
    config.push({
      title: null,
      width: 46,
      render: (_, item) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 0,
                  label: "Edit",
                  onClick: () => onEditClick(item),
                  icon: <EditFilled />,
                },
              ],
            }}
            trigger={["click"]}
          >
            <MoreOutlined className={" hover:text-gray-400"} />
          </Dropdown>
        );
      },
    });

  return config;
};
