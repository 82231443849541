import { create } from "lib/zustand-poxy";
import { persist } from "zustand/middleware";
import _ from "lodash";

export const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      isLoggedIn: false,
      putUser: user => set({ user, isLoggedIn: true }),
      getToken: () => get().user?.token,
      getUser: () => get().user?.data,
      getRoles: () => get().user?.data.roles,
      clear: () => set({ user: null, isLoggedIn: false }),
      isRight: (allowedRoles, specialIds = []) => {
        const user = get().user;
        return (
          specialIds.includes(user?.data.id) ||
          !_.isEmpty(_.intersection(user?.data.roles, allowedRoles))
        );
      },
    }),
    {
      name: "session-storage",
    },
  ),
);
