import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import {
  pagination,
  filters,
  search,
  dateFilters,
  sorting,
} from "utils/standart-stores";

export const useFormulasStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),

      currentFormula: null,
      setCurrentFormula: v =>
        set(state => {
          if (!state.currentFormula) state.currentFormula = v;
        }),
      resetCurrentFormula: () =>
        set(state => {
          state.currentFormula = null;
        }),
    })),
  ),
);

export const useConnectedDevicesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...sorting(set),
      ...search(set),
      ...dateFilters(set),
    })),
  ),
);

export const useDisconnectedDevicesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...sorting(set),
      ...search(set),
      ...dateFilters(set),
    })),
  ),
);

export const useDevicesWithoutSaleStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...search(set),
    })),
  ),
);

export const useDevicesWthOtherStatusesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...search(set),
    })),
  ),
);

export const useConnectedSKUsStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...sorting(set),
      ...search(set),
      ...dateFilters(set),
    })),
  ),
);

export const useDisconnectedSKUsStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...sorting(set),
      ...search(set),
      ...dateFilters(set),
    })),
  ),
);

export const useSKUsWithoutSaleStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...filters(set),
      ...sorting(set),
      ...search(set),
    })),
  ),
);
