import React from "react";
import LogoImg from "assets/logo.png";
import { useNavigate } from "react-router";

export const Logo = ({ width, height, clickable = false }) => {
  const navigate = useNavigate();

  return (
    <img
      src={LogoImg}
      alt="logo"
      width={width}
      height={height}
      onClick={() => {
        clickable && navigate("/");
      }}
      style={{ cursor: clickable ? "pointer" : "auto" }}
    />
  );
};
