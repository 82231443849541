import { useMutation } from "react-query";
import { message } from "antd";
import { editEmployee } from "./edit-employee";

export const useNewPassword = ({ employeeId, onSuccess }) => {
  const { mutateAsync: setPassword, isLoading: isSettingPassword } =
    useMutation(data => editEmployee(employeeId, data), {
      onSuccess: async () => {
        message.success("New password created successfully");
        onSuccess?.();
      },
    });

  return { setPassword, isSettingPassword };
};
