import { Link } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const DirectorySupplierLink = ({
  supplierId,
  label = null,
  rightRoles = ["admin"],
}) => {
  const { isRight } = useAuthStore();

  if (isRight(rightRoles))
    return (
      <Link
        className="whitespace-nowrap"
        to={`/directory/suppliers/${supplierId}`}
        target={"_blank"}
      >
        {label || supplierId}
      </Link>
    );

  return <span>{label || supplierId}</span>;
};
