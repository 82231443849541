// NOTE: don't forget connect immer in store

export const pagination = set => ({
  page: 1,
  per_page: 12,
  total: 0,
  setPageSize: per_page => set({ per_page }),
  setPage: page => set({ page }),
  setTotal: (total, lastPage = null) =>
    set(state => {
      if (!!lastPage && lastPage < state.page) state.page = lastPage;
      state.total = total;
    }),
});

export const filters = set => ({
  filter_by: {},
  setFilter: fieldObj => {
    set(state => {
      const [key, value] = Object.entries(fieldObj)[0];
      if (value === null || value === undefined) {
        delete state.filter_by[key];
      } else {
        state.filter_by[key] = value;
      }
    });
  },
  clearFilters: (excluded = []) => {
    set(state => {
      if (!excluded.length) {
        state.filter_by = {};
      } else {
        for (const filterName in state.filter_by) {
          if (!excluded.includes(filterName))
            delete state.filter_by[filterName];
        }
      }
    });
  },
});

export const sorting = set => ({
  sorting: {},
  setSorting: (key, value) =>
    set(state => {
      if (key && value) {
        state.sorting = { [key]: value };
      } else {
        state.sorting = {};
      }
    }),
});

export const dateFilters = set => ({
  start_date: null,
  end_date: null,
  setStartDate: v =>
    set(state => {
      state.start_date = v || null;
    }),
  setEndDate: v =>
    set(state => {
      state.end_date = v || null;
    }),
  resetDates: () =>
    set(state => {
      state.start_date = null;
      state.end_date = null;
    }),
});

export const search = set => ({
  search_term: null,
  setSearchTerm: v =>
    set(state => {
      state.search_term = v || null;
    }),
});
