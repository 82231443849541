import { useState } from "react";
import _ from "lodash";
import { Button, Form, Modal, Input, Switch } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { useEditPair, useRemovePair, useSwapPairSKU } from "features/sales";
import { requiredRule } from "constants/validation";

export const EditSKUPair = ({ pairData, pairId, close }) => {
  const [form] = Form.useForm();
  const [touched, setTouched] = useState(false);

  const { editPairList, isEditing } = useEditPair();
  const { removePairList, isRemoving } = useRemovePair({
    onSuccess: close,
  });
  const { swapSKUs, isSwapping } = useSwapPairSKU();

  const onFinish = values => {
    editPairList({ id: pairId, data: values });
  };

  const idle = isEditing || isRemoving || isSwapping;

  return (
    <Modal
      title={`Edit SKU pair #${pairId}`}
      open={true}
      footer={null}
      onOk={form.submit}
      onCancel={() => {
        if (!idle) close();
      }}
    >
      <Form
        onValuesChange={() =>
          setTouched(!_.isEqual(form.getFieldsValue(), pairData))
        }
        form={form}
        onFinish={onFinish}
        disabled={idle}
        className="mt-8"
      >
        <Form.Item
          name={"sku_1"}
          label={"SKU 1"}
          required
          rules={[requiredRule]}
          initialValue={pairData.sku_1}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"sku_2"}
          label={"SKU 2"}
          required
          rules={[requiredRule]}
          initialValue={pairData.sku_2}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"active"}
          label={"Active"}
          valuePropName="checked"
          initialValue={pairData.active}
        >
          <Switch />
        </Form.Item>
      </Form>

      <div className="flex gap-2">
        <Button
          danger
          disabled={idle}
          onClick={() => {
            Modal.confirm({
              title: "Deleting",
              content: "Are you sure you want to delete this pair?",
              onOk: () => removePairList(pairId),
            });
          }}
        >
          Delete
        </Button>

        <Button
          className="mx-auto"
          disabled={idle}
          icon={<SwapOutlined />}
          onClick={() => {
            swapSKUs(pairId);
            close();
          }}
        >
          Swap SKUs
        </Button>

        <Button
          onClick={() => {
            if (!idle) close();
          }}
          disabled={idle}
        >
          Cancel
        </Button>

        <Button
          type={"primary"}
          disabled={!touched}
          loading={isEditing}
          onClick={form.submit}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};
