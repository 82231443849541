import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { SKULink } from "features/sales";
import { ReplaceDevice } from "./ReplaceDevice";
import ReactCountryFlag from "react-country-flag";

export const comListProductsConfig = [
  {
    title: "ID",
    dataIndex: "product_id",
    key: "product_id",
    width: 120,
    align: "center",
    render: id => <Link to={`/devices/${id}`}>{id}</Link>,
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    width: 140,
    align: "center",
    render: (_, record) => <SKULink device={record} />,
  },
  {
    title: "Device Type",
    dataIndex: "type",
    key: "type",
    align: "center",
    width: "18%",
    render: (text, record) => (
      <span>{record.sku?.device_type?.device_type_name}</span>
    ),
  },
  {
    title: "Device",
    dataIndex: "name",
    align: "center",
    key: "name",
    ellipsis: {
      showTitle: false,
    },
    render: (text, { color, field_values }) => {
      const model =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Model",
        )?.device_field.device_field_option[0]?.value || "";

      const memory =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      return (
        <Tooltip
          placement="topLeft"
          title={`${model} ${memory} ${color?.name || ""}`}
        >{`${model} ${memory} ${color?.name || ""}`}</Tooltip>
      );
    },
  },
  {
    title: "Order",
    key: "order",
    align: "center",
    dataIndex: "sale_histories",
    render: sh => {
      const order = sh?.[0]?.order;
      if (!order) return "";
      return (
        <Link to={order?.order_link} target={"_blank"}>
          {order?.order_id}
        </Link>
      );
    },
  },
  {
    title: "Country",
    key: "country",
    align: "center",
    dataIndex: "sale_histories",
    render: sh => {
      const code = sh?.[0]?.order?.country_code;
      if (!code) return null;
      return (
        <div className="flex items-center gap-1.5 justify-center text-slate-600">
          <ReactCountryFlag countryCode={code} svg />
          {code}
        </div>
      );
    },
  },
  {
    title: "Readiness",
    align: "center",
    key: "ready",
    dataIndex: "readiness",
    width: 110,
    render: active =>
      active ? (
        <CheckCircleFilled className="text-green-500" />
      ) : (
        <CloseCircleFilled className="text-red-700" />
      ),
  },
  {
    align: "center",
    key: "action",
    width: 60,
    render: ({ product_id }) => <ReplaceDevice deviceId={product_id} />,
  },
];
