import { apiClient } from "lib/api-client";
import { useInfiniteQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { useMemo, useState } from "react";

const infinite_getGradeGroup = async ({
  pageParam,
  search_term,
  grade_id = null,
}) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term, grade_id },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteGradeGroup = ({ onSuccess, grade_id = null } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-grades-group", grade_id, debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/directory/grades/group?per_page=12&page=1`,
      }) =>
        infinite_getGradeGroup({
          pageParam,
          search_term: debouncedQuery || null,
          grade_id,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const groupOptions = useMemo(
    () =>
      data?.pages
        ? data?.pages
            .map(({ data }) =>
              data?.map(({ name, id }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            )
            ?.flat()
        : [],
    [data],
  );

  const additionalOptions = useMemo(() => {
    if (!data?.pages) return null;

    const grades = data?.pages?.map(({ data }) => data)?.flat();
    const result = {};
    for (const grade of grades) {
      result[grade.id] = grade?.additional_options;
    }
    return result;
  }, [data]);

  const gradeGroup = data?.pages?.map(({ data }) => data)?.flat();

  return {
    gradeGroup,
    groupOptions,
    additionalOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
