import { useEffect, useMemo } from "react";
import _ from "lodash";
import { CopyOutlined, DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { PaginatedSelect } from "components";
import { useMarketplaceNames } from "features/marketplaces";
import { useInfiniteGrades } from "features/directory";
import { DeviceTypeMultiSelect } from "features/devices";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;

const example = "{sale_price}/1.19-{sale_price}*0.07";
const onCopy = async () => {
  try {
    await navigator.clipboard.writeText(example);
    message.success("Copied!");
  } catch (e) {
    message.error("Failure! You probably need to set permissions.");
  }
};

export const ExpressionForm = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  formulaData,
  onDelete,
}) => {
  const [form] = Form.useForm();
  const gradesValue = Form.useWatch("grades", form);
  const marketplaceValue = Form.useWatch("marketplace", form);
  const expressionValue = Form.useWatch("expression", form);

  const { marketplaceOptions, isLoadingMarketplaces } = useMarketplaceNames();

  const {
    gradeOptions,
    isFetchingNextPage,
    isLoading: isLoadingGradeOptions,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteGrades();

  const preparedOptions = useMemo(() => {
    if (!formulaData) return gradeOptions;

    const existingOpts = formulaData?.grades.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const diff = _.differenceWith(existingOpts, gradeOptions, _.isEqual);
    return [...diff, ...gradeOptions];
  }, [gradeOptions, formulaData]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }

    if (isOpen && formulaData) {
      form.setFieldsValue({
        title: formulaData.title,
        marketplace: formulaData.marketplace,
        expression: formulaData.expression,
        device_types: formulaData.device_types.map(({ id }) => id),
        grades: formulaData.grades.map(({ id }) => id),
        greater: formulaData.greater,
      });
    }
  }, [isOpen, formulaData, form]);

  const title = (
    <div className="flex">
      {formulaData ? "Edit Expression" : "Create Expression"}
      <Tooltip title={example}>
        <Button className="mx-auto" size="small" type="link" onClick={onCopy}>
          <CopyOutlined />
          Copy example
        </Button>
      </Tooltip>
    </div>
  );

  const footer = (
    <div className={"flex"}>
      {formulaData && (
        <Button
          danger
          className={"ml-1"}
          icon={<DeleteFilled />}
          onClick={onDelete}
          disabled={isLoading}
        >
          Delete
        </Button>
      )}

      <div className={"ml-auto"}>
        <Button onClick={() => !isLoading && onClose()}>Cancel</Button>
        <Button type={"primary"} onClick={form.submit} loading={isLoading}>
          Ok
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={() => !isLoading && onClose()}
      confirmLoading={isLoading}
      width={640}
      footer={footer}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={isLoading}
        className={"mt-6"}
        initialValues={{ difference_price: 0 }}
      >
        <Form.Item
          required
          name="title"
          label="Title"
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Enter Expression Title" />
        </Form.Item>

        <Form.Item
          required
          name="marketplace"
          label="Marketplace"
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <Select
            loading={isLoadingMarketplaces}
            placeholder={"Select Marketplace"}
            options={marketplaceOptions.concat({ value: "B2B", label: "B2B" })}
          />
        </Form.Item>

        <Form.Item
          required
          name="expression"
          label="Expression"
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <TextArea
            autoSize
            placeholder="Enter Expression"
            className={`text-green-900 ${expressionValue ? "font-mono" : ""}`}
          />
        </Form.Item>

        <DeviceTypeMultiSelect
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
          name="device_types"
          initialData={formulaData?.device_types}
          label="Device Type"
          placeholder="Select Device Type"
          disabled={isLoading}
          onSelect={e => {
            let current = form.getFieldValue("device_types");
            if (!current) current = [];
            form.setFieldValue("device_types", [...current, e]);
          }}
          onDeselect={e => {
            const current = form.getFieldValue("device_types");
            form.setFieldValue(
              "device_types",
              current.filter(item => item !== e),
            );
          }}
          onClear={() => {
            form.setFieldValue("device_types", []);
          }}
        />

        <Form.Item
          name="grades"
          label="Grades"
          valuePropName="fake"
          initialValue={[]}
          required
          rules={[requiredRule]}
          labelCol={{ span: 6 }}
        >
          <PaginatedSelect
            className={"w-full"}
            value={gradesValue}
            mode="multiple"
            isLoading={isLoadingGradeOptions}
            placeholder="Select Grade"
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            searchValue={searchQuery}
            onSearch={setSearchQuery}
            onDeselect={value => {
              form.setFieldValue(
                "grades",
                gradesValue.filter(item => item !== value),
              );
            }}
            onChange={value => {
              form.setFieldValue("grades", [...gradesValue, value]);
            }}
            onClear={() => form.setFieldValue("grades", [])}
            options={preparedOptions}
            disabled={isLoading}
          />
        </Form.Item>

        {marketplaceValue === "EBAY" && (
          <Form.Item
            initialValue={false}
            name="greater"
            className={"inline-block ml-20"}
            label="Grater"
            valuePropName="checked"
          >
            <Switch defaultChecked={true} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
