import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const createNewEmployee = data => apiClient.post("api/users", { ...data });

export const useNewEmployee = ({ onSuccess }) => {
  const { mutate: createEmployee, isLoading: isCreatingEmployee } = useMutation(
    createNewEmployee,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["employees"]);
        onSuccess?.();
      },
    },
  );

  return { createEmployee, isCreatingEmployee };
};
