import { Button, message } from "antd";
import clsx from "clsx";
import { useProfitabilityExport } from "features/profitability";

export const ProductsExport = ({
  disabled,
  className = "",
  store,
  groupName,
  isSKU = false,
}) => {
  const { download, isDownloading } = useProfitabilityExport({
    store,
    onSuccess: res => message.success(res?.message),
    groupName,
    isSKU,
  });

  return (
    <Button
      disabled={disabled}
      onClick={download}
      loading={isDownloading}
      className={clsx("text-xs sm:text-sm", className)}
    >
      Export
    </Button>
  );
};
