import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const editInvoice = (data, id) =>
  apiClient.put(`api/invoice-products/${id}`, data);

export const useEditInvoice = ({ onSuccess, id } = {}) => {
  const { mutateAsync: edit, isLoading: isEditing } = useMutation(
    data => editInvoice(data, id),
    {
      onSuccess: res => {
        onSuccess?.(res);
        queryClient.invalidateQueries(["invoiceProducts"]);
      },
    },
  );

  return { edit, isEditing };
};
