import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useSendingStatuses } from "features/sending";
import dayjs from "dayjs";

export const SendingDevicesTable = ({ data, loading }) => {
  const navigate = useNavigate();

  const { statuses, isLoadingStatuses } = useSendingStatuses();

  const columns = [
    {
      title: "List Number",
      dataIndex: "list_number",
      key: "list_number",
    },
    {
      title: "Devices Inside",
      dataIndex: "number_of_devices",
      key: "number_of_devices",
    },
    {
      title: "Employee",
      dataIndex: "user",
      key: "user",
      render: ({ first_name, last_name }) => (
        <span>{`${first_name} ${last_name}`}</span>
      ),
    },
    {
      title: "Date and Time",
      dataIndex: "date_formation",
      key: "date_formation",
      render: date_formation => (
        <span>{dayjs(date_formation).format("YYYY-MM-DD  H:mm")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => <span>{statuses?.data?.[status]}</span>,
    },
  ];

  return (
    <Table
      className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
      rowClassName="hover:cursor-pointer"
      loading={loading || isLoadingStatuses}
      columns={columns}
      dataSource={data?.data}
      sticky={true}
      rowKey={record => record.list_number}
      pagination={false}
      onRow={({ list_number }) => ({
        onClick: () =>
          navigate(`/sending/devices/commission-list/${list_number}`),
      })}
      scroll={{ x: 600 }}
    />
  );
};
