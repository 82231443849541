import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getColor = id => apiClient.get(`api/directory/colors/${id}`);

export const useColor = ({ id, onSuccess, onError }) => {
  const { data: color, isLoading } = useQuery(
    ["color", id],
    () => getColor(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
      retry: false,
    },
  );

  return { color, isLoading };
};
