import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

export const useFormulaData = ({ onSuccess, onError, formulaId } = {}) => {
  const url = `api/profit/marketplace-formula/${formulaId}`;
  const {
    data: formula,
    isLoading,
    isFetching,
  } = useQuery(
    ["marketplaces-formula-data", formulaId],
    () => apiClient.get(url),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );
  return { formula, isLoading, isFetching };
};
