import {
  useGrade,
  useEditGrade,
  useFormConstructor,
  FormItem,
  styles,
} from "features/directory";
import { useEffect } from "react";
import { Row, Button, Switch, message } from "antd";
import { Loader } from "components";
import { useParams } from "react-router-dom";
import { SwapOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { globalErrorHandler } from "lib/react-query-client";
import { useAuthStore } from "features/auth";

export const Grade = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin"]);
  const navigate = useNavigate();
  const { gradeId } = useParams();
  const {
    fields,
    addNewField,
    swapFields,
    clear,
    setData,
    validation,
    onChangeName,
    onChangeType,
    removeField,
    onChangeOptionText,
    addNewOption,
    removeOption,
    validate,
  } = useFormConstructor();

  const { grade, idLoadingGrade } = useGrade({
    id: gradeId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });
  const { edit, isEditing } = useEditGrade(gradeId, () =>
    message.success("Success!"),
  );

  // eslint-disable-next-line
  useEffect(() => clear, []);

  useEffect(() => {
    if (grade && !fields?.length) {
      const { description, additional_options, sku_display } = grade.data;
      const additionalOptions = JSON.parse(JSON.stringify(additional_options));
      setData({
        fields: [
          { name: sku_display },
          { name: description },
          ...additionalOptions,
        ],
      });
    }
    // eslint-disable-next-line
  }, [grade]);

  return (
    <div className="flex flex-col h-full w-full px-4 relative overflow-y-auto">
      <h2 className="text-center ">{`Grade ${
        grade?.data.name || "#" + gradeId
      }`}</h2>

      {grade && !!fields.length && (
        <div className="max-w-lg w-full mx-auto">
          <div className="flex gap-3 mb-2">
            Show in SKU
            <Switch
              className="mr-auto"
              checked={fields[0].name}
              onChange={val => onChangeName(0, val)}
              disabled={isEditing}
            />
            {`id: #${grade.data.id}`}
          </div>

          <FormItem
            placeholder="Enter Description"
            disabled={isEditing}
            textArea
            fixedType
            isStatic
            nameValue={fields[1]?.name}
            onChangeName={value => onChangeName(1, value)}
          />

          <p style={{ margin: "40px 0 10px", textAlign: "center" }}>
            Additional Fields:
          </p>

          {fields.slice(2).map((item, i) => {
            const realIndex = i + 2;
            const isOriginalBoxField = item.name === "Original Box";
            const afterOriginalBoxField = i === 1;

            return (
              <div className="flex flex-col" key={`field-${i}`}>
                {i !== 0 && (
                  <Button
                    disabled={isEditing || afterOriginalBoxField}
                    className="text-slate-300 hover:text-sky-500"
                    size="small"
                    type="ghost"
                    onClick={() => swapFields(realIndex - 1)}
                  >
                    <SwapOutlined />
                  </Button>
                )}

                <FormItem
                  readOnly={isOriginalBoxField}
                  isStatic={isOriginalBoxField}
                  fieldIndex={realIndex}
                  disabled={isEditing}
                  type={item.type}
                  nameValue={item.name}
                  error={item.error && validation}
                  onChangeType={value => onChangeType(realIndex, value)}
                  onChangeName={value => onChangeName(realIndex, value)}
                  onRemove={() => removeField(realIndex)}
                  onRemoveOption={key => removeOption(realIndex, key)}
                  onChangeOption={(index, value, key) =>
                    onChangeOptionText(realIndex, value, key)
                  }
                  onAddNewOption={() => addNewOption(realIndex)}
                />
              </div>
            );
          })}

          {rightRole && (
            <Row justify="center">
              <Button
                disabled={isEditing}
                type="primary"
                className={styles.constructor_add_btn}
                onClick={() => addNewField()}
              >
                Add Field
              </Button>
            </Row>
          )}

          {rightRole && (
            <Row justify="center">
              <Button
                className="mb-4"
                onClick={() => {
                  const isValid = validate();
                  if (isValid)
                    edit({
                      sku_display: fields[0].name,
                      description: fields[1].name,
                      additional_options: fields.slice(2),
                    });
                }}
                loading={isEditing}
                disabled={idLoadingGrade || isEditing}
              >
                Update
              </Button>
            </Row>
          )}
        </div>
      )}

      {idLoadingGrade && <Loader />}
    </div>
  );
};
