import { Button, Checkbox, Form, message, Modal } from "antd";
import { useState } from "react";
import {
  useIdentifyDevice,
  RegistrarForm,
  useOnKeySubmit,
} from "features/devices";
import { useAuthStore } from "features/auth";
import { useScanner } from "features/qr-scanner";
import { useMediaQuery } from "react-responsive";

export const Registering = ({ deviceData }) => {
  const isTightly = useMediaQuery({
    query: "(max-width: 460px)",
  });

  const { isRight } = useAuthStore();
  const { openScanner } = useScanner();
  const [form] = Form.useForm();

  const { isOpen, setIsOpen } = useOnKeySubmit(form.submit);
  const [keepOpen, setKeepOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const { identify, isIdentifying } = useIdentifyDevice({
    id: deviceData.product_id,
    onSuccess: response => {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      onClose();
      if (keepOpen) openScanner();
    },
  });

  if (!isRight(["admin", "registrar"])) return <span>Grading</span>;

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Identify
      </Button>

      <Modal
        destroyOnClose
        title="Input IMEI/Serial"
        open={isOpen}
        onCancel={({ target }) => {
          if (
            typeof target.className === "string" &&
            target.className?.includes("ant-modal-wrap")
          )
            return;

          if (!isIdentifying) onClose();
        }}
        footer={
          <div className="flex gap-2 items-center flex-wrap">
            <Checkbox
              disabled={isIdentifying}
              checked={keepOpen}
              className="mr-auto"
              onChange={() => setKeepOpen(!keepOpen)}
            >
              Open scanner on success
            </Checkbox>

            <div className="ml-auto">
              <Button
                disabled={isIdentifying}
                onClick={onClose}
                size={isTightly ? "small" : "middle"}
              >
                Cancel
              </Button>

              <Button
                size={isTightly ? "small" : "middle"}
                type="primary"
                onClick={form.submit}
                loading={isIdentifying}
              >
                Ok
              </Button>
            </div>
          </div>
        }
      >
        <RegistrarForm
          disabled={isIdentifying}
          form={form}
          onSubmit={data => {
            data && identify(data);
          }}
          deviceFields={deviceData?.device_type?.fields}
        />
      </Modal>
    </>
  );
};
