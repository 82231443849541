import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";
import { message } from "antd";

export const editEmployee = (id, data) =>
  apiClient.put(`api/users/${id}`, {
    ...data,
  });

export const useEditEmployee = ({ employeeId, onSuccess }) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    value => editEmployee(employeeId, value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["employee", employeeId]);
        message.success("Employee updated successfully");
        onSuccess?.();
        // setIsDirty(false);
      },
    },
  );

  return { edit, isEditing };
};
