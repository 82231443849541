import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import _ from "lodash";

const getProductSKUs = ({ params, groupName }) =>
  apiClient.get(`api/profit/${groupName}/skus`, {
    params,
  });

export const useProductSKUs = ({ store, groupName, queryName } = {}) => {
  const {
    page = 1,
    per_page = 12,
    search_term,
    filter_by,
    start_date,
    end_date,
    setTotal,
    sorting,
  } = store || {};

  const [debouncedQuery] = useDebounce(search_term, 500);

  const realFilters = _.omit(filter_by, "color");
  if (!_.isEmpty(sorting)) Object.assign(realFilters, sorting);

  const { data, isLoading, isFetching } = useQuery(
    [
      queryName,
      page,
      per_page,
      debouncedQuery,
      realFilters,
      start_date,
      end_date,
    ],
    () =>
      getProductSKUs({
        groupName,
        params: {
          page,
          per_page,
          search_term: debouncedQuery,
          filter_by: realFilters,
          start_date,
          end_date,
        },
      }),
    {
      onSuccess: ({ meta }) => setTotal?.(meta.total, meta.last_page),
      enabled: groupName === "without-sale" || "market_formula" in filter_by,
    },
  );

  return { data, isLoading, isFetching };
};
