import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const editGrade = (id, data) => {
  return apiClient.put(`api/directory/grades/${id}`, data);
};

export const useEditGrade = (id, onSuccess) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    data => editGrade(id, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["grades"]);
        await queryClient.invalidateQueries(["grade", id]);
        onSuccess?.();
      },
    },
  );

  return { edit, isEditing };
};
