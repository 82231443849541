import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useStatisticsStore } from "features/statistics";
import { useDebounce } from "use-debounce";

const getProductsStats = params =>
  apiClient.get("api/statistic/product/data", {
    params,
    data: null,
  });

export const useProductsStats = ({ onSuccess, onError } = {}) => {
  const {
    status,
    per_page,
    page,
    period,
    by_auth_user,
    by_user_id,
    start_date,
    end_date,
    setPage,
    setTotal,
    activeTab,
    activeRegistrarTab,
    search,
    byDevices,
  } = useStatisticsStore();

  const [debouncedQuery] = useDebounce(search, 500);

  const {
    data: productsStats,
    isLoading,
    isFetching,
  } = useQuery(
    [
      "prod-stats",
      page,
      per_page,
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
      debouncedQuery,
    ],
    () =>
      getProductsStats({
        page,
        per_page,
        status,
        period,
        by_auth_user: by_auth_user ? 1 : 0,
        by_user_id,
        start_date,
        end_date,
        search: debouncedQuery,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
      enabled:
        !(activeTab === "Registration" && activeRegistrarTab === "Invoices") &&
        !byDevices,
    },
  );

  return { productsStats, isProductsStatsLoading: isLoading || isFetching };
};
