import { SearchInput } from "components";
import {
  useSalesIssues,
  useSalesIssuesStore,
  SalesIssuesTable,
  CreateSale,
} from "features/sales";

export const SalesIssues = () => {
  const store = useSalesIssuesStore();
  const { issues, isFetchingIssues, isLoadingIssues } = useSalesIssues();

  return (
    <div className="page-container flex-col ">
      <h2 className="text-center mb-6 sm:mb-10">Sales Issues</h2>

      <div className="mb-2 flex justify-between">
        <SearchInput
          searchQuery={store.search_term}
          setSearchQuery={store.setSearchTerm}
        />
        <CreateSale />
      </div>

      <SalesIssuesTable
        dataSource={issues?.data}
        store={store}
        isLoading={isLoadingIssues || isFetchingIssues}
        isDisabled={isLoadingIssues || !issues}
      />
    </div>
  );
};
