import {
  Table,
  Pagination,
  Button,
  Modal,
  message,
  Badge,
  Tooltip,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  GiftFilled,
} from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import {
  useReadyDevices,
  DevicesFilter,
  useReadyForSaleStore,
  useMoveInSale,
} from "features/devices";
import { SKULink } from "features/sales";
import { SearchInput } from "components";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

export const Sales = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const store = useReadyForSaleStore();

  const [selection, setSelection] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { devices, isLoading, isFetching } = useReadyDevices();
  const { move, isMoving } = useMoveInSale(data => {
    setSelection([]);
    setIsConfirmOpen(false);
    message.success(data.message || "Success!");
  });

  useEffect(() => {
    const skuNumber = searchParams.get("skuNumber");
    const grade = +searchParams.get("grade");

    if (skuNumber) store.setSearchQuery(skuNumber);
    if (grade) store.setFilter({ grade_id: grade });

    setSearchParams({});
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: "SKU",
        key: "sku",
        width: 100,
        render: (_, record) => <SKULink device={record} />,
      },
      {
        title: "Type",
        key: "type",
        dataIndex: ["device_type", "name"],
        align: "center",
      },
      {
        title: "Device",
        key: "device",
        align: "center",
        render: (_, { color, field_values, product_id }) => {
          const model =
            Object.values(field_values).find(
              field => field.device_field.device_field_name === "Model",
            )?.device_field.device_field_option[0]?.value || "";

          const memory =
            Object.values(field_values).find(
              field => field.device_field.device_field_name === "Memory",
            )?.device_field.device_field_option[0]?.value || "";

          return (
            <Link
              to={`/devices/${product_id}`}
            >{`${model} ${memory} ${color?.name}`}</Link>
          );
        },
        // ellipsis: true,
      },
      {
        title: "Date",
        key: "sort_by_ready_date",
        render: (_, record) => {
          return (
            <span>
              {record.ready_for_sale_date
                ? `${dayjs(record.ready_for_sale_date).format(
                    "YYYY-MM-DD HH:mm",
                  )}`
                : ""}
            </span>
          );
        },
        sorter: true,
        sortOrder: store.sorting.sort_by_ready_date,
        showSorterTooltip: false,
        // ellipsis: true,
        width: 160,
        align: "center",
      },
      {
        title: "WE Nr",
        key: "WE",
        align: "center",
        render: (_, { invoice }) => (
          <span>{invoice.invoice_number + invoice.supplier.supplier_nr}</span>
        ),
      },
      {
        title: (
          <Tooltip title="Original Box">
            <GiftFilled />
          </Tooltip>
        ),
        key: "original_box",
        width: 60,
        align: "center",
        render: (_, { original_box }) =>
          original_box ? (
            <CheckCircleOutlined className="text-green-500" />
          ) : null,
      },
    ];
  }, [store.sorting]);

  return (
    <div className="page-container flex-col">
      <h2 className="text-center">Sales</h2>

      <div className="flex justify-between mb-3 gap-2">
        {!!selection.length && (
          <Badge
            className="mr-1"
            count={selection.length}
            size="small"
            color="volcano"
            offset={[-4, 0]}
          >
            <Button
              className="text-xs sm:text-sm"
              disabled={isMoving}
              onClick={() => setIsConfirmOpen(true)}
              type="primary"
            >
              Send To Sell
            </Button>
          </Badge>
        )}

        <SearchInput
          searchQuery={store.searchQuery}
          setSearchQuery={store.setSearchQuery}
        />

        <div className="flex-1" />

        <DevicesFilter
          showPriority
          fixedStatus
          store={store}
          isLoading={isLoading || isFetching}
          disabled={isLoading}
        />
      </div>

      <Table
        className="overflow-y-auto"
        loading={isLoading || isFetching}
        columns={columns}
        rowSelection={{
          onChange: setSelection,
          selectedRowKeys: selection,
        }}
        dataSource={devices?.data}
        sticky={true}
        rowKey={record => record.product_id}
        pagination={false}
        scroll={{ x: 720 }}
        onChange={(pag, f, { columnKey, order }) => {
          store.setSorting(columnKey, order);
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        showTotal={false}
        current={store.page}
        pageSize={store.pageSize}
        onChange={store.setPage}
        disabled={isLoading || isFetching}
      />

      <Modal
        title="Are you sure to send all list items to sell?"
        open={isConfirmOpen}
        onOk={() => move(selection)}
        confirmLoading={isMoving}
        onCancel={() => setIsConfirmOpen(false)}
      >
        <p>
          They will be listed according to the last listing settings for each
          SKU.
        </p>
      </Modal>
    </div>
  );
};
