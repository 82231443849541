import { Button, Table, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useSKUs, useSKUSelection } from "features/directory";
import { useAuthStore } from "features/auth";
import { SearchInput } from "components";

export const SKUDirectory = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);

  const navigate = useNavigate();
  const {
    setSearchQuery,
    searchQuery,
    page,
    pageSize,
    setPage,
    total,
    setPageSize,
  } = useSKUSelection();

  const { skus, isLoading, isFetching } = useSKUs();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">SKU</h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-4 mb-3">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        {rightRole && (
          <Button
            type="primary"
            onClick={() => navigate("/directory/sku/create")}
          >
            Create New
          </Button>
        )}
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName={rightRole ? "hover:cursor-pointer" : ""}
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={skus?.data}
        sticky={true}
        rowKey={record => record.sku_id}
        pagination={false}
        onRow={record => ({
          onClick: () => {
            if (rightRole) navigate(`/directory/sku/${record.sku_id}`);
          },
        })}
        scroll={{
          x: 600,
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};

const columns = [
  {
    title: "SKU",
    dataIndex: "sku_number",
    key: "sku_number",
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    key: "device_type",
    render: (text, record) => (
      <span>{record.device_type.device_type_name}</span>
    ),
  },
  {
    title: "Model",
    dataIndex: "Model",
    key: "Model",
    render: (text, record) => (
      <span>
        {
          Object.values(record.field_values).find(
            field => field.device_field.device_field_name === "Model",
          )?.device_field.device_field_option[0]?.value
        }
      </span>
    ),
  },
  {
    title: "Color",
    dataIndex: "color_shade",
    key: "color_shade",
    render: (text, record) => <span>{record.color_shade?.name}</span>,
  },
  {
    title: "Memory",
    dataIndex: "Memory",
    key: "Memory",
    render: (text, record) => (
      <span>
        {
          Object.values(record?.field_values).find(
            field => field.device_field.device_field_name === "Memory",
          )?.device_field.device_field_option[0]?.value
        }
      </span>
    ),
  },
];
