import { Pagination } from "antd";
import { useEmployeesStore, styles } from "features/employees";

export const EmployeesPagination = () => {
  const employeesStore = useEmployeesStore();

  return (
    <Pagination
      className={styles.pagination}
      onShowSizeChange={(_, pageSize) => employeesStore.setPageSize(pageSize)}
      pageSizeOptions={[4, 8, 12, 24, 48]}
      showSizeChanger
      defaultCurrent={1}
      total={employeesStore.total}
      current={employeesStore.page}
      pageSize={employeesStore.pageSize}
      onChange={employeesStore.setPage}
    />
  );
};
