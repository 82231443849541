import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const flashDevice = (id, data) =>
  apiClient.put(`api/product/flash/${id}`, data);

export const useFlashing = ({ id, onSuccess, onError }) => {
  const { mutate: flash, isLoading: isFlashing } = useMutation(
    data => flashDevice(id, data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        await queryClient.invalidateQueries(["device", id]);
        queryClient.invalidateQueries(["activities", id]);
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );

  return { flash, isFlashing };
};
