import { useState } from "react";
import { useBuyBoxPrice } from "features/sales";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { clsx } from "clsx";

export const BuyboxPrice = ({ marketplaceId, className = "" }) => {
  const [open, setOpen] = useState(false);

  const { getBBPrice, isFetching, isLoading, buyBoxData } = useBuyBoxPrice({
    onSuccess: res => {
      console.log(res);
      setOpen(true);
    },
  });

  const idle = isFetching || isLoading;

  return (
    <Popover
      content={buyBoxData?.data}
      rootClassName="w-[80px]"
      title="Buybox"
      trigger="click"
      placement="rightBottom"
      open={open}
      onOpenChange={newOpen => {
        if (!newOpen) setOpen(newOpen);
      }}
    >
      <Button
        type="link"
        className={clsx(className, (idle || open) && "opacity-100")}
        icon={idle ? <LoadingOutlined spin /> : <QuestionCircleOutlined />}
        onClick={() => getBBPrice(marketplaceId)}
      />
    </Popover>
  );
};
