import { Table } from "antd";
import _ from "lodash";
import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { getProductChanges, TypedValue } from "features/logs";

const normalizeName = name => {
  return name[0].toUpperCase() + name.replaceAll("_", " ").slice(1);
};

export const DetailsTable = ({ data = [] }) => {
  const isProductType = data?.type === `Modules\\Product\\Models\\Product`;
  const getFlatFieldValues = (fieldValues = []) => {
    return fieldValues.map(field => {
      const name = field.device_field.name;
      return {
        name,
        new: field.value || field.device_field_option.value,
        id: `field_value-${name}`,
      };
    });
  };

  const getDiff = data => {
    const { properties } = data;
    const result = properties?.attributes || properties;
    const finalArr = [];

    const isMarketplaceSku =
      data.type === `Modules\\MarketplaceDirectory\\Models\\MarketplaceSku`;

    for (let key in result) {
      if (key === "updated_at" || key === "step_of_process") continue;

      if (
        (result && !_.isEqual(result[key], properties?.old?.[key])) ||
        isMarketplaceSku
      ) {
        finalArr.push({
          id: `diff-${key}`,
          name: normalizeName(key),
          old: _.isBoolean(properties?.old?.[key])
            ? String(properties?.old[key])
            : properties?.old?.[key],
          new: _.isBoolean(result[key]) ? String(result[key]) : result[key],
        });
      }
    }

    return finalArr;
  };

  const formatTableData = () => {
    const { attributes } = data?.properties;
    if (data.causer_by === "System" && data.event === "product-updated") {
      return [
        {
          name: "Invoice number",
          new: attributes.invoice.invoice_number,
          id: "invoice",
        },
        {
          name: "Device type id",
          new: attributes.device_type_id,
          id: data.id,
        },
        {
          name: "Grade",
          new: attributes.grade.name,
          id: `${data.id}-grade`,
        },
        {
          name: "Color shade id",
          new: attributes.color_shade_id,
          id: `${data.id}-shade`,
        },
        ...getFlatFieldValues(attributes.field_values),
      ];
    } else {
      return getDiff(data);
    }
  };

  return (
    <>
      <Table
        className="my-5 mr-4"
        columns={columns}
        dataSource={isProductType ? getProductChanges(data) : formatTableData()}
        rowKey={record => record.name}
        pagination={false}
        size="small"
        bordered
      />
    </>
  );
};

const columns = [
  { title: "Property", key: "prop", dataIndex: "name", ellipsis: true },
  {
    title: "Old",
    key: "old",
    dataIndex: "old",
    ellipsis: true,
    render: (data, { type }) => {
      return typeof data === "object" && data !== null ? (
        <JsonView
          data={data}
          shouldInitiallyExpand={level => level === 0}
          style={{ ...defaultStyles, container: "bg-transparent" }}
        />
      ) : (
        <TypedValue value={data} type={type} />
      );
    },
  },
  {
    title: "New",
    key: "new",
    dataIndex: "new",
    ellipsis: true,
    render: (data, { type }) => {
      return typeof data === "object" && data !== null ? (
        <JsonView
          data={data}
          shouldInitiallyExpand={level => level === 0}
          style={{ ...defaultStyles, container: "bg-transparent" }}
        />
      ) : (
        <TypedValue value={data} type={type} />
      );
    },
  },
];
