import { EbayFormulas, MarketplacesFormulas } from "features/formulas";

export const Formulas = () => {
  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Formulas</h2>
      <EbayFormulas />
      <MarketplacesFormulas />
    </div>
  );
};
