import { Modal, Form, App } from "antd";
import { useNewEmployee, EmployeeForm } from "features/employees";

export const CreateEmployee = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const { createEmployee, isCreatingEmployee } = useNewEmployee({
    onSuccess: () => {
      onClose();
      message.success("Employee created successfully");
      form.resetFields();
    },
  });

  return (
    <Modal
      title="Add New Employee"
      confirmLoading={isCreatingEmployee}
      centered
      open={open}
      onOk={form.submit}
      onCancel={({ target }) => {
        if (
          typeof target.className === "string" &&
          target.className?.includes("ant-modal-wrap")
        )
          return;

        form.resetFields();
        onClose();
      }}
    >
      <EmployeeForm
        isAdding
        form={form}
        onFinish={val => {
          delete val.repeatPassword;
          createEmployee(val);
        }}
        disabled={isCreatingEmployee}
      />
    </Modal>
  );
};
