import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useStatisticsStore } from "features/statistics";

const getInvoicesGraph = ({
  status,
  period,
  by_auth_user,
  by_user_id,
  start_date,
  end_date,
}) => {
  return apiClient.get("api/statistic/invoice/diagram", {
    params: {
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
    },
    data: null,
  });
};

export const useInvoicesGraph = ({ onSuccess, onError } = {}) => {
  const {
    status,
    period,
    by_auth_user,
    by_user_id,
    start_date,
    activeTab,
    activeRegistrarTab,
    end_date,
  } = useStatisticsStore();

  const {
    data: invoicesGraph,
    isLoading: isInvoicesGraphLoading,
    isFetching,
  } = useQuery(
    [
      "invoices-stats",
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
    ],
    () =>
      getInvoicesGraph({
        status,
        period,
        by_auth_user: by_auth_user ? 1 : 0,
        by_user_id,
        start_date,
        end_date,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
      enabled:
        activeTab === "Registration" && activeRegistrarTab === "Invoices",
    },
  );

  return { invoicesGraph, isInvoicesGraphLoading, isFetching };
};
