import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

const compare = data =>
  apiClient.post("/api/faktura/compare-system-quantity", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useCompareQuantity = ({ onSuccess } = {}) => {
  const {
    mutate: upload,
    isLoading: isImporting,
    isSuccess,
  } = useMutation(data => compare(data), {
    onSuccess: res => {
      onSuccess?.(res);
    },
  });

  return { upload, isImporting, isSuccess };
};
