import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useStatisticsStore } from "../store";

const requestReport = async (byDevices = false, params) =>
  await apiClient.post(
    `api/statistic/product/data/${byDevices ? "devices/" : ""}export`,
    {},
    { params },
  );

export const useStatisticsExport = ({ onSuccess, onError } = {}) => {
  const {
    status,
    period,
    by_auth_user,
    by_user_id,
    start_date,
    end_date,
    search,
    byDevices,
  } = useStatisticsStore();

  const { mutate: download, isLoading: isDownloading } = useMutation(
    () =>
      requestReport(byDevices, {
        status,
        period,
        by_auth_user: by_auth_user ? 1 : 0,
        by_user_id,
        start_date,
        end_date,
        search,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
    },
  );

  return { download, isDownloading };
};
