import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const useLogsSelection = create(
  devtools(
    immer((set, get) => ({
      filter_by: {},
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      start_date: "",
      end_date: "",
      setSearchQuery: searchQuery =>
        set(state => {
          state.searchQuery = searchQuery;
        }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (!value) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      setStartDate: start_date =>
        set(state => {
          state.start_date = start_date;
        }),
      setEndDate: end_date =>
        set(state => {
          state.end_date = end_date;
        }),
      clearFilters: () => {
        set(state => {
          state.filter_by = {};
          state.start_date = "";
          state.end_date = "";
        });
      },
    })),
  ),
);
