import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useComListStore } from "../store";
import { useDebounce } from "use-debounce";

const getListProducts = ({
  id,
  search_term = null,
  per_page = 12,
  page = 1,
  end_date,
  start_date,
  filter_by,
}) =>
  apiClient.get(`api/sending/commission-list/${id}/products`, {
    params: { per_page, page, search_term, end_date, start_date, filter_by },
  });

export const useCommissionListProducts = ({ id, onSuccess, onError }) => {
  const {
    page,
    pageSize,
    setPage,
    setTotal,
    searchQuery,
    end_date,
    start_date,
    filter_by,
  } = useComListStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: products,
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
  } = useQuery(
    [
      "commission-list-products",
      id,
      page,
      pageSize,
      debouncedQuery,
      end_date,
      start_date,
      filter_by,
    ],
    () =>
      getListProducts({
        id,
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        end_date,
        start_date,
        filter_by,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { products, isProductsLoading, isProductsFetching };
};
