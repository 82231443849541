import { Form, Select, Input, Spin, Checkbox } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useInfiniteDeviceTypes, useInfiniteColors } from "features/directory";
import { useMemo } from "react";
import { useAuthStore } from "features/auth";
import { PaginatedSelect } from "components";

const rules = [
  {
    required: true,
    message: "Required",
  },
];

export const SKUForm = ({ form, disabled, onSubmit }) => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const color = Form.useWatch("color", form);
  const type = Form.useWatch("device_type_id", form);

  const {
    deviceTypeOptions,
    devices,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteDeviceTypes();

  const {
    colorOptions,
    colors,
    isFetchingNextPage: isFetchingNextColors,
    isLoading: isColorsLoading,
    fetchNextPage: fetchNextColors,
    setSearchQuery: searchColor,
    searchQuery: searchingColor,
  } = useInfiniteColors();

  const shades = useMemo(() => {
    if (!colors || !color) return [];

    return colors
      ?.filter(item => item.id === color)[0]
      ?.shades?.map(shade => ({
        label: shade.name,
        value: shade.id,
      }));
  }, [color, colors]);

  const restFields = useMemo(() => {
    const result = devices
      ?.find(item => item.id === type)
      ?.fields?.filter(item => item.name !== "Color");
    return result || [];
  }, [type, devices]);

  const finish = data => {
    const settedFormFieldsObject = data.field_values;
    const selectedTypeFieldsArray = devices?.find(
      item => item.id === type,
    ).fields;

    const getTypeField = name =>
      selectedTypeFieldsArray.find(field => field.name === name);

    const field_values = Object.entries(settedFormFieldsObject)
      .map(([formEntryKey, formEntryValue]) => {
        const typeField = getTypeField(formEntryKey);

        if (
          typeField.type.toUpperCase() === "TEXT" ||
          typeField.type.toUpperCase() === "CHECKBOX"
        ) {
          return {
            device_field_id: typeField.id,
            value: formEntryValue.toString(),
          };
        } else if (typeField.type.toUpperCase() === "SELECT") {
          return {
            device_field_id: typeField.id,
            device_field_option_id: formEntryValue,
          };
        } else {
          //MULTISELECT
          return formEntryValue.map(opt => ({
            device_field_id: typeField.id,
            device_field_option_id: opt,
          }));
        }
      })
      .flat();

    onSubmit({ ...data, field_values });
  };

  return (
    <Form
      className="max-w-lg w-full mx-auto px-5"
      disabled={disabled}
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={finish}
    >
      <Form.Item
        className="my-7"
        label="SKU Number"
        name="sku_number"
        rules={rules}
      >
        <Input placeholder="Enter SKU Number" readOnly={!rightRole} />
      </Form.Item>

      <Form.Item
        className="my-7"
        label="Device Type"
        name="device_type_id"
        rules={rules}
      >
        <PaginatedSelect
          localSearch
          isLoading={isLoading}
          disabled={disabled}
          placeholder="Select Device Type"
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onClear={() => form.setFieldValue("device_type_id", null)}
          options={deviceTypeOptions}
          filterOption={(searchValue, { children }) =>
            children?.toLowerCase().includes(searchValue?.toLowerCase())
          }
        />
      </Form.Item>

      <div className="flex">
        <Form.Item label="Color" rules={rules} className="flex-1" name="color">
          <PaginatedSelect
            disabled={disabled}
            localSearch
            searchValue={searchingColor}
            isLoading={isColorsLoading}
            placeholder="Select Color"
            fetchNextPage={fetchNextColors}
            isFetchingNextPage={isFetchingNextColors}
            onSearch={searchColor}
            onClear={() => {
              form.setFieldValue("color", null);
              form.resetFields(["color_shade_id"]);
            }}
            options={colorOptions}
            onChange={() => {
              form.resetFields(["color_shade_id"]);
            }}
            filterOption={(searchValue, { children }) =>
              children?.toLowerCase?.().includes(searchValue?.toLowerCase?.())
            }
          />
        </Form.Item>

        <div className="flex items-center m-2">
          <CaretRightOutlined className="text-slate-400" />
        </div>

        <Form.Item
          className="flex-1"
          label="Shade"
          name="color_shade_id"
          rules={rules}
        >
          <Select
            disabled={!color || disabled}
            notFoundContent={isColorsLoading ? <Spin size="small" /> : null}
            allowClear
            showArrow
            showSearch
            placeholder="Select Shade"
            options={shades}
            filterOption={(searchValue, { label }) =>
              label?.toLowerCase().includes(searchValue?.toLowerCase())
            }
          />
        </Form.Item>
      </div>

      {restFields
        .filter(field => field.name !== "IMEI" && field.name !== "Serial")
        ?.map(field => {
          if (
            field.type.toUpperCase() === "SELECT" ||
            field.type.toUpperCase() === "MULTISELECT"
          ) {
            return (
              <Form.Item
                className="mb-7"
                label={field.name}
                name={["field_values", field.name]}
                key={field.id}
                rules={rules}
              >
                <Select
                  placeholder={`Select ${field?.name}`}
                  showArrow
                  showSearch
                  mode={
                    field.type.toUpperCase() === "MULTISELECT"
                      ? "multiple"
                      : null
                  }
                  allowClear
                  options={field.options.map(opt => ({
                    label: opt.value,
                    value: opt.id,
                  }))}
                  filterOption={(searchValue, { label }) =>
                    label?.toUpperCase().includes(searchValue.toUpperCase())
                  }
                />
              </Form.Item>
            );
          } else if (field.type.toUpperCase() === "CHECKBOX") {
            return (
              <Form.Item
                initialValue={false}
                className="mb-7"
                name={["field_values", field.name]}
                key={field.id}
                valuePropName="checked"
              >
                <Checkbox>{field.name}</Checkbox>
              </Form.Item>
            );
          } else {
            return (
              <Form.Item
                className="mb-7"
                label={field.name}
                name={["field_values", field.name]}
                key={field.id}
                rules={rules}
              >
                <Input placeholder="type here" />
              </Form.Item>
            );
          }
        })}
    </Form>
  );
};
