import { PaginatedSelect } from "components";
import { Form } from "antd";
import { useInfiniteAccessoryCategories } from "features/directory";
import { requiredRule } from "constants/validation";

export const AccessoryCategoriesInput = ({
  className = "",
  disabled,
  onClear,
  onChange,
  initialData,
  placeholder = "Select accessory category",
  name = "accessory_categories",
  label = "Categories",
  rules = [requiredRule],
}) => {
  const {
    categoriesOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteAccessoryCategories();

  const noInitialOption =
    initialData &&
    !categoriesOptions?.some(({ value }) => value === initialData.id);

  return (
    <Form.Item
      className={className}
      required
      name={name}
      label={label}
      labelCol={{ span: 5 }}
      rules={rules}
    >
      <PaginatedSelect
        disabled={disabled}
        searchValue={searchQuery}
        isLoading={isLoading}
        placeholder={placeholder}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        onClear={onClear}
        onChange={id => onChange?.(id)}
        options={
          noInitialOption
            ? [
                { value: initialData.id, label: initialData.name },
                ...categoriesOptions,
              ]
            : categoriesOptions
        }
      />
    </Form.Item>
  );
};
