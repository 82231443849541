import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useSearchDevicesStore } from "features/devices";
import _ from "lodash";

const downloadReport = async body => {
  return await apiClient.post(
    "api/product/export",
    _.omitBy(body, v => !Boolean(v)),
  );
};

export const useDeviceExport = ({ onSuccess, onError } = {}) => {
  const { searchQuery, filter_by } = useSearchDevicesStore();

  const { mutate: download, isLoading: isDownloading } = useMutation(
    () =>
      downloadReport({
        search_term: searchQuery,
        filter_by: _.isEmpty(filter_by) ? null : _.omit(filter_by, "color"),
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { download, isDownloading };
};
