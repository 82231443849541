import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const apply = ({ price, sku_id, grade_id, original_box }) => {
  return apiClient.post("api/sku/marketplaces/apply-formula", {
    price,
    sku_id,
    grade_id,
    original_box,
  });
};

export const useApplyFormulas = ({ onSuccess } = {}) => {
  const { mutate: applyFormulas, isLoading: isApplying } = useMutation(
    data => apply(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { applyFormulas, isApplying };
};
