import { Button, Collapse, Tag } from "antd";
import { EditFilled } from "@ant-design/icons";
import {
  useAccessoryCategories,
  useAccessoryCategoriesStore,
} from "features/directory";
import { Pagination, Loader } from "components";

export const AccessoryCategoryList = ({ onEdit, editable = true }) => {
  const store = useAccessoryCategoriesStore();
  const { categories, isLoading, isFetching } = useAccessoryCategories();

  return (
    <>
      <Collapse accordion className="relative min-h-[100px] overflow-y-scroll">
        {categories?.data?.map(category => {
          const header = (
            <div className="flex gap-2 relative">
              <span className="text-xs flex items-center text-slate-400 min-w-[40px]">
                {category.id}
              </span>

              <Tag className="flex-2 max-w-[80%] text-gray-900 text-sm font-bold overflow-hidden text-ellipsis">
                {category.name}
              </Tag>

              <div className="flex-1 text-gray-500 text-xs flex items-center overflow-hidden">
                <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                  {category.description}
                </span>
              </div>
            </div>
          );

          return (
            <Collapse.Panel
              className="overflow-hidden"
              header={header}
              extra={
                editable ? (
                  <Button
                    type="ghost"
                    size="small"
                    className="ml-auto min-w-[40px]"
                    icon={
                      <EditFilled className="text-gray-500 hover:text-gray-800" />
                    }
                    onClick={e => {
                      e.stopPropagation();
                      onEdit(category);
                    }}
                  />
                ) : null
              }
              key={category.id}
              collapsible={category.description ? null : "disabled"}
            >
              {category.description}
            </Collapse.Panel>
          );
        })}

        {(isLoading || isFetching) && <Loader />}
      </Collapse>

      <Pagination
        store={store}
        disabled={isLoading || isFetching}
        className="mt-auto"
      />
    </>
  );
};
