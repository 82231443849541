import { Result, Button } from "antd";

export const FrontEndError = () => (
  <div className="h-screen flex justify-center items-center">
    <Result
      status="500"
      title="WOW"
      subTitle="Sorry, something went wrong. Bug report sent."
      extra={
        <Button type="primary" onClick={() => window.location.reload()}>
          Try reload
        </Button>
      }
    />
  </div>
);
