import { Button, Pagination, Radio, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "components";
import {
  promotionsColumns,
  usePromotions,
  usePromotionsStore,
} from "features/promotions";

export const Promotions = () => {
  const store = usePromotionsStore();
  const navigate = useNavigate();

  const { promotions, isLoading } = usePromotions();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center mb-6 sm:mb-10">Promotions</h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-4 mb-3 gap-2">
        <Radio.Group
          disabled={isLoading}
          value={store.filter_by.active}
          onChange={({ target }) => store.setFilter({ active: target.value })}
          buttonStyle="solid"
        >
          <Radio.Button value={undefined}>
            <span className="lg:text-sm text-xs">All</span>
          </Radio.Button>
          <Radio.Button value={1}>
            <span className="lg:text-sm text-xs">Active</span>
          </Radio.Button>
          <Radio.Button value={0}>
            <span className="lg:text-sm text-xs">Inactive</span>
          </Radio.Button>
        </Radio.Group>

        <SearchInput
          searchQuery={store.searchQuery}
          setSearchQuery={store.setSearchQuery}
        />

        <Button
          type="primary"
          className="ml-auto lg:text-sm text-xs"
          onClick={() => navigate(`/promotions/create`)}
        >
          Create
        </Button>
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName="hover:cursor-pointer"
        loading={isLoading}
        columns={promotionsColumns}
        dataSource={promotions?.data}
        sticky={true}
        rowKey={record => `promotion_${record.id}`}
        pagination={false}
        onRow={({ id }) => ({
          onClick: () => navigate(`/promotions/${id}`),
        })}
        scroll={{ x: 900 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        current={store.page}
        pageSize={store.pageSize}
        onChange={store.setPage}
      />
    </div>
  );
};
