import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const showEmployee = id => apiClient.get(`api/users/${id}`);

export const useEmployee = ({ id, onError }) => {
  const { data: employee, isLoading: isLoadingEmployee } = useQuery(
    ["employee", id],
    () => showEmployee(id),
    {
      onError: err => {
        onError?.(err);
      },
      retry: false,
    },
  );

  return { employee, isLoadingEmployee };
};
