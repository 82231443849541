import {
  useProductSKUs,
  useConnectedSKUsStore,
  ProductsToolbar,
  QUERY_NAMES,
  ProductsTable,
  ProfitStats,
  ProfitabilityPage,
} from "features/profitability";

export const ConnectedSKUs = () => {
  const store = useConnectedSKUsStore();

  const {
    data: skus,
    isFetching,
    isLoading,
  } = useProductSKUs({
    store,
    groupName: "connected",
    queryName: QUERY_NAMES.PRODUCTS.SKUS.CONNECTED,
  });

  const isProcessing = !skus || isLoading || isFetching;

  return (
    <ProfitabilityPage
      store={store}
      title="Connected Products by SKU"
      preloadFormula
    >
      <ProfitStats
        data={skus}
        keys={[
          "total_count",
          "total_profit",
          "total_invoice_price",
          "total_sale_price",
          "total_formula_price",
        ]}
      />

      <ProductsToolbar
        store={store}
        disabled={isProcessing}
        isLoading={isProcessing}
        allowExport={!!skus?.data.length}
        groupName="connected"
        isSKU
      />

      <ProductsTable
        isSKUTable
        isLoading={isProcessing}
        queryName={QUERY_NAMES.PRODUCTS.SKUS.CONNECTED}
        data={skus?.data}
        idKey="item_id"
        store={store}
      />
    </ProfitabilityPage>
  );
};
