import { useState } from "react";
import { Button, FloatButton, Table } from "antd";
import dayjs from "dayjs";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  ExpressionForm,
  ProfitabilityPage,
  useCreateExpression,
  useEditExpression,
  useFormulaData,
  useRemoveExpression,
} from "features/profitability";

const initialModalFormState = {
  isOpen: false,
  editedFormula: null,
};

export const ExpressionsList = () => {
  const { formulaId } = useParams();

  const [modalForm, setModalForm] = useState(initialModalFormState);
  const closeForm = () => setModalForm({ isOpen: false, editedFormula: null });
  const openForm = (editedFormula = null) =>
    setModalForm({ isOpen: true, editedFormula });

  const { formula, isLoading, isFetching } = useFormulaData({ formulaId });
  const { createExpression, isCreating } = useCreateExpression({
    formulaId,
    onSuccess: closeForm,
  });
  const { editExpression, isEditing } = useEditExpression({
    formulaId,
    onSuccess: closeForm,
  });
  const { removeExpression, isRemoving } = useRemoveExpression({
    formulaId,
    onSuccess: closeForm,
  });

  const onSubmit = formValues => {
    if (modalForm.editedFormula?.id) {
      editExpression({
        data: formValues,
        expressionId: modalForm.editedFormula.id,
      });
    } else {
      createExpression(formValues);
    }
  };

  const onDelete = () => removeExpression(modalForm.editedFormula.id);

  return (
    <ProfitabilityPage
      title={`Expressions #${formulaId} (${dayjs(formula?.data?.created_at).format("MMM DD YYYY • HH:mm")})`}
    >
      <ExpressionForm
        formulaData={modalForm.editedFormula}
        isOpen={modalForm.isOpen}
        onClose={closeForm}
        onDelete={onDelete}
        isLoading={isCreating || isEditing || isRemoving}
        onSubmit={onSubmit}
      />

      <FloatButton
        disabled={isCreating}
        type="primary"
        className="absolute top-20 right-10"
        icon={<PlusOutlined />}
        onClick={() => openForm()}
      />

      <Table
        className="overflow-y-auto mb-4 mt-2"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        dataSource={formula?.data?.expressions}
        scroll={{ x: 1000 }}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            align: "center",
          },
          {
            title: "Marketplace",
            dataIndex: "marketplace",
            align: "center",
            width: 160,
          },
          {
            title: "Device Types",
            dataIndex: "device_types",
            render: dt => dt.map(({ name }) => name).join(", "),
            align: "center",
          },
          {
            title: "Grades",
            dataIndex: "grades",
            render: grades => grades.map(({ name }) => name).join(", "),
            align: "center",
          },
          {
            title: "Formula",
            dataIndex: "expression",
            render: expression => {
              return (
                <span className="font-mono text-green-900 text-xs">
                  {expression}
                </span>
              );
            },
          },
          {
            key: "edit",
            fixed: "right",
            width: 70,
            render: (_, item) => (
              <Button icon={<EditFilled />} onClick={() => openForm(item)} />
            ),
            align: "right",
          },
        ]}
      />
    </ProfitabilityPage>
  );
};
