import {
  Button,
  Form,
  Modal,
  Input,
  Select,
  Checkbox,
  message,
  Divider,
  Alert,
} from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import {
  useInfiniteDeviceTypes,
  useInfiniteGradeGroup,
} from "features/directory";
import {
  useFlashing,
  Device,
  DeviceDynamicInput,
  SkuInput,
  ColorShadeInput,
  DeviceTypeInput,
  useOnKeySubmit,
  GroupedGradeInput,
} from "features/devices";
import { useAuthStore } from "features/auth";
import { queryClient } from "lib/react-query-client";
import { globalErrorHandler } from "lib/react-query-client";
import { useScanner } from "features/qr-scanner";
import { CloseCircleFilled, LockFilled, ToolFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;

export const Flashing = ({ deviceData }) => {
  const { deviceId } = useParams();
  const { getRoles } = useAuthStore();
  const [form] = Form.useForm();
  const { openScanner } = useScanner();

  const selectedColor = Form.useWatch("color", form);
  const selectedGrade = Form.useWatch("grade_id", form);
  const selectedDeviceType = Form.useWatch("device_type_id", form);
  const selectedFieldValues = Form.useWatch(["field_values"], form);

  const { deviceTypesFields } = useInfiniteDeviceTypes();

  const availableModelColors = useMemo(
    () =>
      deviceTypesFields?.[selectedDeviceType]
        ?.find(({ name }) => name === "Model")
        ?.options.find(({ id }) => id === selectedFieldValues?.Model)?.colors,
    [selectedFieldValues, deviceTypesFields, selectedDeviceType],
  );

  const {
    additionalOptions,
    groupOptions,
    isLoading: isGradesLoading,
  } = useInfiniteGradeGroup({
    grade_id: deviceData?.grade?.id,
  });

  const { flash, isFlashing } = useFlashing({
    id: deviceId,
    onSuccess: response => {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      form.resetFields();
      setIsOpen(false);
      openScanner();
    },
    onError: err => {
      if (err.response?.status === 404) {
        message.open({
          type: "warning",
          content: err.response.data?.message,
          duration: 5,
        });
        form.resetFields();
        setIsOpen(false);
        queryClient.invalidateQueries(["device", deviceId]);
        queryClient.invalidateQueries(["activities", deviceId]);
      } else {
        globalErrorHandler(err);
      }
    },
  });

  const onChangeSku = skuData => Device.fillSkuFields(skuData, form);
  const onClearSku = () => {
    form.resetFields([
      "sku_id",
      "color_shade_id",
      "color",
      "device_type_id",
      "field_values",
    ]);
  };

  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onSubmit = formData => {
    flash(formData);
  };

  const onFinish = data => {
    onSubmit(
      Device.buildDeviceObj(data, deviceTypesFields[selectedDeviceType]),
    );
  };

  const onFlash = () => {
    form.setFieldValue("status", "REGISTERED");
    form.submit();
  };

  const onRepair = () => {
    form.setFieldValue("status", "REPAIR");
    form.submit();
  };

  const onHold = () => {
    form.setFieldValue("status", "HOLD");
    form.submit();
  };

  const { isOpen, setIsOpen } = useOnKeySubmit(onFlash);
  useEffect(() => {
    if (deviceData && isOpen) {
      Device.fillDeviceForm({ deviceData, form });
    }
  }, [deviceData, isOpen]);

  const isWrongGrade = !isGradesLoading && groupOptions?.length === 0;
  useEffect(() => {
    if (isOpen && isWrongGrade) {
      setTimeout(() => {
        form.scrollToField(["grade_id"]);
      }, 0);
    }
  }, [isOpen, isWrongGrade]);

  const isAdmin = getRoles().some(item => item.toLowerCase() === "admin");

  const hasRight = getRoles().some(
    item =>
      item.toLowerCase() === "admin" ||
      item.toLowerCase() === "flasher" ||
      item.toLowerCase() === "head_of_flasher",
  );

  const isTightly = useMediaQuery({
    query: "(max-width: 420px)",
  });

  if (!hasRight) return <span>Flashing</span>;

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Register It
      </Button>

      <Modal
        title={
          <>
            {isWrongGrade && (
              <CloseCircleFilled className="mr-2 text-red-500" />
            )}
            Registering
          </>
        }
        centered
        wrapClassName="h-screen relative"
        style={{ top: 0, maxHeight: "100%" }}
        width={860}
        open={isOpen}
        maskClosable={false}
        onCancel={onClose}
        confirmLoading={isFlashing}
        modalRender={modal => (
          <div className="max-h-screen pb-0 pt-6">{modal}</div>
        )}
        footer={
          <div className="flex">
            <Button
              danger
              icon={<ToolFilled />}
              onClick={onRepair}
              loading={isFlashing}
              disabled={isWrongGrade}
            >
              {isTightly ? "" : "Repair"}
            </Button>

            <Button
              danger
              icon={<LockFilled />}
              className="mr-auto"
              onClick={onHold}
              loading={isFlashing}
              disabled={isWrongGrade}
            >
              {isTightly ? "" : "Hold"}
            </Button>

            <Button
              type={isWrongGrade ? "primary" : "default"}
              danger={isWrongGrade}
              onClick={() => {
                if (!isFlashing) onClose();
              }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              onClick={onFlash}
              loading={isFlashing}
              disabled={isWrongGrade}
            >
              Ok
            </Button>
          </div>
        }
      >
        <div className="overflow-y-auto pr-3" style={{ maxHeight: "76vh" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            disabled={isFlashing}
          >
            <Form.Item
              hidden
              rules={[requiredRule]}
              label="Status"
              name="status"
            >
              <Select
                getPopupContainer={triggerNode => triggerNode.parentElement}
                className="w-full"
                placeholder="Select Status"
                options={[
                  { label: "REGISTERED", value: "REGISTERED" },
                  { label: "REPAIR", value: "REPAIR" },
                ]}
              />
            </Form.Item>

            <GroupedGradeInput
              disabled={isFlashing || groupOptions?.length === 0}
              initialData={deviceData?.grade}
            />
            {isWrongGrade && (
              <Alert
                message="Wrong Grade"
                description="Current product grade is not supported at this step.
                Contact your administrator to resolve the issue."
                type="error"
                showIcon
              />
            )}
            {additionalOptions &&
              additionalOptions[selectedGrade]?.map(field => (
                <DeviceDynamicInput
                  field={field}
                  key={field.name}
                  groupName="additional_options"
                />
              ))}

            <Divider />

            <SkuInput
              hidden={!isAdmin}
              onClear={onClearSku}
              onChange={onChangeSku}
              disabled={isFlashing}
              initialData={deviceData?.sku}
              typeId={deviceData?.device_type?.id}
            />

            <Form.Item
              className="mb-10"
              initialValue={false}
              name="soft_update"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => {
                    const status = form.getFieldValue("status");
                    if (["HOLD", "REPAIR"].includes(status)) {
                      return Promise.resolve();
                    } else {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Soft update must be performed"),
                          );
                    }
                  },
                },
              ]}
            >
              <Checkbox>Soft Update</Checkbox>
            </Form.Item>

            <DeviceTypeInput disabled initialData={deviceData?.device_type} />
            {deviceTypesFields &&
              deviceTypesFields[selectedDeviceType]?.map(field => {
                const isIMEI = field?.name.toLowerCase() === "imei";
                const isSerial = field?.name.toLowerCase() === "serial";
                const noVerify =
                  (isIMEI || isSerial) && selectedDeviceType == 8;

                return (
                  <DeviceDynamicInput
                    onSelect={() =>
                      form.resetFields(["color_shade_id", "color"])
                    }
                    field={field}
                    key={field.id}
                    groupName="field_values"
                    rules={noVerify ? false : [requiredRule]}
                  />
                );
              })}

            {!availableModelColors?.length && (
              <ColorShadeInput
                rules={[requiredRule]}
                currentColor={selectedColor}
                disabled={isFlashing}
                onChange={() => form.resetFields(["color_shade_id"])}
                onClear={() => form.resetFields(["color", "color_shade_id"])}
                initialData={deviceData?.color}
              />
            )}

            {!!availableModelColors?.length && (
              <Form.Item
                label="Model color"
                required
                name="color_shade_id"
                rules={[requiredRule]}
              >
                <Select
                  options={availableModelColors.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                />
              </Form.Item>
            )}

            <Form.Item label="Comment" name="comment">
              <TextArea placeholder="Enter Comment" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};
