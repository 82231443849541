import { FilterDrawer, FilterSummary } from "components";
import { DatesFilter, SendingStatusesFilter } from "features/filter";
import { useMemo } from "react";

export const FilterLists = ({ disabled, store }) => {
  const { start_date, end_date, filter_by, clear } = store;

  const active = useMemo(() => {
    const active = Object.entries(filter_by).map(([key, value]) => ({
      label: key,
      value,
    }));

    if (start_date) active.push({ label: "Start Date", value: start_date });
    if (end_date) active.push({ label: "End Date", value: end_date });

    const tooltip = active.length ? <FilterSummary list={active} /> : null;

    return { count: active.length, tooltip };
  }, [start_date, end_date, filter_by]);

  return (
    <FilterDrawer
      disabled={disabled}
      title="Commission Lists"
      onClear={clear}
      activeCount={active.count}
      btnStyle="primary"
      tooltip={active.tooltip}
    >
      <div className="flex flex-col gap-8 px-6 py-10">
        <DatesFilter store={store} />
        <SendingStatusesFilter store={store} />
      </div>
    </FilterDrawer>
  );
};
