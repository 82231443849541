import { useEffect, useState } from "react";
import { Loader } from "components";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Space } from "antd";
import {
  EmployeeForm,
  useEditEmployee,
  useEmployee,
  NewPassword,
} from "features/employees";
import { globalErrorHandler } from "lib/react-query-client";
import { useNavigate } from "react-router-dom";

export const Employee = () => {
  const [form] = Form.useForm();
  const { employeeId } = useParams();
  const navigate = useNavigate();

  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isNewPasswordOpen, setIsNewPasswordOpen] = useState(false);
  const openNewPassword = () => setIsNewPasswordOpen(true);
  const closeNewPassword = () => setIsNewPasswordOpen(false);

  const { employee, isLoadingEmployee } = useEmployee({
    id: employeeId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });
  const { edit, isEditing } = useEditEmployee({
    employeeId,
    onSuccess: () => setIsFormChanged(false),
  });

  const loadExistingEmployee = () => {
    if (employee) {
      const user = {
        ...employee.data,
        role: employee.data.roles,
        department: employee.data.departments.map(dep => dep.id),
      };
      delete user.roles;
      form.setFieldsValue(user);
      setIsFormChanged(false);
    }
  };

  const checkIfFormChanged = (field, all) => {
    const realData = { ...employee.data, role: employee.data.roles };
    delete realData.roles;
    delete realData.id;

    setIsFormChanged(JSON.stringify(all) !== JSON.stringify(realData));
  };

  // eslint-disable-next-line
  useEffect(loadExistingEmployee, [employee]);

  const isProcessing = isLoadingEmployee || isEditing;

  return (
    <div className="relative flex flex-col h-full  items-center">
      <h2 className="text-center">
        {employee
          ? `Employee: ${employee.data.first_name} ${employee.data.last_name}`
          : `Employee #${employeeId}`}
      </h2>

      <div className="overflow-y-auto w-full  max-w-2xl">
        <div className="px-8">
          <div className="flex justify-end">
            <Button disabled={isProcessing} onClick={openNewPassword}>
              New Password
            </Button>
          </div>

          <EmployeeForm
            onFinish={edit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            layout="horizontal"
            disabled={isProcessing}
            onValuesChange={checkIfFormChanged}
          />

          {isFormChanged && (
            <Row justify="end">
              <Space>
                <Button disabled={isProcessing} onClick={loadExistingEmployee}>
                  Cancel
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={form.submit}
                  disabled={isProcessing}
                >
                  Save Changes
                </Button>
              </Space>
            </Row>
          )}

          {isProcessing && <Loader />}
        </div>

        <NewPassword
          isOpen={isNewPasswordOpen}
          onCancel={closeNewPassword}
          employee={employee}
          employeeId={employeeId}
        />
      </div>
    </div>
  );
};
