import { Navigate } from "react-router-dom";
import { useAuthStore } from "features/auth";
import _ from "lodash";

export const WithAuthorization = ({ children, permissions = ["admin"] }) => {
  const { user } = useAuthStore();

  if (_.isEmpty(_.intersection(user?.data.roles, permissions)))
    return <Navigate to="/" replace />;

  return <>{children}</>;
};
