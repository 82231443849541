import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";
import { useNavigate } from "react-router-dom";

const removeSupplier = id => {
  return apiClient.delete(`api/directory/suppliers/${id}`);
};

export const useRemoveSupplier = (id, onSuccess) => {
  const navigate = useNavigate();

  const { mutate: remove, isLoading: isRemoving } = useMutation(
    () => removeSupplier(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["suppliers"]);
        navigate("/directory/suppliers");
        onSuccess?.();
      },
    },
  );

  return { remove, isRemoving };
};
