import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const editSKUs = (id, data) => apiClient.put(`api/directory/skus/${id}`, data);

export const useEditSKU = (id, onSuccess) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    data => editSKUs(id, data),
    {
      onSuccess: async dep => {
        await queryClient.invalidateQueries(["skus"]);
        await queryClient.invalidateQueries(["sku", id]);
        onSuccess?.(dep);
      },
    },
  );

  return { edit, isEditing };
};
