import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, message, App } from "antd";
import { useSKU, SKUForm, useEditSKU, useRemoveSKU } from "features/directory";
import { Loader } from "components";
import { globalErrorHandler } from "lib/react-query-client";
import { useAuthStore } from "features/auth";

export const EditSKU = () => {
  const { isRight } = useAuthStore();
  const { isAdmin } = isRight(["admin"]);
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);

  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const { skuId } = useParams();
  const navigate = useNavigate();

  const { sku, isLoading } = useSKU({
    id: skuId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { edit, isEditing } = useEditSKU(skuId, () => {
    navigate(`/directory/sku`, { replace: true });
    message.success("Success!");
  });

  const { remove, isRemoving } = useRemoveSKU(skuId, () => {
    navigate("/directory/sku", { replace: true });
    message.success("Removed!");
  });

  useEffect(() => {
    if (sku) {
      form.setFieldValue("color_shade_id", sku.data.color_shade?.id);
      form.setFieldValue("color", sku.data.color_shade?.color_id);
      form.setFieldValue(
        "device_type_id",
        sku.data.device_type?.device_type_id,
      );
      form.setFieldValue("sku_number", sku.data.sku_number);

      Object.values(sku.data.field_values).forEach(field => {
        const isSelect =
          field.device_field.device_field_type.toUpperCase() === "SELECT";

        const isMultiSelect =
          field.device_field.device_field_type.toUpperCase() === "MULTISELECT";

        const isCheckbox =
          field.device_field.device_field_type.toUpperCase() === "CHECKBOX";

        let value;

        if (isSelect) {
          value = field.device_field.device_field_option[0]?.id;
        } else if (isMultiSelect) {
          value = field.device_field.device_field_option?.map(opt => opt?.id);
        } else if (isCheckbox) {
          value = field.device_field.device_field_value !== "false";
        } else {
          value = field.device_field.device_field_value;
        }

        form.setFieldValue(
          ["field_values", field.device_field.device_field_name],
          value,
        );
      });
    }
    // eslint-disable-next-line
  }, [sku]);

  const onSubmit = data => edit(data);

  return (
    <div className="h-full flex flex-col">
      <h2 className="text-center">
        Edit SKU #{sku?.data?.sku_number || skuId}
      </h2>

      <div className="overflow-auto relative">
        <SKUForm
          form={form}
          onSubmit={onSubmit}
          disabled={isLoading || isEditing || isRemoving}
        />

        {rightRole && (
          <div className="flex justify-center gap-2 my-16">
            <Button
              danger
              onClick={() => {
                modal.confirm({
                  title: "SKU Deleting",
                  content: "Are you sure you want to delete this SKU?",
                  onOk: async () => await remove(),
                });
              }}
              loading={isRemoving}
              disabled={isLoading || isEditing || !isAdmin}
            >
              Delete
            </Button>

            <Button
              type="primary"
              onClick={form.submit}
              loading={isEditing}
              disabled={isLoading || isRemoving}
            >
              Update
            </Button>
          </div>
        )}

        {isLoading && <Loader />}
      </div>
    </div>
  );
};
