import { Form } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useInfiniteColors, useInfiniteShades } from "features/directory";
import { Device } from "features/devices";
import { PaginatedSelect } from "components";

export const ColorShadeInput = ({
  currentColor,
  className = "",
  itemClassName = "",
  disabled,
  onClear,
  rules = [],
  initialData,
  onChange,
  hideLabels = false,
}) => {
  const {
    colorOptions,
    shadeOptions,
    isFetchingNextPage: isFetchingNextColors,
    isLoading: isColorsLoading,
    fetchNextPage: fetchNextColors,
    setSearchQuery: searchColor,
    searchQuery: searchingColor,
  } = useInfiniteColors();

  const {
    isLoading: isShadesLoading,
    shadeOptions: allShadesOptions,
    isFetchingNextPage: isFetchingNextShadesPage,
    fetchNextPage: fetchNextShadesPage,
    setSearchQuery: setShadesSearchQuery,
    searchQuery: shadesSearchQuery,
  } = useInfiniteShades();

  const noInitialOption =
    currentColor && !colorOptions?.some(({ value }) => value === currentColor);

  return (
    <div className={"flex " + className}>
      <Form.Item
        label={hideLabels ? null : "Color"}
        className={"flex-1 " + itemClassName}
        name="color"
      >
        <PaginatedSelect
          localSearch
          disabled={disabled}
          searchValue={searchingColor}
          isLoading={isColorsLoading}
          placeholder="Select Color"
          fetchNextPage={fetchNextColors}
          isFetchingNextPage={isFetchingNextColors}
          onSearch={searchColor}
          onClear={onClear}
          onChange={onChange}
          options={
            noInitialOption
              ? [Device.getColorOption(initialData), ...colorOptions]
              : colorOptions
          }
          filterOption={(searchValue, { children }) =>
            children?.toLowerCase().includes(searchValue?.toLowerCase())
          }
        />
      </Form.Item>

      <div className={hideLabels ? "" : "flex items-center m-2"}>
        <CaretRightOutlined className="text-slate-400" />
      </div>

      <Form.Item
        label={hideLabels ? null : "Shades"}
        className={"flex-1 " + itemClassName}
        name="color_shade_id"
        rules={rules}
      >
        <PaginatedSelect
          localSearch={!!currentColor}
          disabled={disabled}
          searchValue={shadesSearchQuery}
          isLoading={isShadesLoading}
          placeholder="Select Shade"
          fetchNextPage={() => !currentColor && fetchNextShadesPage()}
          isFetchingNextPage={isFetchingNextShadesPage}
          onSearch={setShadesSearchQuery}
          onClear={onClear}
          options={
            !!currentColor
              ? noInitialOption
                ? Device.getColorOption(initialData).shades
                : shadeOptions[currentColor]
              : allShadesOptions
          }
          filterOption={(searchValue, { children }) =>
            !!currentColor
              ? children?.toLowerCase().includes(searchValue?.toLowerCase())
              : true
          }
        />
      </Form.Item>
    </div>
  );
};
