import { Button, Card, Statistic, Upload, Input } from "antd";
import { useState, useTransition } from "react";
import clsx from "clsx";
import {
  ClearOutlined,
  UploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import {
  ComparisonTable,
  useCompareQuantityStore,
  useCompareQuantity,
} from "features/faktura";

export const CompareQuantity = () => {
  const { saveResult, result, clear } = useCompareQuantityStore();

  const [isPending, startTransition] = useTransition();
  const [skus, setSkus] = useState(result?.skus || []);

  function performSearch(searchQuery) {
    startTransition(() => {
      setSkus(
        result?.skus.filter(record => {
          return record && record?.sku.includes(searchQuery);
        }),
      );
    });
  }

  const { upload, isImporting } = useCompareQuantity({
    onSuccess: res => {
      saveResult(res.data);
      setSkus(res.data.skus);
    },
  });

  const onSubmit = ({ file }) => {
    const body = new FormData();
    body.append("file", file);
    upload(body);
  };

  const onClear = () => {
    clear();
    setSkus([]);
  };

  return (
    <div className="page-container flex-col relative pb-4">
      <h2 className="text-center mb-6 sm:mb-10">Compare Quantity</h2>

      <div
        className={clsx("flex justify-center gap-6 mb-8", {
          hidden: !result,
        })}
      >
        <Card bordered={false} size="small">
          <Statistic
            className="px-3"
            title={"Total Faktura"}
            value={result?.total_faktura}
            valueStyle={{ color: "#006086" }}
          />
        </Card>

        <Card bordered={false} size="small">
          <Statistic
            className="px-3"
            title={"Total System"}
            value={result?.total_system}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card>
      </div>

      <div className="flex mb-3 gap-2">
        <Input
          className="w-64 text-xs sm:text-sm [&_.anticon]:text-red-400 hover:[&_.anticon]:text-red-600"
          placeholder="Enter Search Query"
          allowClear
          onChange={({ target }) => {
            setTimeout(() => {
              performSearch(target.value);
            }, 100);
          }}
          prefix={<SearchOutlined style={{ color: "black" }} />}
          disabled={isImporting || !result}
        />
        <Upload
          className="ml-auto"
          name="file"
          withCredentials
          fileList={null}
          customRequest={onSubmit}
        >
          <Button
            type={!!result ? "default" : "primary"}
            loading={isImporting}
            disabled={isImporting}
            icon={<UploadOutlined />}
          >
            Upload Faktura
          </Button>
        </Upload>

        {!!result && (
          <Button danger onClick={onClear} icon={<ClearOutlined />}>
            Clear
          </Button>
        )}
      </div>

      <ComparisonTable data={skus} isLoading={isImporting || isPending} />
    </div>
  );
};
