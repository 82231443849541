import { MessageOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useDeviceComment } from "features/devices";

const { TextArea } = Input;

export const CommentForm = ({ disabled = false, deviceId }) => {
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(false);

  const { toComment, isCommenting } = useDeviceComment({
    id: deviceId,
    onSuccess: data => {
      console.log(data);
      form.resetFields();
      onClose();
    },
  });

  const onOpen = () => setIsOpen(true);
  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Tooltip title="comment">
        <Button
          size="small"
          onClick={onOpen}
          disabled={disabled || isCommenting}
        >
          <MessageOutlined />
        </Button>
      </Tooltip>

      <Modal
        title="Add Comment"
        open={isOpen}
        onCancel={onClose}
        maskClosable={false}
        onOk={form.submit}
        confirmLoading={isCommenting}
      >
        <Form onFinish={toComment} form={form} disabled={isCommenting}>
          <Form.Item
            className="mt-6"
            name="comment"
            rules={[{ required: true, message: "Add your comment" }]}
          >
            <TextArea placeholder="Enter Comment" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
