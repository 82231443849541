import {
  useDevice,
  DeviceConstructor,
  useEditDevice,
  useRemoveDevice,
  useFormConstructor,
} from "features/directory";
import { Loader } from "components";
import { useParams } from "react-router-dom";
import { message, Button, App } from "antd";
import { useNavigate } from "react-router-dom";
import { globalErrorHandler } from "lib/react-query-client";
import { useAuthStore } from "features/auth";

export const DeviceType = () => {
  const { isRight } = useAuthStore();
  const isAdmin = isRight(["admin"]);
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const { deviceId } = useParams();

  const { device, isLoading } = useDevice({
    id: deviceId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { edit, isEditing } = useEditDevice(deviceId, res => {
    message.success(res?.message || "Successfully updated!");
  });

  const { remove, isRemoving } = useRemoveDevice(deviceId, () =>
    message.success("Removed!"),
  );

  const { validate, fields } = useFormConstructor();

  const buildRequest = () => {
    const fieldsCopy = JSON.parse(JSON.stringify(fields));

    fieldsCopy.forEach(field => {
      delete field.error;
      delete field.readOnly;
      delete field.optionsIndexes;

      field.type = field.type.toUpperCase();

      if (field.options?.length === 0) {
        delete field.options;
      } else if (!!field.options) {
        field.options = field.options
          .map(opt => ({ value: opt.value, id: opt.id }))
          .filter(opt => opt.value !== "");
      }
    });
    return { name: fieldsCopy[0].name, fields: fieldsCopy.slice(1) };
  };

  const onRemove = () => {
    modal.confirm({
      title: "Device Removing",
      content: "Are you sure you want to delete the device?",
      onOk: () => remove(),
    });
  };

  const onUpdate = () => {
    const isValid = validate();
    if (isValid) edit(buildRequest());
  };

  const isAccessory = device?.data?.name === "Accessory";

  return (
    <div className="relative flex flex-col h-full">
      <h2 className="text-center">
        {`Device Type: ${device ? device.data.name : deviceId}`}
      </h2>

      <div className="overflow-y-auto flex-2">
        <div className="max-w-lg my-12 mx-auto flex flex-col">
          {device && (
            <>
              <DeviceConstructor
                value={device?.data}
                disabled={isLoading || isEditing || isRemoving}
              />

              {rightRole && (
                <div className="flex gap-2 px-7 sm:px-0">
                  <Button
                    className="flex-1"
                    danger
                    onClick={onRemove}
                    loading={isRemoving}
                    disabled={isLoading || isEditing || !isAdmin || isAccessory}
                  >
                    Delete
                  </Button>

                  <Button
                    className="flex-1"
                    type="primary"
                    onClick={onUpdate}
                    loading={isEditing}
                    disabled={isLoading || isRemoving}
                  >
                    Update
                  </Button>
                </div>
              )}
            </>
          )}

          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};
