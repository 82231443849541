import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useMemo } from "react";

const getRoles = () => apiClient.get("api/users/roles");

export const useRoles = ({ onSuccess } = {}) => {
  const { data, isLoading: isRolesLoading } = useQuery(["roles"], getRoles, {
    onSuccess: data => {
      onSuccess?.(data);
    },
  });

  const roles = useMemo(
    () =>
      data?.reduce(
        (prev, current) => ({ ...prev, [current.role_name]: current.id }),
        {},
      ),
    [data],
  );

  return { roles, isRolesLoading };
};
