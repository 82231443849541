import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Table, Pagination, Button, Badge } from "antd";
import { PrinterFilled } from "@ant-design/icons";

import { useAuthStore } from "features/auth";
import { QRLabel } from "features/invoices";
import { SearchInput } from "components";
import {
  useDevices,
  DevicesFilter,
  useSearchDevicesStore,
  useStatusOptions,
  DevicesExport,
  devicesTable,
  DevicesStatusChange,
  DevicesRepairOptions,
  PredefinedDevices,
} from "features/devices";

export const SearchDevice = () => {
  const { isRight } = useAuthStore();
  const store = useSearchDevicesStore();

  useStatusOptions(); // just triggers prefetching of statuses pretty names
  const { devices, isLoading, isFetching } = useDevices();

  let printedRef = useRef();
  const onPrint = useReactToPrint({
    content: () => printedRef,
  });

  useEffect(() => {
    return () => store.clearSelection();
  }, []);

  const selectRow = record => {
    const selectedRowKeys = [...store.selectedToPrint];
    if (selectedRowKeys.indexOf(record.product_id) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.product_id), 1);
    } else {
      selectedRowKeys.push(record.product_id);
    }
    store.selectToPrint(selectedRowKeys);
  };

  return (
    <div className="flex h-full px-4 w-full mx-auto max-w-[1800px] flex-col relative">
      <h2 className="text-center">Search Device</h2>

      <div className="flex justify-between mb-3 gap-2">
        <Badge
          className="mr-1"
          count={store.selectedToPrint.length}
          size="small"
          color="volcano"
          offset={[-4, 0]}
        >
          <Button
            onClick={onPrint}
            disabled={!store.selectedToPrint.length}
            type="primary"
          >
            <PrinterFilled />
          </Button>
        </Badge>

        <div className={"mr-auto"}>
          <SearchInput
            searchQuery={store.searchQuery}
            setSearchQuery={store.setSearchQuery}
          />
        </div>

        {isRight(["admin", "sales_manager"]) && (
          <>
            <DevicesRepairOptions
              clearSelection={store.clearSelection}
              disabled={!store.selectedToPrint.length}
              count={store.selectedToPrint.length}
              ids={store.selectedToPrint}
            />
            <DevicesStatusChange
              clearSelection={store.clearSelection}
              disabled={!store.selectedToPrint.length}
              count={store.selectedToPrint.length}
              ids={store.selectedToPrint}
            />
            <PredefinedDevices />
          </>
        )}

        {isRight(["admin"]) && <DevicesExport disabled={isLoading} />}

        <DevicesFilter
          showPriority
          store={store}
          isLoading={isLoading || isFetching}
          disabled={isLoading}
        />
      </div>

      <Table
        className="overflow-y-auto"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        columns={devicesTable}
        rowSelection={{
          onChange: store.selectToPrint,
          selectedRowKeys: store.selectedToPrint,
        }}
        dataSource={devices?.data}
        sticky={true}
        rowKey={record => record.product_id}
        pagination={false}
        onRow={record => ({
          onClick: () => selectRow(record),
        })}
        scroll={{ x: 1200 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        showTotal={false}
        current={store.page}
        pageSize={store.pageSize}
        onChange={page => {
          store.clearSelection();
          store.setPage(page);
        }}
        disabled={isLoading || isFetching}
      />

      {devices?.data && (
        <div className="hidden">
          <div className="m-0 p-0" ref={el => (printedRef = el)}>
            {store.selectedToPrint.map(rowId => {
              const device = devices.data?.find(
                device => device.product_id === rowId,
              );

              return (
                <QRLabel
                  priority={device?.priority}
                  key={device?.product_id}
                  url={device?.qr}
                  id={device?.product_id}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
