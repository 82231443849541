import { useFormConstructor } from "features/directory";
import { FormItem } from "./FormItem";
import { Button } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAuthStore } from "features/auth";
import _ from "lodash";

export const DeviceConstructor = ({ value = null, disabled = false }) => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const store = useFormConstructor();

  const [optionalFields, setOptionalFields] = useState({
    imei: false,
    memory: false,
    serial: false,
  });

  const readConfig = ({ fields, name }) => {
    const restOfFields = _.cloneDeep(fields);
    const draft = [{ name, type: "text" }];

    const indexOfModel = restOfFields.findIndex(f => f.name === "Model");
    if (indexOfModel >= 0) draft.push(restOfFields.splice(indexOfModel, 1)[0]);

    const indexOfColor = restOfFields.findIndex(f => f.name === "Color");
    if (indexOfColor >= 0) draft.push(restOfFields.splice(indexOfColor, 1)[0]);

    const indexOfMemory = restOfFields.findIndex(f => f.name === "Memory");
    if (indexOfMemory >= 0) {
      draft.push(restOfFields.splice(indexOfMemory, 1)[0]);
      setOptionalFields(state => ({ ...state, memory: true }));
    }

    const indexOfImei = restOfFields.findIndex(f => f.name === "IMEI");
    if (indexOfImei >= 0) {
      draft.push(restOfFields.splice(indexOfImei, 1)[0]);
      setOptionalFields(state => ({ ...state, imei: true }));
    }

    const indexOfSerial = restOfFields.findIndex(f => f.name === "Serial");
    if (indexOfSerial >= 0) {
      draft.push(restOfFields.splice(indexOfSerial, 1)[0]);
      setOptionalFields(state => ({ ...state, serial: true }));
    }

    return { fields: draft.concat(restOfFields) };
  };

  const dynamicFieldsStartsFrom = useMemo(
    () =>
      3 +
      Object.values(optionalFields).reduce(
        (count, current) => (current ? count + 1 : count),
        0,
      ),
    [optionalFields, value],
  );

  useEffect(() => {
    store.setData({
      fields: [
        { name: "", type: "text" },
        {
          name: "Model",
          type: "select",
          options: [{ key: uuidv4(), value: "" }],
        },
        { name: "Color", type: "text" },
      ],
    });
    return store.clear;
  }, []);

  useEffect(() => {
    if (value) store.setData(readConfig(value));
  }, [value]);

  const isAccessory = value?.name === "Accessory";

  return (
    <div className="mb-12 flex flex-col gap-4 px-7 sm:px-0">
      <FormItem
        fieldIndex={0}
        showType={false}
        placeholder="Enter Device Type Name"
        fixedType
        isStatic
        disabled={disabled}
        type="text"
        nameValue={store.fields[0]?.name || ""}
        error={store.fields[0]?.error && store.validation}
        onChangeName={value => store.onChangeName(0, value)}
        readOnly={!rightRole || isAccessory}
      />

      {/* MODEL */}
      <FormItem
        showOptionMenu={true}
        showCategories={!isAccessory}
        fieldIndex={1}
        readOnly
        readOnlyTotal={!rightRole}
        isStatic
        disabled={disabled}
        type="select"
        nameValue={store.fields[1]?.name || ""}
        error={store.fields[1]?.error && store.validation}
        onChangeName={value => store.onChangeName(1, value)}
        onRemoveOption={key => store.removeOption(1, key)}
        onChangeOption={store.onChangeOptionText}
        onAddNewOption={() => store.addNewOption(1)}
        options={store.fields[1]?.options}
      />

      <FormItem
        fieldIndex={2}
        readOnly
        readOnlyTotal={!rightRole}
        isStatic
        disabled={disabled}
        type="text"
        nameValue={store.fields[2]?.name || ""}
        error={store.fields[2]?.error && store.validation}
        onChangeName={value => store.onChangeName(2, value)}
      />

      {!isAccessory && (
        <>
          <FormItem
            fieldIndex={3}
            readOnly
            readOnlyTotal={!rightRole}
            optional
            isStatic
            disabled={disabled || !optionalFields.memory}
            type="select"
            nameValue="Memory"
            error={
              optionalFields.memory &&
              store.fields[3]?.error &&
              store.validation
            }
            onRemoveOption={key => store.removeOption(3, key)}
            onChangeOption={store.onChangeOptionText}
            onAddNewOption={() => store.addNewOption(3)}
            options={optionalFields.memory ? store.fields[3]?.options : []}
            turnedOn={optionalFields.memory}
            onToggle={isOn => {
              setOptionalFields({ ...optionalFields, memory: isOn });
              const draft = _.cloneDeep(store.fields);

              if (isOn) {
                draft.splice(3, 0, {
                  name: "Memory",
                  type: "select",
                  options: [{ key: uuidv4(), value: "" }],
                });
              } else {
                draft.splice(3, 1);
              }
              store.setData({ fields: draft });
            }}
          />
          <FormItem
            fieldIndex={optionalFields.memory ? 4 : 3}
            readOnly
            readOnlyTotal={!rightRole}
            optional
            isStatic
            disabled={disabled || !optionalFields.imei}
            type="text"
            nameValue="IMEI"
            turnedOn={optionalFields.imei}
            onToggle={isOn => {
              setOptionalFields({ ...optionalFields, imei: isOn });
              const draft = _.cloneDeep(store.fields);
              const index = optionalFields.memory ? 4 : 3;

              if (isOn) {
                draft.splice(index, 0, { name: "IMEI", type: "text" });
              } else {
                draft.splice(index, 1);
              }

              store.setData({ fields: draft });
            }}
          />
          <FormItem
            fieldIndex={
              optionalFields.memory ? (optionalFields.imei ? 5 : 4) : 3
            }
            readOnly
            readOnlyTotal={!rightRole}
            optional
            isStatic
            disabled={disabled || !optionalFields.serial}
            type="text"
            nameValue="Serial"
            turnedOn={optionalFields.serial}
            onToggle={isOn => {
              setOptionalFields({ ...optionalFields, serial: isOn });
              const draft = _.cloneDeep(store.fields);
              const index = optionalFields.memory
                ? optionalFields.imei
                  ? 5
                  : 4
                : 3;

              if (isOn) {
                draft.splice(index, 0, { name: "Serial", type: "text" });
              } else {
                draft.splice(index, 1);
              }

              store.setData({ fields: draft });
            }}
          />
        </>
      )}

      {/* USER DEFINED */}
      {store.fields.slice(dynamicFieldsStartsFrom).map((item, i) => {
        const realIndex = i + dynamicFieldsStartsFrom;
        return (
          <div className="flex flex-col" key={`field-${i}`}>
            {i !== 0 && (
              <Button
                disabled={disabled}
                className="text-slate-300 hover:text-sky-500"
                size="small"
                type="ghost"
                onClick={() => store.swapFields(realIndex - 1)}
              >
                <SwapOutlined />
              </Button>
            )}

            <FormItem
              fieldIndex={realIndex}
              disabled={disabled}
              type={item.type}
              nameValue={item.name}
              error={item.error && store.validation}
              onChangeType={value => store.onChangeType(realIndex, value)}
              onChangeName={value => store.onChangeName(realIndex, value)}
              onRemove={() => store.removeField(realIndex)}
              onRemoveOption={key => store.removeOption(realIndex, key)}
              onChangeOption={store.onChangeOptionText}
              onAddNewOption={() => store.addNewOption(realIndex)}
              options={item.options}
              readOnly={!rightRole}
              readOnlyTotal={!rightRole}
            />
          </div>
        );
      })}

      {rightRole && !isAccessory && (
        <Button disabled={disabled} onClick={() => store.addNewField()}>
          Add Field
        </Button>
      )}
    </div>
  );
};
