import { useInfiniteQuery, useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useInvoicesStore } from "features/invoices";
import { useDebounce } from "use-debounce";
import { useMemo, useState } from "react";

const getInvoices = ({
  search_term = null,
  start_date = null,
  end_date = null,
  per_page = 12,
  page = 1,
}) =>
  apiClient.get("api/invoices", {
    params: { per_page, page, search_term, start_date, end_date },
  });

export const useInvoices = () => {
  const {
    page,
    per_page,
    setPage,
    setTotal,
    search_term,
    start_date,
    end_date,
  } = useInvoicesStore();
  const [debouncedQuery] = useDebounce(search_term, 500);

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery(
    ["invoices", page, per_page, debouncedQuery, start_date, end_date],
    () =>
      getInvoices({
        page: page,
        per_page,
        search_term: debouncedQuery || null,
        start_date,
        end_date,
      }),
    {
      onSuccess: invoices => {
        const { last_page, total } = invoices.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
      },
    },
  );
  return { invoices, isLoadingInvoices };
};

const infinite_getInvoices = async ({ pageParam, search_term, typeId }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteInvoices = ({ onSuccess } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-invoices", debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/invoices?per_page=12&page=1`,
      }) =>
        infinite_getInvoices({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const buildInvoiceOption = invoiceData => ({
    value: invoiceData.id,
    label: `${invoiceData.invoice_number} - ${invoiceData.supplier.supplier_name}`,
  });

  const invoicesOptions = useMemo(() => {
    if (!data?.pages) return [];

    const invoices = data?.pages?.map(({ data }) => data)?.flat();
    return invoices.map(invoice => buildInvoiceOption(invoice));
  }, [data]);

  return {
    invoicesOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
