import dayjs from "dayjs";
import { FloatButton, Modal, Table, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  useMarketplaceFormulas,
  useFormulasStore,
  ProfitabilityPage,
  useCreateMarketplaceFormula,
} from "features/profitability";
import { Pagination } from "components";
import { useNavigate } from "react-router-dom";

export const MarketplaceFormulas = () => {
  const navigate = useNavigate();
  const store = useFormulasStore();
  const { formulas, isLoading, isFetching } = useMarketplaceFormulas({ store });

  const { createFormula, isCreating } = useCreateMarketplaceFormula();

  const columns = [
    { title: "ID", dataIndex: "id", key: "id", width: 100 },
    {
      title: "Date",
      key: "created_at",
      render: f => dayjs(f.created_at).format("MMMM DD YYYY • HH:mm"),
      align: "center",
    },
    {
      title: "Expressions",
      render: f => f.expressions?.length,
      align: "center",
      width: 140,
    },
    {
      title: "",
      render: f => {
        const isCurrent = store.currentFormula === f.id;
        if (isCurrent)
          return (
            <Tag className="ml-3" color="magenta">
              Current
            </Tag>
          );
      },
      width: 140,
    },
  ];

  return (
    <ProfitabilityPage title="Marketplace Formulas">
      {/* <div className="relative"> */}
      <FloatButton
        disabled={isCreating}
        type="primary"
        className="absolute top-20 right-10"
        onClick={() => {
          Modal.confirm({
            maskClosable: true,
            title: "New formula history",
            content:
              "Are you sure you want to create a formula history with new expressions?",
            onOk: () => {
              if (!isCreating) createFormula();
            },
          });
        }}
        icon={<PlusOutlined />}
      />

      <Table
        className="overflow-y-auto mt-2"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching || isCreating}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        onRow={({ id }) => ({
          onClick: () => navigate(`/profit/formulas/${id}`),
        })}
        columns={columns}
        dataSource={formulas?.data || []}
      />
      {/* </div> */}

      <Pagination
        store={store}
        disabled={isLoading || isFetching}
        className="mt-auto"
      />
    </ProfitabilityPage>
  );
};
