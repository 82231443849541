import { Result } from "antd";
import { Link } from "react-router-dom";

export const ErrorPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link className="text-base" to="/">
        Back Home
      </Link>
    }
  />
);
