import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import dayjs from "dayjs";

const downloadList = async (id, isAccessory = false) => {
  const typeParam = isAccessory ? "export-accessory" : "export";
  const file = await apiClient.post(
    `api/sending/commission-list/${id}/${typeParam}`,
  );

  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `commission_list_${dayjs().format("YYYY-MM-DD")}.csv`,
  );
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

export const useComListExport = ({
  onSuccess,
  onError,
  id,
  isAccessory = false,
} = {}) => {
  const { mutate: download, isLoading: isDownloading } = useMutation(
    () => downloadList(id, isAccessory),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { download, isDownloading };
};
