import { useState } from "react";
import { Modal, Button, message, InputNumber } from "antd";
import { useScanSendingProduct } from "features/sending";

export const ManualAdding = ({ listId, disabled }) => {
  const [isManualOpen, setIsManualOpen] = useState(false);
  const [inputText, setInputText] = useState("");

  const { scan, isScanning } = useScanSendingProduct({
    listNum: listId,
    onSuccess: res => {
      setIsManualOpen(false);
      setInputText("");

      if (res.message === "Product found in commission list") {
        message.success(res.message);
      } else {
        message.warning(res.message);
      }
    },
  });

  return (
    <>
      <Button
        className="text-xs sm:text-sm"
        disabled={disabled}
        onClick={() => setIsManualOpen(true)}
      >
        Add ID Manually
      </Button>

      <Modal
        confirmLoading={isScanning}
        open={isManualOpen}
        title="Check Product by Id Number"
        okButtonProps={{ disabled: !inputText }}
        onCancel={() => {
          if (!isScanning) {
            setIsManualOpen(false);
            setInputText("");
          }
        }}
        onOk={() => scan(inputText)}
      >
        <div className="p-6">
          <InputNumber
            placeholder="Enter Product Id Number"
            className="w-full"
            controls={false}
            type="number"
            value={inputText}
            onChange={setInputText}
          />
        </div>
      </Modal>
    </>
  );
};
