import { useStatusOptions } from "features/devices";
import {
  useProductDevices,
  useDevicesWthOtherStatusesStore,
  QUERY_NAMES,
  ProductsTable,
  ProfitStats,
  ProductsToolbar,
  ProfitabilityPage,
} from "features/profitability";

export const ProductsWithOtherStatuses = () => {
  const store = useDevicesWthOtherStatusesStore();

  //preload for table status columng
  useStatusOptions();

  const {
    data: devices,
    isFetching,
    isLoading,
  } = useProductDevices({
    store,
    groupName: "other-status",
    queryName: QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES,
  });

  const isProcessing = !devices || isLoading || isFetching;

  return (
    <ProfitabilityPage title="Products with other Statuses">
      <ProfitStats data={devices} keys={["total_count"]} />

      <ProductsToolbar
        store={store}
        disabled={isProcessing}
        isLoading={isProcessing}
        groupName="other-status"
        allowExport={!!devices?.data.length}
      />

      <ProductsTable
        isLoading={isProcessing}
        queryName={QUERY_NAMES.PRODUCTS.DEVICES.OTHER_STATUSES}
        data={devices?.data}
        idKey="product_id"
        store={store}
      />
    </ProfitabilityPage>
  );
};
