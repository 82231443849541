import { useState } from "react";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import { PaginatedSelect } from "components";
import { useCreateSale, useInfiniteMarketsData } from "features/sales";
import { requiredRule } from "constants/validation";

export const CreateSale = () => {
  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const {
    dataOptions,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteMarketsData();

  const { create, isCreating } = useCreateSale({
    onSuccess: res => {
      message.success(res.message || "Sale added successfully");
      close();
    },
  });

  return (
    <>
      <Button type="primary" onClick={open}>
        Create Sale
      </Button>

      <Modal
        open={isOpen}
        onCancel={close}
        title="Create Sale"
        onOk={form.submit}
        confirmLoading={isCreating}
      >
        <Form
          form={form}
          onFinish={create}
          disabled={isCreating}
          className="mt-6"
        >
          <Form.Item
            label="SKU"
            name="marketplace_sku_id"
            rules={[requiredRule]}
            labelCol={{ span: 5 }}
          >
            <PaginatedSelect
              disabled={isCreating}
              searchValue={searchQuery}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              onSearch={setSearchQuery}
              onClear={() => form.setFieldValue("marketplace_sku_id", null)}
              options={dataOptions}
              placeholder="Select marketplace SKU"
            />
          </Form.Item>

          <Form.Item
            label="Price"
            name="price"
            rules={[requiredRule]}
            labelCol={{ span: 5 }}
          >
            <InputNumber
              addonBefore="€"
              min={0}
              className="w-full"
              placeholder="Enter price"
            />
          </Form.Item>

          <Form.Item
            label="Product id"
            name="product_id"
            rules={[requiredRule]}
            labelCol={{ span: 5 }}
          >
            <Input placeholder="Enter product id" />
          </Form.Item>

          <Form.Item
            label="Order id"
            name="order_id"
            rules={[requiredRule]}
            labelCol={{ span: 5 }}
          >
            <Input placeholder="Enter order id" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
