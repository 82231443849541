import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button, Form, Input, InputNumber, message, Radio, Select } from "antd";
import { useNewPromotion, usePromoUpdating } from "features/promotions";
import { DeviceTypeMultiSelect } from "features/devices";
import { requiredRule } from "constants/validation";

export const PromotionForm = ({
  isEditing = false,
  promotion = null,
  onUpdate = () => console.warn("PromotionForm: need onUpdate"),
  onCancel = () => console.warn("PromotionForm: need onCancel"),
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const selectedType = Form.useWatch("type", form);

  const { create, isCreating } = useNewPromotion({
    onSuccess: response => {
      message.success(response?.message);
      navigate(`/promotions/${response?.data.id}`);
    },
  });

  const { updatePromo, isPromoUpdating } = usePromoUpdating({
    promoId: String(promotion?.data?.id),
    onSuccess: res => {
      message.success(res?.message);
      form.resetFields();
      onUpdate();
    },
  });

  const onSubmit = data => {
    isEditing && promotion ? updatePromo(data) : create(data);
  };

  useEffect(() => {
    if (!promotion) return;

    form.setFieldValue("title", promotion.data?.title);
    form.setFieldValue("type", promotion.data?.type);
    form.setFieldValue("price", promotion.data?.price);
    form.setFieldValue(
      "device_types",
      promotion.data?.device_types?.map(({ id }) => id),
    );
  }, [promotion]);

  const typeSelect = (
    <Select
      value={selectedType}
      onChange={val => form.setFieldValue("type", val)}
      options={typeOptions}
    />
  );

  return (
    <Form
      disabled={isCreating || isPromoUpdating}
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={onSubmit}
    >
      <Form.Item name="title" label="Title" rules={[requiredRule]}>
        <Input placeholder="Input Promotion Name" />
      </Form.Item>

      <DeviceTypeMultiSelect
        hidden={!!promotion?.data?.sku_count}
        rules={[requiredRule]}
        initialData={promotion?.data?.device_types}
        name="device_types"
        label="Device Type"
        placeholder="Select Device Type"
        disabled={isCreating || isPromoUpdating}
        onSelect={e => {
          let current = form.getFieldValue("device_types");
          if (!current) current = [];
          form.setFieldValue("device_types", [...current, e]);
        }}
        onDeselect={e => {
          const current = form.getFieldValue("device_types");
          form.setFieldValue(
            "device_types",
            current.filter(item => item !== e),
          );
        }}
        onClear={() => {
          form.setFieldValue("device_types", []);
        }}
      />

      <Form.Item label="Type" name="type" initialValue={true} hidden={true}>
        <Radio.Group>
          <Radio value={true}>Increase</Radio>
          <Radio value={false}>Decrease</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Amount" name="price" rules={[requiredRule]}>
        <InputNumber
          addonBefore={typeSelect}
          className="w-full"
          placeholder="Input Amount"
          min={0}
        />
      </Form.Item>

      <div className="flex justify-end gap-2">
        <Button
          onClick={() => {
            onCancel();
            form.resetFields();
          }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          loading={isCreating || isPromoUpdating}
        >
          {isEditing ? "Save" : "Create"}
        </Button>
      </div>
    </Form>
  );
};

const typeOptions = [
  {
    value: true,
    label: (
      <span>
        <span className="text-green-400 mr-1">▲</span>
        Increase
      </span>
    ),
  },
  {
    value: false,
    label: (
      <span>
        <span className="text-red-300 mr-1">▼</span>
        Decrease
      </span>
    ),
  },
];
