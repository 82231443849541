import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useState } from "react";
import { globalErrorHandler } from "lib/react-query-client";

const getBuyBoxPrice = marketplace =>
  apiClient.get(
    `api/directory/marketplaces/refurbed/${marketplace}/buybox-price`,
  );

export const useBuyBoxPrice = ({ onSuccess, onError } = {}) => {
  const [allowed, setAllowed] = useState(false);
  const [marketplace, setMarketplace] = useState(null);

  const {
    data: buyBoxData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    ["buybox-price", marketplace],
    () => getBuyBoxPrice(marketplace),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.();
      },
      enabled: allowed,
    },
  );

  // const getPrice = allowed ? refetch : allow;
  const getBBPrice = marketplaceId => {
    setMarketplace(marketplaceId);
    if (allowed) {
      refetch();
    } else {
      setAllowed(true);
    }
  };
  return { buyBoxData, isLoading, isFetching, getBBPrice };
};
