import { SearchInput, FilterDrawer } from "components";
import { Spin } from "antd";
import {
  GradeFilter,
  DeviceTypeFilter,
  ColorFilter,
  OriginalBoxFilter,
  DatesFilter,
  InvoicesFilter,
  MarketplacesFilter,
  SuppliersFilter,
  MarketplaceFormulasFilter,
  StatusFilter,
} from "features/filter";
import { ProductsExport } from "features/profitability";
import { useMemo } from "react";

export const ProductsToolbar = ({
  store,
  disabled = false,
  isLoading = false,
  groupName = "",
  allowExport = false,
  isSKU = false,
}) => {
  const clearFilters = () => {
    store.clearFilters(["market_formula"]);
    store.resetDates?.();
  };

  const activeFiltersCount = useMemo(
    () =>
      Object.keys(store.filter_by).filter(key => key !== "market_formula")
        .length +
      +!!store?.start_date +
      +!!store?.end_date,
    [store.filter_by, store.start_date, store.end_date],
  );

  return (
    <div className="flex justify-between mb-3 gap-2">
      <SearchInput
        searchQuery={store?.search_term}
        setSearchQuery={store?.setSearchTerm}
        disabled={disabled}
      />

      <ProductsExport
        className="ml-auto"
        disabled={disabled || !allowExport}
        store={store}
        groupName={groupName}
        isSKU={isSKU}
      />

      <FilterDrawer
        title={
          <span>
            Devices filter
            {isLoading && <Spin size="small" className="ml-2" />}
          </span>
        }
        onClear={clearFilters}
        activeCount={activeFiltersCount}
        btnStyle="primary"
        disabled={disabled}
      >
        <div className="flex flex-col gap-8 px-6 py-10">
          {groupName === "other-status" && (
            <StatusFilter store={store} excluded={["SENT"]} />
          )}
          <SuppliersFilter store={store} name="supplier" />
          <InvoicesFilter store={store} />

          {groupName !== "without-sale" && groupName !== "other-status" && (
            <>
              <MarketplacesFilter store={store} withB2B name="market" />
              <MarketplaceFormulasFilter store={store} name="market_formula" />
              <DatesFilter store={store} />
            </>
          )}

          <GradeFilter store={store} />
          <OriginalBoxFilter store={store} />
          <ColorFilter store={store} />
          <DeviceTypeFilter gap={4} store={store} />
        </div>
      </FilterDrawer>
    </div>
  );
};
