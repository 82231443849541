import { Button } from "antd";
import { useComListExport } from "features/sending";

export const ExportComLIst = ({ disabled, id, isAccessory }) => {
  const { download, isDownloading } = useComListExport({ id, isAccessory });

  return (
    <Button
      className="text-xs sm:text-sm"
      disabled={disabled}
      type="primary"
      onClick={download}
      loading={isDownloading}
    >
      Export
    </Button>
  );
};
