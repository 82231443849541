import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

const check = (data, byMarket = false) => {
  const marketPart = byMarket ? "-by-market" : "";
  return apiClient.post(
    `api/sku/marketplaces/profit-apply-formula${marketPart}`,
    data,
  );
};

export const useCheckingFormulasProfit = ({ onSuccess } = {}) => {
  const { mutate: checkFormulasProfit, isLoading: isChecking } = useMutation(
    ({ data, byMarket = false }) => check(data, byMarket),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { checkFormulasProfit, isChecking };
};
