import { FilterDrawer, FilterSummary } from "components";
import { Spin } from "antd";
import {
  GradeFilter,
  DeviceTypeFilter,
  ColorFilter,
  StatusFilter,
  ConnectingFilter,
  OriginalBoxFilter,
  PriorityFilter,
  ReturnFilter,
  RepairFilter,
} from "features/filter";
import { useMemo } from "react";

export const DevicesFilter = ({
  store,
  disabled,
  isLoading,
  fixedStatus = false,
  showPriority = false,
}) => {
  const active = useMemo(() => {
    const active = Object.entries(store.filter_by).map(([key, value]) => ({
      label: key,
      value,
    }));

    const tooltip = active.length ? <FilterSummary list={active} /> : null;

    return { count: active.length, tooltip };
  }, [store.filter_by]);

  const isRepair = store.filter_by?.status === "REPAIR";

  return (
    <FilterDrawer
      title={
        <span>
          Devices filter
          {isLoading && <Spin size="small" className="ml-2" />}
        </span>
      }
      onClear={store.clearFilters}
      activeCount={active.count}
      btnStyle="primary"
      tooltip={active.tooltip}
      disabled={disabled}
    >
      <div className="flex flex-col gap-8 px-6 py-10">
        {showPriority && (
          <div className="flex flex-col gap-1">
            <label>Priority</label>
            <PriorityFilter store={store} />
          </div>
        )}

        <div className="flex flex-col gap-1">
          <label>Return</label>
          <ReturnFilter store={store} />
        </div>

        {!fixedStatus && <StatusFilter store={store} />}
        {isRepair && <RepairFilter store={store} />}
        <ConnectingFilter store={store} />
        <GradeFilter store={store} />
        <OriginalBoxFilter store={store} />
        <ColorFilter store={store} />
        <DeviceTypeFilter gap={4} store={store} />
      </div>
    </FilterDrawer>
  );
};
