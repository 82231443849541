import { useInfiniteQuery, useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useDebounce } from "use-debounce";
import { useInvoiceProductsStore } from "features/invoices";
import { useMemo, useState } from "react";

const getInvoiceProducts = (
  id,
  { search_term = null, per_page = 12, page = 1, filter_by },
) =>
  apiClient.get(`api/invoices/${id}/products`, {
    params: { per_page, page, search_term, filter_by },
  });

export const useInvoiceProducts = id => {
  const { page, per_page, setPage, setTotal, search_term, filter_by } =
    useInvoiceProductsStore();
  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetching,
  } = useQuery(
    ["invoiceProducts", id, page, per_page, debouncedQuery, filter_by],
    () =>
      getInvoiceProducts(id, {
        page,
        per_page,
        search_term: debouncedQuery || null,
        filter_by,
      }),
    {
      onSuccess: products => {
        const { last_page, total } = products.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
      },
    },
  );
  return { products, isLoadingProducts, isFetching };
};

const infinite_getInvoiceProducts = async ({ pageParam, search_term }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteInvoiceProducts = ({ onSuccess } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);

  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-invoiceProducts", invoiceId, debouncedQuery], // ORDER?
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/invoices/${invoiceId}/products?per_page=12&page=1`,
      }) =>
        infinite_getInvoiceProducts({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
        enabled: !!invoiceId,
      },
    );

  const productOptions = useMemo(() => {
    if (!data?.pages) return [];

    const products = data?.pages?.map(({ data }) => data)?.flat();

    return products.map(product => ({
      value: product.id,
      label: product.name,
    }));
  }, [data]);

  return {
    productOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
    setInvoiceId,
  };
};
