import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { App, Badge, Button, Empty, message, Pagination, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  getPromotionSKUsColumns,
  usePromoInfo,
  usePromotionSKUs,
  usePromotionSKUsStore,
  useSKURemoving,
  useSKUToggle,
  usePromoRemoving,
  PromotionAvailableSkus,
  PromotionInfo,
} from "features/promotions";
import { Loader, SearchInput } from "components";

export const Promotion = () => {
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const store = usePromotionSKUsStore();
  const { promotionId } = useParams();

  const { promotion, isFetching: isPromoInfoFetching } = usePromoInfo({
    id: promotionId,
    onSuccess: response => {
      if (response.data?.sku_count > 0) allowSKUs();
    },
  });

  const { skus, isLoading, isFetching, allowSKUs } = usePromotionSKUs({
    promoId: promotionId,
  });

  const { removePromo, isPromoRemoving } = usePromoRemoving({
    promoId: promotionId,
    onSuccess: res => {
      message.success(res.message);
      navigate("/promotions");
    },
  });

  const { toggleSKU, isToggling } = useSKUToggle({
    promoId: promotionId,
    onSuccess: res => {
      message.success(res.message);
      store.clearSelection();
    },
  });

  const { removeSKU, isRemovingSKU } = useSKURemoving({
    promoId: promotionId,
    onSuccess: res => {
      message.success(res.message);
      store.clearSelection();
    },
  });

  useEffect(() => () => store.clear(), []);

  const isActive = promotion?.data.active;
  const isEmpty = promotion?.data && !promotion?.data.sku_count;
  const idle =
    isLoading ||
    isFetching ||
    isToggling ||
    isPromoRemoving ||
    isPromoInfoFetching ||
    isRemovingSKU;

  return (
    <div className="flex flex-col h-full relative">
      {!promotion && <Loader />}

      <h2 className="flex justify-center items-center gap-2 mb-6">
        {`Promotion #${promotionId}`}

        <Button
          className="ml-3"
          type={isActive ? "default" : "primary"}
          danger={isActive}
          size="small"
          disabled={
            (isEmpty && !isActive) ||
            isToggling ||
            isPromoInfoFetching ||
            isPromoRemoving ||
            isRemovingSKU
          }
          onClick={() => toggleSKU(!isActive)}
        >
          {isActive ? "Deactivate" : "Activate"}
        </Button>

        <Button
          size="small"
          danger
          disabled={
            isToggling ||
            isPromoInfoFetching ||
            isActive ||
            isPromoRemoving ||
            isRemovingSKU
          }
          onClick={() => {
            modal.confirm({
              title: "Promo removing",
              content: <span>Do you really want to remove the promotion?</span>,
              onOk: removePromo,
            });
          }}
          icon={<DeleteOutlined />}
        />
      </h2>

      {promotion && <PromotionInfo promotion={promotion} disabled={idle} />}

      {!!promotion?.data.sku_count && (
        <>
          <div className="flex  max-w-5xl w-full mx-auto px-4 mb-3 mt-6 gap-2 items-end">
            <div className="mr-auto">
              <SearchInput
                disabled={isToggling || isPromoRemoving || isRemovingSKU}
                searchQuery={store.searchQuery}
                setSearchQuery={store.setSearchQuery}
              />
            </div>

            <Badge
              className="ml-auto mr-1"
              size="small"
              color="red"
              count={store.selection?.length}
              offset={[-4, 3]}
            >
              <Button
                type="primary"
                danger
                disabled={!store.selection?.length || idle}
                onClick={() => {
                  removeSKU({ sku_ids: store.selection });
                }}
              >
                Exclude SKU
              </Button>
            </Badge>

            <PromotionAvailableSkus
              promotion={promotion?.data}
              disabled={idle}
            />
          </div>

          <Table
            className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
            loading={idle}
            columns={getPromotionSKUsColumns(promotionId, idle)}
            dataSource={skus?.data}
            sticky={true}
            rowKey={() => _.uniqueId("promotion_")}
            pagination={false}
            scroll={{ x: 980 }}
          />

          <Pagination
            disabled={idle}
            className="text-center py-2 mt-auto"
            onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
            pageSizeOptions={[4, 8, 12, 24, 48]}
            showSizeChanger
            defaultCurrent={1}
            total={store.total}
            current={store.page}
            pageSize={store.pageSize}
            onChange={store.setPage}
          />
        </>
      )}

      {isEmpty && (
        <div className="flex justify-center items-center h-3/5">
          <Empty description={<span>There is no SKU yet</span>}>
            <PromotionAvailableSkus
              title="Add now"
              promotion={promotion?.data}
              disabled={idle}
            />
          </Empty>
        </div>
      )}
    </div>
  );
};
