import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useStatisticsStore } from "features/statistics";

const getProductsGraph = ({
  status,
  period,
  by_auth_user,
  by_user_id,
  start_date,
  end_date,
}) =>
  apiClient.get("api/statistic/product/diagram", {
    params: {
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
    },
    data: null,
  });

export const useProductsGraph = ({ onSuccess, onError } = {}) => {
  const {
    status,
    period,
    by_auth_user,
    by_user_id,
    start_date,
    end_date,
    activeTab,
    activeRegistrarTab,
  } = useStatisticsStore();

  const {
    data: productsGraph,
    isLoading: isProductsGraphLoading,
    isFetching,
  } = useQuery(
    [
      "prod-graph",
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
    ],
    () =>
      getProductsGraph({
        status,
        period,
        by_auth_user: by_auth_user ? 1 : 0,
        by_user_id,
        start_date,
        end_date,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
      enabled: !(
        activeTab === "Registration" && activeRegistrarTab === "Invoices"
      ),
    },
  );

  return { productsGraph, isProductsGraphLoading, isFetching };
};
