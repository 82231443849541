import { Form, Input, InputNumber, Switch } from "antd";
import ReactCountryFlag from "react-country-flag";

export const BMCountryItem = ({ data, index }) => {
  return (
    <div className="flex justify-between items-center gap-4">
      <div className="w-32">
        <ReactCountryFlag countryCode={data?.country_code.split("-")[1]} svg />
        <span className="ml-2">{data?.country_name}</span>
      </div>

      <Form.Item
        name={["countries_setting", index, "price"]}
        className="flex items-center my-4"
      >
        <InputNumber
          prefix={<Concurrency value={data.currency} />}
          className="w-full"
        />
      </Form.Item>

      <Form.Item
        name={["countries_setting", index, "min_price"]}
        className="flex items-center my-4"
      >
        <InputNumber
          prefix={<Concurrency value={data.currency} />}
          className="w-full"
        />
      </Form.Item>

      <Form.Item
        valuePropName="checked"
        name={["countries_setting", index, "increase"]}
        className="flex items-center my-4"
      >
        <Switch size="small" />
      </Form.Item>

      <Form.Item hidden name={["countries_setting", index, "currency"]}>
        <Input />
      </Form.Item>
      <Form.Item hidden name={["countries_setting", index, "country_code"]}>
        <Input />
      </Form.Item>
      <Form.Item hidden name={["countries_setting", index, "country_name"]}>
        <Input />
      </Form.Item>
    </div>
  );
};

const Concurrency = ({ value }) => (
  <span className="text-slate-400 text-xs font-semibold mr-2">{value}</span>
);
