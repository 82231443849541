import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Layout, Menu } from "antd";
import { useAuthStore } from "features/auth";
import { useSuperUsers } from "features/employees";
import { menuItems } from "./config";

export const NavigationMenu = () => {
  const { pathname } = useLocation();
  const { isRight, user } = useAuthStore();
  const { superUsers } = useSuperUsers();

  const [expandedMenuKeys, setExpandedMenuKeys] = useState([]);

  const userHasPermission = itemPermissionsArr => {
    if (!itemPermissionsArr) return true;
    const superCase = itemPermissionsArr.includes("super") && !!superUsers;
    return isRight(itemPermissionsArr, superCase ? superUsers : undefined);
  };

  const getPermittedMenu = menu => {
    menu.forEach((menuItem, i) => {
      if (!userHasPermission(menuItem.permissions)) return menu.splice(i, 1);
      if ("children" in menuItem)
        menuItem.children = getPermittedMenu(menuItem.children);
    });
    return menu;
  };

  const handleSubMenus = newValue => {
    const oldValue = expandedMenuKeys;

    if (oldValue.length === 0) {
      setExpandedMenuKeys(newValue);
      return;
    }

    if (oldValue.length === 1) {
      if (newValue.length === 0) {
        setExpandedMenuKeys([]);
      } else if (newValue.length === 2) {
        const newKey = _.difference(newValue, oldValue)[0];
        if (newKey.split("/").length === 2) {
          setExpandedMenuKeys([newKey]);
        } else {
          setExpandedMenuKeys(keys => [...keys, newKey]);
        }
      }
      return;
    }

    if (oldValue.length === 2) {
      if (newValue.length === 1) {
        const closedKey = _.difference(oldValue, newValue)[0];

        if (closedKey.split("/").length === 2) {
          setExpandedMenuKeys([]);
        } else {
          setExpandedMenuKeys(newValue);
        }
      } else if (newValue.length === 3) {
        const newKey = _.difference(newValue, oldValue)[0];

        if (newKey.split("/").length === 2) {
          setExpandedMenuKeys([newKey]);
        } else {
          setExpandedMenuKeys(
            expandedMenuKeys.map(key =>
              key.split("/").length === 2 ? key : newKey,
            ),
          );
        }
      }
    }
  };

  const permittedMenu = useMemo(
    () => getPermittedMenu([...menuItems]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, superUsers],
  );

  if (_.isEqual(user.data.roles, ["visor"])) return null;

  return (
    <Layout.Sider
      className="h-full z-10 [&_div]:overflow-y-auto !absolute md:!static"
      theme="light"
      breakpoint="md"
      collapsedWidth="0"
    >
      <Menu
        theme="light"
        items={permittedMenu}
        openKeys={expandedMenuKeys}
        selectedKeys={[pathname]}
        onOpenChange={handleSubMenus}
        onClick={() => toggleMobileMenu(() => setExpandedMenuKeys([]))}
        triggerSubMenuAction="click"
        mode="inline"
      />
    </Layout.Sider>
  );
};

const toggleMobileMenu = cb => {
  const btn = document.querySelector(
    ".ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left",
  );

  if (btn) {
    cb?.();
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    btn.dispatchEvent(clickEvent);
  }
};
