import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { Button, Form, message } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  RetourTable,
  useInvoiceRetourProducts,
  useReturnedProductsMapping,
} from "features/invoices";

export const InvoiceRetour = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { retourId } = useParams();

  const { products, isLoadingProducts, isFetchingProducts } =
    useInvoiceRetourProducts({
      id: retourId,
      onError: err => {
        if (err.response?.status === 404) navigate("/error");
      },
      onSuccess: res => {
        res?.data.forEach(({ id, price, product }) => {
          form.setFieldValue(["products", id, "id"], id);
          form.setFieldValue(["products", id, "price"], price);
          form.setFieldValue(
            ["products", id, "product_id"],
            product?.product_id || null,
          );
        });
        setTimeout(() => form.validateFields());
      },
    });

  const { save, isSaving, isFinishing } = useReturnedProductsMapping({
    onSuccess: res => message.success(res?.message || "Success"),
    retourId,
  });

  const onSave = ({ finish }) => {
    const { products } = form.getFieldsValue();

    save({
      data: {
        products: products
          .filter(product => !_.isEmpty(product))
          .map(product => ({
            ...product,
            product_id: product.product_id || null,
          })),
      },
      finish,
    });
  };

  const isMapping = products?.return_list.status === "MAPPING";
  const isMapped = products?.return_list.status === "MAPPED";

  return (
    <div className="page-container flex-col pb-6">
      <h2 className="text-center mb-6 sm:mb-10">
        Retour List Mapping
        <div className="flex justify-center items-center text-sm gap-2 text-slate-400">
          {isMapping ? (
            <LoadingOutlined />
          ) : isMapped ? (
            <CheckCircleOutlined />
          ) : null}
          {products?.return_list.status}
        </div>
      </h2>

      {isMapping && (
        <div className="flex justify-end gap-2 mb-4 px-3">
          <Button onClick={onSave} loading={isSaving} disabled={isFinishing}>
            Save state
          </Button>

          <Button
            danger
            onClick={() => onSave({ finish: true })}
            loading={isFinishing}
            disabled={isSaving}
          >
            Finish Mapping
          </Button>
        </div>
      )}

      <RetourTable
        data={products?.data}
        loading={isLoadingProducts || isFetchingProducts}
        form={form}
        disabled={isSaving || isMapped}
      />
    </div>
  );
};
