import { PaginatedSelect } from "components";
import { Form } from "antd";
import { useInfiniteGradeGroup } from "features/directory";
import { Device } from "features/devices";

export const GroupedGradeInput = ({
  disabled,
  onClear,
  rules = [],
  className,
  initialData,
  noGrouping = false,
  name = "grade_id",
  ...rest
}) => {
  const {
    groupOptions,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    setSearchQuery,
    searchQuery,
  } = useInfiniteGradeGroup({ grade_id: noGrouping ? null : initialData?.id });

  const noInitialOption =
    !searchQuery &&
    initialData?.id &&
    !groupOptions?.some(({ value }) => value === initialData?.id);

  return (
    <Form.Item rules={rules} label="Grade" name={name} className={className}>
      <PaginatedSelect
        disabled={disabled}
        isLoading={isLoading}
        placeholder="Select Grade"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        searchValue={searchQuery}
        onSearch={setSearchQuery}
        onClear={onClear}
        options={
          noInitialOption
            ? [Device.getGradeOption(initialData), ...groupOptions]
            : groupOptions
        }
        {...rest}
      />
    </Form.Item>
  );
};
