import { Button, Table, Pagination } from "antd";
import { useDepartmentsPagination } from "features/directory";
import { useNavigate } from "react-router-dom";
import { useDepartments } from "features/directory";
import { useAuthStore } from "features/auth";

export const DepartmentDirectory = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin"]);
  const navigate = useNavigate();
  const departmentsSelection = useDepartmentsPagination();
  const { departments, isLoading } = useDepartments();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Departments</h2>

      <div className="flex justify-end max-w-5xl w-full mx-auto px-4 mb-3">
        {rightRole && (
          <Button
            type="primary"
            onClick={() => navigate("/directory/departments/create")}
          >
            Create New
          </Button>
        )}
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName={rightRole ? "hover:cursor-pointer" : ""}
        loading={isLoading}
        columns={columns}
        dataSource={departments?.data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        onRow={(record, rowIndex) =>
          rightRole && {
            onClick: () => navigate(`/directory/departments/${record.id}`),
          }
        }
        scroll={{
          x: 350,
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) =>
          departmentsSelection.setPageSize(pageSize)
        }
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={departmentsSelection.total}
        current={departmentsSelection.page}
        pageSize={departmentsSelection.pageSize}
        onChange={departmentsSelection.setPage}
        disabled={isLoading}
      />
    </div>
  );
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Users Count",
    dataIndex: "users_count",
    key: "users_count",
  },
];
