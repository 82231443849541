import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { globalErrorHandler } from "lib/react-query-client";

const getMarketplaces = () =>
  apiClient.get("api/sku/marketplaces/marketplace-names");

export const useMarketplaceNames = ({ onSuccess, onError } = {}) => {
  const { data: marketplaces, isLoading: isLoadingMarketplaces } = useQuery(
    ["marketplace-names"],
    getMarketplaces,
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler();
        onError?.(err);
      },
    },
  );

  const marketplaceOptions = marketplaces
    ? Object.entries(marketplaces.data).map(([key, value]) => ({
        label: value,
        value: key,
      }))
    : [];

  return { marketplaces, isLoadingMarketplaces, marketplaceOptions };
};
