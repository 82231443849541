import { Pagination as AntPagination } from "antd";
import clsx from "clsx";

export const Pagination = ({ store, disabled = false, className = "" }) => {
  return (
    <AntPagination
      className={clsx("text-center py-2", className)}
      onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
      pageSizeOptions={[4, 8, 12, 24, 48]}
      showSizeChanger
      defaultCurrent={1}
      total={store.total}
      current={store.page}
      pageSize={store.per_page}
      onChange={store.setPage}
      disabled={disabled}
    />
  );
};
