import { Radio } from "antd";

const filterName = "original_box";

export const OriginalBoxFilter = ({ store }) => {
  return (
    <div>
      <label>Original Box</label>

      <Radio.Group
        className="w-full mt-1"
        onChange={e => {
          store.setFilter({ [filterName]: e.target.value });
        }}
        value={store.filter_by[filterName]}
      >
        <Radio value={undefined}>All</Radio>
        <Radio value={"true"}>Yes</Radio>
        <Radio value={"false"}>No</Radio>
      </Radio.Group>
    </div>
  );
};
