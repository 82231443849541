import { QueryClient } from "react-query";
import { message } from "antd";

export const globalErrorHandler = err => {
  const text =
    err.response?.data?.message || err.message || "Something went wrong :(";
  message.error(text, 4);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: globalErrorHandler,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: globalErrorHandler,
    },
  },
});
