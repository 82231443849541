import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const editDeviceType = (id, data) => {
  return apiClient.put(`api/directory/devices/${id}`, data);
};

export const useEditDevice = (id, onSuccess) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    data => editDeviceType(id, data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["device-types"]);
        await queryClient.invalidateQueries(["device-type", id]);
        onSuccess?.(res);
      },
    },
  );

  return { edit, isEditing };
};
