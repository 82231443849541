import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getRepairOptions = () => apiClient.get("api/product/repair-options");

export const useRepairOptions = ({ onSuccess, enabled = true } = {}) => {
  const { data: repairOptionsRes, isLoading: isRepairOptionsLoading } =
    useQuery(["repair-options"], getRepairOptions, {
      onSuccess: data => {
        onSuccess?.(data);
      },
      enabled: enabled,
      staleTime: Infinity,
      cacheTime: Infinity,
    });

  const repairOptions = repairOptionsRes?.data
    ? Object.entries(repairOptionsRes.data).map(([value, label]) => ({
        value,
        label,
      }))
    : [];

  return { repairOptions, isRepairOptionsLoading };
};
