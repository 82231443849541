import { Badge, Button, Dropdown, message, Modal } from "antd";
import { useRepairOptions, useRepairWithOption } from "features/devices";

const { info } = Modal;

export const DevicesRepairOptions = ({
  disabled,
  count,
  ids = [],
  clearSelection,
}) => {
  const { repairOptions } = useRepairOptions();

  const { repairWithOption, isRepairing } = useRepairWithOption(res => {
    message.success(res.message);
    info({
      title: "Repair option is updated for next devices:",
      content: ids.join(", "),
      onOk: clearSelection,
    });
  });

  const dropdownOptions =
    repairOptions.map(opt => ({
      ...opt,
      onClick: () =>
        repairWithOption({ product_ids: ids, repair_option: opt.value }),
    })) || [];

  return (
    <Badge count={count} size="small" color="red" offset={[-4, 0]}>
      <Dropdown
        trigger={["click"]}
        menu={{ items: dropdownOptions }}
        placement="bottom"
        disabled={disabled || !dropdownOptions?.length || isRepairing}
      >
        <Button
          type="primary"
          className="text-xs sm:text-sm"
          loading={isRepairing}
        >
          Repair Option
        </Button>
      </Dropdown>
    </Badge>
  );
};
