import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import { Upload, Button, App, Modal, Form, DatePicker, Input } from "antd";
import { useImportInvoice } from "features/invoices";
import { useInfiniteSuppliers } from "features/directory";
import { GroupedGradeInput } from "features/devices";
import { PaginatedSelect } from "components";
import { requiredRule } from "constants/validation";

export const ImportInvoice = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isTightly = useMediaQuery({
    query: "(max-width: 560px)",
  });

  const [file, setFile] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const {
    suppliersOptions,
    isFetchingNextPage: isFetchingNextSuppliers,
    isLoading: isLoadingSuppliers,
    fetchNextPage: fetchNextSuppliers,
  } = useInfiniteSuppliers();

  const { upload, isImporting } = useImportInvoice({
    onSuccess: res => {
      clearImport();
      const returnList = res.data?.return_list?.id;
      if (returnList) navigate(`/invoices/manage/retour/${returnList}`);
      message.success(res.message || "Invoice imported successfully!");
    },
  });

  const clearImport = () => {
    setIsFormOpen(false);
    form.resetFields();
    setFile([]);
  };

  const startImport = () => {
    Modal.confirm({
      title: "Confirmation",
      content:
        "Are you sure that the invoice contains all the information necessary for correct import?",
      onOk: () => form.submit(),
    });
  };

  const onSubmit = e => {
    const body = new FormData();
    body.append("invoice_number", e.invoice_number);
    body.append("supplier_id", e.supplier_id);
    body.append("invoice_date", dayjs(e.invoice_date.$d).format("YYYY-MM-DD"));
    body.append("file", file[0]);

    e.grades.forEach(grade => {
      body.append("grades[]", grade);
    });
    upload(body);
  };

  return (
    <>
      <Button
        className="ml-auto text-xs sm:text-sm"
        type="primary"
        onClick={() => setIsFormOpen(true)}
        loading={isImporting}
        disabled={isImporting}
      >
        {isTightly ? "+" : "New Invoice"}
      </Button>

      <Modal
        title="Import Invoice"
        maskClosable={false}
        open={isFormOpen}
        confirmLoading={isImporting}
        onCancel={() => {
          if (!isImporting) clearImport();
        }}
        onOk={startImport}
        okText="Import"
        okButtonProps={{ disabled: !file.length }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          disabled={isImporting}
        >
          <Form.Item>
            <Upload
              name="file"
              withCredentials
              fileList={file}
              customRequest={({ file }) => {
                setFile([file]);
              }}
              onRemove={() => setFile([])}
            >
              {!file.length && <Button danger>Choose File</Button>}
            </Upload>
          </Form.Item>

          <Form.Item rules={[requiredRule]} label="Supplier" name="supplier_id">
            <PaginatedSelect
              localSearch
              isLoading={isLoadingSuppliers}
              disabled={isImporting}
              placeholder="Select Supplier"
              fetchNextPage={fetchNextSuppliers}
              isFetchingNextPage={isFetchingNextSuppliers}
              onClear={() => form.setFieldValue("supplier_id", null)}
              options={suppliersOptions}
              filterOption={(searchValue, { children }) =>
                children?.toLowerCase().includes(searchValue?.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input data!",
              },
              // {
              //   pattern: /^[0-9]{4,6}$/,
              //   message: "Integers only, from 4 to 6 signs",
              // },
            ]}
            label="Invoice Number"
            name="invoice_number"
          >
            <Input
              className="w-full"
              placeholder="Enter Invoice Number"
              controls={false}
            />
          </Form.Item>

          <GroupedGradeInput
            name="grades"
            rules={[requiredRule]}
            disabled={isImporting}
            mode="multiple"
            placeholder="Select Grades"
            onClear={() => form.setFieldValue("grades", [])}
            onSelect={e => {
              let current = form.getFieldValue("grades");
              if (!current) current = [];
              form.setFieldValue("grades", [...current, e]);
            }}
            onDeselect={e => {
              const current = form.getFieldValue("grades");
              form.setFieldValue(
                "grades",
                current.filter(item => item !== e),
              );
            }}
          />

          <Form.Item
            rules={[requiredRule]}
            label="Date"
            name="invoice_date"
            initialValue={dayjs()}
          >
            <DatePicker
              placeholder="Select Date"
              disabledDate={current =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
