import { Steps as AndSteps, Skeleton } from "antd";
import { Grading, Flashing, Packing, Registering } from "features/devices";
import { useMemo } from "react";
import dayjs from "dayjs";
import { useAuthStore } from "features/auth";

export const Steps = ({ deviceData, activities, isActivitiesLoading }) => {
  const currentStep = getCurrentStep(deviceData);
  const { isRight } = useAuthStore();

  const activitiesDescription = useMemo(() => {
    if (activities) {
      return activities.reduce((accumulator, currentValue) => {
        const { date, action, user } = currentValue;

        const actor =
          user?.first_name && user?.last_name
            ? `${user?.first_name} ${user?.last_name}`
            : "System";

        accumulator[action] = {
          action: (
            <div className="flex flex-col">
              <span>{actor}</span>
              <span className="text-xs">
                {dayjs(date).format("YYYY-MM-DD HH:mm")}
              </span>
            </div>
          ),
        };

        return accumulator;
      }, {});
    }
  }, [activities]);

  return (
    <AndSteps
      className="my-6"
      current={currentStep}
      labelPlacement="vertical"
      items={[
        {
          title: <span className="mr-1">Entered</span>,
          description:
            isActivitiesLoading &&
            !activitiesDescription?.["ENTERED"]?.action ? (
              <ActivitySkeleton />
            ) : (
              activitiesDescription?.["ENTERED"]?.action
            ),
        },
        {
          title:
            currentStep === 1 ? (
              <Registering deviceData={deviceData} />
            ) : currentStep > 1 ? (
              "Identified"
            ) : (
              <span className="mr-1">Identified</span>
            ),
          description:
            currentStep > 1 &&
            isActivitiesLoading &&
            !activitiesDescription?.["IDENTIFIED"]?.action ? (
              <ActivitySkeleton />
            ) : (
              activitiesDescription?.["IDENTIFIED"]?.action
            ),
        },
        {
          title:
            currentStep === 2 ? (
              <Grading deviceData={deviceData} />
            ) : currentStep > 2 ? (
              isRight(["grader"]) ? (
                <Grading deviceData={deviceData} regrade />
              ) : (
                <span className="mr-1">Graded</span>
              )
            ) : (
              "Grading"
            ),
          description:
            currentStep > 2 &&
            isActivitiesLoading &&
            !activitiesDescription?.["GRADED"]?.action ? (
              <ActivitySkeleton />
            ) : (
              activitiesDescription?.["GRADED"]?.action
            ),
        },
        {
          title:
            currentStep === 3 ? (
              <Flashing deviceData={deviceData} />
            ) : currentStep < 3 ? (
              "Registering"
            ) : (
              <span className="mr-1">Registered</span>
            ),
          description:
            currentStep > 3 &&
            isActivitiesLoading &&
            !activitiesDescription?.["REGISTERED"]?.action ? (
              <ActivitySkeleton />
            ) : (
              activitiesDescription?.["REGISTERED"]?.action
            ),
        },
        {
          title:
            currentStep === 4 ? (
              <Packing deviceData={deviceData} />
            ) : currentStep < 4 ? (
              "Packing"
            ) : (
              <span className="mr-1">
                {deviceData.status === "RETURNED" ? "RETURNED" : "Packed"}
              </span>
            ),
          description:
            currentStep > 4 &&
            isActivitiesLoading &&
            !(
              activitiesDescription?.["READY_FOR_SALE"]?.action ||
              activitiesDescription?.["RETURNED"]?.action ||
              activitiesDescription?.["AUCTION"]?.action
            ) ? (
              <ActivitySkeleton />
            ) : (
              activitiesDescription?.["READY_FOR_SALE"]?.action ||
              activitiesDescription?.["RETURNED"]?.action ||
              activitiesDescription?.["AUCTION"]?.action
            ),
          status:
            deviceData.status === "RETURNED"
              ? "error"
              : currentStep === 5
                ? "finish"
                : currentStep === 4
                  ? "process"
                  : "wait",
        },
      ]}
    />
  );
};

const getCurrentStep = ({ step_of_process, status }) => {
  if (step_of_process === "ENTERED") return 1;

  if (
    step_of_process === "IDENTIFIED" ||
    (step_of_process === "GRADED" && status === "REPAIR") ||
    (step_of_process === "GRADE_HOLD" && status === "HOLD")
  )
    return 2;

  if (
    step_of_process === "GRADED" ||
    (step_of_process === "REGISTERED" &&
      (status === "REPAIR" || status === "HOLD"))
  )
    return 3;

  if (
    step_of_process === "REGISTERED" ||
    (step_of_process === "READY_FOR_SALE" &&
      (status === "REPAIR" || status === "HOLD"))
  ) {
    return 4;
  }

  return 5;
};

const ActivitySkeleton = () => (
  <div className="flex flex-col justify-center">
    <Skeleton.Button active style={{ height: 10 }} />
    <Skeleton.Input active size="small" style={{ height: 10 }} />
  </div>
);
