import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useLogsSelection } from "features/logs";
import { useDebounce } from "use-debounce";

const getLogs = ({
  start_date = null,
  end_date = null,
  search_term = null,
  per_page = 12,
  page = 1,
  filter_by = null,
}) =>
  apiClient.get("api/logs", {
    params: {
      per_page,
      page,
      search_term,
      filter_by,
      start_date,
      end_date,
    },
  });

export const useLogs = ({ onSuccess } = {}) => {
  const {
    page,
    setPage,
    pageSize,
    setTotal,
    searchQuery,
    filter_by,
    start_date,
    end_date,
  } = useLogsSelection();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: logs,
    isLoading,
    isFetching,
  } = useQuery(
    ["logs", page, pageSize, debouncedQuery, filter_by, start_date, end_date],
    () =>
      getLogs({
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        filter_by: filter_by || null,
        start_date: start_date || null,
        end_date: end_date || null,
      }),
    {
      onSuccess: res => {
        const { per_page, current_page } = res.meta;
        const { next } = res.links;

        if (next) {
          setTotal(per_page * current_page + 1);
        } else if (!res.data.length) {
          setPage(1);
          setTotal(0);
        }

        onSuccess?.(res);
      },
    },
  );

  return { logs, isLoading, isFetching };
};
