import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { BarsOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Pagination, Tag, Tooltip, Tabs } from "antd";

import { Loader } from "components";
import {
  useDeviceHistory,
  useDeviceHistoryStore,
  useDeviceAccessoryHistory,
  useDeviceAccessoryHistoryStore,
} from "features/devices";

export const DeviceLogs = ({ disabled, deviceId }) => {
  const deviceStore = useDeviceHistoryStore();
  const accessoryStore = useDeviceAccessoryHistoryStore();

  const [isOpen, setIsOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const store = [deviceStore, accessoryStore][activeTab];

  const { history, isLoading: isDHistoryLoading } = useDeviceHistory({
    id: deviceId,
  });
  const { history: aHistory, isLoading: isAHistoryLoading } =
    useDeviceAccessoryHistory({ id: deviceId });

  useEffect(() => {
    if (isOpen && !store.called) store.allowQueries();
  }, [activeTab, isOpen]);

  const isLoading = [isDHistoryLoading, isAHistoryLoading][activeTab];

  return (
    <>
      <Tooltip title="history">
        <Button
          size="small"
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          <BarsOutlined />
        </Button>
      </Tooltip>

      <Modal
        title={
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-base font-semibold m-0">History</h3>
            <Link
              className="flex items-center gap-1 text-sm font-medium mr-3 mt-3"
              to={`/logs?search_term=id:${deviceId}`}
              target="_blank"
            >
              See Logs <ArrowRightOutlined />
            </Link>
          </div>
        }
        open={isOpen}
        footer={
          <Pagination
            className="text-center py-2 mt-auto"
            onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
            showSizeChanger={false}
            defaultCurrent={1}
            total={store.total}
            current={store.page}
            pageSize={12}
            onChange={page => {
              store.setPage(page);
            }}
            disabled={isLoading}
          />
        }
        onCancel={() => setIsOpen(false)}
        width={700}
      >
        <Tabs
          defaultActiveKey={0}
          activeKey={activeTab}
          items={[
            {
              key: 0,
              label: "Device",
              children: <HistoryList data={history?.data} />,
            },
            {
              key: 1,
              label: "Accessories",
              children: <HistoryList data={aHistory?.data} />,
            },
          ]}
          onChange={setActiveTab}
        />

        {isLoading && <Loader />}
      </Modal>
    </>
  );
};

const HistoryList = ({ data = [] }) => {
  return (
    <div
      className="overflow-y-auto pr-3 min-h-[360px]"
      style={{ maxHeight: "calc(100vh - 360px)" }}
    >
      {data.map(item => (
        <Alert
          key={item?.date}
          className="my-2"
          type="info"
          message={
            <>
              <Tag
                className="inline-block font-semibold text-xs"
                color="#609bff"
              >
                {item.action?.replaceAll("_", " ")}
              </Tag>

              <div>
                <span className="font-semibold mr-2 text-blue-500">
                  {typeof item?.user === "string"
                    ? item?.user
                    : `${item?.user?.first_name} ${item?.user?.last_name}`}
                </span>

                <span className="text-blue-500  text-sm">
                  {dayjs(item.date).format("YYYY-MM-DD H:mm")}
                </span>
              </div>
            </>
          }
          description={
            <>
              <div>{item.comment}</div>
              {item.accessory && (
                <span className="flex gap-2">
                  ({item.accessory.sku})<strong>{item.accessory.name}</strong>
                </span>
              )}
            </>
          }
        />
      ))}
    </div>
  );
};
