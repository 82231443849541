import { Link } from "react-router-dom";

const SKULink = ({
  skuId,
  skuNumber,
  gradeId,
  gradeName,
  gradeSkuDisplay,
  originalBox = null,
  disabled = false,
}) => {
  const { label, url } = getLinkData({
    skuId,
    skuNumber,
    gradeId,
    gradeName,
    gradeSkuDisplay,
    originalBox,
  });

  if (disabled) return <span className="whitespace-nowrap">{label}</span>;

  return (
    <Link className="whitespace-nowrap" to={url} target="_blank">
      {label}
    </Link>
  );
};

const getLinkData = ({
  skuId,
  skuNumber,
  gradeId,
  gradeName,
  gradeSkuDisplay,
  originalBox = null,
}) => {
  const gradeLabel = gradeSkuDisplay ? gradeName + "-" : "";
  const nv = gradeId === 1 && !originalBox ? "-NV" : "";
  const label = gradeLabel + skuNumber + nv;

  const url = `/sales/sku/${skuId}/${gradeId}${getNVParam(originalBox)}`;

  return { label, url };
};

const getNVParam = originalBox => {
  const isTrue =
    originalBox === true || originalBox === 1 || originalBox === "1";
  const isFalse =
    originalBox === false || originalBox === 0 || originalBox === "0";

  return isTrue ? "?original_box=1" : isFalse ? "?original_box=0" : "";
};

export const SKU = { Link: SKULink, getLinkData };
