import { Button, message } from "antd";
import { useDeviceExport } from "features/devices";

export const DevicesExport = ({ disabled }) => {
  const { download, isDownloading } = useDeviceExport({
    onSuccess: res => {
      message.success({
        content:
          res.message ||
          "Export in progress. The result will be sent to your email.",
        duration: 5,
        onClick: () => message.destroy(),
      });
    },
  });

  return (
    <Button
      disabled={disabled}
      type="primary"
      onClick={download}
      loading={isDownloading}
      className="text-xs sm:text-sm"
    >
      Export
    </Button>
  );
};
