import {
  useProductSKUs,
  useSKUsWithoutSaleStore,
  ProductsToolbar,
  QUERY_NAMES,
  ProductsTable,
  ProfitStats,
  ProfitabilityPage,
} from "features/profitability";

export const ProductsWithoutSaleBySku = () => {
  const store = useSKUsWithoutSaleStore();

  const {
    data: skus,
    isFetching,
    isLoading,
  } = useProductSKUs({
    store,
    groupName: "without-sale",
    queryName: QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE,
  });

  const isProcessing = !skus || isLoading || isFetching;

  return (
    <ProfitabilityPage title="Products without sale by SKU">
      <ProfitStats data={skus} keys={["total_count"]} />

      <ProductsToolbar
        store={store}
        disabled={isProcessing}
        isLoading={isProcessing}
        groupName="without-sale"
        allowExport={!!skus?.data.length}
        isSKU
      />

      <ProductsTable
        isSKUTable
        isLoading={isProcessing}
        queryName={QUERY_NAMES.PRODUCTS.SKUS.WITHOUT_SALE}
        data={skus?.data}
        idKey="item_id"
        store={store}
      />
    </ProfitabilityPage>
  );
};
