import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const addSupplier = data => apiClient.post("api/directory/suppliers", data);

export const useAddSupplier = onSuccess => {
  const { mutate: add, isLoading: isAdding } = useMutation(
    data => addSupplier(data),
    {
      onSuccess: dep => {
        queryClient.invalidateQueries(["suppliers"]);
        queryClient.setQueryData(["suppliers", dep.data.id], dep);
        onSuccess?.(dep);
      },
    },
  );

  return { add, isAdding };
};
