import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const commentDevice = (id, data) => {
  return apiClient.post(`api/product/comment/${id}`, data);
};

export const useDeviceComment = ({ id, onSuccess } = {}) => {
  const { mutate: toComment, isLoading: isCommenting } = useMutation(
    data => commentDevice(id, data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        await queryClient.invalidateQueries(["device", id]);
        queryClient.invalidateQueries(["activities", id]);
        queryClient.invalidateQueries(["device-history", id]);
        onSuccess?.(data);
      },
    },
  );

  return { toComment, isCommenting };
};
