import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getMarkets = ({ sku_id, grade_id, original_box = null }) =>
  apiClient.get("api/sku/marketplaces", {
    params: { sku_id, grade_id, original_box },
  });

export const useSkuMarketplaces = ({
  onSuccess,
  grade_id,
  sku_id,
  original_box = null,
} = {}) => {
  const {
    data: marketplaces,
    isLoading: isLoadingMarketplaces,
    isFetching: isFetchingMarketplaces,
  } = useQuery(
    ["sku-marketplaces", sku_id, grade_id, original_box],
    () => getMarkets({ sku_id, grade_id, original_box }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );
  return { marketplaces, isLoadingMarketplaces, isFetchingMarketplaces };
};
