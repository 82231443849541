import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const createSKU = data => apiClient.post("api/directory/skus", data);

export const useCreateSKU = onSuccess => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => createSKU(data),
    {
      onSuccess: async ({ data }) => {
        await queryClient.invalidateQueries(["skus"]);
        onSuccess?.(data);
      },
    },
  );

  return { create, isCreating };
};
