import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useSkuDisconnectedProductsStore } from "../store";

const getDisconnected = ({
  sku_id,
  grade_id,
  per_page = 12,
  page = 1,
  original_box = null,
}) =>
  apiClient.get(`api/sku-page/average/disconnected/${sku_id}/${grade_id}`, {
    params: { per_page, page, original_box },
  });

export const useSkuDisconnectedProducts = ({
  onSuccess,
  grade_id,
  sku_id,
  original_box = null,
} = {}) => {
  const { page, pageSize, setPage, setTotal } =
    useSkuDisconnectedProductsStore();
  const {
    data: disconnectedProducts,
    isLoading: isLoadingDisconnected,
    isFetching: isFetchingDisconnected,
  } = useQuery(
    [
      "sku-disconnected-products",
      sku_id,
      grade_id,
      original_box,
      page,
      pageSize,
    ],
    () =>
      getDisconnected({
        sku_id,
        grade_id,
        page,
        per_page: pageSize,
        original_box,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );
  return {
    disconnectedProducts,
    isLoadingDisconnected,
    isFetchingDisconnected,
  };
};
