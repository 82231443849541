import {
  DeviceConstructor,
  useCreateDevice,
  useFormConstructor,
} from "features/directory";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "features/auth";

export const CreateDevice = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const navigate = useNavigate();
  const { create, isCreating } = useCreateDevice(() => {
    navigate(`/directory/devices`);
    message.success("Success!");
  });

  const { validate, fields } = useFormConstructor();

  const buildRequest = () => {
    const fieldsCopy = JSON.parse(JSON.stringify(fields));

    fieldsCopy.forEach(field => {
      delete field.error;
      delete field.readOnly;

      field.type = field.type.toUpperCase();

      if (field.options?.length === 0) {
        delete field.options;
      } else if (!!field.options) {
        field.options = field.options
          .map(opt => ({ value: opt.value }))
          .filter(opt => opt !== "");
      }
    });

    return { name: fieldsCopy[0].name, fields: fieldsCopy.slice(1) };
  };

  return (
    <div className="h-full flex flex-col">
      <h2 className="text-center">Create New Device Type</h2>

      <div className="overflow-y-auto flex-2">
        <div className="max-w-md my-12 mx-auto flex flex-col">
          <DeviceConstructor disabled={isCreating} />

          {rightRole && (
            <Button
              className="w-1/2 mx-auto"
              type="primary"
              loading={isCreating}
              onClick={() => {
                const isValid = validate();
                if (isValid) create(buildRequest());
              }}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
