import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { useOnSaleSkuStore } from "../store";

const sync = ({ search_term, filter_by }) => {
  return apiClient.post("api/product/sync-price-by-sku", {
    search_term,
    filter_by,
  });
};

export const useSyncWithEbay = ({ onSuccess } = {}) => {
  const { searchQuery, filter_by } = useOnSaleSkuStore();

  const getFilterQuery = () => {
    const result = { ...filter_by };
    if ("color" in result) delete result.color;
    return result;
  };

  const { mutate: syncEbay, isLoading: isSyncing } = useMutation(
    () =>
      sync({
        search_term: searchQuery || null,
        filter_by: getFilterQuery(),
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { syncEbay, isSyncing };
};
