import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Avatar, Tooltip } from "antd";
import { formatRole } from "utils/formatRole";
import { styles } from "features/employees";
import { StopOutlined } from "@ant-design/icons";
import TruncateMarkup from "react-truncate-markup";

export const EmployeeCard = ({ data }) => {
  const {
    id,
    first_name,
    last_name,
    email,
    phone_number,
    roles,
    departments,
    dismissed,
  } = data;
  const navigate = useNavigate();

  return (
    <Card bordered={false}>
      <header className="flex justify-between items-center mb-6">
        <h3 className="m-0 text-lg font-bold">
          {first_name + " " + last_name}
        </h3>
        <span className="text-gray-600">
          ID: <b>{id}</b>
        </span>
      </header>

      <div className="flex justify-between items-start w-full gap-4">
        <div className="flex justify-center flex-1">
          <Avatar
            style={{
              backgroundColor: dismissed ? "#ccc" : "#87d068",
            }}
            icon={dismissed ? <StopOutlined /> : null}
            size={80}
          >
            <span className="text-xl font-semibold">
              {first_name[0] + last_name[0]}
            </span>
          </Avatar>
        </div>

        <div
          className="flex flex-col justify-between h-full gap-1"
          style={{ flex: 3 }}
        >
          <div>
            <span>Phone: </span>
            <strong className="whitespace-nowrap text-gray-700">
              {phone_number}
            </strong>
          </div>

          <TruncateMarkup
            lines={1}
            ellipsis={() => {
              return (
                <Tooltip title={email}>
                  <span className="text-xs cursor-default  text-gray-400">
                    {" ..."}
                  </span>
                </Tooltip>
              );
            }}
          >
            <div>
              <span>Email: </span>
              <strong className="text-gray-700">{email}</strong>
            </div>
          </TruncateMarkup>

          <TruncateMarkup
            lines={1}
            ellipsis={node => {
              const displayedDepartments = node.props.children[1];
              const displayed =
                displayedDepartments?.props?.children?.split(",")?.length;
              const diff = departments.length - displayed;

              return (
                <Tooltip
                  title={departments
                    .map(dep => formatRole(dep.name))
                    .join(", ")}
                >
                  <span className="text-xs cursor-default  text-gray-400">
                    {diff ? ` ...(+${diff})` : " ..."}
                  </span>
                </Tooltip>
              );
            }}
          >
            <div>
              <span>Department: </span>
              <strong className="text-gray-700">
                {departments.map(dep => formatRole(dep.name)).join(", ")}
              </strong>
            </div>
          </TruncateMarkup>

          <TruncateMarkup
            lines={1}
            ellipsis={node => {
              const displayedRoles = node.props.children[1];
              const displayed =
                displayedRoles?.props?.children?.split(",")?.length;
              const diff = roles.length - displayed;

              return (
                <Tooltip
                  title={roles?.map(role => formatRole(role)).join(", ")}
                >
                  <span className="text-xs cursor-default text-gray-400">{` ...(+${diff})`}</span>
                </Tooltip>
              );
            }}
          >
            <div>
              <span>Position: </span>
              <strong className="text-gray-700">
                {roles.map(role => formatRole(role)).join(", ")}
              </strong>
            </div>
          </TruncateMarkup>
        </div>
      </div>

      <Button
        className={styles.cardbtn}
        type="primary"
        onClick={() => navigate(`/employees/${id}`)}
      >
        View Profile
      </Button>

      <div
        className={styles.clickbox}
        onClick={() => navigate(`/employees/${id}`)}
      />
    </Card>
  );
};
