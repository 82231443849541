import { Select, Spin } from "antd";
import { useSendingStatuses } from "features/sending";

export const SendingStatusesFilter = ({ store }) => {
  const { statuses, isLoadingStatuses } = useSendingStatuses();

  return (
    <div>
      <label>Status</label>
      <Select
        className="w-full mt-1"
        placeholder="Select Status"
        notFoundContent={isLoadingStatuses ? <Spin size="small" /> : null}
        value={store.filter_by?.status}
        onChange={e => store.setFilter({ status: e })}
        allowClear
        options={
          statuses
            ? Object.entries(statuses?.data)?.map(([key, value]) => ({
                label: value,
                value: key,
              }))
            : []
        }
      />
    </div>
  );
};
