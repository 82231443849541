import { Tag } from "antd";

export const promotionsColumns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
    width: 120,
  },
  {
    title: "Name",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "Amount",
    dataIndex: "price",
    width: 120,
    render: amount => `€ ${amount}`,
  },
  {
    title: "Devices",
    align: "center",
    width: 120,
    dataIndex: "sku_count",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: 150,
    align: "center",
    render: type =>
      type ? (
        <span>
          <span className="text-green-400 mr-1">▲</span>
          Increase
        </span>
      ) : (
        <span>
          <span className="text-red-300 mr-1">▼</span>
          Decrease
        </span>
      ),
  },
  {
    title: "Status",
    width: 100,
    dataIndex: "active",
    align: "center",
    render: active =>
      active ? (
        <Tag color="cyan">Active</Tag>
      ) : (
        <Tag color="magenta">Inactive</Tag>
      ),
  },
];
