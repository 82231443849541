import axios from "axios";
import { useAuthStore } from "features/auth";

axios.defaults.withCredentials = true;

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});

apiClient.interceptors.request.use(
  config => {
    const token = useAuthStore.getState().getToken();
    if (!token) return config;

    config.headers["x-access-token"] = token;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const onError = err => {
  const status = err?.response?.status;
  if (status === 401 || status === 419) {
    useAuthStore.getState().clear();
  } else {
    return Promise.reject(err);
  }
};

apiClient.interceptors.response.use(res => res.data, onError);
