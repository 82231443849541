import { Modal, Pagination, Table } from "antd";
import {
  useSkuDisconnectedProducts,
  useSkuDisconnectedProductsStore,
} from "features/sales";
import { disconnectedProductColumns } from "./productsTableConfig";
import { useEffect } from "react";

export const DisconnectedProducts = ({
  onClose,
  sku_id,
  grade_id,
  original_box,
}) => {
  const store = useSkuDisconnectedProductsStore();
  const {
    disconnectedProducts,
    isLoadingDisconnected,
    isFetchingDisconnected,
  } = useSkuDisconnectedProducts({
    sku_id,
    grade_id,
    original_box,
  });

  useEffect(() => {
    return () => store.setPage(1);
  }, []);

  return (
    <Modal
      open={true}
      onCancel={onClose}
      title={<div className={"text-center mb-4"}>Disconnected Products</div>}
      footer={
        <Pagination
          className="text-center  mt-auto"
          onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
          pageSizeOptions={[4, 8, 12, 24, 48]}
          showSizeChanger
          defaultCurrent={1}
          total={store.total}
          current={store.page}
          pageSize={store.pageSize}
          simple
          onChange={store.setPage}
          disabled={isLoadingDisconnected || isFetchingDisconnected}
        />
      }
    >
      <Table
        loading={isLoadingDisconnected || isFetchingDisconnected}
        columns={disconnectedProductColumns}
        dataSource={disconnectedProducts?.data}
        pagination={false}
        rowKey={record => record.product_id}
      />
    </Modal>
  );
};
