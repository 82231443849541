import { useEffect, useState } from "react";
import { Table } from "antd";
import { useSearchParams } from "react-router-dom";
import {
  useSKUPairList,
  useSKUPairListStore,
  CreatingNewSKUPair,
  EditSKUPair,
} from "features/sales";
import { BooleanBadge, Pagination, SearchInput } from "components";

export const SKUMonitoring = () => {
  const store = useSKUPairListStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const [editing, setEditing] = useState(null);

  const { pairs, isLoading, isFetching } = useSKUPairList();

  useEffect(() => {
    if (searchParams.has("pair")) {
      store?.setSearchTerm(searchParams.get("pair"));
      searchParams.delete("pair");
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams]);

  return (
    <div className="page-container flex flex-col relative">
      <h2 className="text-center mb-6 sm:mb-10">SKU Monitoring</h2>

      <div className="flex justify-between mb-3 gap-2">
        <SearchInput
          searchQuery={store?.search_term}
          setSearchQuery={store?.setSearchTerm}
          disabled={false}
        />
        <CreatingNewSKUPair />
      </div>

      {editing && (
        <EditSKUPair
          pairId={editing.id}
          pairData={editing.pairData}
          close={() => setEditing(null)}
        />
      )}

      <Table
        className="overflow-y-auto"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={pairs?.data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        onRow={({ id, ...pairData }) => ({
          onClick: () => setEditing({ id, pairData }),
        })}
        scroll={{ x: 500 }}
      />

      <Pagination
        store={store}
        disabled={isLoading || !pairs?.data}
        className="mt-auto"
      />
    </div>
  );
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 140,
  },
  {
    title: "SKU 1",
    dataIndex: "sku_1",
    key: "sku_1",
    align: "center",
  },
  {
    title: "SKU 2",
    dataIndex: "sku_2",
    key: "sku_2",
    align: "center",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    align: "center",
    width: 110,
    render: original_box => <BooleanBadge value={original_box} />,
  },
];
