import { Table, Pagination, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useDashboardStats,
  useDashboardProducts,
  useDashboardStore,
  DashboardSelector,
} from "features/dashboard";
import { FireFilled } from "@ant-design/icons";

export const Dashboard = () => {
  const navigate = useNavigate();
  const store = useDashboardStore();
  const { setPageSize, setPage, total, page, pageSize } = store;

  const { stats } = useDashboardStats();
  const { devices, isLoading, isFetching } = useDashboardProducts();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Dashboard</h2>

      <div className="flex flex-col max-w-5xl w-full mx-auto px-4 mb-3 items-end gap-2 sm:flex-row">
        <ul className="m-0 mr-auto p-0 pl-1 list-none [&_li]:font-bold [&_span]:font-normal [&_span]:mr-2  [&_span]:text-gray-600">
          <li>
            <span>Total Quantity of Devices:</span>
            {stats?.data.total_quantity_of_devices}
          </li>
          <li>
            <span>Quantity of Prior Devices:</span>
            {stats?.data.quantity_of_prior_devices}
          </li>
        </ul>

        <DashboardSelector onChangeRole={store.setRole} value={store.role} />
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={devices?.data}
        sticky={true}
        rowKey={record => record.product_id}
        pagination={false}
        onRow={record => ({
          onClick: () => navigate(`/devices/${record.product_id}`),
        })}
        scroll={{ x: 400 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};

const columns = [
  {
    title: "ID",
    dataIndex: "product_id",
    key: "product_id",
    width: 120,
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",

    align: "center",
    render: priority =>
      priority ? <FireFilled className="text-red-400" /> : null,
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    key: "device_type",

    render: (text, record) => <span>{record.device_type?.name}</span>,
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",

    render: (text, { color, field_values }) => {
      const model =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Model",
        )?.device_field.device_field_option[0]?.value || "";

      const memory =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      return (
        <Tooltip
          placement="topLeft"
          title={`${model} ${memory} ${color?.name || ""}`}
        >{`${model} ${memory} ${color?.name || ""}`}</Tooltip>
      );
    },
  },
];
