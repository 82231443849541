export const STATISTICS_TABS = [
  {
    name: "Registration",
    role: ["registrar"],
    tabs: [
      { name: "Invoices", status: "ENTERED" },
      { name: "Generated QR Codes", status: "ENTERED" },
      { name: "Identified Devices", status: "IDENTIFIED" },
    ],
  },
  { name: "Grading", status: "GRADED", role: ["grader"] },
  { name: "Flashing", status: "REGISTERED", role: ["flasher"] },
  { name: "Packing", status: "READY_FOR_SALE", role: ["packer"] },
  { name: "Auction", status: "AUCTION", role: ["packer"] },
  { name: "Selling", status: "ON_SALE", role: ["sales_manager"] },
  { name: "Sending", status: "SENT", role: ["sender"] },

  {
    name: "Hold",
    status: "HOLD",
    role: ["flasher", "packer", "admin", "grader"],
  },
  {
    name: "Repair",
    status: "REPAIR",
    role: ["grader", "flasher", "packer", "admin"],
  },
];
