import { Form, Table, InputNumber, Input } from "antd";
import { DeviceSelect } from "features/devices";

export const RetourTable = ({ data = [], loading = false, form, disabled }) => {
  const onChangeDevice = (deviceId, deviceData, id) => {
    const price = deviceData?.data?.invoice_product?.single_price;
    form.setFieldValue(
      ["products", id, "price"],
      price ? String(price).replace(",", ".") : 0,
    );
    form.validateFields();
  };

  return (
    <Form form={form} className="overflow-y-auto" disabled={disabled}>
      <Table
        loading={loading}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: id => {
              return (
                <Form.Item
                  initialValue={id}
                  name={["products", id, "id"]}
                  hidden
                >
                  <Input value={id} />
                </Form.Item>
              );
            },
            width: 0,
          },
          {
            title: "SKU",
            dataIndex: "article",
            key: "article",
            width: 140,
          },
          {
            title: "Model",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "IMEI",
            dataIndex: "imei",
            key: "imei",
            align: "center",
            width: 170,
          },
          {
            title: "€ Price",
            dataIndex: "price",
            key: "price",
            align: "center",
            width: 120,
            render: (price, item) => (
              <Form.Item
                className="m-0"
                initialValue={price}
                name={["products", item.id, "price"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value === price) return Promise.resolve();
                      return Promise.reject();
                    },
                    warningOnly: true,
                  },
                ]}
              >
                <InputNumber min={0} step={0.01} />
              </Form.Item>
            ),
          },
          {
            title: "Product",
            dataIndex: "product",
            key: "product",
            align: "center",
            render: (_, { id, product }) => (
              <DeviceSelect
                initialData={product}
                onChange={(value, data) => onChangeDevice(value, data, id)}
                disabled={disabled}
                className="m-0"
                onClear={() => {
                  form.setFieldValue(["products", id, "product_id"], null);
                  form.validateFields();
                }}
                name={["products", id, "product_id"]}
                filter_by={{ status: ["SENT", "MARKET_RETURN"] }}
                rules={[
                  {
                    validator: (_, value) => {
                      const initialValue = product ? product?.product_id : null;
                      if (value === initialValue) return Promise.resolve();
                      return Promise.reject();
                    },
                    warningOnly: true,
                  },
                ]}
              />
            ),
          },
        ]}
        dataSource={data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        scroll={{ x: 900 }}
      />
    </Form>
  );
};
