import { useEffect } from "react";
import { useAuthStore } from "../store";
import { useNavigate } from "react-router-dom";
import { pusher } from "lib/socket-client";

export const useScannerChannel = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const channel = pusher.subscribe(`qr-scanned-channel-${user?.data?.id}`);
    channel.bind("qr-scanned-event", data => {
      navigate(data.productUrl);
    });

    return () => {
      pusher.unsubscribe(`qr-scanned-channel-${user?.data?.id}`);
    };
  }, []);
};
