import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { usePromotionsByTypeStore } from "features/promotions";

const getPromotions = ({ type_id, per_page = 12, page = 1 }) =>
  apiClient.get(`api/marketplaces/promotion/device-type/${type_id}`, {
    params: { per_page, page },
  });

export const usePromotionsByDeviceType = type_id => {
  const { page, pageSize, setPage, setTotal } = usePromotionsByTypeStore();

  const { data: promotions, isLoading } = useQuery(
    ["promotions-by-type", type_id, page, pageSize],
    () =>
      getPromotions({
        page: page,
        per_page: pageSize,
        type_id,
      }),
    {
      onSuccess: sending => {
        const { last_page, total } = sending.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
      },
    },
  );
  return { promotions, isLoading };
};
