import _ from "lodash";

const listType = [{ label: "Label", value: "Value" }];

export const FilterSummary = ({ list = listType }) => {
  if (!list?.length) return null;

  return (
    <div className="px-1">
      {list.map(({ label, value }, i) => (
        <p key={`${label}-${i}`}>
          <span className="text-gray-400 mx-1">{_.capitalize(label)}:</span>
          <b>{_.toLower(value)}</b>
        </p>
      ))}
    </div>
  );
};
