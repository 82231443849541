import { PaginatedSelect } from "components";
import { Form } from "antd";
import {
  useInfAccessoriesStore,
  useInfiniteAccessories,
} from "features/directory";
import { requiredRule } from "constants/validation";

export const AccessoryInput = ({
  className = "",
  disabled,
  onClear,
  onChange,
  initialData,
  placeholder = "Select accessory category",
  name = "accessory_categories",
  label = "Categories",
}) => {
  const { search_term, setSearchTerm } = useInfAccessoriesStore();
  const { accessoryOptions, isFetchingNextPage, isLoading, fetchNextPage } =
    useInfiniteAccessories();

  const noInitialOption =
    initialData &&
    !accessoryOptions?.some(({ value }) => value === initialData.id);

  return (
    <Form.Item
      className={className}
      required
      name={name}
      label={label}
      labelCol={{ span: 5 }}
      rules={[requiredRule]}
    >
      <PaginatedSelect
        disabled={disabled}
        searchValue={search_term}
        isLoading={isLoading}
        placeholder={placeholder}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchTerm}
        onClear={onClear}
        onChange={id => onChange?.(id)}
        options={
          noInitialOption
            ? [
                { value: initialData.id, label: initialData.name },
                ...accessoryOptions,
              ]
            : accessoryOptions
        }
      />
    </Form.Item>
  );
};
