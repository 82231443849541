import { message, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export const CopyToClipboard = ({ text }) => (
  <Button
    type="text"
    size="small"
    shape="circle"
    onClick={async () => {
      try {
        await navigator.clipboard.writeText(text);
        message.success("Copied!");
      } catch (e) {
        message.error("Failure! You probably need to set permissions.");
      }
    }}
  >
    <CopyOutlined className="text-gray-400" />
  </Button>
);
