import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const useFormulasStore = create(
  devtools(
    immer((set, get) => ({
      page: 1,
      pageSize: 12,
      total: 0,
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
    })),
  ),
);
