import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useDepartmentsPagination } from "features/directory";

const getDepartments = ({ per_page = 12, page = 1 }) =>
  apiClient.get(`api/directory/departments`, { params: { per_page, page } });

export const useDepartments = onSuccess => {
  const { page, pageSize, setPage, setTotal } = useDepartmentsPagination();

  const {
    data: departments,
    isLoading,
    isFetching,
  } = useQuery(
    ["departments", page, pageSize],
    () =>
      getDepartments({
        page: page,
        per_page: pageSize,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
    },
  );

  return { departments, isLoading, isFetching };
};
