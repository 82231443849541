import { Checkbox } from "antd";
import { queryClient } from "lib/react-query-client";
import { usePromotionSKUsStore } from "../store";

export const PromotionSKUSelector = ({ disabled, id }) => {
  const { page, pageSize, searchQuery, selection, setSelection } =
    usePromotionSKUsStore();

  const currentPageIds = queryClient
    .getQueryData(["promotion-skus", id, page, pageSize, searchQuery])
    ?.data?.map(({ id }) => id);

  const isSelectedAll =
    !!currentPageIds?.length &&
    currentPageIds?.every(i => selection.includes(i));
  const isSelectedSome = currentPageIds?.some(i => selection.includes(i));

  return (
    <Checkbox
      disabled={disabled}
      checked={isSelectedAll}
      indeterminate={isSelectedSome && !isSelectedAll}
      onChange={({ target }) => {
        setSelection(currentPageIds, target.checked);
      }}
    />
  );
};
