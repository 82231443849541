import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import _ from "lodash";

export const useComListsStore = create(
  devtools(
    immer((set, get) => ({
      filter_by: {},
      start_date: null,
      end_date: null,
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (!value) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clear: () => {
        set(state => {
          state.start_date = null;
          state.end_date = null;
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useAccessoryComListsStore = create(
  devtools(
    immer((set, get) => ({
      filter_by: {},
      start_date: null,
      end_date: null,
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (!value) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clear: () => {
        set(state => {
          state.start_date = null;
          state.end_date = null;
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useReadyListSelection = create(
  devtools(
    immer((set, get) => ({
      selection: [],
      filter_by: {},
      start_date: null,
      end_date: null,
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSelection: (id, checked) =>
        set(state => {
          if (checked) state.selection = _.uniq(state.selection.concat(id));
          else
            _.isArray(id)
              ? _.pullAll(state.selection, id)
              : _.pull(state.selection, id);
        }),
      isSelected: id => get().selection.some(existing => existing === id),
      clearSelection: () =>
        set(state => {
          state.selection = [];
        }),
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (!value) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clear: () => {
        set(state => {
          state.start_date = null;
          state.end_date = null;
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useAccessoryReadyListSelection = create(
  devtools(
    immer((set, get) => ({
      selection: [],
      filter_by: {},
      start_date: null,
      end_date: null,
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSelection: (id, checked) =>
        set(state => {
          if (checked) state.selection = _.uniq(state.selection.concat(id));
          else
            _.isArray(id)
              ? _.pullAll(state.selection, id)
              : _.pull(state.selection, id);
        }),
      isSelected: id => get().selection.some(existing => existing === id),
      clearSelection: () =>
        set(state => {
          state.selection = [];
        }),
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (!value) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clear: () => {
        set(state => {
          state.start_date = null;
          state.end_date = null;
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useComListStore = create(
  devtools(
    immer((set, get) => ({
      selection: [],
      filter_by: {},
      start_date: null,
      end_date: null,
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSelection: selection =>
        set(state => {
          state.selection = selection;
        }),
      isSelected: id => get().selection.some(existing => existing === id),
      clearSelection: () =>
        set(state => {
          state.selection = [];
        }),
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (value === null || value === undefined) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clear: () => {
        set(state => {
          state.start_date = null;
          state.end_date = null;
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useAccessoryComListStore = create(
  devtools(
    immer((set, get) => ({
      selection: [],
      filter_by: {},
      start_date: null,
      end_date: null,
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSelection: (id, checked) =>
        set(state => {
          if (checked) state.selection = _.uniq(state.selection.concat(id));
          else
            _.isArray(id)
              ? _.pullAll(state.selection, id)
              : _.pull(state.selection, id);
        }),
      isSelected: id => get().selection.some(existing => existing === id),
      clearSelection: () =>
        set(state => {
          state.selection = [];
        }),
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setEndDate: end_date => set({ end_date }),
      setStartDate: start_date => set({ start_date }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          if (value === null || value === undefined) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      clear: () => {
        set(state => {
          state.start_date = null;
          state.end_date = null;
          state.filter_by = {};
        });
      },
    })),
  ),
);
