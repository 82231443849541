import { Alert, Tag } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useMemo } from "react";

export const Comments = ({ activities }) => {
  const comments = useMemo(
    () =>
      activities?.filter(({ comment }) => Boolean(comment))?.reverse() || [],
    [activities],
  );

  if (!comments.length) return null;

  return (
    <div className="lg:mx-10 mx-0 md:mt-20 mt-2">
      <h3 className="mb-4 gap-1 flex justify-center text-blue-500">
        <CommentOutlined />
        Comments
      </h3>

      {comments.map(item => (
        <Alert
          key={item?.date}
          className="my-2"
          type="info"
          message={
            <>
              <Tag
                className="inline-block font-semibold text-xs"
                color="#609bff"
              >
                {item.action?.replaceAll("_", " ")}
              </Tag>

              <div>
                <span className="font-semibold mr-2 text-blue-500">
                  {item?.user?.first_name && item?.user?.last_name
                    ? `${item?.user?.first_name} ${item?.user?.last_name}`
                    : "System"}
                </span>

                <span className="text-blue-500  text-sm">
                  {dayjs(item.date).format("YYYY-MM-DD H:mm")}
                </span>
              </div>
            </>
          }
          description={item.comment}
        />
      ))}
    </div>
  );
};
