import { Select } from "antd";
import { useInfiniteDeviceTypes } from "features/directory";
import { PaginatedSelect } from "components";
import _ from "lodash";
import { useMemo } from "react";

export const DeviceTypeFilter = ({
  gap = 0,
  store,
  fixedType = false,
  availableTypes,
}) => {
  const { setFilter, filter_by } = store;

  const onChangeDynamicField = (value, options) => {
    const isEmptyState = !filter_by.option_ids?.length;
    let draft = _.cloneDeep(filter_by.option_ids) || [];

    if (isEmptyState && !value) {
      draft = null;
    } else if (isEmptyState && value) {
      draft = [value];
    } else if (!isEmptyState) {
      const oldValue = _.intersection(
        options.map(o => o.value),
        filter_by.option_ids,
      );
      _.pullAll(draft, oldValue);

      if (value) {
        draft.push(value);
      } else {
        if (!draft.length) draft = null;
      }
    }

    setFilter({ option_ids: draft });
  };

  const { devices, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteDeviceTypes();

  const deviceTypeOptions = useMemo(() => {
    if (!devices) return [];

    const available = availableTypes
      ? devices.filter(({ id }) => availableTypes.some(type => type.id === id))
      : devices;

    return available.map(({ id, name, fields }) => {
      const result = { label: name, value: id, fields: {} };

      fields.forEach(({ name, type, options }) => {
        if (type === "SELECT" || type === "MULTISELECT") {
          result.fields[name] = options.map(({ id, value }) => ({
            label: value,
            value: id,
          }));
        }
      });

      return result;
    });
  }, [availableTypes, devices]);

  const currentDeviceType = deviceTypeOptions?.find(
    ({ value }) => value === filter_by.device_type_id,
  );

  return (
    <div className={`flex flex-col gap-${gap}`}>
      {!fixedType && (
        <div>
          <label>Device Type</label>
          <PaginatedSelect
            className="w-full mt-1"
            localSearch
            value={filter_by?.device_type_id}
            isLoading={isLoading}
            placeholder="Select Device Type"
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onClear={() => {
              setFilter({ model_id: null });
              setFilter({ option_ids: null });
              setFilter({ device_type_id: null });
            }}
            options={deviceTypeOptions}
            filterOption={(searchValue, { children }) =>
              children?.toLowerCase().includes(searchValue?.toLowerCase())
            }
            onChange={e => {
              setFilter({ model_id: null });
              setFilter({ option_ids: null });
              setFilter({ device_type_id: e });
            }}
          />
        </div>
      )}

      {!_.isEmpty(currentDeviceType?.fields) &&
        Object.entries(currentDeviceType.fields)?.map(([name, options]) => {
          const value = _.intersection(
            options.map(o => o.value),
            filter_by.option_ids,
          )?.[0];

          return (
            <div key={name}>
              <label>{name}</label>
              <Select
                className="w-full mt-1"
                name={name}
                placeholder={`Select ${name}`}
                options={options}
                value={value}
                onChange={id => onChangeDynamicField(id, options)}
                allowClear
                showArrow
                showSearch
                filterOption={(searchValue, { label }) =>
                  label?.toLowerCase().includes(searchValue?.toLowerCase())
                }
              />
            </div>
          );
        })}
    </div>
  );
};
