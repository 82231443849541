import { PaginatedSelect } from "components";
import { useInfiniteInvoices } from "features/invoices";

export const InvoicesFilter = ({ store, name = "invoice_id" }) => {
  const {
    invoicesOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteInvoices();

  return (
    <div>
      <label>Invoice</label>

      <PaginatedSelect
        value={store.filter_by[name]}
        className="w-full mt-1"
        searchValue={searchQuery}
        isLoading={isLoading}
        placeholder="Select Invoice"
        onChange={value => store.setFilter({ [name]: value })}
        onClear={() => store.setFilter({ [name]: null })}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        options={invoicesOptions}
      />
    </div>
  );
};
