import { Select, Empty, Spin } from "antd";
import { useState } from "react";

const handler = () => console.error("PaginatedSelect: need handler");

export const PaginatedSelect = ({
  options = [],
  fetchNextPage = handler,
  isLoading = false,
  isFetchingNextPage = false,
  onSearch = handler,
  onClear = handler,
  className = "",
  onChange = handler,
  placeholder = "",
  searchValue = null,
  localSearch = false,
  disabled = false,
  ...rest
}) => {
  const [localSearchValue, setLocalSearchValue] = useState("");
  const onScroll = ({ target }) => {
    if (isLoading || isFetchingNextPage) return;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      fetchNextPage();
    }
  };

  return (
    <Select
      disabled={disabled}
      searchValue={localSearch ? localSearchValue : searchValue}
      className={className}
      filterOption={
        localSearch
          ? (searchValue, { children }) => searchValue === children
          : () => true
      }
      placeholder={placeholder}
      allowClear
      showSearch
      onPopupScroll={onScroll}
      onSearch={localSearch ? setLocalSearchValue : onSearch}
      onClear={
        localSearch
          ? () => {
              setLocalSearchValue("");
              onClear();
            }
          : onClear
      }
      notFoundContent={
        isLoading ? (
          <div className="text-center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onSelect={val => {
        if (isFetchingNextPage) return;
        onChange(val);
      }}
      getPopupContainer={triggerNode => triggerNode.parentElement}
      {...rest}
    >
      {options.map(el => (
        <Select.Option key={el.value} value={el.value}>
          {el.label}
        </Select.Option>
      ))}

      {isFetchingNextPage && (
        <Select.Option disabled value={"should not be null"}>
          <div className="text-center">
            <Spin size="small" />
          </div>
        </Select.Option>
      )}
    </Select>
  );
};
