import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const changeStatus = ({ product_ids, status }) => {
  return apiClient.post("api/product/change-status", {
    product_ids,
    status,
  });
};

export const useRedirectDevice = ({ onSuccess } = {}) => {
  const { mutate: redirect, isLoading: isRedirecting } = useMutation(
    ({ product_ids, status }) => changeStatus({ product_ids, status }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["sending-ready-list"]);
        onSuccess?.(data);
      },
    },
  );

  return { redirect, isRedirecting };
};
