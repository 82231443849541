import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const generateQRCode = data => apiClient.post("api/product/register", data);

export const useGenerateQRCode = onSuccess => {
  const { mutate: generate, isLoading: isGenerating } = useMutation(
    data => generateQRCode(data),
    {
      onSuccess: devices => {
        queryClient.invalidateQueries(["invoiceProducts"]);
        onSuccess?.(devices);
      },
    },
  );

  return { generate, isGenerating };
};
