import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getStatuses = () => apiClient.get(`api/product/statuses`);

export const useStatusOptions = ({ onSuccess } = {}) => {
  const { data: statuses, isLoading: isLoadingStatuses } = useQuery(
    ["statuses"],
    getStatuses,
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { statuses, isLoadingStatuses };
};
