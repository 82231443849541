import { useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Checkbox,
  message,
  Divider,
  Input,
  Tooltip,
  Radio,
  Spin,
} from "antd";
import { EditFilled, ExclamationCircleFilled } from "@ant-design/icons";
import {
  ColorShadeInput,
  DeviceTypeInput,
  DeviceDynamicInput,
  GradeInput,
  StatusInput,
  SkuInput,
  InvoiceProductInput,
  useEditDevice,
  useRemoveDeviceFromSale,
  Device,
  useOnKeySubmit,
  useRepairOptions,
} from "features/devices";
import { InvoiceInput } from "features/invoices";
import {
  useAccessoryType,
  useInfiniteDeviceTypes,
  useInfiniteGrades,
} from "features/directory";
import { requiredRule } from "constants/validation";

const { confirm } = Modal;
const { TextArea } = Input;

export const EditDevice = ({ initialDeviceData, disabled, deviceId }) => {
  const [form] = Form.useForm();
  const { isOpen, setIsOpen } = useOnKeySubmit(form.submit);

  const onClose = () => {
    if (!isEditing) {
      setIsOpen(false);
      form.resetFields();
    }
  };

  const onOpen = () => {
    if (initialDeviceData?.status === "ON_SALE") {
      confirm({
        title: "Device is On Sale now!",
        icon: <ExclamationCircleFilled />,
        content:
          "To make changes, you first need to remove the device from sale on marketplaces. " +
          "Do you want to do this to continue?",
        onOk: remove,
      });
    } else {
      setIsOpen(true);
    }
  };

  const { edit, isEditing } = useEditDevice({
    id: deviceId,
    onSuccess: response => {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      setIsOpen(false);
      form.resetFields();
    },
  });

  const { remove, isRemoving } = useRemoveDeviceFromSale({
    id: deviceId,
    onSuccess: response => {
      message.success({
        content: response.message || "Success!",
        style: { left: 0, position: "absolute" },
      });
      setIsOpen(true);
    },
  });

  const { accessory } = useAccessoryType();
  const { deviceTypesFields } = useInfiniteDeviceTypes();
  const { additionalOptions } = useInfiniteGrades();

  const selectedColor = Form.useWatch("color", form);
  const selectedDeviceType = Form.useWatch("device_type_id", form);
  const selectedGrade = Form.useWatch("grade_id", form);
  const selectedInvoice = Form.useWatch("invoice_id", form);
  const selectedStatus = Form.useWatch("status", form);
  const isSelectedAccessoryGradeA =
    selectedDeviceType === accessory?.data?.id && Number(selectedGrade) === 1;

  const { repairOptions, isRepairOptionsLoading } = useRepairOptions({
    enabled: selectedStatus === "REPAIR",
  });

  const onChangeSku = skuData => Device.fillSkuFields(skuData, form);
  const onClearGrade = () => form.resetFields(["grade_id"]);
  const onClearInvoice = () =>
    form.resetFields(["invoice_id", "invoice_product_id"]);
  const onClearProduct = () => form.resetFields(["invoice_product_id"]);
  const onClearSku = () => {
    form.resetFields([
      "sku_id",
      "color_shade_id",
      "color",
      "device_type_id",
      "field_values",
    ]);
  };

  const onFinish = data => {
    const body = Device.buildDeviceObj(
      data,
      deviceTypesFields[selectedDeviceType],
    );

    if (!data.sku_id) {
      Modal.confirm({
        content: "Are you sure you want to leave the SKU empty?",
        onOk: () => edit(body),
      });
    } else {
      edit(body);
    }
  };

  useEffect(() => {
    if (
      initialDeviceData &&
      form &&
      isOpen &&
      initialDeviceData.status !== "ON_SALE"
    )
      Device.fillDeviceForm({ deviceData: initialDeviceData, form });
  }, [initialDeviceData, form, isOpen]);

  const showComment = ["SENT", "HOLD", "RETURNED", "REPAIR"].some(
    status => selectedStatus === status,
  );

  const showRepairOptions = selectedStatus === "REPAIR";

  return (
    <>
      <Tooltip title="edit">
        <Button
          size="small"
          onClick={onOpen}
          disabled={!initialDeviceData || disabled || isEditing || isRemoving}
        >
          <EditFilled />
        </Button>
      </Tooltip>

      <Modal
        width={860}
        centered
        wrapClassName="h-screen relative"
        style={{ top: 0, maxHeight: "100%" }}
        title={`Edit Device #${deviceId}`}
        open={isOpen}
        onOk={form.submit}
        maskClosable={false}
        onCancel={onClose}
        modalRender={modal => (
          <div className="max-h-screen pb-0 pt-6">{modal}</div>
        )}
        confirmLoading={isEditing}
      >
        <div className="overflow-y-auto pr-3" style={{ maxHeight: "76vh" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            disabled={isEditing}
          >
            <InvoiceInput
              onClear={onClearInvoice}
              disabled={isEditing}
              initialData={initialDeviceData?.invoice}
            />
            <InvoiceProductInput
              disabled={isEditing}
              currentInvoice={selectedInvoice}
              onClear={onClearProduct}
              initialData={initialDeviceData?.invoice_product}
            />

            <Divider />

            <StatusInput
              excluded={["ON_SALE"]}
              disabledOptions={["READY_FOR_SENDING", "IN_COMMISSION_LIST"]}
            />

            {showRepairOptions && (
              <Form.Item name="repair_option" label="Repair option">
                {isRepairOptionsLoading ? (
                  <Spin size="small" className="ml-4" />
                ) : (
                  <Radio.Group>
                    {repairOptions.map(({ value, label }) => (
                      <Radio key={value} value={value}>
                        {label}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            )}

            {showComment && (
              <Form.Item label="Comment" name="comment" rules={[requiredRule]}>
                <TextArea placeholder="Enter Comment" />
              </Form.Item>
            )}

            <div className="flex gap-4">
              <Form.Item
                initialValue={false}
                name="priority"
                valuePropName="checked"
              >
                <Checkbox>Priority</Checkbox>
              </Form.Item>

              <Form.Item
                initialValue={false}
                name="soft_update"
                valuePropName="checked"
              >
                <Checkbox>Soft Update</Checkbox>
              </Form.Item>
            </div>

            <Divider />

            <GradeInput
              onClear={onClearGrade}
              disabled={isEditing}
              initialData={initialDeviceData?.grade}
            />

            {additionalOptions &&
              additionalOptions[selectedGrade]?.map(field => {
                return (
                  <DeviceDynamicInput
                    field={field}
                    key={field.name}
                    groupName="additional_options"
                    disabled={
                      field.name === "Original Box" && isSelectedAccessoryGradeA
                    }
                  />
                );
              })}

            <Divider />

            <SkuInput
              onClear={onClearSku}
              onChange={onChangeSku}
              disabled={isEditing}
              initialData={
                form.isFieldTouched("sku_id") ? null : initialDeviceData?.sku
              }
            />

            <ColorShadeInput
              disabled
              currentColor={selectedColor}
              initialData={initialDeviceData?.color}
            />

            <DeviceTypeInput
              disabled
              initialData={initialDeviceData?.device_type}
            />

            {deviceTypesFields &&
              deviceTypesFields[selectedDeviceType]?.map(field => (
                <DeviceDynamicInput
                  disabled={
                    (field?.name !== "IMEI" && field?.name !== "Serial") ||
                    isEditing
                  }
                  field={field}
                  key={field.id}
                  groupName="field_values"
                />
              ))}
          </Form>
        </div>
      </Modal>
    </>
  );
};
