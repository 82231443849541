import { Select, Spin } from "antd";
import { useMarketplaceNames } from "features/marketplaces";

export const MarketplacesFilter = ({
  store,
  name = "marketplace",
  withB2B = false,
}) => {
  const { isLoadingMarketplaces, marketplaceOptions } = useMarketplaceNames();
  const { setFilter, filter_by } = store;

  const options = withB2B
    ? marketplaceOptions.concat({ label: "B2B", value: "B2B" })
    : marketplaceOptions;

  return (
    <div>
      <label>Marketplace</label>

      <Select
        className="w-full mt-1"
        placeholder="Select Marketplace"
        allowClear
        notFoundContent={
          isLoadingMarketplaces ? (
            <div className="text-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        value={filter_by?.[name]}
        onChange={e => setFilter({ [name]: e })}
        options={options}
      />
    </div>
  );
};
