import { Input, Switch } from "antd";
import { SelectType } from "./TypeSelection";
import { RemoveBtn } from "./RemoveBtn";
import { Options } from "./Options";

export const FormItem = ({
  fieldIndex = null,
  readOnly = false,
  readOnlyTotal = false,
  textArea = false,
  placeholder = "Enter Parameter Name",
  isStatic = false,
  type = "text",
  showType = true,
  disabled = false,
  nameValue = "",
  error = false,
  showOptionMenu = false,
  showCategories = false,
  onChangeName = value => console.log(value),
  onChangeType = value => console.log(value),
  onChangeOption = (i, value, key) => console.log(i, value, key),
  onRemoveOption = key => console.log(key),
  onAddNewOption = () => {},
  optional = false,
  turnedOn = false,
  onToggle = null,
  onRemove = () => {},
}) => {
  const isSelect =
    type?.toLocaleLowerCase() === "select" ||
    type?.toLocaleLowerCase()?.replaceAll("-", "") === "multiselect";

  return (
    <div
      className="relative pt-3  border border-solid rounded-md border-slate-200 bg-white "
      style={{
        paddingBottom: isSelect ? 0 : 12,
        borderColor: error ? "red" : "#ccc",
        opacity: optional && !turnedOn ? 0.4 : 1,
      }}
    >
      {error && (
        <span className="absolute -top-0.5 text-red-500  text-xs">
          No complete
        </span>
      )}

      {textArea ? (
        <div className="mx-4">
          <Input.TextArea
            readOnly={readOnly}
            autoSize
            disabled={disabled}
            autoFocus
            placeholder={placeholder}
            value={nameValue}
            onChange={({ target }) => onChangeName(target.value)}
          />
        </div>
      ) : (
        <div className="mx-4">
          <Input
            classNames={{
              input: `font-semibold text-base ${
                readOnly
                  ? "hover:border-slate-300 hover:cursor-default text-gray-500"
                  : ""
              }`,
            }}
            readOnly={readOnly}
            disabled={disabled}
            autoFocus
            placeholder={placeholder}
            value={nameValue}
            onFocus={e => readOnly && e.target.blur()}
            onChange={({ target }) => onChangeName(target.value)}
            addonAfter={
              showType && (
                <SelectType
                  disabled={disabled || isStatic || readOnlyTotal}
                  value={type}
                  onChange={value => onChangeType(value)}
                />
              )
            }
          />
        </div>
      )}

      {!isStatic && !readOnlyTotal && (
        <RemoveBtn
          disabled={disabled}
          className="absolute text-xs -top-1 -right-6 [&_span]:text-slate-300 hover:[&_span]:text-red-700"
          onClick={onRemove}
        />
      )}

      {!!onToggle && (
        <Switch
          disabled={readOnlyTotal}
          size="small"
          className="absolute top-5 -right-7"
          checked={turnedOn}
          style={{ transform: "rotate(270deg)" }}
          onChange={value => onToggle(value)}
        />
      )}

      {isSelect && (
        <Options
          showOptionMenu={showOptionMenu}
          showCategories={showCategories}
          fieldIndex={fieldIndex}
          readOnly={readOnlyTotal}
          disabled={disabled}
          onRemoveOption={onRemoveOption}
          onChangeOption={onChangeOption}
          onAddNewOption={onAddNewOption}
        />
      )}
    </div>
  );
};
