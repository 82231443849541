import { FireFilled, FireOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { queryClient } from "lib/react-query-client";
import { Link } from "react-router-dom";
import { SKU } from "components";

export const devicesTable = [
  {
    title: (
      <Tooltip title="Priority">
        <FireOutlined />
      </Tooltip>
    ),
    dataIndex: "priority",
    key: "priority",
    width: 40,
    align: "center",
    render: priority =>
      priority ? <FireFilled className="text-red-400" /> : null,
  },
  {
    title: "ID",
    dataIndex: "product_id",
    key: "product_id",
    width: 100,
    align: "center",
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    key: "device_type",
    render: (text, record) => <span>{record.device_type?.name}</span>,
    align: "center",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
    align: "center",
    render: (text, { color, field_values, product_id }) => {
      const model =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Model",
        )?.device_field.device_field_option[0]?.value || "";

      const memory =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Memory",
        )?.device_field.device_field_option[0]?.value || "";

      let title = `${model} ${memory} ${color?.name || ""}`;
      if (!(model || memory || color?.name)) title = "Unknown";

      return (
        <div className="flex-1" onClick={e => e.stopPropagation()}>
          <Tooltip placement="topLeft" title={title}>
            <Link className="font-medium" to={`/devices/${product_id}`}>
              {title}
            </Link>
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    align: "center",
    width: 140,
    render: (sku, r) => {
      if (!sku) return null;

      const { label, url } = SKU.getLinkData({
        skuId: sku.sku_id,
        skuNumber: sku.sku_number,
        gradeId: r.grade?.id,
        gradeName: r.grade?.name,
        gradeSkuDisplay: r.grade?.sku_display,
        originalBox: r.original_box,
      });

      return (
        <Link target="_blank" to={url}>
          {label}
        </Link>
      );
    },
  },
  {
    title: "IMEI",
    dataIndex: "imei",
    key: "imei",
    align: "center",
    render: (text, { field_values }) => {
      const imei =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "IMEI",
        )?.device_field.device_field_value || "";

      return (
        <Tooltip placement="topLeft" title={imei}>
          {imei}
        </Tooltip>
      );
    },
  },
  {
    title: "Serial Nr",
    dataIndex: "Serial",
    key: "serial",
    align: "center",
    render: (text, { field_values }) => {
      const serial =
        Object.values(field_values).find(
          field => field.device_field.device_field_name === "Serial",
        )?.device_field.device_field_value || "";

      return (
        <Tooltip placement="topLeft" title={serial}>
          {serial}
        </Tooltip>
      );
    },
  },
  {
    title: "Supplier",
    render: (_, { invoice }) => {
      const supplierName = invoice.supplier.supplier_name || "";
      const firstSupplier = invoice.first_supplier
        ? ` (${invoice.first_supplier})`
        : "";
      return supplierName + firstSupplier;
    },
    width: 140,
    align: "center",
  },
  {
    title: "Grade",
    dataIndex: "grade",
    key: "grade",
    align: "center",
    render: (text, record) => <span>{record.grade?.name}</span>,
    width: 80,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
    render: status => {
      const statusLabels = queryClient.getQueryData(["statuses"])?.data;

      return (
        <span className="whitespace-nowrap">
          {statusLabels ? statusLabels[status] : status}
        </span>
      );
    },
    width: 140,
  },
];
