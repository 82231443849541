import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const addDepartment = data => apiClient.post("api/directory/departments", data);

export const useAddDepartment = onSuccess => {
  const { mutate: add, isLoading: isAdding } = useMutation(
    data => addDepartment(data),
    {
      onSuccess: dep => {
        queryClient.invalidateQueries(["departments"]);
        queryClient.setQueryData(["department", dep.data.id], dep);
        onSuccess?.(dep);
      },
    },
  );

  return { add, isAdding };
};
