import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useState } from "react";

const getAveragePrice = ({ sku_id, grade_id, original_box = null }) =>
  apiClient.get(`api/sku-page/average/${sku_id}/${grade_id}`, {
    params: { original_box },
  });

export const useSkuAveragePrice = ({
  onSuccess,
  grade_id,
  sku_id,
  original_box = null,
} = {}) => {
  const [allowed, setAllowed] = useState(false);
  const allow = () => setAllowed(true);
  const {
    data: priceData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    ["sku-average-price", sku_id, grade_id, original_box],
    () => getAveragePrice({ sku_id, grade_id, original_box }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      enabled: allowed,
    },
  );

  const getPrice = allowed ? refetch : allow;
  return { priceData, isLoading, isFetching, getPrice };
};
