import {
  useProductDevices,
  useDevicesWithoutSaleStore,
  QUERY_NAMES,
  ProductsTable,
  ProfitStats,
  ProductsToolbar,
  ProfitabilityPage,
} from "features/profitability";

export const ProductsWithoutSale = () => {
  const store = useDevicesWithoutSaleStore();

  const {
    data: devices,
    isFetching,
    isLoading,
  } = useProductDevices({
    store,
    groupName: "without-sale",
    queryName: QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE,
  });

  const isProcessing = !devices || isLoading || isFetching;

  return (
    <ProfitabilityPage title="Products without sale by Devices">
      <ProfitStats data={devices} keys={["total_count"]} />

      <ProductsToolbar
        store={store}
        disabled={isProcessing}
        isLoading={isProcessing}
        groupName="without-sale"
        allowExport={!!devices?.data.length}
      />

      <ProductsTable
        isLoading={isProcessing}
        queryName={QUERY_NAMES.PRODUCTS.DEVICES.WITHOUT_SALE}
        data={devices?.data}
        idKey="product_id"
        store={store}
      />
    </ProfitabilityPage>
  );
};
