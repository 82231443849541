import { App, Button, Modal, Pagination, Table } from "antd";
import {
  promotionsColumns,
  useAddPromoToSKU,
  usePromotionsByDeviceType,
  usePromotionsByTypeStore,
} from "features/promotions";
import { useEffect } from "react";

export const PromoModal = ({ onCancel, deviceType, marketId }) => {
  const { modal, message } = App.useApp();
  const store = usePromotionsByTypeStore();
  const { promotions, isLoading } = usePromotionsByDeviceType(deviceType);

  const { addPromo, isAddingPromo } = useAddPromoToSKU({
    marketPlaceId: marketId,
    onSuccess: response => {
      message.success(response?.message);
      onCancel();
    },
  });

  useEffect(() => {
    store.reset();
    return () => {
      store.reset();
    };
  }, []);

  const onSelect = (promoId, promoName) => {
    modal.confirm({
      title: <div>Promotion "{promoName}"</div>,
      content: "Do you want to add this SKU to the chosen promotion?",
      onOk() {
        addPromo(promoId);
      },
    });
  };

  return (
    <Modal
      title="Promotions"
      open={true}
      onCancel={onCancel}
      width={1200}
      footer={
        <div className="flex pt-4">
          <Pagination
            className="text-center flex-1"
            onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
            pageSizeOptions={[4, 8, 12, 24, 48]}
            showSizeChanger
            defaultCurrent={1}
            total={store.total}
            current={store.page}
            pageSize={store.pageSize}
            onChange={store.setPage}
            disabled={isLoading || isAddingPromo}
          />
          <div>
            <Button disabled={isAddingPromo} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      }
    >
      <Table
        className="overflow-y-auto w-full mt-4"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isAddingPromo}
        columns={promotionsColumns}
        dataSource={promotions?.data}
        sticky={true}
        rowKey={record => `promotion_${record.id}`}
        pagination={false}
        onRow={({ id, title }) => ({
          onClick: () => {
            onSelect(id, title);
          },
        })}
        scroll={{ x: 900 }}
      />
    </Modal>
  );
};
