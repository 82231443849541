import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

export const useCreateExpression = ({ onSuccess, formulaId } = {}) => {
  const url = `api/profit/create/${formulaId}/expression`;

  const { mutate: createExpression, isLoading: isCreating } = useMutation(
    data => apiClient.post(url, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(["marketplaces-formula-data", formulaId]);
      },
    },
  );

  return { createExpression, isCreating };
};
