import { useInfiniteQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useState } from "react";

const infinite_getUsersByRole = async ({ pageParam, role_id }) => {
  const result = await apiClient.get(pageParam, {
    params: { role_id },
    data: null,
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useRoleEmployees = ({ onSuccess } = {}) => {
  const [id, setRoleId] = useState(null);

  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-employees-by-role", id],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/users/by_role?per_page=12&page=1`,
      }) =>
        infinite_getUsersByRole({
          pageParam,
          role_id: id,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
        enabled: id > 0,
      },
    );

  const roleUsers = data?.pages?.map(({ data }) => data)?.flat();

  return {
    setRoleId,
    roleUsers,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  };
};
