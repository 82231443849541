import { Checkbox } from "antd";
import { queryClient } from "lib/react-query-client";
import { useAvailableSKUsStore } from "../store";

export const AvailableSKUSelector = ({ disabled, id }) => {
  const {
    page,
    pageSize,
    searchQuery,
    selection,
    setSelection,
    getFilterQuery,
  } = useAvailableSKUsStore();

  const currentPageIds = queryClient
    .getQueryData([
      "available-promotion-skus",
      id,
      page,
      pageSize,
      searchQuery,
      getFilterQuery(),
    ])
    ?.data?.map(({ id }) => id);

  const isSelectedAll =
    !!currentPageIds?.length &&
    currentPageIds?.every(i => selection.includes(i));

  const isSelectedSome = currentPageIds?.some(i => selection.includes(i));

  return (
    <Checkbox
      disabled={disabled}
      checked={isSelectedAll}
      indeterminate={isSelectedSome && !isSelectedAll}
      onChange={({ target }) => {
        setSelection(currentPageIds, target.checked);
      }}
    />
  );
};
