import { Select, Spin } from "antd";
import { useLogTypes } from "features/logs";

export const LogTypeFilter = ({ store }) => {
  const { logTypes, isLoadingLogTypes } = useLogTypes();
  const { setFilter, filter_by } = store;

  return (
    <div>
      <label>Event Type</label>
      <Select
        className="w-full mt-1"
        placeholder="Select Event Type"
        allowClear
        showArrow
        showSearch
        notFoundContent={
          isLoadingLogTypes ? (
            <div className="text-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        value={filter_by?.type}
        onChange={e => setFilter({ type: e })}
        filterOption={(searchValue, { label }) =>
          label?.toLowerCase().includes(searchValue?.toLowerCase())
        }
        options={
          logTypes?.data &&
          Object.entries(logTypes?.data)?.map(([value, label]) => {
            return {
              value: value?.replaceAll("\\", "\\\\"),
              label,
            };
          })
        }
      />
    </div>
  );
};
