import {
  AvailableSKUSelector,
  useAvailableSKUsStore,
} from "features/promotions";
import { Checkbox, Tooltip } from "antd";
import { SKULink } from "features/sales";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const getAvailableSKUsColumns = promoId => {
  const { sort_by_sku, sort_by_price } =
    useAvailableSKUsStore.getState().sorting;

  return [
    {
      key: "checkboxes",
      dataIndex: "id",
      width: 40,
      title: <AvailableSKUSelector id={promoId} />,
      render: id => {
        return (
          <Checkbox
            checked={useAvailableSKUsStore.getState().isSelected(id)}
            onChange={({ target }) => {
              useAvailableSKUsStore.getState().setSelection(id, target.checked);
            }}
          />
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "Id",
      width: 120,
      align: "center",
    },
    {
      title: "SKU",
      key: "sort_by_sku",
      width: 160,
      align: "center",
      render: (_, item) => {
        const emulatedDevice = {
          grade: {
            id: item.grade.id,
            name: item.grade.name,
            sku_display: item.grade.sku_display,
          },
          original_box: item.original_box,
          sku: { sku_id: item.sku.sku_id, sku_number: item.sku.sku_number },
        };

        return <SKULink device={emulatedDevice} />;
      },
      sorter: true,
      sortOrder: sort_by_sku,
      showSorterTooltip: false,
    },
    {
      title: "Device",
      key: "device",
      dataIndex: "device",
      align: "center",
      render: (text, { sku }) => {
        const model =
          Object.values(sku.field_values).find(
            field => field.device_field.device_field_name === "Model",
          )?.device_field.device_field_option[0]?.value || "";

        const memory =
          Object.values(sku.field_values).find(
            field => field.device_field.device_field_name === "Memory",
          )?.device_field.device_field_option[0]?.value || "";

        const color = sku.color_shade?.name || "";

        const result = `${model} ${memory} ${color}`;

        return (
          <Tooltip placement="topLeft" title={result}>
            {result}
          </Tooltip>
        );
      },
    },
    {
      title: "Original Box",
      key: "original_box",
      width: 120,
      align: "center",
      render: (_, { original_box }) =>
        original_box ? (
          <CheckCircleOutlined className="text-green-500" />
        ) : null,
    },
    {
      title: "Price",
      key: "sort_by_price",
      dataIndex: "price",
      width: 120,
      align: "center",
      render: item => `€ ${item}`,
      sorter: true,
      sortOrder: sort_by_price,
      showSorterTooltip: false,
    },
    { title: "Qty", dataIndex: "quantity", width: 100, align: "center" },
    {
      title: "Afterbuy",
      dataIndex: "afterbuy",
      key: "afterbuy",
      width: 160,
      align: "center",
      render: (afterbuy, { target_link }) =>
        afterbuy &&
        target_link && (
          <Link to={target_link} target="_blank">
            {afterbuy}
          </Link>
        ),
    },
  ];
};
