import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const editDepartment = (id, data) =>
  apiClient.put(`api/directory/departments/${id}`, data);

export const useEditDepartment = (id, onSuccess) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    data => editDepartment(id, data),
    {
      onSuccess: async dep => {
        await queryClient.invalidateQueries(["departments"]);
        await queryClient.invalidateQueries(["department", id]);
        onSuccess?.(dep);
      },
    },
  );

  return { edit, isEditing };
};
