import { useMemo } from "react";
import { Button, Dropdown, Modal } from "antd";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useAddMarketplace, useMarketplaceNames } from "features/marketplaces";
const { confirm } = Modal;

export const AddMarketplace = ({ sku }) => {
  const { marketplaces } = useMarketplaceNames();

  const { addMarketplace, isAdding } = useAddMarketplace();

  const showConfirm = marketplace => {
    const name = marketplaces?.data[marketplace];
    confirm({
      title: "Adding Marketplace",
      icon: <ExclamationCircleFilled />,
      content: (
        <p className="my-2">
          Are you sure you want to add
          <strong className="ml-1">{name}</strong>?
        </p>
      ),
      onOk: async () => {
        const queryData = { ...sku };
        queryData.marketplace = marketplace;
        if (!queryData.original_box) delete queryData.original_box;

        await addMarketplace(queryData);
      },
    });
  };

  const items = useMemo(() => {
    if (marketplaces?.data) {
      return Object.entries(marketplaces.data).map(([key, value]) => ({
        key,
        label: value,
      }));
    }

    return [];
  }, [marketplaces]);

  return (
    <>
      <Dropdown
        disabled={!marketplaces?.data || isAdding}
        trigger={["click"]}
        menu={{ items, onClick: item => showConfirm(item.key) }}
      >
        <Button icon={<PlusOutlined />}>Add Marketplace</Button>
      </Dropdown>
    </>
  );
};
