import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const show = ({ sku_id, grade_id, original_box }) => {
  return apiClient.post("api/sku/marketplaces/available-formula", {
    sku_id,
    grade_id,
    original_box,
  });
};

export const useApplyingFormulas = ({
  onSuccess,
  grade_id,
  sku_id,
  original_box = null,
} = {}) => {
  const {
    data: availableFormulas,
    isLoading,
    isFetching,
  } = useQuery(
    ["available-formulas", sku_id, grade_id, original_box],
    () => show({ sku_id, grade_id, original_box }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
    },
  );

  return { availableFormulas, isLoading, isFetching };
};
