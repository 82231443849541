import { useState } from "react";
import { Button, Drawer } from "antd";
import { FilterFilled, CloseCircleFilled } from "@ant-design/icons";

export const FilterDrawer = ({
  title = "Title",
  onClear = () => {},
  children,
  tooltip,
  activeCount = 0,
  btnStyle = "default",
  disabled,
}) => {
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);

  const open = () => setFiltersIsOpen(true);
  const close = () => setFiltersIsOpen(false);

  return (
    <>
      <Button
        className="text-xs sm:text-sm"
        type={btnStyle}
        danger={!!activeCount}
        onClick={open}
        disabled={disabled}
      >
        <FilterFilled />

        {`Filter ${!!activeCount ? `(${activeCount})` : ""}`}

        {!!activeCount && (
          <CloseCircleFilled
            className="hover:text-red-600"
            onClick={e => {
              e.stopPropagation();
              onClear();
            }}
          />
        )}
      </Button>

      <Drawer
        bodyStyle={{ padding: 0 }}
        title={
          <div className="flex justify-between items-center">
            {title}
            {!!activeCount && (
              <Button type="link" size="small" onClick={onClear}>
                Clear All
              </Button>
            )}
          </div>
        }
        placement="right"
        onClose={close}
        open={filtersIsOpen}
      >
        {children}
      </Drawer>
    </>
  );
};
