import { Button, message, App } from "antd";
import { useSyncWithEbay } from "../api/sync-ebay";

export const SyncEbay = () => {
  const { modal } = App.useApp();
  const { syncEbay, isSyncing } = useSyncWithEbay({
    onSuccess: () => {
      message.success({
        content:
          "Sync Price in progress. The result will be sent to your email.",
        duration: 5,
        onClick: () => message.destroy(),
      });
    },
  });

  const onClick = () => {
    modal.confirm({
      title: "Sync Price",
      content: "Do you really want to run this procedure?",
      onOk: () => syncEbay(),
    });
  };

  return (
    <>
      <Button
        onClick={onClick}
        loading={isSyncing}
        className="text-xs sm:text-sm"
      >
        Sync with eBay
      </Button>
    </>
  );
};
