import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useReadyListSelection } from "features/sending";
import { useDebounce } from "use-debounce";

const getReadyList = ({
  search_term = null,
  per_page = 12,
  page = 1,
  start_date,
  end_date,
  filter_by,
}) =>
  apiClient.get("api/sending/product-list", {
    params: {
      per_page,
      page,
      search_term,
      filter_by: {
        ...filter_by,
        status: "READY_FOR_SENDING",
      },
      start_date,
      end_date,
    },
  });

export const useSendingReadyList = ({ onSuccess, onError } = {}) => {
  const {
    page,
    pageSize,
    setPage,
    setTotal,
    searchQuery,
    filter_by,
    start_date,
    end_date,
  } = useReadyListSelection();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: readyDevices,
    isLoading: isLoadingReadyDevices,
    isFetching: isFetchingReadyDevices,
  } = useQuery(
    [
      "sending-ready-list",
      page,
      pageSize,
      debouncedQuery,
      filter_by,
      start_date,
      end_date,
    ],
    () =>
      getReadyList({
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        filter_by,
        start_date,
        end_date,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { readyDevices, isLoadingReadyDevices, isFetchingReadyDevices };
};
