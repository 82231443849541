import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const RemoveBtn = ({ className, onClick, disabled = false }) => {
  return (
    <Button
      className={className}
      disabled={disabled}
      shape="circle"
      size="small"
      type="ghost"
      onClick={onClick}
    >
      <CloseOutlined className="text-red-900" />
    </Button>
  );
};
