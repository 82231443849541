import { globalErrorHandler } from "lib/react-query-client";
import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { useAvailableSKUsStore } from "features/promotions";
import { useState } from "react";

const getSKUs = ({
  promoId,
  search_term = null,
  per_page = 12,
  page = 1,
  filter_by = null,
}) =>
  apiClient.get(`api/marketplaces/promotion/${promoId}/adding-sku-listing`, {
    params: {
      per_page,
      page,
      search_term,
      filter_by,
    },
  });

export const useAvailableSKUs = ({ promoId, onSuccess, onError } = {}) => {
  const [allowed, setAllowed] = useState(false);
  const allowAvailableSKUs = () => setAllowed(true);

  const { page, pageSize, setPage, setTotal, searchQuery, getFilterQuery } =
    useAvailableSKUsStore();

  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: availableSkus,
    isLoading,
    isFetching,
  } = useQuery(
    [
      "available-promotion-skus",
      promoId,
      page,
      pageSize,
      debouncedQuery,
      getFilterQuery(),
    ],
    () =>
      getSKUs({
        promoId,
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        filter_by: getFilterQuery(),
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      enabled: allowed,
      // retry: false,
    },
  );

  return { availableSkus, isLoading, isFetching, allowAvailableSKUs };
};
