import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const attach = (id, product_id) =>
  apiClient.post(
    `api/sku/marketplaces/sales-issues/attach/${id}/${product_id}`,
    {},
  );

export const useAttachIssueProduct = ({ onSuccess } = {}) => {
  const { mutate: attachProduct, isLoading: isAttaching } = useMutation(
    ({ issueID, productID }) => attach(issueID, productID),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sales-issues");
      },
    },
  );

  return { attachProduct, isAttaching };
};
