import { PaginatedSelect } from "components";
import { useInfiniteSuppliers } from "features/directory";

export const SuppliersFilter = ({ store, name = "supplier_id" }) => {
  const {
    suppliersOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteSuppliers();

  return (
    <div>
      <label>Supplier</label>

      <PaginatedSelect
        className="w-full mt-1"
        value={store.filter_by[name]}
        placeholder="Select Supplier"
        searchValue={searchQuery}
        isLoading={isLoading}
        onChange={value => store.setFilter({ [name]: value })}
        onSearch={setSearchQuery}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onClear={() => store.setFilter({ [name]: null })}
        options={suppliersOptions}
      />
    </div>
  );
};
