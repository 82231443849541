import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useStatisticsStore } from "features/statistics";

const getInvoicesStats = ({
  per_page,
  page,
  status,
  period,
  by_auth_user,
  by_user_id,
  start_date,
  end_date,
}) =>
  apiClient.get("api/statistic/invoice/data", {
    data: null,
    params: {
      status,
      per_page,
      page,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
    },
  });

export const useInvoicesStats = ({ onSuccess, onError } = {}) => {
  const {
    status,
    per_page,
    page,
    period,
    by_auth_user,
    by_user_id,
    start_date,
    end_date,
    setPage,
    setTotal,
    activeTab,
    activeRegistrarTab,
  } = useStatisticsStore();

  const {
    data: invoicesStats,
    isLoading: isInvoicesStatsLoading,
    isFetching,
  } = useQuery(
    [
      "invoices-stats",
      page,
      per_page,
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
    ],
    () =>
      getInvoicesStats({
        page,
        per_page,
        status,
        period,
        by_auth_user: by_auth_user ? 1 : 0,
        by_user_id,
        start_date,
        end_date,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
      enabled:
        activeTab === "Registration" && activeRegistrarTab === "Invoices",
    },
  );

  return { invoicesStats, isInvoicesStatsLoading, isFetching };
};
