import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateSaleSku = ({ id, data }) =>
  apiClient.post(`api/sku/marketplaces/update-settings/${id}`, data);

export const useUpdateSKU = ({ onSuccess }) => {
  const { mutate: updateSKU, isLoading: isSKUUpdating } = useMutation(
    ({ id, data }) => updateSaleSku({ id, data }),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { updateSKU, isSKUUpdating };
};
