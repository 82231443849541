import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";
import { useState } from "react";

const saveMapping = ({ data, retourId, finish = false }) =>
  apiClient.post(
    `api/invoice-return/${retourId}/${finish ? "finish" : "save"}-products`,
    data,
  );

export const useReturnedProductsMapping = ({ onSuccess, retourId } = {}) => {
  const [isFinish, setIsFinish] = useState(false);

  const { mutate: save, isLoading } = useMutation(
    ({ data, finish = false }) => {
      setIsFinish(finish);
      return saveMapping({ data, retourId, finish });
    },
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries([
          "invoiceRetourProducts",
          retourId,
        ]);
        onSuccess?.(res);
      },
    },
  );

  return {
    save,
    isSaving: isLoading && !isFinish,
    isFinishing: isLoading && isFinish,
  };
};
