import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";
import { useState } from "react";

const add = ({ option_id, accessory_id }) =>
  apiClient.post(
    `api/directory/devices/model/${option_id}/accessory/${accessory_id}`,
  );

export const useAddModelAccessory = ({ onSuccess, deviceId } = {}) => {
  const { mutate: addAccessory, isLoading: isAddingAccessory } = useMutation(
    ({ option_id, accessory_id }) => add({ option_id, accessory_id }),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["device-type", deviceId]);
        onSuccess?.(res);
      },
    },
  );

  return { addAccessory, isAddingAccessory };
};

const remove = ({ option_id, accessory_id }) =>
  apiClient.delete(
    `api/directory/devices/model/${option_id}/accessory/${accessory_id}`,
  );

export const useRemoveModelAccessory = ({ onSuccess, deviceId } = {}) => {
  const [removingAccessoryId, setRemovingId] = useState(null);

  const { mutate: removeAccessory, isLoading: isRemovingAccessory } =
    useMutation(
      ({ option_id, accessory_id }) => {
        setRemovingId(accessory_id);
        return remove({ option_id, accessory_id });
      },
      {
        onSuccess: async res => {
          await queryClient.invalidateQueries(["device-type", deviceId]);
          setRemovingId(null);
          onSuccess?.(res);
        },
      },
    );

  return { removeAccessory, isRemovingAccessory, removingAccessoryId };
};
