import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const generateEbayToken = code =>
  apiClient.post("api/directory/marketplaces/ebay/generate-user-token", {
    code,
  });

export const useGenerateEbayToken = onSuccess => {
  const { mutate: generateToken, isLoading: isGeneratingToken } = useMutation(
    code => generateEbayToken(code),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("ebay-connection");
      },
    },
  );

  return { generateToken, isGeneratingToken };
};
