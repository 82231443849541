import React from "react";
import { styles } from "features/auth";
import { RoleTag } from "components";

export const UserInfo = ({ userData }) => {
  const { first_name, last_name, roles } = userData;

  return (
    <div className={styles.user_info}>
      <span className="text-xs mb-0.5 font-semibold sm:text-sm text-slate-100">
        {`${first_name} ${last_name}`}
      </span>

      <div className="flex gap-2 flex-wrap justify-center">
        {roles.map((role, i) => (
          <RoleTag role={role} key={`${role}-${i}`} />
        ))}
      </div>
    </div>
  );
};
