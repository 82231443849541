import { Row, Button, Form, Input, Select, Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useEmployees, useEmployeesStore } from "features/employees";
import { useAddDepartment } from "features/directory";
import { useEffect } from "react";
import { useAuthStore } from "features/auth";

export const CreateDepartment = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin"]);
  const navigate = useNavigate();

  const { setPageSize } = useEmployeesStore();

  const { employees, isLoading } = useEmployees();
  const { add, isAdding } = useAddDepartment(department => {
    message.success("Success!");
    navigate(`/directory/departments/${department.data.id}`);
  });

  useEffect(() => {
    setPageSize(9999);
    return () => setPageSize(12);
  }, []);

  const onSubmit = data => {
    const reqBody = { name: data.name };
    if (data.user_ids?.length) reqBody.user_ids = data.user_ids;
    add(reqBody);
  };

  return (
    <div
      style={{
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: "0 20px",
        position: "relative",
        height: "100%",
      }}
    >
      <Row justify="center" style={{ marginBottom: 60 }}>
        <h2>Create Department</h2>
      </Row>

      <Form layout="vertical" requiredMark={false} onFinish={onSubmit}>
        <Form.Item
          label="Department Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input department name!",
            },
          ]}
        >
          <Input placeholder="Enter Department Name" disabled={isAdding} />
        </Form.Item>

        <Form.Item label="Employees" name="user_ids">
          <Select
            disabled={isAdding}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Employees"
            onChange={() => {
              return;
            }}
            options={
              employees
                ? employees.data.map(item => ({
                    label: item.first_name,
                    value: item.id,
                  }))
                : []
            }
          />
        </Form.Item>

        {rightRole && (
          <Row justify="center">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isAdding}>
                Create
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
    </div>
  );
};
