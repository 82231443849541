import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const approveDevice = (id, data) => {
  return apiClient.post(`api/product/approve/${id}`, data);
};

export const useApprove = (id, onSuccess) => {
  const { mutate: approve, isLoading: isApproving } = useMutation(
    data => approveDevice(id, data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        await queryClient.invalidateQueries(["device", id]);
        queryClient.invalidateQueries(["activities", id]);
        onSuccess?.(data);
      },
    },
  );

  return { approve, isApproving };
};
