import { Card } from "antd";
import { PromotionForm } from "features/promotions";
import { useNavigate } from "react-router-dom";

export const CreatePromotion = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center mb-6 sm:mb-10">Create Promotion</h2>

      <Card className="mt-16 max-w-2xl w-full mx-auto p-4 mb-3">
        <PromotionForm onCancel={() => navigate("/promotions")} />
      </Card>
    </div>
  );
};
