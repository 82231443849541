import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Spin } from "antd";

const height = 240;

export const Diagram = ({ data: data, loading }) => {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(null);

  useLayoutEffect(() => {
    const updateSize = () => setWidth(containerRef.current.clientWidth);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setWidth(containerRef.current?.clientWidth);
  }, [containerRef.current?.clientWidth]);

  return (
    <div ref={containerRef}>
      {loading && (
        <div style={{ height }} className="flex justify-center items-center">
          <Spin size="default" />
        </div>
      )}

      {!loading && (
        <LineChart
          width={width || 200}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 0,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={22}
            tickMargin={10}
            style={{
              fontSize: 10,
              fontWeight: "bold",
            }}
          />
          <YAxis
            allowDecimals={false}
            style={{
              fontSize: 12,
              fontWeight: "bold",
            }}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            activeDot={{ r: 6 }}
          />
          <Line type="monotone" dataKey="average" stroke="#82ca9d" />
        </LineChart>
      )}
    </div>
  );
};
