import { create } from "lib/zustand-poxy";

export const useEmployeesStore = create((set, get) => ({
  searchQuery: "",
  page: 1,
  pageSize: 12,
  total: 0,
  setSearchQuery: searchQuery => set({ searchQuery }),
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));
