import { useState } from "react";
import { Button, Form, Modal, Input, Switch } from "antd";
import { useCreatePair } from "features/sales";
import { requiredRule } from "constants/validation";

export const CreatingNewSKUPair = () => {
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
    form.resetFields();
  };

  const { createPairList, isCreating } = useCreatePair({
    onSuccess: close,
  });

  const onFinish = values => {
    createPairList(values);
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        + Create new pair
      </Button>

      <Modal
        title="Create new SKU pair"
        open={open}
        onOk={form.submit}
        confirmLoading={isCreating}
        onCancel={() => {
          if (!isCreating) close();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isCreating}
          className="mt-8"
        >
          <Form.Item
            name={"sku_1"}
            label={"SKU 1"}
            required
            rules={[requiredRule]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={"sku_2"}
            label={"SKU 2"}
            required
            rules={[requiredRule]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={"active"}
            label={"Active"}
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
