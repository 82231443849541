import { Card, Form, Button, Input } from "antd";
import { PasswordRecovery, useLogin, styles } from "features/auth";

export const LoginForm = () => {
  const [form] = Form.useForm();
  const { submit, isLoading } = useLogin();

  return (
    <Card
      className={styles.card}
      title="Login"
      hoverable
      headStyle={{ textAlign: "center", fontSize: 24 }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={submit}
      >
        <Form.Item label="Email" name="email" rules={rules.email}>
          <Input placeholder="Enter Email" disabled={isLoading} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={rules.password}
          className={styles.password_field}
        >
          <Input.Password placeholder="Enter Password" disabled={isLoading} />
        </Form.Item>

        <PasswordRecovery form={form} />

        <Form.Item className={styles.submit_button}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const rules = {
  email: [
    {
      required: true,
      message: "Please input your Email!",
    },
    {
      pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      message: "Email must be valid value",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your Password!",
    },
    { pattern: /.{8,}/, message: "Minimum 8 symbols" },
  ],
};
