import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

export const useEditExpression = ({ onSuccess, formulaId } = {}) => {
  const { mutate: editExpression, isLoading: isEditing } = useMutation(
    ({ data, expressionId }) =>
      apiClient.put(`api/profit/update/expression/${expressionId}`, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(["marketplaces-formula-data", formulaId]);
      },
    },
  );

  return { editExpression, isEditing };
};
