import { FilterDrawer, FilterSummary } from "components";
import { Spin, Skeleton } from "antd";
import {
  GradeFilter,
  MarketplacesFilter,
  DeviceTypeFilter,
  ColorFilter,
  TextQueryFilter,
} from "features/filter";
import { useMediaQuery } from "react-responsive";
import { useMemo } from "react";
import { useAccessoryType } from "features/directory";

export const SendingAccessoriesFilter = ({ store, disabled, isLoading }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const { isLoading: isAccessoryLoading } = useAccessoryType({
    onSuccess: res => {
      store.setFilter({ device_type_id: res.data?.id });
    },
  });

  const active = useMemo(() => {
    const active = Object.entries(store.filter_by)
      .filter(([key]) => key !== "device_type_id")
      .map(([key, value]) => ({
        label: key,
        value,
      }));

    if (isMobile && store.searchQuery)
      active.unshift({ label: "Search", value: store.searchQuery });

    if (store.start_date)
      active.unshift({ label: "Start Date", value: store.start_date });
    if (store.end_date)
      active.unshift({ label: "End Date", value: store.end_date });

    const tooltip = active.length ? <FilterSummary list={active} /> : null;

    return { count: active.length, tooltip };
  }, [
    store.start_date,
    store.end_date,
    store.filter_by,
    store.searchQuery,
    isMobile,
  ]);

  return (
    <FilterDrawer
      title={
        <span>
          Accessories filter
          {isLoading && <Spin size="small" className="ml-2" />}
        </span>
      }
      onClear={() => {
        store.clear();
        if (isMobile) store.setSearchQuery("");
      }}
      activeCount={active.count}
      btnStyle="primary"
      tooltip={active.tooltip}
      disabled={disabled}
    >
      <div className="flex flex-col gap-8 px-6 py-10">
        {isMobile && <TextQueryFilter store={store} />}

        <MarketplacesFilter store={store} />
        <GradeFilter store={store} />
        <ColorFilter store={store} />
        <DeviceTypeFilter gap={4} store={store} fixedType />
        {isAccessoryLoading && <Skeleton.Input block active />}
      </div>
    </FilterDrawer>
  );
};
