import { useEffect, useRef, useState } from "react";

export const useOnKeySubmit = onSubmit => {
  const [isOpen, _setIsOpen] = useState(false);
  const isOpenRef = useRef(isOpen);
  const setIsOpen = data => {
    isOpenRef.current = data;
    _setIsOpen(data);
  };

  const handleKeyDown = e => {
    if (isOpenRef.current && e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, []);

  return { isOpen, setIsOpen };
};
