import { useParams, useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import { Table, Pagination, Button, message, Tag } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import {
  useCommissionListProducts,
  useComListStore,
  SendingDevicesFilter,
  ManualAdding,
  useCommissionListInfo,
  useSending,
  Approve,
  comListProductsConfig,
  ExportComLIst,
  RedirectDevice,
} from "features/sending";
import MediaQuery from "react-responsive";
import { SearchInput } from "components";
import { useMemo } from "react";
import { useAuthStore } from "features/auth";
import { queryClient } from "../../lib/react-query-client";

export const CommissionListDevices = () => {
  const navigate = useNavigate();
  const { commissionListId } = useParams();
  const comListSelection = useComListStore();
  const { isRight } = useAuthStore();
  const isAdmin = isRight(["admin"]);

  const { info } = useCommissionListInfo({ id: commissionListId });

  const { products, isProductsLoading, isProductsFetching } =
    useCommissionListProducts({
      id: commissionListId,
      onError: err => {
        if (err.response?.status === 404) navigate("/error");
      },
      onSuccess: response => {
        //TODO: remove for production
        console.clear();
        console.log("page-QRs:");
        response.data.forEach(({ qr }) => console.log(qr));
        console.log("");
      },
    });

  const { send, isSending } = useSending({
    listId: commissionListId,
    onSuccess: response => {
      if (
        response.message ===
        "You haven’t scanned all devices from the list. You need approval from the admin."
      ) {
        message.warning(response.message);
      } else {
        message.success(response.message || "Success");
      }
    },
  });

  const idle = isProductsLoading || isProductsFetching || isSending;

  const status = useMemo(() => {
    if (info?.data?.status === "PACKED") {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Packed
        </Tag>
      );
    }

    if (!!info?.data?.waiting_for_approve) {
      return (
        <Tag icon={<ClockCircleOutlined />} color="warning">
          approval
        </Tag>
      );
    }

    if (info?.data?.status === "PACKING" && !info?.data?.waiting_for_approve) {
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          packing
        </Tag>
      );
    }

    return null;
  }, [info]);

  const columns = useMemo(() => {
    if (
      info?.data?.status === "PACKED" ||
      !isRight(["admin", "sales_manager"])
    ) {
      return comListProductsConfig.filter(col => col.key !== "action");
    }

    return comListProductsConfig;
  }, [info]);

  return (
    <div className="flex flex-col h-full relative">
      <h2 className="mb-6 sm:mb-10 flex justify-center items-center gap-2">
        Commission List #{commissionListId} {status}
      </h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-4 mb-3 gap-2">
        <MediaQuery minWidth={500}>
          <SearchInput
            searchQuery={comListSelection.searchQuery}
            setSearchQuery={comListSelection.setSearchQuery}
          />
        </MediaQuery>

        <div className="mr-auto">
          <SendingDevicesFilter
            store={comListSelection}
            disabled={isProductsLoading}
            isLoading={idle}
          />
        </div>

        {isAdmin && (
          <RedirectDevice
            selection={comListSelection.selection}
            disabled={idle}
            onSuccess={() => {
              queryClient.invalidateQueries([
                "commission-list-products",
                commissionListId,
              ]);
              comListSelection.clearSelection();
            }}
          />
        )}

        <ExportComLIst disabled={isProductsLoading} id={commissionListId} />
        <ManualAdding listId={commissionListId} disabled={idle} />

        {info?.data?.status === "PACKED" ? null : info?.data
            ?.waiting_for_approve ? (
          <Approve listId={commissionListId} disabled={idle} />
        ) : (
          <Button
            className="text-xs sm:text-sm"
            type="primary"
            disabled={isProductsLoading || isProductsFetching}
            onClick={send}
            loading={isSending}
          >
            Send
          </Button>
        )}
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        loading={idle}
        columns={columns}
        dataSource={products?.data}
        rowSelection={
          isAdmin
            ? {
                onChange: comListSelection.setSelection,
                selectedRowKeys: comListSelection.selection,
              }
            : null
        }
        rowClassName={record =>
          record.sale_histories[0]?.order?.cancel
            ? "bg-rose-50 text-red-400"
            : ""
        }
        sticky={true}
        rowKey={record => record.product_id}
        pagination={false}
        scroll={{ x: 900 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) =>
          comListSelection.setPageSize(pageSize)
        }
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={comListSelection.total}
        current={comListSelection.page}
        pageSize={comListSelection.pageSize}
        onChange={comListSelection.setPage}
        disabled={comListSelection.idle}
      />
    </div>
  );
};
