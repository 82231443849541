import { Card } from "antd";
import { Link } from "react-router-dom";
import { MarketplaceIcon } from "features/marketplaces";

const config = [
  "ebay",
  "backmarket",
  "refurbed",
  "shopify",
  "amazon",
  "mediamarkt",
  "conrad",
  "voelkner",
];

export const MarketplaceDirectory = () => (
  <>
    <h2 className="text-center">Marketplaces</h2>
    <div className="flex place-content-center max-w-5xl w-full mx-auto px-4 mt-10 mb-3 flex-wrap gap-4">
      {config.map(marketplace => (
        <Card hoverable key={marketplace}>
          <Link to={`/directory/marketplaces/${marketplace}`}>
            <MarketplaceIcon name={marketplace} width="180" height="120" />
          </Link>
        </Card>
      ))}
    </div>
  </>
);
