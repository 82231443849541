import { Table, Tooltip } from "antd";
import { Pagination } from "components";
import { useAccessories, useAccessoriesStore } from "features/directory";

export const AccessoriesTable = ({ onEdit }) => {
  const store = useAccessoriesStore();
  const { accessories, isLoading, isFetching } = useAccessories();

  return (
    <>
      <Table
        className="overflow-y-auto"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={accessories?.data}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        onRow={record => ({
          onClick: () => onEdit(record),
        })}
      />

      <Pagination
        store={store}
        disabled={isLoading || !accessories}
        className="mt-auto"
      />
    </>
  );
};

const columns = [
  { title: "ID", dataIndex: "id", key: "id", width: 100 },
  { title: "Name", dataIndex: "name", key: "name", align: "center" },
  { title: "SKU", dataIndex: "sku", key: "sku", align: "center", width: 120 },

  {
    title: "Categories",
    key: "accessory_categories",
    dataIndex: "accessory_categories",
    render: arr => arr.map(item => item.name).join(", "),
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: "center",
    render: descr => (
      <Tooltip title={descr}>
        <div className="text-ellipsis whitespace-nowrap overflow-hidden">
          {descr}
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: 100,
    align: "center",
  },
];
