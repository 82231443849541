import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";
import { useNavigate } from "react-router-dom";

const editSuppliers = (id, data) =>
  apiClient.put(`api/directory/suppliers/${id}`, data);

export const useEditSuppliers = (id, onSuccess) => {
  const navigate = useNavigate();
  const { mutate: edit, isLoading: isEditing } = useMutation(
    data => editSuppliers(id, data),
    {
      onSuccess: async dep => {
        await queryClient.invalidateQueries(["suppliers"]);
        await queryClient.invalidateQueries(["supplier", id]);
        navigate(`/directory/suppliers`);
        onSuccess?.(dep);
      },
    },
  );

  return { edit, isEditing };
};
