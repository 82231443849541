import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";

const getSKU = ({ skuId, gradeId, original_box = null }) =>
  apiClient.get(`api/sku-page/${skuId}/${gradeId}`, {
    params: { original_box },
  });

export const useSkuPage = ({
  skuId,
  gradeId,
  onSuccess,
  onError,
  original_box = null,
} = {}) => {
  const {
    data: sku,
    isLoading,
    isFetching,
  } = useQuery(
    ["sku-page", skuId, gradeId, original_box],
    () => getSKU({ skuId, gradeId, original_box }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: error => {
        globalErrorHandler(error);
        onError?.(error);
      },
      retry: false,
    },
  );

  return { sku, isLoading, isFetching };
};
