import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const sendList = listId => {
  return apiClient.post(`api/sending/commission-list/${listId}/sent`);
};

export const useSending = ({ listId, onSuccess } = {}) => {
  const { mutate: send, isLoading: isSending } = useMutation(
    () => sendList(listId),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["commission-list-products", listId]);
        queryClient.invalidateQueries(["commission-list-info", listId]);
        queryClient.invalidateQueries(["sending-commission-lists"]);

        onSuccess?.(data);
      },
    },
  );

  return { send, isSending };
};
