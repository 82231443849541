import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient, globalErrorHandler } from "lib/react-query-client";

const scanProduct = ({ listNum, productId }) => {
  return apiClient.post(
    `api/sending/commission-list/${listNum}/scanning/${productId}`,
  );
};

export const useScanSendingProduct = ({ listNum, onSuccess, onError } = {}) => {
  const { mutate: scan, isLoading: isScanning } = useMutation(
    productId => scanProduct({ listNum, productId }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["commission-list-products", listNum]);
        queryClient.invalidateQueries(["commission-list-info", listNum]);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
    },
  );

  return { scan, isScanning };
};
