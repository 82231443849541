import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const createSale = ({ marketplace_sku_id, order_id, price, product_id }) => {
  return apiClient.post(
    "api/sku/marketplaces/sales-issues/create-manual-sale",
    {
      marketplace_sku_id,
      order_id,
      price,
      product_id,
    },
  );
};

export const useCreateSale = ({ onSuccess } = {}) => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => createSale(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sales-issues");
      },
    },
  );

  return { create, isCreating };
};
