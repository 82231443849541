import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const addColor = data => apiClient.post("api/directory/colors", data);

export const useAddColor = onSuccess => {
  const { mutate: add, isLoading: isAdding } = useMutation(
    data => addColor(data),
    {
      onSuccess: async color => {
        await queryClient.invalidateQueries(["colors"]);
        onSuccess?.();
      },
    },
  );

  return { add, isAdding };
};
