import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

export const useRemoveExpression = ({ onSuccess, formulaId } = {}) => {
  const { mutate: removeExpression, isLoading: isRemoving } = useMutation(
    id => apiClient.delete(`api/profit/delete/expression/${id}`),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(["marketplaces-formula-data", formulaId]);
      },
    },
  );

  return { removeExpression, isRemoving };
};
