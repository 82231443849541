import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const approveList = ({ listId, email, password }) => {
  return apiClient.post(`api/sending/commission-list/${listId}/approve`, {
    email,
    password,
  });
};

export const useSendingApprove = ({ listId, onSuccess } = {}) => {
  const { mutate: approve, isLoading: isApproving } = useMutation(
    ({ email, password }) => approveList({ listId, email, password }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["commission-list-products", listId]);
        queryClient.invalidateQueries(["commission-list-info", listId]);
        queryClient.invalidateQueries(["sending-commission-lists"]);

        onSuccess?.(data);
      },
    },
  );

  return { approve, isApproving };
};
