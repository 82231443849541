import { Radio, Modal, DatePicker } from "antd";
import { useStatisticsStore } from "features/statistics";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
const { RangePicker } = DatePicker;

export const PeriodSelector = () => {
  const store = useStatisticsStore();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const isTightly = useMediaQuery({
    query: "(max-width: 960px)",
  });

  return (
    <>
      <Radio.Group
        size={isTightly ? "small" : "middle"}
        value={store.period}
        onChange={({ target }) => store.setPeriod(target.value)}
        buttonStyle="solid"
      >
        {periods.map(({ label, value }) => {
          if (value === "custom") {
            return (
              <Radio.Button
                key={value}
                value={value}
                onClick={() => {
                  setIsDatePickerOpen(true);
                }}
              >
                <span className="lg:text-sm text-xs lg:font-normal font-semibold">
                  {label}
                </span>
              </Radio.Button>
            );
          } else {
            return (
              <Radio.Button key={value} value={value}>
                <span className="lg:text-sm text-xs lg:font-normal font-semibold">
                  {" "}
                  {label}
                </span>
              </Radio.Button>
            );
          }
        })}
      </Radio.Group>

      <Modal
        footer={false}
        open={isDatePickerOpen}
        onCancel={() => setIsDatePickerOpen(false)}
      >
        <RangePicker
          placeholder={["Start Date ", "End Date"]}
          className="mx-auto w-full mt-9"
          // presets={rangePresets}
          onChange={(_, dateStrings) => {
            store.setStartDate(dateStrings[0]);
            store.setEndDate(dateStrings[1]);
          }}
        />
      </Modal>
    </>
  );
};

const periods = [
  { label: "1H", value: "last_1_hour" },
  { label: "2H", value: "last_2_hours" },
  { label: "4H", value: "last_4_hours" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Week", value: "last_week" },
  { label: "Month", value: "last_month" },
  { label: "Adjust", value: "custom" },
];
