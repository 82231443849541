import { useState } from "react";
import { Button } from "antd";
import {
  AccessoryCategoryForm,
  AccessoryCategoryList,
  useAccessoryCategoriesStore,
} from "features/directory";
import { PlusOutlined } from "@ant-design/icons";
import { SearchInput } from "components";
import { useAuthStore } from "features/auth";
import clsx from "clsx";

export const AccessoryCategories = () => {
  const store = useAccessoryCategoriesStore();

  const { isRight } = useAuthStore();
  const userCanReadOnly = !isRight(["admin", "registrar", "head_of_flasher"]);

  const [formState, setFormState] = useState({ isOpen: false, category: null });
  const open = (category = null) => setFormState({ isOpen: true, category });
  const close = () => setFormState({ isOpen: false, category: null });

  return (
    <div className="page-container flex-col relative">
      <h2 className="text-center mb-6 sm:mb-10">Accessory Categories</h2>

      <div className="flex justify-between mb-3 gap-2">
        <SearchInput
          searchQuery={store?.search_term}
          setSearchQuery={store?.setSearchTerm}
        />

        <Button
          type="primary"
          className={clsx("text-xs sm:text-sm", userCanReadOnly && "hidden")}
          onClick={() => open()}
          icon={<PlusOutlined />}
        >
          New Category
        </Button>
      </div>

      <AccessoryCategoryList onEdit={open} editable={!userCanReadOnly} />

      {!userCanReadOnly && (
        <AccessoryCategoryForm
          isOpen={formState.isOpen}
          onClose={close}
          initialData={formState.category}
        />
      )}
    </div>
  );
};
