import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useStatisticsStore } from "features/statistics";
import { useDebounce } from "use-debounce";

const getDevices = ({
  per_page,
  page,
  status,
  period,
  by_auth_user,
  by_user_id,
  start_date,
  end_date,
  search,
}) =>
  apiClient.get("api/statistic/product/data/devices", {
    params: {
      status,
      per_page,
      page,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
      search,
    },
    data: {},
  });

export const useProductsStatsByDevices = ({ onSuccess, onError } = {}) => {
  const {
    status,
    per_page,
    page,
    period,
    by_auth_user,
    by_user_id,
    start_date,
    end_date,
    setPage,
    setTotal,
    activeTab,
    activeRegistrarTab,
    search,
    byDevices,
  } = useStatisticsStore();

  const [debouncedQuery] = useDebounce(search, 500);

  const {
    data: productsStatsByDevices,
    isLoading,
    isFetching,
  } = useQuery(
    [
      "prod-stats-by-devices",
      page,
      per_page,
      status,
      period,
      by_auth_user,
      by_user_id,
      start_date,
      end_date,
      debouncedQuery,
    ],
    () =>
      getDevices({
        page,
        per_page,
        status,
        period,
        by_auth_user: by_auth_user ? 1 : 0,
        by_user_id,
        start_date,
        end_date,
        search: debouncedQuery,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
      enabled:
        !(activeTab === "Registration" && activeRegistrarTab === "Invoices") &&
        byDevices,
    },
  );

  return {
    productsStatsByDevices,
    isProductsByDevicesStatsLoading: isLoading || isFetching,
  };
};
