import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const remove = id => apiClient.delete(`api/marketplaces/formula/${id}`);

export const useDeleteFormula = ({ onSuccess } = {}) => {
  const { mutate: deleteFormula, isLoading: isDeleting } = useMutation(
    id => remove(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("marketplaces-formulas");
      },
    },
  );

  return { deleteFormula, isDeleting };
};
