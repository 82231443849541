import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const showInvoice = id => apiClient.get(`api/invoices/${id}`);

export const useInvoice = ({ id, onError }) => {
  const { data: invoice, isLoading: isLoadingInvoice } = useQuery(
    ["invoice", id],
    () => showInvoice(id),
    {
      onError: err => {
        onError?.(err);
      },
      retry: false,
    },
  );
  return { invoice, isLoadingInvoice };
};
