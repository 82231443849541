import { useEffect } from "react";
import { Form, Input, Modal, InputNumber, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEditInvoice } from "features/invoices";
import { requiredRule } from "constants/validation";

export const EditInvoice = ({ initialInvoiceData, isOpen, onClose }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      form.setFieldValue("article", initialInvoiceData.article);
      form.setFieldValue("name", initialInvoiceData.name);
      form.setFieldValue("quantity", initialInvoiceData.quantity);
      form.setFieldValue("single_price", initialInvoiceData.single_price);
    }
  }, [isOpen]);

  const { edit, isEditing } = useEditInvoice({
    id: initialInvoiceData?.id,
    onSuccess: res => {
      message.success(res.message);
    },
  });

  const constCloseAndResetForm = () => {
    onClose();
    form.resetFields();
  };

  const onSaveInvoice = async data => {
    await edit(data);
    constCloseAndResetForm();
  };

  return (
    <Modal
      title="Edit Product"
      onCancel={constCloseAndResetForm}
      destroyOnClose={true}
      open={isOpen}
      onOk={form.submit}
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      confirmLoading={isEditing}
      cancelButtonProps={{ disabled: isEditing }}
    >
      <Form
        onFinish={onSaveInvoice}
        form={form}
        className="mt-6"
        disabled={isEditing}
      >
        <Form.Item
          rules={[requiredRule]}
          name="article"
          label={<span className="w-[40px]">SKU</span>}
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[requiredRule]}
          name="name"
          label={<span className="w-[40px]">Model</span>}
          required
        >
          <TextArea autoSize />
        </Form.Item>

        <div className="flex gap-12">
          <Form.Item
            rules={[requiredRule]}
            required
            name="single_price"
            label={<span className="w-[40px]">Price</span>}
            className="flex-1"
          >
            <InputNumber min={0} addonBefore="€" />
          </Form.Item>

          <Form.Item
            rules={[requiredRule]}
            required
            name="quantity"
            label="Quantity"
            className="flex-1"
          >
            <InputNumber min={0} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
