import _ from "lodash";

export const fillSkuFields = (sku, form) => {
  form.setFieldValue("color_shade_id", sku?.color_shade?.id);
  form.setFieldValue("color", sku?.color_shade?.color_id);
  form.setFieldValue("device_type_id", sku?.device_type?.device_type_id);
  form.setFieldValue("sku_id", sku?.sku_id);

  if (!!sku) {
    Object.values(sku?.field_values).forEach(field => {
      const isSelect =
        field.device_field.device_field_type.toUpperCase() === "SELECT";
      const isMultiSelect =
        field.device_field.device_field_type.toUpperCase() === "MULTISELECT";
      const isCheckbox =
        field.device_field.device_field_type.toUpperCase() === "CHECKBOX";

      let value;
      if (isSelect) {
        value = field.device_field.device_field_option[0]?.id;
      } else if (isMultiSelect) {
        value = field.device_field.device_field_option?.map(opt => opt?.id);
      } else if (isCheckbox) {
        value = field.device_field.device_field_value !== "false";
      } else {
        value = field.device_field.device_field_value;
      }

      form.setFieldValue(
        ["field_values", field.device_field.device_field_name],
        value,
      );
    });
  }
};

export const fillPackerApprovement = (deviceData, form) => {
  form.setFieldValue("status", deviceData.status);
  form.setFieldValue("grade_id", deviceData.grade_id);

  if (
    !_.isEmpty(deviceData?.additional_options) &&
    _.isArray(deviceData?.additional_options)
  ) {
    for (const opt of deviceData.additional_options) {
      const entries = Object.entries(opt)[0];
      if (!entries) continue;
      const [key, value] = entries;
      form.setFieldValue(["additional_options", key], value);
    }
  }

  if (!!deviceData.comment) {
    form.setFieldValue("comment", deviceData.comment);
  }
};

export const fillDeviceForm = ({
  deviceData,
  form,
  isGradeApprove = false,
}) => {
  if (!_.isEmpty(deviceData.invoice)) {
    form.setFieldValue("invoice_id", deviceData.invoice.invoice_id);
  }

  form.setFieldValue("priority", deviceData.priority);

  if (!_.isEmpty(deviceData.invoice_product)) {
    form.setFieldValue("invoice_product_id", deviceData.invoice_product.id);
  }

  form.setFieldValue("status", deviceData.status);

  if (!_.isEmpty(deviceData.sku)) {
    form.setFieldValue("sku_id", deviceData.sku.sku_id);
  }

  if (!isGradeApprove && !_.isEmpty(deviceData.grade)) {
    form.setFieldValue("grade_id", deviceData.grade.id);
  }
  if (isGradeApprove) {
    form.setFieldValue("grade_id", deviceData.grade_id);
  }

  if (
    !_.isEmpty(deviceData?.additional_options) &&
    _.isArray(deviceData?.additional_options)
  ) {
    for (const opt of deviceData.additional_options) {
      const entries = Object.entries(opt)[0];
      if (!entries) continue;
      const [key, value] = entries;
      form.setFieldValue(["additional_options", key], value);
    }
  }

  form.setFieldValue("soft_update", deviceData.soft_update);

  if (!_.isEmpty(deviceData.color)) {
    form.setFieldValue("color", deviceData.color.color_id);
    form.setFieldValue("color_shade_id", deviceData.color.id);
  }

  if (!_.isEmpty(deviceData.device_type)) {
    form.setFieldValue("device_type_id", deviceData.device_type.id);
  }

  if (!_.isEmpty(deviceData.field_values)) {
    const field_values = Object.values(deviceData.field_values);
    for (const field of field_values) {
      const type = field.device_field.device_field_type;
      const name = field.device_field.device_field_name;
      let value;

      if (type.toUpperCase() === "SELECT") {
        value = field.device_field.device_field_option[0]?.id;
      } else if (type.toUpperCase() === "MULTISELECT") {
        value = field.device_field.device_field_option?.map(opt => opt?.id);
      } else if (type.toUpperCase() === "CHECKBOX") {
        value = Boolean(field.device_field.device_field_value);
      } else {
        value = field.device_field.device_field_value;
      }

      form.setFieldValue(["field_values", name], value);
    }
  }

  form.setFieldValue("repair_option", deviceData.repair_option);

  // if (!!deviceData.comment) {
  //   form.setFieldValue("comment", deviceData.comment);
  // }
};

export const buildDeviceObj = (data, typeFields) => {
  let result = { ...data };

  if (!result.comment) delete result.comment;

  if (!!data.additional_options) {
    const additional_options = Object.entries(data.additional_options)
      ?.filter(([key, value]) => !!value && key !== "verified")
      .map(([key, value]) => {
        // if (!value) return null;
        return { [key]: value };
      });

    result = { ...result, additional_options };
  }

  if (!!data.field_values && typeFields) {
    const getTypeField = name => typeFields.find(field => field.name === name);
    const field_values = Object.entries(data.field_values)
      .map(([formEntryKey, formEntryValue]) => {
        const typeField = getTypeField(formEntryKey);
        if (
          typeField.type.toUpperCase() === "TEXT" ||
          typeField.type.toUpperCase() === "CHECKBOX"
        ) {
          return {
            device_field_id: typeField.id,
            value: formEntryValue?.toString(),
          };
        }

        if (typeField.type.toUpperCase() === "SELECT") {
          return {
            device_field_id: typeField.id,
            device_field_option_id: formEntryValue,
          };
        }

        //MULTISELECT
        return formEntryValue.map(opt => ({
          device_field_id: typeField.id,
          device_field_option_id: opt,
        }));
      })
      .flat();

    result = { ...result, field_values };
  }

  return result;
};

export const getInvoiceOption = data => ({
  value: data?.invoice_id,
  label: `${data?.invoice_number} - ${data?.supplier?.supplier_name}`,
});

export const getInProductOption = data => ({
  value: data?.id,
  label: data?.name,
});

export const getDevTypeOption = data => ({
  value: data?.id,
  label: data?.name,
});

export const getGradeOption = data => ({
  value: data?.grade_id || data?.id,
  label: data?.name,
});

export const getColorOption = data => {
  return {
    value: data?.color_id,
    label: data?.color?.name,
    shades:
      data?.color?.shades?.map(({ name, id }) => ({
        value: id,
        label: name,
      })) || [],
  };
};

export const getSkuOption = sku => {
  if (!sku) return { value: null };

  let label = `${sku.sku_id} - no data`;
  if (!_.isEmpty(sku.field_values)) {
    const field_values = Object.values(sku.field_values);

    const model = field_values.find(
      item => item.device_field.device_field_name === "Model",
    )?.device_field.device_field_option[0]?.value;

    const memory =
      field_values.find(
        item => item.device_field.device_field_name === "Memory",
      )?.device_field.device_field_option[0]?.value || "";

    label = `#${sku.sku_number} - ${model} ${sku.color_shade?.name} ${memory}`;
  }

  return {
    value: sku.sku_id,
    label,
  };
};

export const getDeviceAccessoriesByCategories = device => {
  if (!device.field_values) return [];

  const field_values = device.field_values;
  const modelField = Object.values(field_values)
    .map(item => item.device_field)
    .find(({ device_field_name }) => device_field_name === "Model");

  const accessories =
    modelField.device_field_option?.[0]?.grouped_accessories?.[0];

  return accessories || [];
};

export const getFlatSelectedAccessoriesByIDs = (device, selectedIds) => {
  const flatList = Object.values(
    getDeviceAccessoriesByCategories(device),
  )?.flat();

  return flatList.filter(accessory => selectedIds.includes(accessory.id));
};
