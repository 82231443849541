import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getPromo = id => apiClient.get(`api/marketplaces/promotion/${id}`);

export const usePromoInfo = ({ id, onSuccess } = {}) => {
  const {
    data: promotion,
    isLoading,
    isFetching,
  } = useQuery(["promotion-info", id], () => getPromo(id), {
    onSuccess: data => {
      onSuccess?.(data);
    },
  });

  return { promotion, isLoading, isFetching };
};
