export const SkuChips = ({ label = "Label", value = "Value", color }) => {
  return (
    <div className="border border-gray-300 border-solid rounded-md flex overflow-hidden">
      <div
        className="text-white font-semibold bg-blue-900 text-xs flex justify-center items-center px-2"
        style={color ? { backgroundColor: color } : {}}
      >
        {label}
      </div>
      <div className="px-2 font-semibold text-gray-600  bg-white">{value}</div>
    </div>
  );
};
