import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const addRN = (marketplaceId, amazon_rn) =>
  apiClient.post(`api/sku/marketplaces/connect-amazon-rn/${marketplaceId}`, {
    amazon_rn,
  });

export const useAmazonRN = ({ onSuccess } = {}) => {
  const { mutate: addAmazonRN, isLoading: isAddingRN } = useMutation(
    ({ marketplaceId, amazon_rn }) => addRN(marketplaceId, amazon_rn),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { addAmazonRN, isAddingRN };
};
