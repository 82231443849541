import { Link } from "react-router-dom";
import { Pagination, Table, Radio, Tooltip } from "antd";
import { DirectorySkuLink, DirectorySupplierLink } from "features/directory";
import { InvoiceLink } from "features/invoices";
import _ from "lodash";
import { BooleanBadge, SearchInput, SKU } from "components";
import { useStatisticsStore } from "../store";
import { StatisticsExport } from "./StatisticsExport";
import { useAuthStore } from "features/auth";

export const DevicesTable = ({ data, isLoading, total = 0 }) => {
  const store = useStatisticsStore();

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center">
        <SearchInput
          disabled={_.isEmpty(data) && !store.search}
          searchQuery={store.search}
          setSearchQuery={store.setSearchQuery}
        />
        <Radio.Group
          className="ml-auto mr-2"
          onChange={store.toggleByDevices}
          value={store.byDevices}
          defaultValue={false}
        >
          <Radio value={false}>Grouped</Radio>
          <Radio value={true}>By Devices</Radio>
        </Radio.Group>
        <StatisticsExport />
      </div>

      {store.byDevices && (
        <Table
          className="overflow-y-auto w-full mt-2"
          loading={isLoading}
          columns={columnsByDevices}
          dataSource={data}
          sticky={true}
          rowKey={() => _.uniqueId()}
          pagination={false}
          size="small"
          footer={() => (
            <span className="text-xs font-bold">{`Total: ${total}`}</span>
          )}
          scroll={{ x: 800 }}
        />
      )}

      {!store.byDevices && (
        <Table
          className="overflow-y-auto w-full mt-2"
          loading={isLoading}
          columns={columnsBySKU}
          dataSource={data}
          sticky={true}
          rowKey={() => _.uniqueId()}
          pagination={false}
          size="small"
          footer={() => (
            <span className="text-xs font-bold">{`Total: ${total}`}</span>
          )}
          scroll={{ x: 800 }}
        />
      )}

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        current={store.page}
        pageSize={store.per_page}
        onChange={store.setPage}
      />
    </div>
  );
};

const columnsBySKU = [
  {
    title: "Supplier",
    dataIndex: "supplier_name",
    key: "supplier_name",
    render: (_, { supplier_id, supplier_name }) => {
      return (
        <DirectorySupplierLink supplierId={supplier_id} label={supplier_name} />
      );
    },
  },
  {
    title: "Invoice Nr",
    dataIndex: "invoice_id",
    key: "invoice_number",
    align: "center",
    render: (_, { invoice_id, invoice_number }) => {
      return <InvoiceLink invoiceId={invoice_id} label={invoice_number} />;
    },
    width: 140,
  },
  {
    title: "SKU",
    dataIndex: "sku_number",
    key: "sku_number",
    align: "center",
    render: (_, { sku_id, sku_number }) => (
      <DirectorySkuLink skuId={sku_id} label={sku_number} />
    ),

    width: 140,
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "sku_number",
    align: "center",
  },
  {
    title: "Quantity",
    dataIndex: "total_quantity",
    key: "total_quantity",
    align: "center",
    width: 100,
  },
  {
    title: "Grades",
    children: [
      {
        title: () => <span className="whitespace-nowrap text-xs">A</span>,
        dataIndex: "A_quantity",
        key: "A_quantity",
        width: 40,
        align: "center",
      },
      {
        title: () => <span className="whitespace-nowrap text-xs">A-NV</span>,
        dataIndex: "A_NV_quantity",
        key: "A_NV_quantity",
        width: 60,
        align: "center",
      },
      {
        title: () => <span className="whitespace-nowrap text-xs">B</span>,
        dataIndex: "B_quantity",
        key: "B_quantity",
        width: 40,
        align: "center",
      },
      {
        title: () => <span className="whitespace-nowrap text-xs">BB</span>,
        dataIndex: "BB_quantity",
        key: "BB_quantity",
        width: 60,
        align: "center",
      },
      {
        title: () => <span className="whitespace-nowrap text-xs">C</span>,
        dataIndex: "C_quantity",
        key: "C_quantity",
        width: 40,
        align: "center",
      },
      {
        title: () => <span className="whitespace-nowrap text-xs">CС</span>,
        dataIndex: "CC_quantity",
        key: "CC_quantity",
        width: 40,
        align: "center",
      },
      {
        title: () => <span className="whitespace-nowrap text-xs">D</span>,
        dataIndex: "D_quantity",
        key: "D_quantity",
        width: 40,
        align: "center",
      },
    ],
  },
];

const columnsByDevices = [
  {
    title: "Supplier",
    dataIndex: "supplier_name",
    key: "supplier_name",
    render: (_, { supplier_id, supplier_name }) => {
      return (
        <DirectorySupplierLink supplierId={supplier_id} label={supplier_name} />
      );
    },
  },
  {
    title: "Invoice Nr",
    dataIndex: "invoice_number",
    key: "invoice_number",
    render: (_, { invoice_id, invoice_number }) => {
      return <InvoiceLink invoiceId={invoice_id} label={invoice_number} />;
    },
    align: "center",
    width: 140,
  },
  {
    title: "SKU",
    dataIndex: "sku_number",
    key: "sku_number",
    render: (_, r) => {
      const hasPermissions = useAuthStore
        .getState()
        .isRight(["admin", "sales_manager"]);

      return SKU.Link({
        skuId: r.sku_id,
        skuNumber: r.sku_number,
        gradeId: r.grade_id,
        gradeName: r.grade,
        gradeSkuDisplay: r.grade_sku_display,
        originalBox: r.original_box,
        disabled: !hasPermissions,
      });
    },
    align: "center",
    width: 140,
  },
  {
    title: "ID",
    dataIndex: "product_id",
    key: "product_id",
    align: "center",
    render: product_id => (
      <Link
        className="font-medium"
        to={`/devices/${product_id}`}
        target="_blank"
      >
        {product_id}
      </Link>
    ),
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
    align: "center",
    render: (name, { product_id }) => (
      <Tooltip placement="topLeft" title={name} mouseEnterDelay={1}>
        <Link
          className="font-medium"
          to={`/devices/${product_id}`}
          target="_blank"
        >
          {name}
        </Link>
      </Tooltip>
    ),
  },

  {
    title: "Grade",
    dataIndex: "grade",
    key: "grade",
    align: "center",
    width: 110,
  },
  {
    title: "Original Box",
    dataIndex: "original_box",
    key: "original_box",
    align: "center",
    width: 110,
    render: original_box => <BooleanBadge value={original_box} />,
  },
];
