export const FORMULAS_OPTIONS = [
  { key: "amazon_expression", name: "Amazon" },
  { key: "b2b_expression", name: "B2B" },
  { key: "backmarket_expression", name: "Backmarket" },
  { key: "ebay_expression_greater", name: "eBay > 400" },
  { key: "ebay_expression_less", name: "eBay < 400" },
  { key: "refurbed_expression", name: "Refurbed" },
  { key: "shopify_a_expression", name: "Shopify-A" },
  { key: "shopify_default_expression", name: "Shopify default" },
  {
    key: "mediamarkt_default_expression",
    name: "MediaMarkt default",
    shortName: "MMarkt default",
  },
  {
    key: "mediamarkt_accessory_expression",
    name: "MediaMarkt Accessory",
    shortName: "MMarkt Accessory",
  },
  {
    key: "mediamarkt_speaker_expression",
    name: "MediaMarkt Speaker",
    shortName: "MMarkt Speaker",
  },
  { key: "conrad_expression", name: "Conrad" },
];

export const QUERY_NAMES = {
  FORMULAS: "profitability-formulas",
  FORMULAS_INFINITE: "i-profitability-formulas",
  PRODUCTS: {
    DEVICES: {
      CONNECTED: "profitability-connected-devices",
      DISCONNECTED: "profitability-disconnected-devices",
      WITHOUT_SALE: "profitability-devices-without-sale",
      OTHER_STATUSES: "profitability-devices-with-other-statuses",
    },
    SKUS: {
      CONNECTED: "profitability-connected-skus",
      DISCONNECTED: "profitability-disconnected-skus",
      WITHOUT_SALE: "profitability-skus-without-sale",
    },
  },
};

export const SORTING_NAMES_MAP = {
  ascend: "ASC",
  descend: "DESC",
};
