import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

const statusesMap = {
  admin: "IDENTIFIED",
  grader: "IDENTIFIED",
  flasher: "GRADED",
  packer: "REGISTERED",
  sales_manager: "READY_FOR_SALE",
  all_hold: "HOLD",
  all_repair: "REPAIR",
};

export const useDashboardStore = create(
  devtools(
    immer((set, get) => ({
      filter_by: { status: "IDENTIFIED" },
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      role: "grader",
      setSearchQuery: searchQuery =>
        set(state => {
          state.searchQuery = searchQuery;
        }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setRole: role => {
        set(state => {
          state.filter_by.status = statusesMap[role.toLowerCase()];
          state.role = role.toLowerCase();
        });
      },
    })),
  ),
);
