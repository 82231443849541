import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getGrade = id => apiClient.get(`api/directory/grades/${id}`);

export const useGrade = ({ id, onSuccess, onError } = {}) => {
  const { data: grade, isLoading: idLoadingGrade } = useQuery(
    ["grade", id],
    () => getGrade(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
      retry: false,
      enabled: !!id,
    },
  );

  return { grade, idLoadingGrade };
};
