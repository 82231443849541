import { useFormConstructor } from "features/directory";
import { FormItem } from "./FormItem";
import { useEffect } from "react";
import { Space } from "antd";
import { useAuthStore } from "features/auth";

export const ColorConstructor = ({ value = null, disabled = false }) => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const {
    fields,
    clear,
    setData,
    validation,
    onChangeName,
    onChangeOptionText,
    addNewOption,
    removeOption,
  } = useFormConstructor();

  useEffect(() => {
    if (!value) {
      setData({
        fields: [
          { name: "", type: "text" },
          { name: "Shades", type: "select", options: [{ value: "" }] },
          { name: "Possible Names", type: "select", options: [{ value: "" }] },
        ],
      });
    } else {
      setData({
        fields: [
          { name: value.name, type: "text" },
          {
            name: "Shades",
            type: "select",
            options: value.shades.map(item => ({
              value: item.name,
              id: item.id,
            })),
          },
          {
            name: "Possible Names",
            type: "select",
            options: value.possible_names.map(item => ({
              value: item.name,
              id: item.id,
            })),
          },
        ],
      });
    }
    // eslint-disable-next-line
  }, [value]);

  // eslint-disable-next-line
  useEffect(() => clear, []);

  return (
    <>
      <Space direction="vertical" size="middle" className="mb-12">
        <FormItem
          fieldIndex={0}
          readOnly={!rightRole}
          readOnlyTotal={!rightRole}
          placeholder="Enter Color Name"
          fixedType
          isStatic
          disabled={disabled}
          type="text"
          nameValue={fields[0]?.name || ""}
          error={fields[0]?.error && validation}
          onChangeName={value => onChangeName(0, value)}
        />

        <FormItem
          fieldIndex={1}
          readOnly
          readOnlyTotal={!rightRole}
          fixedType
          isStatic
          disabled={disabled}
          type="select"
          nameValue={fields[1]?.name || ""}
          error={fields[1]?.error && validation}
          onChangeName={value => onChangeName(1, value)}
          onRemoveOption={key => removeOption(1, key)}
          onChangeOption={(index, value, key) =>
            onChangeOptionText(1, value, key)
          }
          onAddNewOption={() => addNewOption(1)}
          options={fields[1]?.options}
        />

        <FormItem
          fieldIndex={2}
          readOnly
          readOnlyTotal={!rightRole}
          fixedType
          isStatic
          disabled={disabled}
          type="select"
          nameValue={fields[2]?.name || ""}
          error={fields[2]?.error && validation}
          onChangeName={value => onChangeName(2, value)}
          onRemoveOption={key => removeOption(2, key)}
          onChangeOption={(index, value, key) =>
            onChangeOptionText(2, value, key)
          }
          onAddNewOption={() => addNewOption(2)}
          options={fields[2]?.options}
        />
      </Space>
    </>
  );
};
