import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getEbayFormulas = () => apiClient.get("api/marketplaces/formula/ebay");

export const useEbayFormulas = ({ onSuccess, onError } = {}) => {
  const {
    data: ebayFormulas,
    isLoading,
    isFetching,
  } = useQuery(["ebay-formulas"], getEbayFormulas, {
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: err => {
      onError?.(err);
    },
  });
  return { ebayFormulas, isLoading, isFetching };
};
