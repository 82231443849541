import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const reimport = (data, invoiceId) =>
  apiClient.post(`api/invoices/re-import/${invoiceId}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useInvoiceReImport = ({ onSuccess, invoiceId }) => {
  const { mutate: upload, isLoading: isUploading } = useMutation(
    data => reimport(data, invoiceId),
    {
      onSuccess: async res => {
        onSuccess?.(res);
        queryClient.invalidateQueries(["invoiceProducts", invoiceId]);
      },
    },
  );

  return { upload, isUploading };
};
