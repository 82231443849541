import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";

const getActivities = id => apiClient.get(`api/product/activities/${id}`);

export const useActivities = ({ id, onSuccess }) => {
  const {
    data: activities,
    isLoading,
    isFetching,
  } = useQuery(["activities", id], () => getActivities(id), {
    onSuccess: data => {
      onSuccess?.(data);
    },
  });

  return { activities, isActivitiesLoading: isLoading || isFetching };
};
