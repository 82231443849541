import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { useDeviceAccessoryHistoryStore } from "../store";

const getHistory = ({ id, page = 1 }) =>
  apiClient.get(`api/product/accessory-histories/${id}`, {
    params: {
      page,
    },
  });

export const useDeviceAccessoryHistory = ({ id, onSuccess } = {}) => {
  const { page, setPage, setTotal, called } = useDeviceAccessoryHistoryStore();

  const { data: history, isLoading } = useQuery(
    ["device-accessory-history", id, page],
    () => getHistory({ id, page }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      enabled: called,
    },
  );

  return { history, isLoading };
};
