import { Table, Pagination, Tooltip } from "antd";
import { useSkuSalesHistory, useSalesHistoryPagination } from "features/sales";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

export const SalesHistory = ({ gradeId, skuId }) => {
  const [searchParams] = useSearchParams();
  const original_box = searchParams.get("original_box");

  const { total, page, pageSize, setPage, setPageSize } =
    useSalesHistoryPagination();
  const { history, isLoadingHistory, isFetchingHistory } = useSkuSalesHistory({
    grade_id: gradeId,
    sku_id: skuId,
    original_box,
  });

  return (
    <>
      <h3 className="text-center mt-24">Sales History</h3>
      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4 mb-16"
        rowClassName={record =>
          record.order?.cancel ? "bg-rose-50 text-red-400" : ""
        }
        dataSource={history?.data}
        rowKey={record => record.id}
        loading={isLoadingHistory || isFetchingHistory}
        footer={() => (
          <Pagination
            className="text-center m-0 p-0"
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            pageSizeOptions={[4, 8, 12, 24, 48]}
            showSizeChanger
            defaultCurrent={1}
            total={total}
            current={page}
            pageSize={pageSize}
            onChange={setPage}
            disabled={isLoadingHistory || isFetchingHistory}
          />
        )}
        pagination={false}
        columns={[
          {
            title: "Marketplace",
            key: "marketplace",
            dataIndex: ["marketplace", "marketplace"],
          },
          {
            title: "Price",
            align: "center",
            key: "price",
            dataIndex: "price",
            render: price => (
              <span className="whitespace-nowrap">{`€ ${price}`}</span>
            ),
          },

          {
            title: "Quantity",
            align: "center",
            key: "quantity",
            dataIndex: "quantity",
            render: (quantity, record) => {
              const tooltip = record.products?.map(({ id }) => (
                <Link
                  key={id}
                  to={`/devices/${id}`}
                  target="_blank"
                  className="mx-1 whitespace-nowrap"
                >
                  {id}
                </Link>
              ));

              return (
                <Tooltip
                  title={tooltip}
                  className="cursor-pointer"
                  color="#EEE"
                  mouseEnterDelay={0.2}
                >
                  <div>{quantity}</div>
                </Tooltip>
              );
            },
          },
          {
            title: "Order",
            key: "order",
            align: "center",
            dataIndex: "order",
            render: order => {
              if (!order) return "";
              return (
                <Link to={order?.order_link} target={"_blank"}>
                  {order?.order_id}
                </Link>
              );
            },
          },
          {
            title: "Country",
            key: "country",
            align: "center",
            dataIndex: "order",
            render: order => (
              <div className="flex items-center gap-1.5 justify-center text-slate-600">
                {order?.country_code && (
                  <ReactCountryFlag countryCode={order?.country_code} svg />
                )}
                {order?.country_code || ""}
              </div>
            ),
          },
          {
            title: "Date",
            align: "center",
            key: "date",
            dataIndex: "date",
            render: date => (
              <span className="whitespace-nowrap">
                {dayjs(date).format("YYYY-MM-DD H:mm")}
              </span>
            ),
          },
        ]}
        scroll={{ x: 600 }}
      />
    </>
  );
};
