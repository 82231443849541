import { Empty, Select, Spin } from "antd";
import { useEmployees } from "features/employees";
import { useEffect, useMemo } from "react";

export const EmployeesFilter = ({ store }) => {
  const { employees, isLoading } = useEmployees();

  const employeesOptions = useMemo(() => {
    return employees
      ? employees.data.map(employee => ({
          label: employee.first_name + " " + employee.last_name,
          value: employee.id,
        }))
      : [];
  }, [employees]);

  return (
    <div>
      <label>Employee</label>
      <Select
        showSearch
        className="w-full mt-1"
        placeholder="Select Employee"
        value={store?.filter_by.user_id}
        onChange={e => store?.setFilter({ user_id: e })}
        allowClear
        options={employeesOptions}
        filterOption={(searchValue, { label }) =>
          label?.toLowerCase().includes(searchValue?.toLowerCase())
        }
        notFoundContent={
          isLoading ? (
            <div className="text-center">
              <Spin size="small" />
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
      />
    </div>
  );
};
