import { Table, Pagination, Tooltip } from "antd";
import { CheckCircleOutlined, GiftFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  useInSaleDevices,
  DevicesFilter,
  useInSaleStore,
} from "features/devices";
import { SearchInput } from "components";
import { SKULink } from "features/sales";
import dayjs from "dayjs";
import { useMemo } from "react";

export const OnSaleDevices = () => {
  const store = useInSaleStore();
  const {
    searchQuery,
    setSearchQuery,
    setPageSize,
    setPage,
    total,
    page,
    pageSize,
    sorting,
    setSorting,
  } = store;

  const { devices, isLoading, isFetching } = useInSaleDevices();

  const columns = useMemo(() => {
    return [
      {
        title: "SKU",
        key: "sku",
        width: 100,
        render: (_, record) => <SKULink device={record} />,
      },
      {
        title: "Type",
        key: "type",
        dataIndex: ["device_type", "name"],
        align: "center",
      },
      {
        title: "Device",
        key: "device",
        align: "center",
        render: (text, { color, field_values, product_id }) => {
          const model =
            Object.values(field_values).find(
              field => field.device_field.device_field_name === "Model",
            )?.device_field.device_field_option[0]?.value || "";

          const memory =
            Object.values(field_values).find(
              field => field.device_field.device_field_name === "Memory",
            )?.device_field.device_field_option[0]?.value || "";

          return (
            <Link
              to={`/devices/${product_id}`}
            >{`${model} ${memory} ${color?.name}`}</Link>
          );
        },
        width: "30%",
      },
      {
        title: "Date",
        key: "sort_by_sale_date",
        align: "center",
        render: (_, record) => {
          return (
            <span>
              {record.in_sale_date
                ? `${dayjs(record.in_sale_date).format("YYYY-MM-DD HH:mm")}`
                : ""}
            </span>
          );
        },
        width: 160,
        sorter: true,
        sortOrder: sorting.sort_by_sale_date,
        showSorterTooltip: false,
      },
      {
        title: "WE Nr",
        key: "WE",
        align: "center",
        render: (_, { invoice }) => (
          <span>{invoice.invoice_number + invoice.supplier.supplier_nr}</span>
        ),
      },
      {
        title: (
          <Tooltip title="Original Box">
            <GiftFilled />
          </Tooltip>
        ),
        key: "original_box",
        width: 60,
        align: "center",
        render: (_, { original_box }) =>
          original_box ? (
            <CheckCircleOutlined className="text-green-500" />
          ) : null,
      },
    ];
  }, [sorting]);

  return (
    <div className="page-container flex flex-col relative">
      <h2 className="text-center">Sales Control by Devices</h2>

      <div className="flex justify-between w-full mb-3 gap-2">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <DevicesFilter
          fixedStatus
          store={store}
          isLoading={isLoading || isFetching}
          disabled={isLoading}
        />
      </div>

      <Table
        className="overflow-y-auto"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={devices?.data}
        sticky={true}
        rowKey={record => record.product_id}
        pagination={false}
        scroll={{ x: 820 }}
        onChange={(pag, f, { columnKey, order }) => {
          setSorting(columnKey, order);
        }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={total}
        showTotal={false}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};
