import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";
import { useDashboardStore } from "features/dashboard";
import { useDebounce } from "use-debounce";
import _ from "lodash";

const getDashboardProducts = ({ per_page = 12, page = 1, filter_by = null }) =>
  apiClient.get("api/dashboard/products", {
    params: {
      per_page,
      page,
      filter_by,
    },
  });

export const useDashboardProducts = ({
  onSuccess,
  onError,
  fixedFilter = {},
} = {}) => {
  const { page, pageSize, setPage, setTotal, searchQuery, filter_by } =
    useDashboardStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: devices,
    isLoading,
    isFetching,
  } = useQuery(
    ["dashboard-products", page, pageSize, debouncedQuery, filter_by],
    () =>
      getDashboardProducts({
        page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        filter_by: _.isEmpty(filter_by) ? null : _.omit(filter_by, "color"),
        fixedFilter,
      }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
    },
  );

  return { devices, isLoading, isFetching };
};
