import { LogoAmazon } from "./LogoAmazon";
import { LogoVoelkner } from "./LogoVoelkner";
import { LogoBackmarket } from "./LogoBackmarket";
import { LogoConrad } from "./LogoConrad";
import { LogoEbay } from "./LogoEbay";
import { LogoIdealo } from "./LogoIdealo";
import { LogoMediamarkt } from "./LogoMediamarkt";
import { LogoRefurbed } from "./LogoRefurbed";
import { LogoShopify } from "./LogoShopify";

export const MarketplaceIcon = ({ width = "100%", height, name }) => {
  if (name?.toLowerCase() === "idealo")
    return <LogoIdealo width={width} height={height} />;

  if (name?.toLowerCase() === "ebay")
    return <LogoEbay width={width} height={height} />;

  if (name?.toLowerCase() === "backmarket")
    return <LogoBackmarket width={width} height={height} />;

  if (name?.toLowerCase() === "refurbed")
    return <LogoRefurbed width={width} height={height} />;

  if (name?.toLowerCase() === "amazon") {
    return <LogoAmazon width={width} height={height} />;
  }

  if (name?.toLowerCase() === "shopify") {
    return <LogoShopify width={width} height={height} />;
  }

  if (name?.toLowerCase() === "mediamarkt") {
    return <LogoMediamarkt width={width} height={height} />;
  }

  if (name?.toLowerCase() === "conrad") {
    return <LogoConrad width={width} height={height} />;
  }

  if (name?.toLowerCase() === "voelkner") {
    return <LogoVoelkner width={width} height={height} />;
  }

  return null;
};
