import { Alert, Form, Input } from "antd";
import { useEffect, useRef } from "react";
import React from "react";

export const RegistrarForm = ({
  form,
  className = "",
  onSubmit,
  deviceFields,
  disabled,
}) => {
  const imeiRef = useRef(null);
  const serialRef = useRef(null);

  useEffect(() => {
    if (!!imeiRef.current) {
      imeiRef.current?.focus({
        cursor: "start",
      });
    } else {
      serialRef.current?.focus({
        cursor: "start",
      });
    }
  }, []);

  const imeiId = deviceFields?.find(field => field.name === "IMEI")?.id;
  const serialId = deviceFields?.find(field => field.name === "Serial")?.id;

  const onFinish = data => {
    if (!(imeiId || serialId)) return onSubmit(false);

    const field_values = [];
    if (imeiId)
      field_values.push({ device_field_id: imeiId, value: data.IMEI });
    if (serialId)
      field_values.push({ device_field_id: serialId, value: data.Serial });

    onSubmit({ field_values });
  };

  const onIMEIPressEnter = () => {
    if (serialRef.current) {
      serialRef.current.focus({ cursor: "start" });
    } else {
      form.submit();
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={className}
      onFinish={onFinish}
      disabled={disabled}
    >
      {!(imeiId || serialId) && (
        <Alert
          type="error"
          showIcon
          message="Device has no IMEI or Serial"
          className="mb-6"
        />
      )}

      {imeiId && (
        <Form.Item name="IMEI" label="IMEI" rules={[{ required: true }]}>
          <Input ref={imeiRef} onPressEnter={onIMEIPressEnter} />
        </Form.Item>
      )}

      {serialId && (
        <Form.Item
          name="Serial"
          label="Serial Number"
          rules={[{ required: true }]}
        >
          <Input ref={serialRef} onPressEnter={form.submit} />
        </Form.Item>
      )}
    </Form>
  );
};
