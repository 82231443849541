import {
  ImportInvoice,
  useInvoices,
  useInvoicesStore,
  InvoicesTable,
  InvoicesPagination,
} from "features/invoices";
import { DateRangePicker, SearchInput } from "components";
import { useAuthStore } from "features/auth";

export const Invoices = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin", "registrar"]);
  const { invoices, isLoadingInvoices } = useInvoices();
  const store = useInvoicesStore();

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-center">Invoices</h2>

      <div
        className="flex justify-between max-w-5xl w-full mx-auto px-2 mb-3
                  gap-1 sm:flex-row sm:gap-2 sm:px-4"
      >
        <SearchInput
          searchQuery={store.search_term}
          setSearchQuery={store.setSearchTerm}
        />

        <DateRangePicker
          className="mr-auto"
          onRangeChange={(_, dateStrings) => {
            store.setStartDate(dateStrings[0]);
            store.setEndDate(dateStrings[1]);
          }}
        />

        {rightRole && <ImportInvoice />}
      </div>

      <InvoicesTable data={invoices} loading={isLoadingInvoices} />

      <InvoicesPagination controller={store} />
    </div>
  );
};
