import { apiClient } from "lib/api-client";
import { useQuery } from "react-query";
import { globalErrorHandler } from "lib/react-query-client";

const getListInfo = id => apiClient.get(`api/sending/commission-list/${id}`);

export const useCommissionListInfo = ({ id, onSuccess, onError } = {}) => {
  const { data: info, isLoading: isInfoLoading } = useQuery(
    ["commission-list-info", id],
    () => getListInfo(id),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        globalErrorHandler(err);
        onError?.(err);
      },
      retry: false,
    },
  );

  return { info, isInfoLoading };
};
