import clsx from "clsx";
import { Button, Collapse, List, Tag, Tooltip } from "antd";
import { useEffect, useMemo } from "react";
import {
  CustomerServiceFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { usePackingAccessoriesStore, Device } from "features/devices";
import _ from "lodash";

export const DeviceAccessories = ({ device, className = "" }) => {
  const store = usePackingAccessoriesStore();

  const isPacking = device?.step_of_process === "REGISTERED";
  const isAfterPacking = ![
    "ENTERED",
    "IDENTIFIED",
    "GRADED",
    "GRADE_HOLD",
    "REGISTERED",
  ].includes(device?.step_of_process);

  const accessoriesByCategories = useMemo(
    () => Device.getDeviceAccessoriesByCategories(device),
    [device],
  );

  useEffect(() => {
    if (isPacking || isAfterPacking)
      store.setIds(device.packed_accessories?.map(({ id }) => id));
    else store.reset();
    return store.reset;
  }, [isPacking, isAfterPacking]);

  const isEmptyList = _.isEmpty(accessoriesByCategories);

  const header = (
    <h3
      className={clsx("flex justify-center items-center gap-2 m-0", {
        "text-gray-400": isEmptyList,
        "text-red-400": !isEmptyList,
      })}
    >
      <CustomerServiceFilled />
      {`${isEmptyList ? "No " : ""}Accessories`}
    </h3>
  );

  return (
    <Collapse
      defaultActiveKey={isEmptyList ? null : ["1"]}
      className={className}
      collapsible={isEmptyList ? "disabled" : null}
    >
      <Collapse.Panel key="1" header={header}>
        {Object.entries(accessoriesByCategories).map(([cat, accessories]) => {
          return (
            <List
              key={cat}
              size="small"
              className="w-full mb-1 overflow-hidden"
              header={
                <div className="flex">
                  <Tag color="red" bordered={false} className="font-normal">
                    {cat}
                  </Tag>
                </div>
              }
              bordered
              dataSource={accessories || [""]}
              renderItem={accessory => {
                if (!accessory) return null;

                return (
                  <List.Item
                    className={clsx("min-h-[54px] ", {
                      "active:bg-slate-100": isPacking && accessory.quantity,
                    })}
                    onClick={() => {
                      if (isPacking && accessory.quantity)
                        store.toggleOne(accessory.id);
                    }}
                    extra={
                      isPacking || isAfterPacking ? (
                        <AccessoryCheckbox
                          disabled={!accessory.quantity}
                          asIndicator={isAfterPacking}
                          checked={store.ids.includes(accessory.id)}
                          tooltip={`Available ${accessory.quantity} pcs.`}
                        />
                      ) : null
                    }
                  >
                    <div
                      key={accessory.id}
                      className="flex flex-wrap items-center"
                    >
                      <span className="text-xs font-normal text-slate-800 mr-1">
                        #{accessory.sku} ·
                      </span>

                      <strong className="mr-3">{accessory.name}</strong>
                      {/* <span className="border border-solid rounded px-0.5 ml-1 mr-3 text-xs font-normal border-slate-300 text-slate-600 align-top">
                        {accessory.quantity}
                      </span> */}

                      <span className="text-sx text-slate-500 font-normal text-left italic">
                        {accessory.description}
                      </span>
                    </div>
                  </List.Item>
                );
              }}
            />
          );
        })}
      </Collapse.Panel>
    </Collapse>
  );
};

const AccessoryCheckbox = ({
  checked,
  disabled,
  onToggle,
  tooltip = "",
  asIndicator = false,
}) => {
  if (asIndicator && checked)
    return <CheckCircleFilled className="text-green-600" />;

  if (asIndicator) return null;

  const icon = disabled ? (
    <CheckCircleFilled className="text-gray-200" />
  ) : checked ? (
    <CheckCircleFilled className="text-green-600" />
  ) : (
    <CheckCircleOutlined className="text-slate-500" />
  );

  return (
    <Tooltip title={tooltip}>
      <Button
        shape="circle"
        type="text"
        icon={icon}
        onClick={onToggle}
        disabled={disabled}
      />
    </Tooltip>
  );
};
