import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useComListsStore } from "../store";
import { useDebounce } from "use-debounce";

const getSending = ({
  search_term = null,
  per_page = 12,
  page = 1,
  end_date,
  start_date,
  filter_by,
}) =>
  apiClient.get("api/sending", {
    params: { per_page, page, search_term, end_date, start_date, filter_by },
  });

export const useComLists = () => {
  const {
    page,
    pageSize,
    setPage,
    setTotal,
    searchQuery,
    end_date,
    start_date,
    filter_by,
  } = useComListsStore();
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data: comLists, isLoading: isLoadingComLists } = useQuery(
    [
      "sending-commission-lists",
      page,
      pageSize,
      debouncedQuery,
      end_date,
      start_date,
      filter_by,
    ],
    () =>
      getSending({
        page: page,
        per_page: pageSize,
        search_term: debouncedQuery || null,
        end_date,
        start_date,
        filter_by,
      }),
    {
      onSuccess: sending => {
        const { last_page, total } = sending.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
      },
    },
  );
  return { comLists, isLoadingComLists };
};
