import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Last 3 Days",
    value: [dayjs().add(-3, "d"), dayjs()],
  },
  {
    label: "Last 5 Days",
    value: [dayjs().add(-5, "d"), dayjs()],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];
export const DateRangePicker = ({ onRangeChange, className = "" }) => (
  <RangePicker
    placeholder={["Start Date ", "End Date"]}
    className={className}
    presets={rangePresets}
    onChange={onRangeChange}
  />
);
