import { useState } from "react";
import { Button } from "antd";
import clsx from "clsx";
import { PlusOutlined } from "@ant-design/icons";
import {
  AccessoryCategoriesInput,
  AccessoriesTable,
  AccessoryForm,
  useAccessoriesStore,
  ImportAccessories,
  SyncInventory,
  ExportAccessories,
} from "features/directory";
import { useAuthStore } from "features/auth";
import { SearchInput } from "components";
import { useMediaQuery } from "react-responsive";

export const Accessories = () => {
  const store = useAccessoriesStore();
  const isTightly = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const { isRight } = useAuthStore();
  const userCanReadOnly = !isRight(["admin", "registrar", "head_of_flasher"]);

  const [formState, setFormState] = useState({
    isOpen: false,
    accessory: null,
  });

  const open = (accessory = null) => setFormState({ isOpen: true, accessory });
  const close = () => setFormState({ isOpen: false, accessory: null });

  const activeCategoryFilterStyles =
    "[&_.anticon]:text-red-400 hover:[&_.anticon]:text-red-600";

  return (
    <div className="page-container flex-col relative">
      <h2 className="text-center mb-6 sm:mb-10">Accessories</h2>

      <div className="flex justify-between mb-3 gap-2">
        <SearchInput
          searchQuery={store?.search_term}
          setSearchQuery={store?.setSearchTerm}
        />

        <AccessoryCategoriesInput
          className={clsx(
            "m-0 w-44 mr-auto",
            store.filter_by.category_id && activeCategoryFilterStyles,
          )}
          label=""
          placeholder="Select Category"
          name="category_id"
          onChange={value => store.setFilter({ category_id: value })}
          onClear={() => store.clearFilters()}
        />

        {!userCanReadOnly && (
          <>
            <SyncInventory />
            <ImportAccessories />
            <ExportAccessories />
          </>
        )}

        <Button
          type="primary"
          className={clsx("text-xs sm:text-sm shrink-0", {
            hidden: userCanReadOnly,
          })}
          onClick={() => open()}
          icon={<PlusOutlined />}
          disabled={userCanReadOnly}
        >
          {isTightly ? "" : "New Accessory"}
        </Button>
      </div>

      <AccessoriesTable onEdit={record => !userCanReadOnly && open(record)} />

      {!userCanReadOnly && (
        <AccessoryForm
          isOpen={formState.isOpen}
          onClose={close}
          initialData={formState.accessory}
        />
      )}
    </div>
  );
};
