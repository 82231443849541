import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const updateEbayFormula = (id, data) =>
  apiClient.put(`api/marketplaces/formula/ebay/${id}`, data);

export const useUpdateEbayFormula = ({ onSuccess } = {}) => {
  const { mutate: updateFormula, isLoading: isUpdating } = useMutation(
    ({ id, data }) => updateEbayFormula(id, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("ebay-formulas");
      },
    },
  );

  return { updateFormula, isUpdating };
};
