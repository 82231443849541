import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const duplicateEbay = (id, afterbuy) =>
  apiClient.post(`api/sku/marketplaces/connect-ebay/${id}`, { afterbuy });

export const useEbayDuplicate = ({ onSuccess }) => {
  const { mutate: duplicate, isLoading: isDuplication } = useMutation(
    ({ id, afterbuyKey }) => duplicateEbay(id, afterbuyKey),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("sku-marketplaces");
      },
    },
  );

  return { duplicate, isDuplication };
};
