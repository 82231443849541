import { useInfiniteGrades } from "features/directory";
import { PaginatedSelect } from "components";

export const GradeFilter = ({ store }) => {
  const { setFilter, filter_by } = store;
  const {
    gradeOptions,
    isFetchingNextPage,
    isLoading: isLoadingGradeOptions,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = useInfiniteGrades();

  return (
    <div>
      <label>Grade</label>

      <PaginatedSelect
        className="w-full mt-1"
        value={filter_by.grade_id}
        searchValue={searchQuery}
        isLoading={isLoadingGradeOptions}
        placeholder="Select Grade"
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={setSearchQuery}
        onClear={() => setFilter({ grade_id: null })}
        onChange={grade_id => setFilter({ grade_id })}
        options={gradeOptions}
      />
    </div>
  );
};
