import {
  Row,
  Button,
  Form,
  Input,
  Select,
  Spin,
  message,
  Col,
  App,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useEmployees, useEmployeesStore } from "features/employees";
import {
  useEditDepartment,
  useDepartment,
  useRemoveDepartment,
} from "features/directory";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "components";
import { globalErrorHandler } from "lib/react-query-client";
import { useAuthStore } from "features/auth";

export const EditDepartment = () => {
  const { isRight } = useAuthStore();
  const rightRole = isRight(["admin"]);
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const { departmentId } = useParams();
  const navigate = useNavigate();

  const { setPageSize } = useEmployeesStore();

  const { department, isDepartmentLoading } = useDepartment({
    id: departmentId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { employees, isLoading } = useEmployees();
  const { edit, isEditing } = useEditDepartment(departmentId, () => {
    message.success("Success!");
  });

  const { remove, isRemoving } = useRemoveDepartment(departmentId, () => {
    message.success("Removed");
    navigate("/directory/departments");
  });

  useEffect(() => {
    setPageSize(9999);
    return () => setPageSize(12);
  }, []);

  useEffect(() => {
    if (department) {
      form.setFieldsValue({
        name: department.data.name,
        user_ids: department.data.users.map(user => user.id),
      });
    }
  }, [department]);

  const employeeOptions = useMemo(
    () =>
      employees
        ? employees.data.map(item => ({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
          }))
        : [],
    [employees],
  );

  const onSubmit = data => {
    const reqBody = { name: data.name };
    if (data.user_ids?.length) reqBody.user_ids = data.user_ids;
    edit(reqBody);
  };

  return (
    <div
      style={{
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: "0 20px",
        position: "relative",
        height: "100%",
      }}
    >
      <Row justify="center" style={{ marginBottom: 60 }}>
        {department ? (
          <h2>{`Department: ${department.data.name}`}</h2>
        ) : (
          <h2>{`Department #${departmentId}`}</h2>
        )}
      </Row>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
        style={{ position: "relative" }}
      >
        <Form.Item
          initialValue={department?.data.name}
          label="Department Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input department name!",
            },
          ]}
        >
          <Input
            placeholder="Enter Department Name"
            disabled={isDepartmentLoading || isEditing || isRemoving}
          />
        </Form.Item>

        <Form.Item label="Employees" name="user_ids">
          <Select
            disabled={isDepartmentLoading || isEditing || isRemoving}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Select Employees"
            onChange={() => {
              return;
            }}
            options={employeeOptions}
          />
        </Form.Item>

        {rightRole && (
          <Row justify="center" gutter={12}>
            <Col>
              <Button
                danger
                onClick={() => {
                  modal.confirm({
                    title: "Department Deleting",
                    content: "Are you sure you want to delete the department?",
                    onOk: async () => await remove(),
                  });
                }}
                loading={isRemoving}
                disabled={isDepartmentLoading || isEditing}
              >
                Delete
              </Button>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={isEditing}
                  disabled={isDepartmentLoading || isRemoving}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}

        {isDepartmentLoading && <Loader />}
      </Form>
    </div>
  );
};
