import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import _ from "lodash";
import { LogTypeTag } from "features/logs";
import { useSearchParams } from "react-router-dom";

import { Pagination, Table } from "antd";
import { SearchInput } from "components";
import {
  useLogsSelection,
  useLogs,
  LogsFilter,
  DetailsTable,
} from "features/logs";

export const Logs = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const store = useLogsSelection();
  const { logs, isLoading, isFetching } = useLogs();

  const onExpand = id => {
    if (_.isEqual(expandedRowKeys, [id])) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys([id]);
    }
  };

  useEffect(() => {
    const initialSearchTerm = searchParams.get("search_term");
    if (initialSearchTerm) {
      store.setSearchQuery(initialSearchTerm);
      setSearchParams({});
    }
  }, []);

  return (
    <div className="flex flex-col h-full relative">
      <h2 className="text-center">Log History</h2>

      <div className="flex justify-between max-w-5xl w-full mx-auto px-4 mb-3 gap-2 sm:flex-row">
        <SearchInput
          searchQuery={store.searchQuery}
          setSearchQuery={store.setSearchQuery}
        />

        <LogsFilter store={store} isLoading={isFetching} disabled={isLoading} />
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4 flex-1"
        rowClassName={record => {
          return !!record.properties.attributes &&
            record.properties.action !== "ENTERED"
            ? "hover:cursor-pointer"
            : "";
        }}
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={logs?.data}
        expandable={{
          expandedRowClassName: () => "bg-slate-100",
          expandedRowKeys: expandedRowKeys,
          rowExpandable: record =>
            !!record.properties.attributes &&
            record.properties.action !== "ENTERED",
          expandedRowRender: record => <DetailsTable data={record} />,
          onExpand: (expanded, record) => onExpand(record.id),
        }}
        onRow={record => ({
          onClick: () => onExpand(record.id),
        })}
        sticky={true}
        rowKey={record => record.id}
        pagination={false}
        scroll={{ x: 720 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={store.total}
        showTotal={false}
        current={store.page}
        pageSize={store.pageSize}
        onChange={store.setPage}
        disabled={isLoading || isFetching}
      />
    </div>
  );
};

const columns = [
  {
    title: "When",
    key: "date",
    render: (_, record) => {
      return (
        <span className="text-xs">{`${dayjs(record.created_at).format(
          "YYYY-MM-DD HH:mm",
        )}`}</span>
      );
    },
    width: 140,
    ellipsis: true,
  },
  {
    title: "What",
    key: "description",
    dataIndex: "description",
    render: (__, record) =>
      !!record.properties?.attributes?.id ? (
        <Link to={`/devices/${record.properties?.attributes?.id}`}>
          {normalizeLabel(record.properties?.action || record.description)}
        </Link>
      ) : (
        <span>{normalizeLabel(record.description)}</span>
      ),
  },
  {
    title: "Who",
    key: "causer_by",
    render: (__, { causer_by }) =>
      _.isObject(causer_by) ? (
        <Link to={`/employees/${causer_by.id}`}>
          {`${causer_by.first_name} ${causer_by.last_name}`}
        </Link>
      ) : (
        <span>{causer_by}</span>
      ),
  },
  {
    title: "Type",
    key: "type",
    render: (__, record) => <LogTypeTag name={record.type} />,
  },
];

const normalizeLabel = text =>
  text[0].toUpperCase() + text.replaceAll("_", " ").slice(1);
