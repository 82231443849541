import { useMemo, useState } from "react";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import {
  useAccessoriesStore,
  useAccessoryCategoriesStore,
  useInfAccessoriesStore,
} from "../../store";

const getAccessory = () => apiClient.get("api/directory/devices/accessory");

export const useAccessoryType = ({ onSuccess, onError } = {}) => {
  const { data: accessory, isLoading } = useQuery(
    ["accessory-type"],
    getAccessory,
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );

  return { accessory, isLoading };
};

// CATEGORIES

const getCategories = params =>
  apiClient.get("api/directory/accessories/categories/list", {
    params,
  });

export const useAccessoryCategories = ({ onSuccess, onError } = {}) => {
  const { page, per_page, search_term, setTotal } =
    useAccessoryCategoriesStore();

  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: categories,
    isLoading,
    isFetching,
  } = useQuery(
    ["accessory-categories", page, per_page, debouncedQuery],
    () =>
      getCategories({
        page,
        per_page,
        search_term: debouncedQuery,
      }),
    {
      onSuccess: ({ meta, data }) => {
        setTotal?.(meta.total, meta.last_page);
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );

  return { categories, isLoading, isFetching };
};

const infiniteGetQuery = async ({ pageParam, search_term, filter_by }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term, filter_by },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteAccessoryCategories = ({ onSuccess } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: categories,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["i-accessory-categories", debouncedQuery],
    ({
      pageParam = `${
        import.meta.env.VITE_APP_API_BASE_URL
      }/api/directory/accessories/categories/list?per_page=12&page=1`,
    }) =>
      infiniteGetQuery({
        pageParam,
        search_term: debouncedQuery || null,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      getPreviousPageParam: firstPage => firstPage.next ?? undefined,
      getNextPageParam: lastPage => lastPage.next ?? undefined,
    },
  );

  const categoriesOptions = useMemo(() => {
    const data = categories?.pages?.map(({ data }) => data)?.flat();
    return data?.map(({ id, name }) => ({ value: id, label: name })) || [];
  }, [categories]);

  return {
    categories,
    categoriesOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};

const addCategory = data =>
  apiClient.post("api/directory/accessories/categories/create", data);

export const useCreateAccessoryCategory = ({ onSuccess } = {}) => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => addCategory(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessory-categories"]);
        onSuccess?.(res);
      },
    },
  );

  return { create, isCreating };
};

const editCategory = (id, data) =>
  apiClient.put(`api/directory/accessories/categories/edit/${id}`, data);

export const useEditAccessoryCategory = ({ onSuccess } = {}) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    ({ id, data }) => editCategory(id, data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessory-categories"]);
        onSuccess?.(res);
      },
    },
  );

  return { edit, isEditing };
};

const deleteCategory = id =>
  apiClient.delete(`api/directory/accessories/categories/${id}`);

export const useRemoveAccessoryCategory = ({ onSuccess } = {}) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(
    id => deleteCategory(id),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessory-categories"]);
        onSuccess?.(res);
      },
    },
  );

  return { remove, isRemoving };
};

// ACCESSORIES

const getAccessories = params =>
  apiClient.get("api/directory/accessories/list", {
    params,
  });

export const useAccessories = ({ onSuccess, onError } = {}) => {
  const { page, per_page, search_term, setTotal, filter_by } =
    useAccessoriesStore();

  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: accessories,
    isLoading,
    isFetching,
  } = useQuery(
    ["accessories", page, per_page, debouncedQuery, filter_by],
    () =>
      getAccessories({
        page,
        per_page,
        search_term: debouncedQuery,
        filter_by,
      }),
    {
      onSuccess: ({ meta, data }) => {
        setTotal?.(meta.total, meta.last_page);
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );

  return { accessories, isLoading, isFetching };
};

const exportAccessories = params =>
  apiClient.post(
    "api/directory/accessories/export",
    {},
    {
      params,
    },
  );

export const useExportAccessory = ({ onSuccess } = {}) => {
  const { search_term, filter_by } = useAccessoriesStore();

  const { mutate: startExport, isLoading: isExporting } = useMutation(
    () =>
      exportAccessories({
        search_term,
        filter_by,
      }),
    {
      onSuccess: res => {
        onSuccess?.(res);
      },
    },
  );

  return { startExport, isExporting };
};

export const useInfiniteAccessories = ({ onSuccess } = {}) => {
  const { filter_by, search_term } = useInfAccessoriesStore();

  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: accessories,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["i-accessories", debouncedQuery, filter_by],
    ({
      pageParam = `${
        import.meta.env.VITE_APP_API_BASE_URL
      }/api/directory/accessories/list?per_page=12&page=1`,
    }) =>
      infiniteGetQuery({
        pageParam,
        search_term: debouncedQuery || null,
        filter_by,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      getPreviousPageParam: firstPage => firstPage.next ?? undefined,
      getNextPageParam: lastPage => lastPage.next ?? undefined,
    },
  );

  const accessoryOptions = useMemo(() => {
    const data = accessories?.pages?.map(({ data }) => data)?.flat();
    return data?.map(({ id, name }) => ({ value: id, label: name })) || [];
  }, [accessories, filter_by]);

  return {
    accessories,
    accessoryOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  };
};

const createAccessory = data =>
  apiClient.post("api/directory/accessories/create", data);

export const useCreateAccessory = ({ onSuccess } = {}) => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => createAccessory(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessories"]);
        onSuccess?.(res);
      },
    },
  );

  return { create, isCreating };
};

const editAccessory = (id, data) =>
  apiClient.put(`api/directory/accessories/edit/${id}`, data);

export const useEditAccessory = ({ onSuccess } = {}) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    ({ id, data }) => editAccessory(id, data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessories"]);
        onSuccess?.(res);
      },
    },
  );

  return { edit, isEditing };
};

const deleteAccessory = id =>
  apiClient.delete(`api/directory/accessories/${id}`);

export const useRemoveAccessory = ({ onSuccess } = {}) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(
    id => deleteAccessory(id),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessories"]);
        onSuccess?.(res);
      },
    },
  );

  return { remove, isRemoving };
};

const importAccessories = data =>
  apiClient.post("api/directory/accessories/import", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useImportAccessories = ({ onSuccess } = {}) => {
  const { mutate: upload, isLoading: isImporting } = useMutation(
    data => importAccessories(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessories"]);
        onSuccess?.(res);
      },
    },
  );

  return { upload, isImporting };
};

const syncInventory = data =>
  apiClient.post("api/directory/accessories/import/sync", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useSyncInventory = ({ onSuccess } = {}) => {
  const { mutate: upload, isLoading: isImporting } = useMutation(
    data => syncInventory(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["accessories"]);
        onSuccess?.(res);
      },
    },
  );

  return { upload, isImporting };
};
