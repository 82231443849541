import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";

const getEbayAuthLink = () =>
  apiClient.get("api/directory/marketplaces/ebay/authorization-url");

export const useEbayAuthLink = ({ onSuccess }) => {
  const {
    data: authLink,
    isLoading,
    isRefetching,
    refetch: generateEbayAuthLink,
  } = useQuery(["ebay-auth-link"], getEbayAuthLink, {
    onSuccess: data => {
      onSuccess?.(data);
    },
    enabled: false,
  });
  return {
    authLink,
    generateEbayAuthLink,
    isLoadingAuthLink: isRefetching || isLoading,
  };
};
