import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { useFormulasStore } from "../store";

const getFormulas = ({ per_page = 12, page = 1 }) =>
  apiClient.get("api/marketplaces/formula", {
    params: {
      per_page,
      page,
    },
  });

export const useFormulas = ({ onSuccess, onError } = {}) => {
  const { page, pageSize, setPage, setTotal } = useFormulasStore();
  const {
    data: formulas,
    isLoading,
    isFetching,
  } = useQuery(
    ["marketplaces-formulas", page, pageSize],
    () => getFormulas({ page, per_page: pageSize }),
    {
      onSuccess: data => {
        const { last_page, total } = data.meta;
        if (last_page < page) setPage(last_page);
        setTotal(total);
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );
  return { formulas, isLoading, isFetching };
};
