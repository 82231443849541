import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import _ from "lodash";

export const usePromotionsStore = create(
  devtools(
    immer((set, get) => ({
      filter_by: { active: undefined },
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSearchQuery: searchQuery => set({ searchQuery }),
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldObj => {
        set(state => {
          const [key, value] = Object.entries(fieldObj)[0];
          state.filter_by[key] = value;
        });
      },
      clear: () => {
        set(state => {
          state.filter_by = {};
        });
      },
      reset: () => {
        set(state => {
          state.page = 1;
          state.pageSize = 12;
          state.total = 0;
          state.filter_by = {};
          state.searchQuery = "";
        });
      },
    })),
  ),
);

export const usePromotionsByTypeStore = create(
  devtools(
    immer((set, get) => ({
      page: 1,
      pageSize: 12,
      total: 0,
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      reset: () => {
        set(state => {
          state.page = 1;
          state.pageSize = 12;
          state.total = 0;
        });
      },
    })),
  ),
);

export const usePromotionSKUsStore = create(
  devtools(
    immer((set, get) => ({
      selection: [],
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSelection: (id, checked) =>
        set(state => {
          if (checked) state.selection = _.uniq(state.selection.concat(id));
          else
            _.isArray(id)
              ? _.pullAll(state.selection, id)
              : _.pull(state.selection, id);
        }),
      isSelected: id => get().selection.some(existing => existing === id),
      clearSelection: () =>
        set(state => {
          state.selection = [];
        }),
      setSearchQuery: searchQuery => {
        set(state => {
          state.selection = [];
          state.searchQuery = searchQuery;
        });
      },
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      clear: () => {
        set(state => {
          state.selection = [];
          state.page = 1;
          state.pageSize = 12;
          state.total = 0;
        });
      },
    })),
  ),
);

export const useAvailableSKUsStore = create(
  devtools(
    immer((set, get) => ({
      selection: [],
      filter_by: {},
      sorting: {
        sort_by_sku: null,
        sort_by_price: null,
      },
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSorting: (key, value) =>
        set(state => {
          if (key === "sort_by_sku") state.sorting.sort_by_price = null;
          if (key === "sort_by_price") state.sorting.sort_by_sku = null;

          state.sorting[key] = value;
        }),
      setSelection: (id, checked) =>
        set(state => {
          if (checked) state.selection = _.uniq(state.selection.concat(id));
          else
            _.isArray(id)
              ? _.pullAll(state.selection, id)
              : _.pull(state.selection, id);
        }),
      isSelected: id => get().selection.some(existing => existing === id),
      clearSelection: () =>
        set(state => {
          state.selection = [];
        }),
      setSearchQuery: searchQuery => {
        set(state => {
          state.selection = [];
          state.searchQuery = searchQuery;
        });
      },
      setPageSize: pageSize => set({ pageSize }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldObj => {
        set(state => {
          state.selection = [];
          const [key, value] = Object.entries(fieldObj)[0];

          if (!value) {
            delete state.filter_by[key];
          } else {
            state.filter_by[key] = value;
          }
        });
      },
      getFilterQuery: () => {
        const sortingNamesMap = {
          ascend: "ASC",
          descend: "DESC",
        };

        const result = { ...get().filter_by };
        const sorting = get().sorting;

        for (const key in sorting) {
          if (Boolean(sorting[key])) {
            result[key] = sortingNamesMap[sorting[key]];
            break;
          }
        }
        if ("color" in result) delete result.color;
        if (Object.keys(result).length === 0) return null;
        return result;
      },
      clear: () => {
        set(state => {
          state.filter_by = {};
          // for (const key in state.filter_by) {
          //   if (key !== "device_type_id") delete state.filter_by[key];
          // }
          state.selection = [];
          state.page = 1;
          state.pageSize = 12;
          state.total = 0;
        });
      },
    })),
  ),
);
