import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const editColor = (id, data) =>
  apiClient.put(`api/directory/colors/${id}`, data);

export const useEditColor = (id, onSuccess) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    data => editColor(id, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["colors"]);
        await queryClient.invalidateQueries(["color", id]);
        onSuccess?.();
      },
    },
  );

  return { edit, isEditing };
};
