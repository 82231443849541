import { useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { globalErrorHandler } from "lib/react-query-client";

const getRetourProducts = id =>
  apiClient.get(`api/invoice-return/${id}`, {
    params: { per_page: 9999 },
  });

export const useInvoiceRetourProducts = ({ id, onSuccess, onError } = {}) => {
  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
  } = useQuery(["invoiceRetourProducts", id], () => getRetourProducts(id), {
    onSuccess: res => {
      onSuccess?.(res);
    },
    onError: err => {
      globalErrorHandler(err);
      onError?.(err);
    },
    retry: false,
  });
  return { products, isLoadingProducts, isFetchingProducts };
};
