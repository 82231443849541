import { useMutation, useQuery } from "react-query";
import { apiClient } from "lib/api-client";
import { globalErrorHandler, queryClient } from "lib/react-query-client";

const KEY_SUFFIX = "-marketplace-config";
const ROOT_PATH = "api/directory/marketplaces";

export const useMarketplaceConfigInfo = ({
  onSuccess,
  marketKey,
  onError,
} = {}) => {
  const url = `${ROOT_PATH}/${marketKey}/show`;

  const {
    data: info,
    isLoading: isLoadingInfo,
    isFetching: isFetchingInfo,
    error: infoError,
  } = useQuery([marketKey + KEY_SUFFIX], () => apiClient.get(url), {
    onSuccess: res => onSuccess?.(res),
    onError: err => {
      globalErrorHandler(err);
      onError?.(err);
    },
    retry: false,
  });

  return { info, isLoadingInfo, isFetchingInfo, infoError };
};

export const useSaveMarketplaceConfig = ({ onSuccess, marketKey } = {}) => {
  const url = `${ROOT_PATH}/${marketKey}/save-config`;

  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    data => apiClient.post(url, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(marketKey + KEY_SUFFIX);
      },
    },
  );

  return { saveConfig, isSaving };
};

export const useUpdateMarketplaceConfig = ({ onSuccess, marketKey }) => {
  const url = `${ROOT_PATH}/${marketKey}/update-config`;

  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(
    data => apiClient.put(url, data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(marketKey + KEY_SUFFIX);
      },
    },
  );

  return { updateConfig, isUpdating };
};

export const useRemoveMarketplaceConfig = ({ onSuccess, marketKey }) => {
  const url = `${ROOT_PATH}/${marketKey}/delete`;

  const { mutate: remove, isLoading: isRemoving } = useMutation(
    () => apiClient.delete(url),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries(marketKey + KEY_SUFFIX);
      },
    },
  );

  return { remove, isRemoving };
};
