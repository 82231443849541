import { useEffect } from "react";
import { Alert, App, Button, Card, Form, Input } from "antd";
import _ from "lodash";
import {
  useMarketplaceConfigInfo,
  useSaveMarketplaceConfig,
  useUpdateMarketplaceConfig,
  useRemoveMarketplaceConfig,
} from "features/marketplaces";
import { Loader } from "components";
import { requiredRule } from "constants/validation";

const { TextArea } = Input;
const marketKey = "amazon";

export const AmazonConfig = () => {
  const { modal, message } = App.useApp();
  const [form] = Form.useForm();

  const { info, isLoadingInfo, isFetchingInfo } = useMarketplaceConfigInfo({
    marketKey,
  });

  const { saveConfig, isSaving } = useSaveMarketplaceConfig({
    marketKey,
  });

  const { updateConfig, isUpdating } = useUpdateMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  const { remove, isRemoving } = useRemoveMarketplaceConfig({
    marketKey,
    onSuccess: data => message.success(data.message || "Success"),
  });

  useEffect(() => {
    if (info) {
      form.setFieldValue("client_secret", info.data.client_secret);
      form.setFieldValue("seller_id", info.data.seller_id);
      form.setFieldValue("client_id", info.data.client_id);
      form.setFieldValue("refresh_token", info.data.refresh_token);
    }
    // eslint-disable-next-line
  }, [info]);

  const noConfig = _.isEmpty(info?.data);
  const anyProcessing =
    isSaving || isUpdating || isRemoving || isFetchingInfo || isLoadingInfo;

  return (
    <div className="flex flex-col max-w-2xl w-full mx-auto relative">
      <h2 className="text-center">Amazon</h2>

      {info?.data && noConfig && (
        <Alert
          className="mb-5 mt-6"
          type="error"
          message="First you need create config"
        />
      )}

      {info?.data?.is_connect && (
        <Alert
          className="mb-12 mt-8 "
          type="success"
          showIcon
          message="Connected"
        />
      )}

      {info?.data.is_connect === false && (
        <Alert
          className=" mb-5 mt-6"
          type="error"
          message="Need to update config (has no connection)"
        />
      )}

      <Card title="Config Settings">
        <Form
          form={form}
          requiredMark
          onFinish={noConfig ? saveConfig : updateConfig}
          disabled={anyProcessing}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item label="Client ID" name="client_id" rules={[requiredRule]}>
            <TextArea placeholder="Enter Client ID" autoSize />
          </Form.Item>

          <Form.Item label="Seller ID" name="seller_id" rules={[requiredRule]}>
            <TextArea placeholder="Enter Client ID" autoSize />
          </Form.Item>

          <Form.Item
            label="Client Secret"
            name="client_secret"
            rules={[requiredRule]}
          >
            <TextArea placeholder="Enter Client ID" autoSize />
          </Form.Item>

          <Form.Item
            label="Refresh Token"
            name="refresh_token"
            rules={[requiredRule]}
          >
            <Input placeholder="Enter Refresh Token" type={"password"} />
          </Form.Item>

          <div className="flex justify-end gap-2">
            {noConfig ? (
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Create
              </Button>
            ) : (
              <>
                <Button
                  danger
                  loading={isRemoving}
                  onClick={() => {
                    modal.confirm({
                      title: "Config Deleting",
                      content: "Are you sure you want to delete the config?",
                      onOk: () => remove(),
                    });
                  }}
                >
                  Delete
                </Button>

                <Button htmlType="submit" loading={isUpdating}>
                  Update
                </Button>
              </>
            )}
          </div>

          {(isLoadingInfo || isFetchingInfo) && <Loader />}
        </Form>
      </Card>
    </div>
  );
};
