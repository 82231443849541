import { useParams, useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import { Table, Pagination, Button, message, Tag } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import {
  useCommissionListInfo,
  useSending,
  Approve,
  ExportComLIst,
  useCommissionListAccessories,
  useAccessoryComListStore,
  comListAccessoryConfig,
  SendingAccessoriesFilter,
} from "features/sending";
import MediaQuery from "react-responsive";
import { SearchInput } from "components";
import { useMemo } from "react";
import _ from "lodash";

export const CommissionListAccessory = () => {
  const navigate = useNavigate();
  const { accessoryCommissionListId } = useParams();
  const comListSelection = useAccessoryComListStore();

  const { info } = useCommissionListInfo({ id: accessoryCommissionListId });

  const { products, isProductsLoading, isProductsFetching } =
    useCommissionListAccessories({
      id: accessoryCommissionListId,
      onError: err => {
        if (err.response?.status === 404) navigate("/error");
      },
    });

  const { send, isSending } = useSending({
    listId: accessoryCommissionListId,
    onSuccess: response => {
      if (
        response.message ===
        "You haven’t scanned all devices from the list. You need approval from the admin."
      ) {
        message.warning(response.message);
      } else {
        message.success(response.message || "Success");
      }
    },
  });

  const idle = isProductsLoading || isProductsFetching || isSending;

  const status = useMemo(() => {
    if (info?.data?.status === "PACKED") {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Packed
        </Tag>
      );
    }

    if (!!info?.data?.waiting_for_approve) {
      return (
        <Tag icon={<ClockCircleOutlined />} color="warning">
          approval
        </Tag>
      );
    }

    if (info?.data?.status === "PACKING" && !info?.data?.waiting_for_approve) {
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          packing
        </Tag>
      );
    }

    return null;
  }, [info]);

  return (
    <div className="flex flex-col h-full relative">
      <h2 className="mb-6 sm:mb-10 flex justify-center items-center gap-2">
        Commission List #{accessoryCommissionListId} {status}
      </h2>

      <div className="flex justify-end max-w-5xl w-full mx-auto px-4 mb-3 gap-2">
        <MediaQuery minWidth={500}>
          <div className="mr-auto">
            <SearchInput
              searchQuery={comListSelection.searchQuery}
              setSearchQuery={comListSelection.setSearchQuery}
            />
          </div>
        </MediaQuery>

        <ExportComLIst
          isAccessory
          disabled={isProductsLoading}
          id={accessoryCommissionListId}
        />

        <SendingAccessoriesFilter
          store={comListSelection}
          disabled={isProductsLoading}
          isLoading={idle}
        />

        {info?.data?.status === "PACKED" ? null : info?.data
            ?.waiting_for_approve ? (
          <Approve listId={accessoryCommissionListId} disabled={idle} />
        ) : (
          <Button
            className="text-xs sm:text-sm"
            type="primary"
            disabled={isProductsLoading || isProductsFetching}
            onClick={send}
            loading={isSending}
          >
            Send
          </Button>
        )}
      </div>

      <Table
        className="overflow-y-auto max-w-5xl w-full mx-auto px-4"
        loading={idle}
        columns={comListAccessoryConfig}
        dataSource={products?.data}
        sticky={true}
        rowKey={() => _.uniqueId()}
        pagination={false}
        scroll={{ x: 720 }}
      />

      <Pagination
        className="text-center py-2 mt-auto"
        onShowSizeChange={(_, pageSize) =>
          comListSelection.setPageSize(pageSize)
        }
        pageSizeOptions={[4, 8, 12, 24, 48]}
        showSizeChanger
        defaultCurrent={1}
        total={comListSelection.total}
        current={comListSelection.page}
        pageSize={comListSelection.pageSize}
        onChange={comListSelection.setPage}
        disabled={comListSelection.idle}
      />
    </div>
  );
};
