import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";
import { useNavigate } from "react-router-dom";

const removeColor = id => {
  return apiClient.delete(`api/directory/colors/${id}`);
};

export const useRemoveColor = (id, onSuccess) => {
  const navigate = useNavigate();

  const { mutate: remove, isLoading: isRemoving } = useMutation(
    () => removeColor(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["colors"]);
        navigate("/directory/colors");
        onSuccess?.();
      },
    },
  );

  return { remove, isRemoving };
};
