import { useMemo, useState } from "react";
import { apiClient } from "lib/api-client";
import { useInfiniteQuery } from "react-query";
import { useDebounce } from "use-debounce";

const infiniteGetQuery = async ({ pageParam, search_term, filter_by }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term, filter_by },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteMarketsData = ({ onSuccess } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const { data, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery(
      ["i-marketplace-data", debouncedQuery],
      ({
        pageParam = `${
          import.meta.env.VITE_APP_API_BASE_URL
        }/api/sku/marketplaces/data?per_page=12&page=1`,
      }) =>
        infiniteGetQuery({
          pageParam,
          search_term: debouncedQuery || null,
        }),
      {
        onSuccess: data => {
          onSuccess?.(data);
        },
        getPreviousPageParam: firstPage => firstPage.next ?? undefined,
        getNextPageParam: lastPage => lastPage.next ?? undefined,
      },
    );

  const dataOptions = useMemo(() => {
    const flatData = data?.pages?.map(({ data }) => data)?.flat();
    return flatData?.map(({ id, sku }) => ({ value: id, label: sku })) || [];
  }, [data]);

  return {
    data,
    dataOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  };
};
