import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const removeSKU = id => apiClient.delete(`api/directory/skus/${id}`);

export const useRemoveSKU = (id, onSuccess) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(
    () => removeSKU(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["skus"]);
        onSuccess?.();
      },
    },
  );

  return { remove, isRemoving };
};
