import { apiClient } from "lib/api-client";
import { useMutation } from "react-query";
import { queryClient } from "lib/react-query-client";

const removeDeviseFromSale = id => {
  return apiClient.post(`api/product/remove-from-sale/${id}`);
};

export const useRemoveDeviceFromSale = ({ id, onSuccess } = {}) => {
  const { mutateAsync: remove, isLoading: isRemoving } = useMutation(
    async () => await removeDeviseFromSale(id),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(["devices"]);
        await queryClient.invalidateQueries(["device", id]);
        queryClient.invalidateQueries(["activities", id]);
        onSuccess?.(data);
      },
    },
  );

  return { remove, isRemoving };
};
