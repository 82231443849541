import { Form, Modal, Input } from "antd";
import { useNewPassword } from "features/employees";

export const NewPassword = ({
  isOpen,
  onOk,
  onCancel,
  employee,
  employeeId,
}) => {
  const [passwordForm] = Form.useForm();
  const { setPassword, isSettingPassword } = useNewPassword({
    employeeId,
    onSuccess: () => {
      passwordForm.resetFields();
      onCancel();
    },
  });

  return (
    <Modal
      title="Create New Password"
      confirmLoading={isSettingPassword}
      centered
      open={isOpen}
      onOk={passwordForm.submit}
      onCancel={() => {
        passwordForm.resetFields();
        onCancel();
      }}
    >
      <Form
        form={passwordForm}
        disabled={isSettingPassword}
        layout="vertical"
        requiredMark={false}
        onFinish={val => {
          const query = { ...val };
          delete query.repeatPassword;

          const user = {
            ...employee.data,
            role: employee.data.roles,
            department: employee.data.departments.map(dep => dep?.id),
          };
          delete user.roles;
          delete user.departments;
          setPassword({ ...query, ...user });
        }}
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            { pattern: /.{8,}/, message: "Minimum 8 symbols" },
            {
              pattern: /[a-z]/,
              message: "At least one lower case letter",
            },
            {
              pattern: /[A-Z]/,
              message: "At least one upper case letter",
            },
            {
              pattern: /\W/,
              message: "At least one symbol",
            },
            {
              pattern: /[\d]/,
              message: "At least one number",
            },
          ]}
        >
          <Input.Password placeholder="Enter Password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="repeatPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!"),
                );
              },
            }),
          ]}
          style={{
            margin: 0,
          }}
        >
          <Input.Password placeholder={"Confirm Password"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
