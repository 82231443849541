import {
  ColorConstructor,
  useColor,
  useEditColor,
  useFormConstructor,
  useRemoveColor,
} from "features/directory";
import { Loader } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { App, Button, message } from "antd";
import { globalErrorHandler } from "lib/react-query-client";
import { useAuthStore } from "features/auth";

export const ColorType = () => {
  const { isRight } = useAuthStore();
  const isAdmin = isRight(["admin"]);
  const rightRole = isRight(["admin", "registrar", "head_of_flasher"]);
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const { colorId } = useParams();

  const { color, isLoading } = useColor({
    id: colorId,
    onError: err => {
      globalErrorHandler(err);
      if (err.response?.status === 404) navigate("/error");
    },
  });

  const { edit, isEditing } = useEditColor(colorId, () => {
    navigate(`/directory/colors`);
    message.success("Success!");
  });

  const { remove, isRemoving } = useRemoveColor(colorId, () =>
    message.success("Removed!"),
  );

  const { validate, fields } = useFormConstructor();

  const buildRequest = () => {
    const newValue = [...fields];
    const request = {
      name: newValue[0].name,
      shades: newValue[1].options?.map(shade => ({
        name: shade.value,
        id: shade.id,
      })),
      possible_names: newValue[2].options?.map(possible_name => ({
        name: possible_name.value,
        id: possible_name.id,
      })),
    };
    return request;
  };

  return (
    <div className="relative flex flex-col h-full">
      <h2 className="text-center">
        Color Type: {color?.data?.name || colorId}
      </h2>

      <div className="overflow-y-auto flex-2">
        <div className="max-w-md my-12 mx-auto flex flex-col px-7 sm:px-0">
          {color && (
            <>
              <ColorConstructor
                value={color?.data}
                disabled={isLoading || isEditing || isRemoving}
              />

              {rightRole && (
                <div className="flex gap-2">
                  <Button
                    className="flex-1"
                    danger
                    onClick={() => {
                      modal.confirm({
                        title: "Device Removing",
                        content: "Are you sure you want to delete the device?",
                        onOk: () => remove(),
                      });
                    }}
                    loading={isRemoving}
                    disabled={isLoading || isEditing || !isAdmin}
                  >
                    Delete
                  </Button>

                  <Button
                    className="flex-1"
                    type="primary"
                    onClick={() => {
                      const isValid = validate();
                      if (isValid) edit(buildRequest());
                    }}
                    loading={isEditing}
                    disabled={isLoading || isRemoving}
                  >
                    Update
                  </Button>
                </div>
              )}
            </>
          )}

          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};
