import { Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

export const AmountBanner = ({ amount }) => {
  return (
    <Tooltip overlayInnerStyle={{ width: 300 }} title={text} placement="left">
      <div className="text-slate-400 absolute -right-11 -top-3">
        <InfoCircleFilled className="text-inherit" />
        <span
          className="text-inherit ml-1 align-middle"
          style={{ fontSize: 10 }}
        >
          {amount} left
        </span>
      </div>
    </Tooltip>
  );
};

const text = (
  <div className="p-3 w-60">
    <h3>Quantity</h3>
    <p>
      By default, the maximum number of products is indicated on all
      marketplaces. After editing the quantity on a certain marketplace (or
      two), the others are billed with a default balance
    </p>

    <h3 className="mt-7">Flows</h3>
    <p className="mb-2">
      1. Default - when the entire quantity is distributed to all marketplaces.
    </p>
    <span>
      <i>Indication</i>: <b>Highlighted in bold font</b>
    </span>

    <p className="mb-2">
      2. Manual - when one quantity is displayed on a certain marketplace, and
      the balance is distributed to others according to the default
    </p>
    <span>
      <i>Indication</i>: Highlighted in regular font
    </span>
  </div>
);
