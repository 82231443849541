import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";

const create = data => apiClient.post(`api/marketplaces/formula`, data);

export const useCreateFormula = ({ onSuccess } = {}) => {
  const { mutate: createFormula, isLoading: isCreating } = useMutation(
    data => create(data),
    {
      onSuccess: data => {
        onSuccess?.(data);
        queryClient.invalidateQueries("marketplaces-formulas");
      },
    },
  );

  return { createFormula, isCreating };
};
