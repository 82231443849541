import { Modal, Pagination, Table } from "antd";
import {
  useSkuReturnedProducts,
  useSkuReturnedProductsStore,
} from "features/sales";
import { returnedProductColumns } from "./productsTableConfig";
import { useEffect } from "react";

export const ReturnedProducts = ({
  onClose,
  sku_id,
  grade_id,
  original_box,
}) => {
  const store = useSkuReturnedProductsStore();
  const { returnedProducts, isFetchingReturned, isLoadingReturned } =
    useSkuReturnedProducts({
      sku_id,
      grade_id,
      original_box,
    });

  useEffect(() => {
    return () => store.setPage(1);
  }, []);

  return (
    <Modal
      open={true}
      onCancel={onClose}
      title={<div className={"text-center mb-4"}>Returned Products</div>}
      footer={
        <Pagination
          className="text-center  mt-auto"
          onShowSizeChange={(_, pageSize) => store.setPageSize(pageSize)}
          pageSizeOptions={[4, 8, 12, 24, 48]}
          showSizeChanger
          defaultCurrent={1}
          total={store.total}
          current={store.page}
          pageSize={store.pageSize}
          simple
          onChange={store.setPage}
          disabled={isLoadingReturned || isFetchingReturned}
        />
      }
    >
      <Table
        loading={isLoadingReturned || isFetchingReturned}
        columns={returnedProductColumns}
        dataSource={returnedProducts?.data}
        pagination={false}
        rowKey={record => record.product_id}
      />
    </Modal>
  );
};
