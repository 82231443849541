export const LogoRefurbed = ({ width = "100%", height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 152 40"
      fill="none"
    >
      <path
        d="M38.6623 12.1765C38.5433 10.7759 38.3095 9.38745 37.9633 8.02524C37.8584 7.83594 37.7451 7.64664 37.6299 7.45735C36.6151 6.46456 35.5204 5.55728 34.3569 4.74472C31.3738 2.65115 27.9874 1.20467 24.4143 0.497851C22.8991 0.199975 21.3608 0.0352902 19.817 0.00567867C19.6489 0.00567867 19.4779 0 19.307 0C19.136 0 18.965 -2.30948e-07 18.7969 0.00567867C18.9707 0.0795044 19.1388 0.158064 19.307 0.240408C19.84 0.497598 20.3578 0.78517 20.858 1.10171C22.9172 2.39417 24.6503 4.14633 25.9217 6.22122C27.1931 8.29611 27.9684 10.6375 28.1869 13.0624C28.1926 13.1391 28.2002 13.2158 28.2049 13.2915C28.2049 13.3076 28.2049 13.3208 28.2049 13.3341C28.2105 13.3994 28.2153 13.4685 28.2181 13.5338C28.2389 13.8499 28.2474 14.1689 28.2474 14.4907C28.2508 16.5746 27.8398 18.6384 27.0383 20.5614C27.0383 20.5718 27.0298 20.5803 27.0279 20.5908C26.797 21.1438 26.5343 21.6829 26.2411 22.2055C26.2099 22.2604 26.1788 22.3162 26.1467 22.3692C26.1145 22.4222 26.0966 22.4582 26.0682 22.5027C25.3726 23.6679 24.3885 24.6334 23.2113 25.3058C22.0341 25.9781 20.7036 26.3346 19.3485 26.3406H19.2598C17.9047 26.3346 16.5742 25.9781 15.397 25.3058C14.2197 24.6334 13.2357 23.6679 12.54 22.5027C13.9787 24.952 16.0494 26.9676 18.5343 28.3377C18.7572 28.4504 18.9839 28.5564 19.2125 28.6539C19.2587 28.677 19.306 28.6979 19.3542 28.7163C19.7273 28.8744 20.1099 29.0201 20.4981 29.146C21.9826 29.6301 23.5341 29.8764 25.0953 29.8758C27.9379 29.8822 30.7209 29.06 33.1053 27.5096C33.2177 27.4386 33.3283 27.3676 33.4388 27.2909C33.6069 27.1754 33.7731 27.0562 33.9328 26.935C34.3267 26.6395 34.7051 26.3235 35.0663 25.9886C35.3298 25.7463 35.5877 25.4935 35.8314 25.2314C36.2984 24.7387 36.7302 24.2137 37.1236 23.6602C38.2107 20.7273 38.7654 17.6231 38.7615 14.4945C38.764 13.712 38.7309 12.9394 38.6623 12.1765Z"
        fill="url(#paint0_linear)"
      ></path>
      <path
        d="M35.1818 27.5408C32.5585 29.3332 29.4581 30.2941 26.2829 30.2989C23.9344 30.3023 21.6151 29.7774 19.4961 28.7627C19.4685 28.7487 19.4405 28.7354 19.4121 28.723C19.38 28.7078 19.3488 28.6917 19.3176 28.6757C19.1023 28.5706 18.8897 28.4599 18.6819 28.3416C16.197 26.9714 14.1263 24.9558 12.6877 22.5065C12.6593 22.462 12.6329 22.4175 12.6093 22.373C12.5856 22.3285 12.546 22.2642 12.5148 22.2093C11.876 21.0283 11.5485 19.7035 11.5632 18.3603C11.5779 17.0171 11.9344 15.6999 12.5989 14.5333C12.6389 14.4552 12.6837 14.3796 12.733 14.3071C13.4368 13.1555 14.424 12.2043 15.6 11.5447C16.7759 10.8851 18.1013 10.5392 19.4489 10.54C19.2912 10.54 19.1306 10.54 18.9766 10.5486C18.8586 10.5486 18.7433 10.5561 18.6243 10.5637H18.5516C17.7297 10.6142 16.9135 10.7342 16.1117 10.9224C15.0805 11.1587 14.0782 11.5079 13.1231 11.9636C12.9994 12.0213 12.8785 12.0819 12.7575 12.1396C12.0086 12.5234 11.2936 12.9702 10.62 13.4751L10.5416 13.5357C8.4883 15.0947 6.88102 17.168 5.88147 19.5472C4.88193 21.9263 4.52537 24.5273 4.84767 27.0884C4.90246 27.5172 4.97614 27.9402 5.0687 28.3633C5.28617 29.3737 5.61238 30.3575 6.04162 31.2974C8.52991 34.3072 11.6542 36.7256 15.1889 38.3781C16.5624 39.0224 17.9902 39.5435 19.4555 39.9351C20.9209 39.5435 22.3487 39.0224 23.7222 38.3781C27.2628 36.7237 30.3924 34.3016 32.8846 31.287C32.8846 31.287 32.8846 31.287 32.8846 31.2823C33.7099 30.2838 34.4601 29.2252 35.1289 28.1153C35.3102 27.8181 35.484 27.5181 35.6493 27.2124C35.4957 27.3253 35.3398 27.4348 35.1818 27.5408Z"
        fill="url(#paint1_linear)"
      ></path>
      <path
        d="M28.1654 14.0438C28.1465 13.8034 28.1229 13.5705 28.0917 13.332C28.0841 13.2563 28.0728 13.1796 28.0624 13.103C27.7139 10.7166 26.7873 8.45218 25.3636 6.50757C23.94 4.56295 22.0626 2.99738 19.8956 1.94772C19.8843 1.94772 19.8767 1.93731 19.8635 1.93257C19.701 1.85307 19.5357 1.7764 19.3667 1.70542C18.8708 1.48691 18.3627 1.2973 17.8449 1.13753C16.6992 0.783762 15.5142 0.572996 14.3169 0.51001C9.22962 1.52861 4.55625 4.0312 0.884138 7.70328C0.492689 9.16736 0.231283 10.6633 0.102975 12.1735C0.0349651 12.937 0.000644378 13.7097 1.46604e-05 14.4915C-0.00347848 17.8013 0.617296 21.0819 1.82966 24.1607C2.24116 25.207 2.72078 26.2251 3.26541 27.2084C3.43071 27.5141 3.60452 27.8141 3.78587 28.1113C3.76226 27.922 3.74337 27.7327 3.73109 27.5434C3.70181 27.1478 3.68575 26.7475 3.68575 26.3443C3.68575 26.2808 3.68575 26.2155 3.68575 26.155C3.71453 23.6241 4.35026 21.1373 5.53932 18.9043C6.72838 16.6713 8.43589 14.7575 10.5179 13.3244C10.5297 13.315 10.542 13.3061 10.5547 13.2979C10.583 13.2771 10.6152 13.2563 10.6492 13.2355L10.7361 13.1768C13.0631 11.6239 15.7627 10.7235 18.5543 10.5692H18.627C18.7461 10.5617 18.8613 10.556 18.9794 10.5541C19.1371 10.5484 19.2977 10.5456 19.4517 10.5456C20.7994 10.5448 22.1248 10.8908 23.3009 11.5503C24.477 12.2099 25.4644 13.161 26.1685 14.3126C26.2175 14.3854 26.2623 14.4609 26.3027 14.5388C26.9666 15.7056 27.3227 17.0228 27.3374 18.3658C27.3521 19.7089 27.0249 21.0336 26.3867 22.2147C26.3575 22.2696 26.3263 22.3227 26.2923 22.3785C26.3244 22.3255 26.3556 22.2696 26.3867 22.2147C26.6799 21.6922 26.9426 21.1531 27.1736 20.6C27.1736 20.5896 27.1811 20.5811 27.184 20.5707C27.7238 19.2037 28.0534 17.7625 28.1616 16.2964V16.2614C28.1909 15.9064 28.2013 15.5477 28.2013 15.1871C28.2019 14.801 28.1899 14.4198 28.1654 14.0438Z"
        fill="url(#paint2_linear)"
      ></path>
      <path
        d="M48.2021 13.9976H50.7525V16.0988C51.801 14.7359 53.4162 13.6853 55.2298 13.6853V16.2692C54.941 16.2085 54.6464 16.18 54.3513 16.184C53.0762 16.184 51.3476 17.1494 50.7525 18.1716V27.7122H48.2021V13.9976Z"
        fill="url(#paint3_linear)"
      ></path>
      <path
        d="M63.7873 13.657C67.8962 13.657 70.4466 16.8656 70.4466 21.068V21.6926H59.6217C59.8201 24.021 61.5203 25.9518 64.269 25.9518C65.7142 25.9518 67.3011 25.3839 68.3213 24.3333L69.5114 26.0086C68.1512 27.3431 66.2243 28.053 64.014 28.053C59.9334 28.053 56.9297 25.1568 56.9297 20.8408C56.9297 16.8656 59.7917 13.657 63.7873 13.657ZM59.5934 19.847H67.9529C67.8962 18.0297 66.6777 15.7582 63.7873 15.7582C61.0386 15.7582 59.7351 17.973 59.5934 19.847Z"
        fill="url(#paint4_linear)"
      ></path>
      <path
        d="M74.2719 16.2407H72.0049V13.9976H74.2719V13.0889C74.2719 10.1359 76.0004 8.48901 78.4374 8.48901C79.3159 8.48901 80.081 8.65938 80.6761 9.00012L80.081 10.9026C79.7092 10.6932 79.289 10.5855 78.8625 10.5902C77.5873 10.5902 76.8222 11.442 76.8222 13.0889V13.9976H79.5993V16.2407H76.8222V27.7121H74.2719V16.2407Z"
        fill="url(#paint5_linear)"
      ></path>
      <path
        d="M91.2731 25.8369C90.2246 26.9726 88.496 28.0516 86.3991 28.0516C83.4804 28.0516 82.0352 26.5751 82.0352 23.6789V13.9973H84.5855V22.828C84.5855 25.0995 85.719 25.781 87.4759 25.781C89.0628 25.781 90.508 24.8724 91.2731 23.8502V13.9973H93.8234V27.7118H91.2731V25.8369Z"
        fill="url(#paint6_linear)"
      ></path>
      <path
        d="M97.876 13.9976H100.426V16.0988C101.475 14.7359 103.09 13.6853 104.904 13.6853V16.2692C104.615 16.2085 104.32 16.18 104.025 16.184C102.75 16.184 101.021 17.1494 100.426 18.1716V27.7122H97.876V13.9976Z"
        fill="url(#paint7_linear)"
      ></path>
      <path
        d="M107.452 8.77295H110.003V15.9851C111.079 14.5086 112.695 13.6568 114.48 13.6568C118.05 13.6568 120.572 16.4679 120.572 20.869C120.572 25.3553 118.022 28.0528 114.48 28.0528C112.638 28.0528 111.023 27.1158 110.003 25.7528V27.7121H107.452V8.77295ZM113.8 25.7812C116.35 25.7812 117.937 23.7368 117.937 20.869C117.937 18.0012 116.35 15.9284 113.8 15.9284C112.241 15.9284 110.683 16.8654 110.003 17.9728V23.7936C110.684 24.901 112.243 25.7812 113.802 25.7812H113.8Z"
        fill="url(#paint8_linear)"
      ></path>
      <path
        d="M129.839 13.657C133.948 13.657 136.498 16.8656 136.498 21.068V21.6926H125.673C125.872 24.021 127.572 25.9518 130.321 25.9518C131.766 25.9518 133.353 25.3839 134.373 24.3333L135.563 26.0086C134.203 27.3431 132.276 28.053 130.066 28.053C125.985 28.053 122.981 25.1568 122.981 20.8408C122.983 16.8656 125.845 13.657 129.839 13.657ZM125.645 19.847H134.005C133.948 18.0297 132.729 15.7582 129.839 15.7582C127.092 15.7582 125.789 17.973 125.647 19.847H125.645Z"
        fill="url(#paint9_linear)"
      ></path>
      <path
        d="M149.45 25.7528C148.43 27.1158 146.786 28.0528 144.972 28.0528C141.402 28.0528 138.852 25.3553 138.852 20.869C138.852 16.4679 141.402 13.6568 144.972 13.6568C146.729 13.6568 148.345 14.5086 149.45 15.9851V8.77295H152V27.7121H149.45V25.7528ZM149.45 17.9444C148.741 16.837 147.211 15.9284 145.653 15.9284C143.102 15.9284 141.515 18.0012 141.515 20.869C141.515 23.7084 143.102 25.7812 145.653 25.7812C147.211 25.7812 148.741 24.901 149.45 23.7936V17.9444Z"
        fill="url(#paint10_linear)"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="23.0815"
          y1="29.8644"
          x2="30.5405"
          y2="2.08506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#3E3F80"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="7370.69"
          y1="14377.7"
          x2="13650.3"
          y2="20982.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#3E3F80"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="12510.7"
          y1="11592"
          x2="4322.83"
          y2="13833.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#3E3F80"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="48.2021"
          y1="20.6987"
          x2="55.2298"
          y2="20.6987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="10304.5"
          y1="7778.3"
          x2="12238.7"
          y2="7778.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="8029.82"
          y1="9817.28"
          x2="8825.84"
          y2="9817.28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="12152.3"
          y1="7619.63"
          x2="13623.4"
          y2="7619.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="8472.15"
          y1="7556.09"
          x2="8995.01"
          y2="7556.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="17072.1"
          y1="9910.13"
          x2="18894.5"
          y2="9910.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="19822.7"
          y1="7778.3"
          x2="21757"
          y2="7778.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="21510.7"
          y1="9910.13"
          x2="23341"
          y2="9910.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3061"></stop>
          <stop offset="1" stopColor="#2F3061"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
