import { useMutation } from "react-query";
import { apiClient } from "lib/api-client";

const remove = id => apiClient.delete(`api/marketplaces/promotion/${id}`, {});

export const usePromoRemoving = ({ onSuccess, promoId } = {}) => {
  const { mutateAsync: removePromo, isLoading: isPromoRemoving } = useMutation(
    () => remove(promoId),
    {
      onSuccess: data => {
        onSuccess?.(data);
        //TODO revalidation
      },
    },
  );

  return { removePromo, isPromoRemoving };
};
